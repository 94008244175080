import React from 'react'
import mayonesas from './img/productosCategorias/mayonesas.png'
import  especias from './img/productosCategorias/especias.png'
import  tes from './img/productosCategorias/tes.png'
import durazno from './img/productosCategorias/durazno-150@3x.png'
import limon from './img/productosCategorias/gela-limon.png'
import mostaza from './img/productosCategorias/mostazabtl.png'
import fresa from './img/productosCategorias/merm-fre.png'
import fondo from './img/productosCategorias/fondo/limon-fresco.png'
import fondo1 from './img/productosCategorias/fondo/fondo-mostaza.png'
import fondo3 from './img/productosCategorias/fondo/fresa.png'




import fondoespecias from './img/productosCategorias/fondo.png'
import producto from './img/productosCategorias/pimienta-limon-1-copy.png'
import adorno from './img/productosCategorias/layer-2.png'
import manzanilla from './img/productosCategorias/manzanilla-51.png'
import adornote from './img/productosCategorias/shutterstock-1015035208-1.png'
import adorno2 from './img/productosCategorias/pimienta.png' 
import adorno3 from './img/productosCategorias/grancuvee-bg.png'




export default[
    {
        "id":1,
        "nombre":"MAYONESAS ",
        "descripcion":"UN SABOR TRADICIONAL CON UN TOQUECITO DE LIMÓN",
        "imagen":mayonesas,
        "imageFondo":""

    },[
        
        {
            "id":2,
            "nombre":"GELATINAS",
            "descripcion":"Su variedad te sorprenderá.",
            "imagen":limon,
            "imagenFondo":fondo

        },
        {
            "id":3,
            "nombre":"MOSTAZA",
            "descripcion":"Un toque especial en cada platillo.",
            "imagen":mostaza,
            "imagenFondo":fondo1

        },
        {
            "id":4,
            "nombre":"MERMELADAS",
            "descripcion":"Cada sabor es una nueva aventura.",
            "imagen":fresa,
            "imagenFondo":fondo3

        }
    ],
    {
        "id":5,
        "nombre":"ESPECIAS",
        "descripcion":"Cambia la percepción de tu paladar.",
        "imagen":[fondoespecias,producto,adorno,adorno2],
        "imageFondo":""

    },
    {
        "id":6, 
         "nombre":"TÉS",
         "descripcion":"Para todo mal, un té. Y para todo bien, también.",
        "imagen":[fondoespecias,manzanilla,adornote,adorno3],
        "imageFondo":""

    }
    
]