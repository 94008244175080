import React ,{PureComponent} from 'react'
import Collapse from 'react-bootstrap/Collapse'
import up from '../../Promociones/img/flecha-hacia-abajo.svg'
import down from '../../Promociones/img/copy.png'
import './index.css'
export default class CollapseComponent extends PureComponent{
    constructor(){
        super()
        this.state={
            open:false
        }
    }
    render(){
        return(
            <div className="container-collapse">
                <div  style={{display:"flex",width:"100%"}}>
                    <label className="collapsible"  >{this.props.titulo}</label>
                    <img src={up} onClick={() => { this.setState({ open: !this.state.open }) }} style={{ transform: this.state.open ? "rotate(180deg)" : "rotate(0deg)", transition: "0.7s", width: "15px", height: "15px", cursor: "pointer" }} />
                </div>
                <Collapse in={this.state.open}>

                    
                    {this.props.html?
                    <pre dangerouslySetInnerHTML={ {__html:this.props.descripcion} } style={{fontFamily:"AvenirBook",position:"relative",top:"1px",whiteSpace:"pre-wrap"}}>
                    </pre>
                :   
                <span style={{whiteSpace:'pre-wrap'}}>{this.props.descripcion}</span> 
                }
                </Collapse>
        </div>
        )
    }
}