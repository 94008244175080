const OBTENER_CATEGORIAS_RELACIONADAS = "OBTENER_CATEGORIAS_RELACIONADAS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_CATEGORIAS_RELACIONADAS:
            return { ...state, categoriasRelacionadas: action.categoriasRelacionadas }

        default:
            return state;
    }

}

export default reducer;