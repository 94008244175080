import axios from 'axios'
import React, { PureComponent,createRef } from 'react';
import { connect } from 'react-redux'
import Swiper from 'react-id-swiper';
import history from '../../history'
import { Helmet } from "react-helmet";


import CollapseComponent from '../MaterialUiCompnents/boostrapCollapse/index'
import './index.css'

class Promociones extends PureComponent {
  constructor() {
    super()
    this.refSwiper = React.createRef();
    this.state = {
      promociones: [],
      displayBtns: false,
      initial: 0,
      festival: "",
      URL: "",
      index:0
  }

  }

  nextprevHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-promociones")[0].children;
    var mySwiper = swiperContainer[1].swiper
    mySwiper.slidePrev();
  }

  nextswiperHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-promociones")[0].children;
    var mySwiper = swiperContainer[1].swiper
    mySwiper.slideNext();
  }

  componentDidMount() {
    this.loadPromociones()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
   
    if (this.state.index !== prevState.index  ) {
      this.setState({
        index:this.state.index
      })
    }
    const swiperContainer1 = document.getElementsByClassName("swiper-promocion")[0].children;
    const swiperContainer2 = document.getElementsByClassName("swiper-promociones")[0].children;
    if (swiperContainer1.length && swiperContainer1[0].swiper && swiperContainer2.length && swiperContainer2[0].swiper) {

      var mySwiper1 = swiperContainer1[0].swiper;
      var mySwiper2 = swiperContainer2[0].swiper;
      mySwiper1.controller.control = mySwiper2;
      mySwiper2.controller.control = mySwiper1;
    }
  }

  loadPromociones = async () => {
    //promociones todas
    if (this.state.promociones) {
      const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/promociones?filter[estatus][_eq]=published&sort[]=orden");
      this.props.dispatch({ type: "OBTENER_PROMOCIONES_TODAS", promociones: result.data.data });
      this.setState({
        promociones: result.data.data
      })
      if(window.location.pathname === "/promociones" || window.location.pathname === "/promociones/" ) {
        history.replace("/promociones/" + result.data.data[0].url)
      }else{
        const PromoIndex = result.data.data.findIndex(st => st.url === window.location.pathname.replace("/promociones/", '') )
        this.setState({
            index: PromoIndex
        })
      }
    
    }

  }
  
  render() {
    const params = {
      on: {
        slideChange: (e) => {
          const swiperref = this.refSwiper.current.children[0];
          const s =  this.state.promociones.find((e,i)=> i === parseInt(swiperref.swiper.realIndex))
          window.history.pushState(null, '', '/promociones/'+s.url);
          this.setState({index:swiperref.swiper.realIndex})
        }
    },
      initialSlide: parseInt(this.state.index),
      speed: 600,
      parallax: true,
      parallaxEl: {
        el: '.parallax-bg',
        value: '-23%'
      },
      slidesPerView: 1,
      spaceBetween: 30,
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      rebuildOnUpdate: true, 
      shouldSwiperUpdate : true
    }
    const params1 = {
      speed: 600,
      parallax: true,
      parallaxEl: {
        el: '.parallax-bg',
        value: '-23%'
      },
      initialSlide:parseInt(this.state.index),
      slidesPerView: 1,
      spaceBetween: 30,
      loop: false,
      rebuildOnUpdate: true, 
      shouldSwiperUpdate : true
    }
    const that = this
    return (
      <>
        <div className="container-promociones">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Promociones, ofertas y descuentos | McCORMICK México</title>
            <meta name="description" content="Promociones, ofertas y descuentos en McCORMICK México: Gana premios increíbles participando en nuestros concursos ¡Conoce más!" />
          </Helmet>
          <div ref={this.refSwiper} className="swiper-promociones">
            {this.state.promociones.length > 0 &&
              <Swiper    {...params} shouldSwiperUpdate>
                {
                  that.state.promociones.map((element, index) => {
                      return (
                        <div key={index} data-history={element.url}  >
                          <div index={index} style={{ width: "100%", position: "relative" }}>
                            <img className='promoBanner' alt={element.alt_imagen} style={{ width: "100%"}} src={process.env.REACT_APP_CMS_ASSETS +element.imagen} ></img>
                          </div>
                        </div>
                      )
                  })
                }
              </Swiper> }
          </div>
          <div style={{ width: "inherit", marginTop: "5%", paddingLeft: "5%", paddingRight: "5%" }} className="swiper-promocion">            
          {
             this.state.promociones.length > 0 &&
                <Swiper {...params1} shouldSwiperUpdate>
                  {
                    this.state.promociones.map((element, index) => {
                      let como_participar_formatted = "";
                      como_participar_formatted = element.como_participar.replace(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g, "<a href='$1://$2$3' target='_blank'>$1://$2$3</a>");
                      return (
                        <div key={index} index={index}>
                           <div className="container-promo-btn" style={{ textAlign: "center" }}>
                         {element.boton_texto &&<a  href={element.boton_url}  target='_blank' className=" btn-promo">{element.boton_texto}</a>}
                          </div>
                          <label style={{ lineHeight: "normal", letterSpacing: "normal" }} className="collapsible-promociones"  > {element.nombre}</label>
                          <CollapseComponent titulo="Cómo participar" descripcion={como_participar_formatted}></CollapseComponent>
                          <CollapseComponent titulo="Vigencia" descripcion={element.vigencia}></CollapseComponent>
                          <CollapseComponent titulo="Bases" html={true} descripcion={element.bases_html} ></CollapseComponent>
                        </div>
                      )
                    })
                  }

                </Swiper> 

            }

          

          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => ({

  data: { ...state, promociones: state.promociones }
});
export default connect(mapStateToProps)(Promociones)

