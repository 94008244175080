import React ,{PureComponent} from  'react'

import history from '../../history'
export default class RecetaView extends PureComponent{
    render(){
        return(
            <>
              <div onClick={() => {window.scrollTo(0,0); history.push("/detalle-receta")}} style={{ width: "33.3%" }} className="card-home">
                    <div style={{ overflow: "hidden" }}>
                        <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={this.props.imagen} />
                    </div>
                    <div style={{ padding: "20px", fontFamily: "Avenir-Medium" }}>
                     {/*
                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{this.props.nombre}</label><br />
                        <label style={{ fontFamily: "AvenirBook" }} ></label>{this.props.descripcion}
                        <br /> <br />
                       
                        <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                            <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                        </div>
                        */}
                    </div>
                </div>
            </>
        )
    }
}