const OBTENER_CATEGORIAS_TAGS = "OBTENER_CATEGORIAS_TAGS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_CATEGORIAS_TAGS:
            return { ...state, categoriaTags: action.categoriaTags }

        default:
            return state;
    }

}

export default reducer;