const OBTENER_RECETAS_TODAS = "OBTENER_RECETAS_TODAS";

const obtenerRecetas = (recetas) => ({ type: OBTENER_RECETAS_TODAS, recetas })

let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_RECETAS_TODAS:
            return { ...state, recetas: action.recetas }

        default:
            return state;
    }

}

export default reducer;