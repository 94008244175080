import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import Fade from 'react-reveal/Fade'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import Swiper from 'react-id-swiper'
import mostazas from './img/mostazas.png'
import saladsmozzas from './img/salads_mozza.png'
import salads from './img/salads.png'
import Tilt from 'react-parallax-tilt'
import timer from '../Resource/iconos/timer.svg'
import { connect } from 'react-redux'
import './index.css'
import closeIcon from './img/shape.svg'
import TePuedeInteresar from '../TePuedeInteresar/index'
import TePuedeInteresarMobile from '../TePuedeInteresarMobile/index'

import Modal from 'react-bootstrap/Modal'
import Timer from './img/timer.svg'
import User from './img/user.svg'
import Horno from './img/horno.svg'
import axios from 'axios'

import walmart from './img/walmart.png'
import comer from './img/comer.png'
import superama from './img/superama.png'
import chedraui from './img/chedraui.png'

import history from '../../history'
import { Redirect } from 'react-router-dom'

const initialState = {
    metatitle: 'McCORMICK México ¡Póngale lo sabroso!',
    metadescription: 'McCORMICK México crea productos con sabores únicos para las familias, colocando en sus despensas mayonesas, especias, tés y más.',
    url: 'https://www.mccormick.mx/',
}

const styles = theme => ({
    root: {
        width: '100%',
        zIndex: 2,
        border: "3px solid rgb(255, 215, 102)",
        width: "-webkit-fill-available"
    },
    detail: {
        width: "inherit"
    }

});

class DetalleRecetas extends PureComponent {
    constructor() {
        super()
        this.state = {
            modal: false,
            recetas: [],
            receta: [],
            metaReceta: initialState,
        }
    }

    handleScrollTop = (e) => {


        const number = document.getElementsByClassName("auto-position")[0].style.top

        const numbertwo = parseInt(number.replace("%", "")) + 100
        document.getElementsByClassName("auto-position")[0].style.top = numbertwo + "px"
        document.getElementsByClassName("auto-position")[0].style.transitionSpeed = "0.5s"

    }

    getRecetasPopulares = () => {
        const recetasPopulares = this.props.data.recetasFavoritas.map((element, index) => {
            if (element.popular) {
                return (
                    <div style={{ border: "0px solid transparent" }} className="card col-4">
                        <div style={{ overflow: "hidden" }}>
                            <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={element.imagen} />
                        </div>
                        <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                            <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
                            <br /> <br />
                            <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                            </div>
                        </div>
                    </div>
                )
            }
        })

        const recetasMobile = this.props.data.recetasFavoritas.map((element, index) => {

            return (
                <div key={index} style={{ maxWidth: "300px", border: "0px solid transparent" }} >
                    <div style={{ overflow: "hidden", width: "300px" }}>
                        <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={element.imagen} />
                    </div>
                    <div style={{ fontFamily: "AvenirNextLTProMedium", width: "300px" }}>
                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px", marginTop: "13px" }}>{element.nombre}</label>
                        <br />
                        <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label>

                        <br /> <br />
                        {/*<div style={{ display: "flex", fontFamily: "AvenirNextLTProMedium", font: "message-box", fontStretch: "extra-expanded"}}>
                                <img src={timer} style={{ width: "20px", height: "20px", marginRight: "11px" }} />{element.tiempo + "  " + element.personas}
                            </div>*/}
                    </div>
                </div>
            )

        })

        this.setState({
            recetasPopulares,
            recetasMobile,
        })

    }

    componentDidMount() {
        //document.getElementsByTagName('video')[0].volume = 0.2;
        this.getReceta()
        this.getRecetasPopulares()
        this.loadRecipes()
        this.peticion()
    }
    getReceta = async () => {
        const Result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas?fields=*.*.*&filter[estatus]=published&filter[url]=" + this.props.match.params.post_id);
        this.setState({
            receta: Result.data.data
        })
    }
    loadRecipes = async () => {
        const Result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas?filter[estatus]=published&sort[]=-id&limit=-1");
        this.props.dispatch({ type: "OBTENER_RECETAS_TODAS", recetas: Result.data.data });
        this.setState({
            recetas: this.props.data.recetas.recetas,
        })
    }
    peticion = async () => {
        let receta = this.props.match.params.post_id;
        const data = (await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas?fields=nombre,metatitle,metadescription,alt_imagenDesk,keywords,alt_imagen,url&filter[estatus]=published&filter[url]=" + receta)).data
        if (data !== undefined) {
            if (data.data.length > 0 && data.data[0].metatitle !== null) {
                const result = data.data[0];
                this.setState({
                    metaReceta: result
                })
            }
            else {
                this.setState({
                    metaReceta: initialState,
                })
            }
        }
        else {
            this.setState({
                metaReceta: initialState,
            })
        }
    }
    render() {

        const paramsSwipeMovProd = {
            slidesPerView: 'auto',
            centeredSlides: false,
            spaceBetween: 5,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },

        }

        return (
            this.state.receta.length > 0 ?
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.metaReceta.metatitle}</title>
                        <meta name="description" content={this.state.metaReceta.metadescription} />
                        <meta name="keywords" content={this.state.metaReceta.keywords} />
                    </Helmet>
                    <section className="detalle-receta-desk">
                        <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "50%", padding: "50px", overflow: "hidden", flex: "50%" }}>
                                {(() => {
                                    return (
                                        <Fade>
                                            <img alt={this.state.metaReceta.alt_imagenDesk} src={process.env.REACT_APP_CMS_ASSETS + this.state.receta[0].imagenDesk.id} className="auto-position" style={{ width: "45%", height: "auto", position: "fixed", top: "140px", maxWidth: "618px" }} />
                                        </Fade>
                                    )
                                })()}



                            </div>
                            <div style={{ width: "50%", flex: "50%" }}>
                                <div style={{ width: "100%", padding: "50px", paddingTop: "69px", paddingBottom: "0", fontFamily: "AvenirBook", flex: "50%" }}>
                                    <label className="ANTOJITOS"><label style={{ cursor: "pointer" }} onClick={() => { window.scrollTo(0, 0); window.scrollTo(0, 0); history.push("/recetas") }}>RECETAS</label> / <label style={{ cursor: "pointer" }} onClick={() => { history.push("/recetas/" + this.state.receta[0].categoria.nombre.toLowerCase()) }}>{this.state.receta[0].categoria.nombre.toUpperCase()}</label></label><br />
                                    <Fade>
                                        <label className="ROLLITO-DE-VEGETALES" >{this.state.receta[0].nombre.toUpperCase()}</label> <br /><br /><br />
                                    </Fade>

                                    <br />

                                    <label className="Sirve-este-excelente">{this.state.receta[0].descripcion}</label>

                                    <hr></hr>

                                    <label className="INGREDIENTES"> INGREDIENTES:</label>

                                    {ReactHtmlParser(this.state.receta[0].Ingredientes)}

                                    <label style={{ color: "black" }} className="INGREDIENTES"> PREPARACIÓN:</label>
                                    {/* <ol className="list-preparacion" type="1"> */}
                                    <div className="list-preparacion">
                                        {
                                            ReactHtmlParser(this.state.receta[0].instrucciones)
                                        }
                                        {/* </ol> */}
                                    </div>
                                    <hr></hr>
                                </div>

                                <div style={{ width: "100%", paddingLeft: "50px" }}>
                                    <div className="prod-util-prin">
                                        <div className="div-color-prod"></div>
                                        <div className=" PARA-ESTA-RECETA-NEC" >
                                            <label>PARA ESTA RECETA NECESITARÁS LOS SIGUIENTES PRODUCTOS</label>
                                        </div>
                                        <div className="productos-swiper">
                                            <Swiper {...paramsSwipeMovProd}>
                                                {this.state.receta[0].productos.map((element, key) => {
                                                    if (element.productos_id != null) {
                                                        return (
                                                            <div style={{ maxWidth: "120px" }}>
                                                                <img onClick={() => { window.scrollTo(0, 0); history.push("/producto/" + element.productos_id.url.toLowerCase().replace(/ /g, "-")) }} src={process.env.REACT_APP_CMS_ASSETS + element.productos_id.imagen} style={{ maxHeight: "213px", maxWidth: "inherit", margin: "5px", cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    }
                                                }
                                                )}
                                                <div style={{ height: "10px", width: "200px" }} ></div>
                                            </Swiper>
                                        </div>


                                    </div>
                                </div>


                            </div>

                        </div>

                        <div style={{ position: "relative", zIndex: 1, background: "#ffffff", width: "100%", height: "130px" }}>

                        </div>

                        <TePuedeInteresar data={this.state.receta[0].categoria.recetas}></TePuedeInteresar>




                    </section>
                    <section className="detalle-receta-mobile">

                        {(() => {
                            if (true) {
                                return (
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={this.state.receta[0].imagenDesk_copy} className="auto-position" style={{ maxWidth: "100%", height: "auto", position: "relative", top: "10px" }} />
                                    </div>
                                )
                            } else {
                                return (
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <video controls style={{ position: "fixed", width: "45%", height: "auto", top: "25%" }} webkit-playsinline="true" autoPlay playsinline="true" id="video-desk" loop="" src="https://herdezstorage.blob.core.windows.net/publicresources/mcCormick/Fruitcake_1920x1080_YouTube.mp4" ><source src="https://herdezstorage.blob.core.windows.net/publicresources/mcCormick/Fruitcake_1920x1080_YouTube.mp4" type="video/mp4"></source> </video>
                                    </div>
                                )
                            }

                        })()}


                        <div style={{ width: "100%", padding: "5.55%", paddingTop: "10px", fontFamily: "AvenirBook" }}>
                            <label className="ANTOJITOS"><label style={{ cursor: "pointer" }} onClick={() => { window.scrollTo(0, 0); window.scrollTo(0, 0); history.push("/recetas") }}>RECETAS</label> / <label style={{ cursor: "pointer" }} onClick={() => { history.push("/recetas/" + this.state.receta[0].categoria.nombre.toLowerCase()) }}>{this.state.receta[0].categoria.nombre.toUpperCase()}</label></label><br />
                            <label className="ROLLITO-DE-VEGETALES" >{this.state.receta[0].nombre}</label>
                            <label className="Sirve-este-excelente">{this.state.receta[0].descripcion}</label>
                            <label className="INGREDIENTES"> INGREDIENTES:</label>


                            {ReactHtmlParser(this.state.receta[0].Ingredientes)}

                            <label style={{ color: "black" }} className="INGREDIENTES"> PREPARACIÓN:</label>
                            {/* <ol className="list-preparacion" type="1"> */}
                            <div className="list-preparacion">
                                {ReactHtmlParser(this.state.receta[0].instrucciones)}
                            </div >
                            {/* </ol> */}
                            <div style={{ background: "#b8e986", width: "100%", marginTop: "50px", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                <div className=" PARA-ESTA-RECETA-NEC col-12" >
                                    <label>PARA ESTA RECETA<br /> NECESITARÁS LOS SIGUIENTES<br /> PRODUCTOS</label>
                                </div>



                            </div>

                        </div>

                        <div style={{ width: "100%", padding: "5.55%", paddingTop: "34px", paddingBottom: "34px", fontFamily: "CocogooseProRegular", fontSize: "16px", background: "#b8e986" }} >
                            PARA ESTA RECETA NECESITARÁS LOS SIGUIENTES PRODUCTOS
                        </div>

                        <div style={{ width: "100%", padding: "5.55%", paddingTop: "10px", fontFamily: "AvenirBook" }} >

                            <div style={{ width: "100%" }} >
                                <Swiper {...paramsSwipeMovProd}>
                                    {this.state.receta[0].productos.map((element, key) => {
                                        if (element.productos_id != null) {
                                            return (
                                                <div style={{ maxWidth: "30%", minWidth: "200px" }}>
                                                    <img onClick={() => { window.scrollTo(0, 0); history.push("/producto/" + element.productos_id.url.toLowerCase().replace(/ /g, "-")) }} src={process.env.REACT_APP_CMS_ASSETS + element.productos_id.imagen} style={{ maxHeight: "261px", margin: "10px", cursor: "pointer" }} />
                                                </div>
                                            )
                                        }
                                    }
                                    )}
                                </Swiper>
                            </div>


                        </div>

                        <div>



                            <TePuedeInteresarMobile data={this.state.receta[0].categoria.recetas}></TePuedeInteresarMobile>
                        </div>

                    </section>


                    <Modal size="lg" show={this.state.modal} onHide={false}>

                        <Modal.Body style={{ borderBottom: "4px solid #d82a57" }}>
                            <img src={closeIcon} onClick={() => { this.setState({ modal: false }) }} style={{ cursor: "pointer", maxWidth: "100%", height: "auto", position: "absolute", zIndex: "1", top: "4%", right: "4%" }}></img>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className="col-12">
                                <label className="donde-comprar" >Dónde comprar</label>
                                <div className="container-tiendas" >
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }} className="container-logo">
                                        <img onClick={() => { window.open("https://nutrisa.com/", "_blank") }} style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }} src={walmart} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }} className="container-logo">
                                        <img onClick={() => { window.open("https://nutrisa.com/", "_blank") }} style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }} src={comer} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }} className="container-logo">
                                        <img onClick={() => { window.open("https://nutrisa.com/", "_blank") }} style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }} src={superama} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }} className="container-logo">
                                        <img onClick={() => { window.open("https://nutrisa.com/", "_blank") }} style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }} src={chedraui} />
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>


                </>
                :
                <div style={{ width: "100%", minHeight: "100vh" }}></div>
        )
    }
}

const mapStateToProps = state => ({

    data: { ...state, recetas: state.recetas }
});

export default connect(mapStateToProps)(DetalleRecetas)
