const OBTENER_SUBCATEGORIAS_POR_CATEGORIAS = "OBTENER_SUBCATEGORIAS_POR_CATEGORIAS";


let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_SUBCATEGORIAS_POR_CATEGORIAS:
            return { ...state, subcategorias: action.subcategorias }

        default:
            return state;
    }

}

export default reducer;