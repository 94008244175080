import React, { PureComponent } from "react"
import { Link } from 'react-router-dom'
import history from '../../history'
import DetoxCSS from './Detox.module.css'
import dataDetox from './DummyDataDetox/dataDetox'
import Parallax from 'parallax-js'
import Swiper from 'react-id-swiper';

import IconTe from './assets/iconTe.svg'
import IconClose from './assets/cerrar.svg'
import IconMostrarMas from './assets/mostrarmas.svg'
import Banner1 from './assets/banner1.jpg'
import Banner2 from './assets/banner2.jpg'
import Banner3 from './assets/banner3.jpg'
import ImgQueEsDetox from './assets/queesdetox.svg'
import ImgVentajasDetox from './assets/ventajasdetox.svg'
import ImgPlanDetox from './assets/plandetox.svg'
import ImgQueEsDetoxMob from './assets/queesdetox_mob.svg'
import ImgVentajasDetoxMob from './assets/ventajasdetox_mob.svg'
import ImgPlanDetoxMob from './assets/plandetox_mob.svg'
import PlanDetox2 from './assets/plandetox2.svg'
import { TeaAnimation } from './AnimTea/index'
import ButtonLiquid from './ButtonLiquid/ButtonLiquid'

const diapositivasDetox = [
    {
        imagenDiap: ImgQueEsDetox,
        imagenDiapMob: ImgQueEsDetoxMob,
        contenido: ContQueEsDetox(),
        menu: [
            {
                texto: "VENTAJAS",
                indexDiap: 1,
            },
            {
                texto: "PLAN DETOX",
                indexDiap: 2,
            },
        ]
    },
    {
        imagenDiap: ImgVentajasDetox,
        imagenDiapMob: ImgVentajasDetoxMob,
        contenido: ContVentajasDetox(),
        menu: [
            {
                texto: "¿QUÉ ES DETOX?",
                indexDiap: 0,
            },
            {
                texto: "PLAN DETOX",
                indexDiap: 2,
            },
        ]
    },
    {
        imagenDiap: ImgPlanDetox,
        imagenDiapMob: ImgPlanDetoxMob,
        contenido: ContPlanDetox(),
        menu: [
            {
                texto: "¿QUÉ ES DETOX?",
                indexDiap: 0,
            },
            {
                texto: "VENTAJAS",
                indexDiap: 1,
            },
        ]
    }
]

class DetoxSection extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedTea: dataDetox[0],
            mostrarDescripcion: false,
            mostrarInformacion: false,
            selectedDiapositive: diapositivasDetox[0]
        }

    }
    myAwesomeCallback = () => {
        // document.getElementById('te-jengibre').style.top = "1vw"
        // document.getElementById('te-jengibre').style.left = "1%"

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        var scene = document.querySelectorAll('.scene-tea');

        var parallaxInstance = new Parallax(scene[0], {
            onReady: this.myAwesomeCallback
        });

        switch (this.props.match.params.tea) {
            case "te-verde":
                this.setState({ selectedTea: dataDetox[1] })
                break;
            case "te-verde-con-mandarina":
                this.setState({ selectedTea: dataDetox[3] })
                break;
            case "te-verde-con-mango":
                this.setState({ selectedTea: dataDetox[2] })
                break;
            default:
                this.setState({ selectedTea: dataDetox[0] })
        }
    }

    selectTea = (teaIndex, name) => {
        history.replace("/productos/tes-sobres/plan-detox/" + name.replace(/ /g, "-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        this.setState({ selectedTea: dataDetox[teaIndex] })
    }

    handleDescripTea = () => {
        this.setState({
            mostrarDescripcion: !this.state.mostrarDescripcion
        })
    }

    handleContentDiap = (index) => {
        this.setState({ selectedDiapositive: diapositivasDetox[index] })
    }

    handleInformacionDetox = (open, index) => {
        if (index !== undefined) {
            this.handleContentDiap(index)
        }
        if (open) {
            this.setState({
                mostrarInformacion: true,
            })
        }
        else {
            this.setState({
                mostrarInformacion: false,
            })
        }
    }

    render() {
        const paramsBanners = {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: false,
            centeredSlides: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true
            }
        }

        return (
            <>
                <div className={DetoxCSS.Container}>
                    <div className={DetoxCSS.TitleSection}>Regálale un Detox a tu cuerpo</div>

                    <div className={DetoxCSS.BoxMenuProductos}>
                        {
                            dataDetox.map((element, index) => {


                                return (
                                    <div onClick={() => { this.selectTea(index, element.producto) }} className={DetoxCSS.BoxProducto}>
                                        <img src={element.iconProducto} />

                                        {this.props.match.params.tea === element.producto.replace(/ /g, "-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ?
                                            <label style={{ fontFamily: "RobotoMedium" }}>{element.producto}</label> : <label>{element.producto}</label>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={DetoxCSS.BoxInfoProductoDesk}>
                        <div className={DetoxCSS.BoxInfoProducto}>
                            <div className={DetoxCSS.DivPreguntas}>
                                <label onClick={() => { this.handleInformacionDetox(true, 0) }}>
                                    <img src={IconTe} /> ¿Qué es Detox?
                                </label>
                                <label onClick={() => { this.handleInformacionDetox(true, 1) }}>
                                    <img src={IconTe} /> Ventajas
                                </label>
                                <label onClick={() => { this.handleInformacionDetox(true, 2) }} style={{ marginBottom: "0" }}>
                                    <img src={IconTe} /> Plan detox
                                </label>

                                <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0) }}>
                                    <button>Ir a tés</button>
                                </div>
                            </div>

                            <div className={DetoxCSS.DivImagenes} >
                                <div className={DetoxCSS.DivImgProducto} >
                                    {
                                        this.state.mostrarDescripcion ? null
                                            :
                                            <ButtonLiquid
                                                onClick={this.handleDescripTea}
                                            />
                                    }

                                    {/*<img className={DetoxCSS.DivImgProductoImg} src={this.state.selectedTea.imagenProducto} />*/}

                                    <TeaAnimation fgColor={this.state.selectedTea.fgColorTe} bgColor={this.state.selectedTea.bgColorTe} />

                                    {
                                        this.state.mostrarDescripcion ?
                                            <>
                                                <div
                                                    style={{
                                                        opacity: this.state.mostrarDescripcion ? '0.62' : '0',
                                                    }}
                                                    className={DetoxCSS.DivDescripcionTe}
                                                />
                                                <div className={DetoxCSS.BoxTxtDesripTea}>
                                                    <img src={IconClose} onClick={this.handleDescripTea} />
                                                    <label>{this.state.selectedTea.producto}</label>
                                                    <text>{this.state.selectedTea.descripcion}</text>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>

                                <div className={DetoxCSS.ImgAbs} className="scene-tea">
                                    <div data-depth-x="0.30" data-depth-y="-0.30" >
                                        <img style={{ animation: "9s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={this.state.selectedTea.imagenIngredientes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={DetoxCSS.BoxInfoProductoIpad}>
                        <div className={DetoxCSS.BoxInfoProducto}>
                            <div className={DetoxCSS.DivImagenes}>
                                <div className={DetoxCSS.DivImgProducto}>
                                    {
                                        this.state.mostrarDescripcion ? null
                                            :
                                            <ButtonLiquid
                                                onClick={this.handleDescripTea}
                                            />
                                    }
                                    {/*<img className={DetoxCSS.DivImgProductoImg} src={this.state.selectedTea.imagenProducto} />*/}
                                    <div style={{ position: "relative", height: "460px" }}>
                                        <TeaAnimation fgColor={this.state.selectedTea.fgColorTe} bgColor={this.state.selectedTea.bgColorTe} />
                                    </div>

                                    <div className={DetoxCSS.ImgAbs}>
                                        <img src={this.state.selectedTea.imagenIngredientes} />
                                    </div>

                                    {
                                        this.state.mostrarDescripcion ?
                                            <>
                                                <div
                                                    className={DetoxCSS.DivDescripcionTe}
                                                    style={{
                                                        opacity: this.state.mostrarDescripcion ? '0.77' : '0'
                                                    }}
                                                />
                                                <div className={DetoxCSS.BoxTxtDesripTea}>
                                                    <img src={IconClose} onClick={this.handleDescripTea} />
                                                    <label>{this.state.selectedTea.producto}</label>
                                                    <text>{this.state.selectedTea.descripcion}</text>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>

                            {
                                this.state.mostrarInformacion ? null
                                    :
                                    <div className={DetoxCSS.DivPreguntas}>
                                        <label onClick={() => { this.handleInformacionDetox(true, 0) }}>
                                            <img src={IconTe} /> ¿Qué es Detox?
                                    </label>
                                        <label onClick={() => { this.handleInformacionDetox(true, 1) }}>
                                            <img src={IconTe} /> Ventajas
                                    </label>
                                        <label onClick={() => { this.handleInformacionDetox(true, 2) }} style={{ marginBottom: "0" }}>
                                            <img src={IconTe} /> Plan detox
                                    </label>

                                        <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0) }}>
                                            <button>Ir a tés</button>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                    <div className={DetoxCSS.BoxInfoProductoMobile}>
                        <div className={DetoxCSS.BoxInfoProducto}>
                            <div className={DetoxCSS.DivImagenes}>
                                <div className={DetoxCSS.DivImgProducto}>
                                    {
                                        this.state.mostrarDescripcion ? null
                                            :
                                            <ButtonLiquid
                                                onClick={this.handleDescripTea}
                                            />
                                    }
                                    {/*<img className={DetoxCSS.DivImgProductoImg} src={this.state.selectedTea.imagenProducto} />*/}
                                    <div style={{ position: "relative", height: "200px" }}>
                                        <TeaAnimation fgColor={this.state.selectedTea.fgColorTe} bgColor={this.state.selectedTea.bgColorTe} />
                                    </div>

                                    <div className={DetoxCSS.ImgAbs}>
                                        <img src={this.state.selectedTea.imagenIngredientes} />
                                    </div>

                                    {
                                        this.state.mostrarDescripcion ?
                                            <>
                                                <div
                                                    className={DetoxCSS.DivDescripcionTe}
                                                    style={{
                                                        opacity: this.state.mostrarDescripcion ? '0.77' : '0'
                                                    }}
                                                />
                                                <div className={DetoxCSS.BoxTxtDesripTea}>
                                                    <img src={IconClose} onClick={this.handleDescripTea} />
                                                    <label>{this.state.selectedTea.producto}</label>
                                                    <text>{this.state.selectedTea.descripcion}</text>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>

                            {
                                this.state.mostrarInformacion ? null
                                    :
                                    <div className={DetoxCSS.DivPreguntas}>
                                        <label onClick={() => { this.handleInformacionDetox(true, 0) }}>
                                            <img src={IconTe} /> ¿Qué es Detox?
                                    </label>
                                        <label onClick={() => { this.handleInformacionDetox(true, 1) }}>
                                            <img src={IconTe} /> Ventajas
                                    </label>
                                        <label onClick={() => { this.handleInformacionDetox(true, 2) }} style={{ marginBottom: "0" }}>
                                            <img src={IconTe} /> Plan detox
                                    </label>

                                        <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0) }}>
                                            <button>Ir a tés</button>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                </div>

                <FloatingDescription onChangeDiap={(index) => { this.handleContentDiap(index) }} isShowing={this.state.mostrarInformacion} diapositiva={this.state.selectedDiapositive} onClose={() => { this.handleInformacionDetox(false) }} />

                <div className={DetoxCSS.EspaciadorBanner} />
                <div className={DetoxCSS.ContainerBanner}>
                    <Swiper {...paramsBanners}>
                        <div>
                            <img src={Banner1} />
                        </div>
                        <div>
                            <img src={Banner2} />
                        </div>
                        <div>
                            <img src={Banner3} />
                        </div>
                    </Swiper>
                </div>
            </>
        )
    }
}

function FloatingDescription({ isShowing, onClose, diapositiva, onChangeDiap }) {
    return (
        <>
            <div className={DetoxCSS.FloatingDescriptionDesk}>
                <div
                    className={DetoxCSS.ContainerInformacionDetox}
                    style={isShowing ? HeightContainerOpen : HeightContainerClosed}
                >
                    <img
                        className={DetoxCSS.IconCerrarPopupDetox}
                        src={IconClose}
                        onClick={() => onClose()}
                    />
                    <div className={DetoxCSS.BoxInformacionDetox}>
                        <div className={DetoxCSS.BoxPreguntas}>
                            <img className={DetoxCSS.ImagenDiapCSS} src={diapositiva.imagenDiap} />

                            {diapositiva.menu.map((element, key) => {
                                return (
                                    <>
                                        <br />
                                        <label key={element.indexDiap} onClick={() => { onChangeDiap(element.indexDiap) }}>
                                            <img src={IconTe} className={DetoxCSS.IconTePopup} /> {element.texto}
                                        </label>
                                    </>
                                )
                            })}

                            <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0); onClose() }}>
                                <button>Ir a tés</button>
                            </div>
                        </div>
                        <div className={DetoxCSS.BoxTexto}>
                            {diapositiva.contenido}
                        </div>
                    </div>
                </div>
            </div>

            <div className={DetoxCSS.FloatingDescriptionIpad}>
                <div
                    className={DetoxCSS.ContainerInformacionDetox}
                    style={isShowing ? HeightContainerOpenIphone : HeightContainerClosed}
                >
                    {
                        isShowing ?
                            <img
                                className={DetoxCSS.IconCerrarPopupDetox}
                                src={IconClose}
                                onClick={() => onClose()}
                            />
                            : null
                    }

                    <div className={DetoxCSS.BoxInformacionDetox}>
                        <div className={DetoxCSS.DivImgTitInfDetox}>
                            <img className={DetoxCSS.ImagenDiapCSS} src={diapositiva.imagenDiapMob} />
                        </div>

                        <div className={DetoxCSS.BoxTexto}>
                            {diapositiva.contenido}
                        </div>

                        <div className={DetoxCSS.BoxPreguntas}>
                            <div className={DetoxCSS.ContainerMenuDiapMob}>
                                {diapositiva.menu.map((element, key) => {
                                    return (
                                        <>
                                            <label key={element.indexDiap} onClick={() => { onChangeDiap(element.indexDiap) }}>
                                                <img src={IconTe} className={DetoxCSS.IconTePopup} /> {element.texto}
                                            </label>
                                        </>
                                    )
                                })}

                                <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0); onClose() }}>
                                    <button>Ir a tés</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={DetoxCSS.FloatingDescriptionMobile}>
                <div
                    className={DetoxCSS.ContainerInformacionDetox}
                    style={isShowing ? HeightContainerOpenMob : HeightContainerClosed}
                >
                    {
                        isShowing ?
                            <img
                                className={DetoxCSS.IconCerrarPopupDetox}
                                src={IconClose}
                                onClick={() => onClose()}
                            />
                            : null
                    }

                    <div className={DetoxCSS.BoxInformacionDetox}>
                        <div className={DetoxCSS.DivImgTitInfDetox}>
                            <img className={DetoxCSS.ImagenDiapCSS} src={diapositiva.imagenDiapMob} />
                        </div>

                        <div className={DetoxCSS.BoxTexto}>
                            {diapositiva.contenido}
                        </div>

                        <div className={DetoxCSS.BoxPreguntas}>
                            <div className={DetoxCSS.ContainerMenuDiapMob}>
                                {diapositiva.menu.map((element, key) => {
                                    return (
                                        <>
                                            <label key={element.indexDiap} onClick={() => { onChangeDiap(element.indexDiap) }}>
                                                <img src={IconTe} className={DetoxCSS.IconTePopup} /> {element.texto}
                                            </label>
                                        </>
                                    )
                                })}

                                <div className={DetoxCSS.DivButtonPreguntas} onClick={() => { history.push("/productos/tes-sobres"); window.scrollTo(0, 0); onClose() }}>
                                    <button>Ir a tés</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function ContQueEsDetox() {
    return (
        <>
            El término <d className={DetoxCSS.FontBlack}>Detox</d> es un proceso en el que alguien se abstiene o elimina del cuerpo sustancias tóxicas o poco saludables. La tendencia a desintoxicarse se ha vuelto muy popular, ya que este proceso puede ayudarte a mejorar diferentes aspectos de tu vida.<br /><br />
            Actualmente vivimos un estilo de vida acelerado, lo que conlleva a una mala alimentación, estrés y cansancio. Muchas veces no podemos reducir el ritmo que llevamos, pero sí podemos ayudar a nuestro organismo a que elimine impurezas, residuos o toxinas. Para esto, hay una opción ideal que le dará a tu cuerpo una sensación de alivio y liberación, tés McCormick: el <d className={DetoxCSS.FontBlack}>té de jengibre-limón y el té verde, con sus deliciosas variedades verde-mango y verde-mandarina</d>, te ayudarán a relajarte y mejorar tu estado físico y anímico, además de ser una manera fácil, práctica y rica para desintoxicarte.
        </>
    )
}

function ContVentajasDetox() {
    return (
        <>
            <d className={DetoxCSS.FontBlack}>TODO LO QUE EL TÉ DE JENGIBRE Y EL TÉ VERDE TIENEN PARA OFRECER</d> <br /><br />
            El té verde tiene antioxidantes y propiedades antiinflamatorias, estimula el metabolismo y regula los niveles de glucosa en la sangre. Por su parte, el de jengibre es uno de los potenciadores del metabolismo, es excelente para desinflamar, mejora la digestión, reduce el colesterol y tiene un efecto relajante.
        </>
    )
}

function ContPlanDetox() {
    return (
        <>
            <d className={DetoxCSS.FontBlack}>PRUEBA UN DÍA D MCCORMICK</d> <br /><br />
            Hoy puede ser el <d className={DetoxCSS.FontMedium}>Día D.</d> De empezar a mejorar hábitos, hacer ejercicio y desintoxicarse con el plan que <d className={DetoxCSS.FontMedium}>McCormick</d> tiene para ti. Sólo <d className={DetoxCSS.FontMedium}>necesitas tomar tres 3 tazas de té al día</d>; una por la mañana para darle energía al cuerpo, una entre comidas que puede servir para reemplazar bebidas azucaradas y otra por la noche para completar y relajarte. Lo mejor es que puedes variar los distintos sabores de té durante el día y prepararlos calientes o fríos si se te antoja algo fresco.<br />
            <br /><div style={{ textAlign: "center" }}>
                <img src={PlanDetox2} className={DetoxCSS.ImgPlanDetox2} /><br /><br />
            </div>
            Este <d className={DetoxCSS.FontMedium}>Detox te ayudará</d> a sentirte más liviano, saludable, lleno de energía y vitalidad. Es una opción sencilla y rápida para tener la mejor versión de ti. Pruébalos y siente la diferencia.
        </>
    )
}

const HeightContainerOpen = {
    height: "650px",
}

const HeightContainerOpenIphone = {
    height: "1280px",
}

const HeightContainerOpenMob = {
    height: "590px",
}

const HeightContainerClosed = {
    height: "0px",
}

export default DetoxSection;