import React,{PureComponent} from 'react';
import { withStyles, withTheme } from '@material-ui/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './index.css'
import PropTypes from 'prop-types';
const styles = theme => ({
    root: {
        width: '100%',
        zIndex:2,
        border:"3px solid rgb(255, 215, 102)",
        width: "-webkit-fill-available"
      },
      detail:{
        width: "inherit"
      }
     
});



class ExpansionPanelComponents extends PureComponent {

render(){
    const { classes } = this.props;
    return (
        <div >
            <ExpansionPanel className={this.props.bordes ? classes.root : ""}>
                <ExpansionPanelSummary
                className={classes.detail}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{this.props.titulo}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails > 
                    <Typography >
                        {this.props.descripcion}
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>

        </div>
    );
}
   
}

ExpansionPanelComponents.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(ExpansionPanelComponents);