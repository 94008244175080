import React, { PureComponent, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import CollapseComponent from '../MaterialUiCompnents/boostrapCollapse/index'


export default class AvisoPrivacidadModal extends PureComponent {
  constructor(props, context) {
    super(props, context);


    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <Fragment>

        <Modal size="lg" show={this.props.modal} onHide={this.props.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>AVISO DE PRIVACIDAD INTEGRAL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>MCCORMICK DE MÉXICO, S.A. DE C.V.</strong>, mejor conocido como <strong>“MCCORMICK”</strong> (en lo
              sucesivo el “Responsable”) con domicilio en Calzada San Bartolo Naucalpan, número 360,
              Colonia Argentina Poniente, Alcaldía Miguel Hidalgo, Ciudad de México, C.P.11230, es el
              responsable del tratamiento de los datos personales que nos proporcione, los cuales serán
              protegidos conforme a lo dispuesto por la Ley Federal de Protección de Datos Personales
              en Posesión de los Particulares, su Reglamento y demás normatividad que resulte
              aplicable, y al respecto le informamos lo siguiente:
            </p>
            <p><strong>¿QUÉ DATOS PERSONALES RECABAMOS?</strong></p>
            <p>
              Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, podremos
              recabar de nuestros clientes, consumidores y público en general a través de nuestros sitios
              web, redes sociales, de forma personal o vía telefónica los siguientes datos personales y
              los documentos que contienen información personal:
            </p>
            <ol>
              <li>Nombre completo.</li>
              <li>Fecha de nacimiento.</li>
              <li>Edad.</li>
              <li>Género.</li>
              <li>Correo electrónico.</li>
              <li>Teléfono fijo o celular.</li>
              <li>Domicilio: calle y número,colonia, ciudad, estado,código postal y país.</li>
              <li>Credencial de elector:
                clave de elector, CURP,
                firma, fotografía, huella
                dactilar, OCR.</li>
            </ol>
            <p>
              Se informa que, para las finalidades previstas en el presente Aviso de Privacidad, no se
              recabarán de usted Datos Personales sensibles.
            </p>
            <p>
              <strong>
                ¿PARA QUÉ FINES RECABAMOS TUS DATOS PERSONALES?
              </strong>
            </p>
            <p>
              Tu información personal será utilizada para las siguientes finalidades primarias, las cuales
              son necesarias para proporcionarte nuestros productos y/o servicios:
            </p>
            <ol type='a'>
              <li>Identificarte y contactarte.</li>
              <li>Validar tu identidad.</li>
              <li>Proporcionarte la información que nos solicites sobre nuestros productos y/o
                servicios, así como enviarte cotizaciones.</li>
              <li>Facilitarle el enlace a nuestras redes sociales.</li>
              <li>Inscribirte a los programas, promociones, concursos y/o eventos que nos solicites.</li>
              <li>Proporcionarte los productos que nos solicites o contrates con nosotros, así como
                brindarte los beneficios que ofrecemos a través de nuestros diferentes programas,
                cursos, eventos en los que tú participes.</li>
              <li>En caso de resultar ganador o beneficiario de algún incentivo u obsequio, hacerte
                entrega del mismo.</li>
              <li>Compartir recetas elaboradas con nuestros productos.</li>

              <li>Documentar y darte solución a problemáticas de servicio.</li>
              <li>Atención y seguimiento de tus dudas, aclaraciones, quejas o sugerencias.</li>
              <li>Elaborar y mantener actualizada nuestra base de datos de consumidores y
                prospectos de consumidores.</li>
              <li>Cumplimiento de disposiciones legales y/o contractuales.</li>
            </ol>
            <p>
              Además de utilizarse para las finalidades primarias, tus datos personales serán empleados
              para otras <strong>finalidades secundarias</strong> que, aunque no son esenciales, resultan altamente
              beneficiosas, para mejorar la calidad de nuestra atención, perfeccionar nuestros productos
              y llevar a cabo actividades publicitarias y promocionales relacionadas con ellos. A
              continuación, se enlistan:
            </p>
            <ol type='a'>
              <li>Construir perfiles de consumo.</li>
              <li>Informarte y posibilitar tu participación en concursos con fines publicitarios y/o
                promocionales.</li>
              <li>Enviarte información, publicidad y/o promociones, a través de nuestros newsletters.</li>
              <li>Realizar actividades mercadotécnicas, publicitarias y de prospección comercial.</li>
              <li>Obtener tus comentarios sobre los servicios y/o productos proporcionados.</li>
              <li>Contactarte por cualquier medio de comunicación con el objeto de ofrecerte
                nuestros productos.</li>
              <li>Elaborar listados de exclusión con el objeto de registrar de manera gratuita su
                negativa al tratamiento de sus datos personales.</li>
            </ol>
            <p><strong>¿NO ESTÁS DE ACUERDO CON LAS FINALIDADES SECUNDARIAS? ¿CÓMO
              PUEDES NEGARTE?</strong></p>
            <p>
              Si no deseas que tus datos personales sean tratados para las finalidades secundarias
              señaladas, o alguna de ellas, puedes negarnos tu consentimiento desde este momento
              enviando tu solicitud al siguiente correo electrónico:
              <a href="mailto: protecciondedatos@herdez.com"> protecciondedatos@herdez.com</a>
              . Tu
              negativa no afectará las finalidades primarias ni será motivo para dejar de proporcionarte
              nuestros productos y servicios.
            </p>
            <p>
              <strong>
                ¿QUÉ TRANSFERENCIAS REALIZA EL RESPONSABLE?
              </strong>
            </p>
            <table>
              <tr>
                <th>Destinatarios de los datos
                  personales</th>
                <th>Finalidad</th>
                <th>Requiere del
                  consentimiento</th>
              </tr>
              <tr>
                <td>1.- Autoridad competente.</td>
                <td>Cumplimiento de leyes, tratados, acuerdos a
                  los que nos encontremos obligados o para
                  dar cumplimiento a requerimientos oficiales.</td>
                <td>No</td>
              </tr>
              <tr>
                <td>2.- Cualquier sociedad del mismo
                  grupo económico al que pertenece la
                  responsable.</td>
                <td>Para brindarle mejor calidad en nuestros
                  productos y servicios; para apoyar en la
                  gestión de alguna consulta, duda o queja;
                  para apoyar en la gestión de las finalidades
                  señaladas en el presente aviso.</td>
                <td>No</td>
              </tr>
              <tr>
                <td>3.- Personas físicas y morales con las
                  que se adquieran productos o que
                  presten servicios a cualquier de las
                  sociedades del mismo grupo
                  económico al que pertenece la
                  responsable.</td>
                <td>Para que estas personas físicas y morales
                  puedan llevar a cabo sus funciones:
                  compañías de mercadotecnia, prestación de
                  servicios de mensajería, facturación,
                  desarrollo de sitios web o software y sus
                  mejoras, desarrollo de cualquier otra
                  infraestructura informática, asesoría de
                  auditores, consultores jurídicos y contables.</td>
                <td>No</td>
              </tr>
            </table>
            <p>
              <strong>
                ¿CÓMO PUEDE LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?
              </strong>
            </p>
            <p>
              Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le
              informamos que puede inscribirse en el siguiente registro: Registro Público para Evitar
              Publicidad, para mayor información consulte el portal del internet de la Procuraduría Federal
              del Consumidor (PROFECO).
            </p>
            <p>
              <strong>
                ¿CÓMO PUEDE EJERCER SUS DERECHOS ARCO PARA ACCEDER, RECTIFICAR,
                CANCELAR U OPONERSE AL USO DE SUS DATOS PERSONALES?
              </strong>
            </p>
            <p>
              Usted podrá ejercer sus derechos ARCO presentando un escrito libre dirigido al
              Departamento de Protección de Datos Personales a través del correo electrónico
              <a href="mailto: protecciondedatos@herdez.com"> protecciondedatos@herdez.com</a>, con los siguientes datos: 1) nombre del titular de los
              datos, domicilio, teléfono y correo electrónico; 2) copia de la identificación oficial vigente del
              titular (INE, pasaporte o cartilla militar); 3) en caso de ser representante del titular deberá
              adjuntar copia del instrumento público donde consten las facultades o en su caso una carta
              poder firmada por el titular, su representante y dos testigos; 4) descripción clara y precisa
              de los datos a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de Rectificación de
              datos personales, deberá indicar la modificación exacta y anexar la documentación soporte;
              5) cualquier elemento que facilite la localización de los datos personales.
            </p>
            <p>
              El Departamento de Protección de Datos Personales dará respuesta en un plazo máximo
              de 20 (veinte) días hábiles contados a partir de la recepción de la solicitud a través del
              correo electrónico que el titular señale en su solicitud.
            </p>
            <p>
              Si desea conocer más detalles del procedimiento para el ejercicio de estos Derechos
              ARCO, puede enviar un correo electrónico a la dirección electrónica antes señalada.
            </p>
            <p>
              <strong>
                REVOCACIÓN DEL CONSENTIMIENTO PARA EL USO DE LOS DATOS PERSONALES.
              </strong>
            </p>
            <p>
              Usted podrá revocar el consentimiento que, en su caso, nos haya otorgado para el
              tratamiento de sus datos personales. Para revocar su consentimiento deberá presentar un
              escrito libre dirigido al Departamento de Protección de Datos Personales a través del correo
              electrónico: <a href="mailto: protecciondedatos@herdez.com"> protecciondedatos@herdez.com</a>, con los siguientes datos: 1) nombre del titular
              de los datos, domicilio, teléfono y correo electrónico; 2) copia de la identificación oficial
              vigente del titular (INE, pasaporte, cartilla militar); 3) en caso de ser representante del titular
              deberá adjuntar copia del instrumento público donde consten las facultades o en su caso
              una carta poder firmada por el titular, su representante y dos testigos; 4) descripción clara
              y precisa de los datos personales respecto de los que se busca revocar el consentimiento;
              5) cualquier elemento que facilite la localización de los datos personales.
            </p>
            <p>
              El Departamento de Protección de Datos Personales dará respuesta en un plazo máximo
              de 20 (veinte) días hábiles contados a partir de la recepción de la solicitud a través del
              correo electrónico que el titular señale en su solicitud.
            </p>
            <p>Si desea conocer más detalles del procedimiento para la revocación del consentimiento,
              puede enviar un correo electrónico a la dirección electrónica antes señalada.</p>
            <p>
              <strong>
                ¿USAMOS TECNOLOGÍAS DE RASTREO EN NUESTRO SITIO WEB?
              </strong>
            </p>
            <p>
              El Responsable utiliza tecnologías para mejorar el funcionamiento de sitios web y
              desarrollos tecnológicos, incluyendo su experiencia cuando navega por dichos sitios o usa
              nuestra red. Entre estas tecnologías se incluye el uso de cookies, que son pequeñas
              cantidades de información que se almacenan en el navegador utilizado por cada usuario
              para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta
              información permite identificarle y guardar sus preferencias personales para brindarle una
              mejor experiencia de navegación.
            </p>
            <p>En nuestros sitios de web y aplicaciones podemos hacer uso de cookies, web beacons y
              otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el
              presente Aviso de Privacidad.</p>
            <p>Los diferentes tipos de cookies que utilizamos para obtener datos identificativos y datos
              relacionados con su comportamiento de compra y uso de servicios en internet son:</p>
            <p>
              <strong>Cookies esenciales</strong>: Utilizamos estas tecnologías para permitirle un uso adecuado
              de nuestros sitios web, por lo que las mismas no pueden ser deshabilitadas al ser
              necesarias para permitirle el uso de las funcionalidades de nuestros sitios web y
              herramientas tecnológicas.<br />
              <strong>Cookies para recordar sus preferencias y experiencia</strong>: a través de estas
              tecnologías obtenemos ID de usuario, fecha, hora y origen de la visita, ubicación
              geográfica, género, edad, interacciones con el sitio, intereses y tipos de búsqueda,
              tiempo de navegación en nuestra página de Internet, secciones consultadas, y
              páginas de Internet accedidas previo a la nuestra, estas cookies nos permiten
              recordar sus preferencias, opciones de navegación y funciones personalizadas.<br />
              <strong>Cookies para objetivos de rendimiento y análisis</strong>: Podemos usar cookies para
              obtener datos de carácter técnico y estadístico que nos permitan identificar la forma
              en la cual usted hace uso de nuestros servicios con la finalidad de mejorar su
              rendimiento y futuros desarrollos.<br />
              <strong>Cookies publicitarias</strong>: Podemos colocar cookies publicitarias propias para obtener
              información relativa a sus preferencias de compra, consumo y visualización de sitios
              web para mostrarle publicidad que consideremos relevante para usted.<br />
              <strong>Análisis del sitio</strong>: Utilizamos herramientas de Google para ayudar a analizar el uso
              de nuestro sitio web. Esta herramienta analítica utiliza “cookies” que son archivos
              de textos que se colocan en su computadora para recabar información del registro
              estándar de Internet e información de conducta de los visitantes en forma anónima.
              La información generada por la cookie sobre su uso el sitio web (inclusive su
              dirección IP) se transmite a Google. Esta información se utiliza para evaluar el uso
              del sitio web de parte de los visitantes y para compilar informes estadísticos sobre
              la actividad del sitio web. Para decidir no participar en el registro de Google en todos
              los sitios web, visite: <a href="http://tools.google.com/dlpage/gaoptout" rel="noreferrer" target="_blank"> http://tools.google.com/dlpage/gaoptout.</a>
            </p>
            <p>
              Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los
              procedimientos del navegador de internet que utiliza para acceder a nuestros sitios web,
              por ejemplo:
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>
                Google Chrome (Computadora, Android, iPhone y iPad):
                <br /> (
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=es-41"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  https://support.google.com/accounts/answer/61416?hl=es-41
                </a>
                )
              </li>
              <li>
                Internet Explorer:
                <br /> (
                <a
                  href="https://support.microsoft.com/es-es/help/17442/windows-internet-
                explorer-delete-manage-cookies"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  https://support.microsoft.com/es-es/help/17442/windows-internet-
                  explorer-delete-manage-cookies
                </a>
                )
              </li>
            </ul>
            <p>
              En caso de usar un navegador o servicio de internet distinto de los anteriores, debe revisar
              el centro de Ayuda y/o configuración sobre uso de cookies del mismo.
            </p>
            <p>
              <strong>¿CÓMO PUEDE CONOCER LOS CAMBIOS EN ESTE AVISO DE PRIVACIDAD?</strong>
            </p>
            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
              derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
              productos o servicios que ofrecemos; de nuestras prácticas de privacidad; o por otras
              causas. En caso de que exista un cambio de este aviso de privacidad, lo haremos de su
              conocimiento mediante nuestro sitio web: <a target='_blank' rel="noopener" href='https://www.mccormick.mx/'>https://www.mccormick.mx/</a></p>
            <div style={{ width: '100%', textAlign: 'end' }}>
              <p>Última actualización: Octubre/2023.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn mr-auto ml-auto btn-carousel" variant="primary" onClick={this.props.handleModal}>
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}