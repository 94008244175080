import React, { PureComponent } from 'react'
import banner from './img/group-20.png'
import platillo from './img/plato.png'
import menta from './img/menta.png'
import hoja from './img/hoja.png'
import chicharo from './img/chicharo.png'
import calabaza from './img/calabaza.png'
import bannermobile from './img/bannerMobilr.png'
import timer from '../Resource/iconos/timer.svg'
import iconoX from './img-mobile/shape.png'
import '../Receta/index.css'
import Select from 'react-select'
import { connect } from 'react-redux'
import { border } from '@material-ui/system';
import Fade from 'react-reveal/Fade'
import Parallax from 'parallax-js'
import axios from 'axios'
import TePuedeInteresar from '../TePuedeInteresar/index'
import TePuedeInteresarMobile from '../TePuedeInteresarMobile/index'
import { Helmet } from "react-helmet";

import GetFilterRecipes from '../redux/actions/GetFilterRecipes'
import ExpansionPanel from '../MaterialUiCompnents/ExpancionPanel/index'
import Swiper from 'react-id-swiper'
import WOW from 'wowjs'
import { ExpansionPanelDetails } from '@material-ui/core'
import zIndex from '@material-ui/core/styles/zIndex'
import { Label } from 'react-bootstrap'
import { grey } from '@material-ui/core/colors'


/*
 * TODO:
 * Utlizar el pathname (window.location.pathname) para darle valor a la categoría seleccionada 
 * en los botones de categoría en lugar de tomar el valor que devuelve el servicio, ya que el 
 * valor tarda en aparecer.
 */

class Recetas extends PureComponent {
    constructor() {
        super()
        const optionsDificultades = []
        const optionsSabores = []
        const ptionsMomentos = []
        const optionsPara = []
        const sabor = null
        const momentoDia = null
        const para = null
        const dificultad = null
        const filtrar = ""
        this.state = {
            recetas: null,
            buttonMas: null,
            button: null,
            recetasPopulares: null,
            recetamenumovil: false,
            categoriaelegida: "TODOS",
            recetamenumovil2: true,
            limit: 0,
            limitM: 0,
            recetas_show: [],
            recetas: [],
            metadata: [],
            metas: [],
        }

    }

    controlMenuMovil = () => {
        this.setState({
            recetamenumovil: !this.state.recetamenumovil
        })

    }

    getButtonMas = () => {
        const button = this.props.data.categorias.map((element, index) => {
            if (index >= 5) {
                return (
                    <button onClick={() => { this.getRecetasPorCategoria(element.id, element.url); this.controlMenuMovil() }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                )
            }
        })
        this.setState({
            button: button
        })

    }
    getButton = () => {
        const buttonMas = this.props.data.categorias.map((element, index) => {
            if (index <= 5) {
                return (
                    <button onClick={() => { this.getRecetasPorCategoria(element.id, element.nombre); this.controlMenuMovil() }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                )
            }

        })
        this.setState({
            buttonMas: buttonMas
        })

    }
    getRecetasPorCategoria = (id, nomcat) => {
        this.setState({
            categoriaelegida: nomcat
        })
        const recetas = []
        this.props.data.recetas.map((element, index) => {
            if (element.idCategoria === id) {
                recetas.push(<div style={{ border: "0px solid transparent" }} className="card col-4" >
                    <div style={{ overflow: "hidden" }}>
                        <img alt={element.alt_imagen} className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                    </div>
                    <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                        <Fade left>   <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label></Fade>
                        <br />
                        <Fade left>  <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>

                        <br /> <br />
                        <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                            <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                        </div>
                    </div>
                </div>)

            }

        })

        this.state.recetas = []
        this.state.recetas.length = 0
        this.setState({
            recetas: recetas
        })
    }
    loadRecipes = async () => {
        var nombreReceta = this.props.match.params.post_id;
        if (this.state.recetas.length == 0) {
            //Recetas todas
            const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas?fields=*&filter[estatus]=published&sort[]=-id&limit=-1");
            this.props.dispatch({ type: "OBTENER_RECETAS_TODAS", recetas: result.data.data });
            //Recetas por categorias 
            const categoriaResult = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias_recetas?fields=*.*");
            this.props.dispatch({ type: "OBTENER_RECETAS_POR_CATEGORIA", categoriaRecetas: categoriaResult.data.data });
            //propiedades filtro recetas
            const metadata = await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas/metadata");
            this.props.dispatch({ type: "OBTENER_METADATA_RECETAS", metadata: metadata.data });

        }
        // const data = this.props.data.recipes.slice(0);
        const recipes = [];
        const recipesM = []
        let metaSeo = [];
        //
        const limite = this.state.limit + 12;
        const limiteM = this.state.limitM + 6;
        if (nombreReceta == null) {
            for (let i = 0; i < limite; i++) {
                if (i < this.props.data.recetas.length) {
                    const item = this.props.data.recetas[i];
                    recipes.push(item);
                }
            }
            for (let i = 0; i < limiteM; i++) {
                if (i < this.props.data.recetas.length) {
                    const item = this.props.data.recetas[i];
                    recipesM.push(item);
                }
            }
        } else {
            this.props.data.categoriaRecetas.categoriaRecetas.map((element, index) => {
                if (element.url) {
                    if (element.url === nombreReceta) {
                        metaSeo = {
                            metadescription: element.metadescription,
                            metatitle: element.metatitle
                        }
                        if (limite > element.recetas.length) {
                            for (let i = 0; i < element.recetas.length; i++) {
                                const item = element.recetas[i];
                                recipes.push(item);
                            }
                        } else {
                            for (let i = 0; i < limite; i++) {
                                const item = element.recetas[i];
                                recipes.push(item);
                            }

                        }
                        if (limiteM > element.recetas.length) {
                            for (let i = 0; i < element.recetas.length; i++) {
                                const item = element.recetas[i];
                                recipesM.push(item);
                            }
                        } else {
                            for (let i = 0; i < limiteM; i++) {
                                const item = element.recetas[i];
                                recipesM.push(item);
                            }
                        }
                    }
                }
            })
        }
        const arrayMetadatos = []
        arrayMetadatos.push(
            {
                "nombre": {
                    "sabores": this.props.data.getMetadataRecipes.metadata.sabores
                }
            }
        );
        arrayMetadatos.push(
            {
                "nombre": {
                    "para": this.props.data.getMetadataRecipes.metadata.para
                }
            }
        );
        arrayMetadatos.push(
            {
                "nombre": {
                    "momentos": this.props.data.getMetadataRecipes.metadata.momentos
                }
            }
        );
        arrayMetadatos.push(
            {
                "nombre": {
                    "dificultades": this.props.data.getMetadataRecipes.metadata.dificultades
                }
            }
        );
        this.setState({
            meta: metaSeo,
            metadata: arrayMetadatos,
            recetas_show: recipes.filter((e) => { return (e.estatus === "published") }),
            categoriaRecetas: this.props.data.categoriaRecetas.categoriaRecetas,
            limit: limite,
            limitM: limiteM,
            catgoriaSelected: nombreReceta
        });
    }
    getRecetas = () => {
        const recetas = this.state.recetas_show.map((element, index) => {
            return (
                <div style={{ border: "0px solid transparent" }} className="card col-4">
                    <div style={{ overflow: "hidden" }}>
                        <img alt={element.alt_imagen} className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                    </div>
                    <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                        <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br /></Fade>
                        <Fade left>  <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>
                        <br /> <br />
                    </div>
                </div>
            )
        })
        this.setState({
            recetas,
        })
    }
    getFiltroRecetas = (e) => {
        switch (e.target.id) {
            case 'sabor':
                this.sabor = e.target.value
                break;
            case "momento":
                this.momento = e.target.value
                break;
            case "para":
                this.para = e.target.value
                break;
            default:
                this.dificultad = e.target.value
        }

        this.props.GetFilterRecipes(this.sabor, this.momento, this.para, this.dificultad)
        this.getFilterReciper()
    }
    getFilterReciper = () => {
        if (this.props.data.getDatafilterrecipes) {
            const recetas = this.props.data.getDatafilterrecipes.map((element, index) => {
                return (
                    <div style={{ border: "0px solid transparent" }} className="card col-4">
                        <div style={{ overflow: "hidden" }}>
                            <img alt={element.alt_imagen} className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                        </div>
                        <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                            <Fade left></Fade>
                            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                            <Fade left></Fade>
                            <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
                            <br /> <br />
                            <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                            </div>
                        </div>
                    </div>
                )
            })
            this.setState({
                recetas
            })
        }
    }
    getRecetasPopulares = () => {
        const recetasPopulares = this.props.data.recetasFavoritas.map((element, index) => {
            if (element.popular) {
                return (
                    <div style={{ border: "0px solid transparent" }} className="card col-4">
                        <div style={{ overflow: "hidden" }}>
                            <img alt={element.alt_imagen} className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                        </div>
                        <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                            <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br /></Fade>

                            <Fade left>  <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>

                            <br /> <br />
                            <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                            </div>
                        </div>
                    </div>
                )
            }
        })
        const recetasPopularesMobile = this.props.data.recetasFavoritas.map((element, index) => {
            if (element.popular) {
                return (
                    <div key={index} style={{ maxWidth: "300px", border: "0px solid transparent" }} >
                        <div style={{ overflow: "hidden", width: "300px" }}>
                            <img alt={element.alt_imagen} className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                        </div>
                        <div style={{ fontFamily: "AvenirNextLTProMedium", width: "300px" }}>
                            <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px", marginTop: "13px" }}>{element.nombre}</label></Fade>
                            <br />
                            <Fade left>   <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>

                            <br /> <br />
                            {/*<div style={{ display: "flex", fontFamily: "AvenirNextLTProMedium", font: "message-box", fontStretch: "extra-expanded"}}>
                                <img src={timer} style={{ width: "20px", height: "20px", marginRight: "11px" }} />{element.tiempo + "  " + element.personas}
                            </div>*/}
                        </div>
                    </div>
                )
            }
        })
        this.setState({
            recetasPopulares,
            recetasPopularesMobile
        })

    }
    myAwesomeCallback = () => {
        document.getElementById('scene').style.pointerEvents = "auto"
        document.getElementById('banner-receta-platillo').style.top = "38%"
        document.getElementById('banner-receta-platillo').style.left = "13%"
        document.getElementById('banner-receta-hoja').style.top = "45%"
        document.getElementById('banner-receta-hoja').style.left = "86%"
        document.getElementById('banner-receta-menta').style.top = "9%"
        document.getElementById('banner-receta-menta').style.left = "31%"
        document.getElementById('banner-receta-chicharo').style.top = "7%"
        document.getElementById('banner-receta-chicharo').style.left = "5%"
        document.getElementById('banner-receta-calabaza').style.top = "38%"
        document.getElementById('banner-receta-calabaza').style.left = "66%"
        document.getElementById('banner-recetas-text').style.top = "20%"
        document.getElementById('banner-recetas-text').style.left = "5%"
    }
    handleRutas = () => {
        const home = document.getElementById("home")
        const productos = document.getElementById("productos")
        const promociones = document.getElementById("promociones")
        const p = window.location.pathname.search("/recetas")
        if (p === 0) {
            const recetas = document.getElementById("recetas")
            recetas.style.borderBottom = "5px solid red"
            recetas.style.transition = ".3s"
            home.style.borderBottom = "5px solid  transparent"
            productos.style.borderBottom = "5px solid transparent"
            promociones.style.borderBottom = "5px solid transparent"
        }
        switch (window.location.pathname) {
            case "/":
                home.style.borderBottom = "5px solid red"
                home.style.transition = ".3s"
                break;
            case "/productos":
                productos.style.borderBottom = "5px solid red"
                productos.style.transition = ".3s"
                break;
            case "/recetas":
                const recetas = document.getElementById("recetas")
                recetas.style.borderBottom = "5px solid red"
                recetas.style.transition = ".3s"
                home.style.borderBottom = "5px solid  transparent"
                productos.style.borderBottom = "5px solid transparent"
                promociones.style.borderBottom = "5px solid transparent"
                break;
            case "/promociones":
                promociones.style.borderBottom = "5px solid red"
                promociones.style.transition = ".3s"
                break;
            case "/contacto":
                const contacto = document.getElementById("contacto")
                contacto.style.borderBottom = "5px solid red"
                contacto.style.transition = ".3s"
                break;
            case "/nosotros":
                const nosotros = document.getElementById("nosotros")
                nosotros.style.borderBottom = "5px solid red"
                nosotros.style.transition = ".3s"
                break;
            default:
                //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
                break;
        }
    }
    componentDidMount() {
        this.loadRecipes()
    }
    handleFilter = async () => {
        this.filtro = ""
        if (this.sabor) {
            this.filtro += "&filter[sabor][nombre]=" + this.sabor
        }
        if (this.momentoDia) {
            this.filtro += "&filter[momento][nombre]=" + this.momentoDia
        }
        if (this.para) {
            this.filtro += "&filter[para][nombre]=" + this.para
        }
        if (this.dificultad) {
            this.filtro += "&filter[dificultad][nombre]=" + this.dificultad
        }
        const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/recetas?fields=*,dificultad.*,para.*,momento.*,sabor.*" + this.filtro);
        this.props.dispatch({ type: "OBTENER_RECETAS_TODAS", recetas: result.data.data });
        this.setState({
            recetas_show: result.data.data.filter((e) => { return (e.estatus === "published") }),
        })

    }
    handleChangeSabor = (selected) => {
        this.sabor = selected.value
        this.handleFilter()
    }
    handleChangeMomento = (selected) => {
        this.momentoDia = selected.value
        this.handleFilter()
    }
    handleChangePara = (selected) => {
        this.para = selected.value
        this.handleFilter()
    }
    handleChangeDificultad = (selected) => {
        this.dificultad = selected.value
        this.handleFilter()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.recetas != this.props.data.recetas) {
            this.handleRutas()
            var parallax;
            var scenesSelector = document.getElementById('scene');
            parallax = new Parallax(scenesSelector, {
                onReady: this.myAwesomeCallback
            });
            new WOW.WOW(
                {
                    callback: function (box) {
                        box.addEventListener('animationend', function (event) {
                            box.classList.remove("fadeInDown")
                            box.classList.add("scale-class")
                        });
                    }
                }
            ).init()
            this.sabor = null; this.momento = null; this.para = null; this.dificultad = null
            this.getRecetasPopulares()
            // this.getButton()
            // this.getButtonMas()
        }
    }
    render() {
        const customStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    backgroundColor: "transparent",
                    color: "gray",
                    ':hover': {
                        ...styles[':hover'],
                        backgroundColor: "transparent",
                        color: "red"
                    },
                };
            },
            container: () => ({
                width: "30%",
                border: "4px solid #ffd766",
                margin: "10px",
                position: "relative",
                zIndex: 100

            }),
            control: () => ({
                outline: "none",
                display: "flex"
            }),
            menu: (provided, state) => ({
                ...provided,
                width: "100%",
                borderBottom: '1px dotted pink',
                border: " 3px solid #ffd766",
                color: state.selectProps.menuColor,
                padding: 20,


            }),

            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';
                return { ...provided, opacity, transition };
            }
        }
        const customStylesMobile = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    backgroundColor: "transparent",
                    color: "black",
                    ':hover': {
                        ...styles[':hover'],
                        backgroundColor: "transparent",
                    },
                };
            },
            container: () => ({
                width: "100%",
                position: "static",
                zIndex: 500,
            }),
            control: () => ({
                outline: "none",
                display: "flex"
            }),
            menu: (provided, state) => ({
                ...provided,
                width: "100%",
                position: "static",
                padding: 10,
                zIndex: 600,
                boxShadow: 0
            }),
            option: () => ({
                lineHeight: 2.5,
                borderBottom: "2px solid rgba(0, 0, 0, .08)"
            }),

            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';


                return { ...provided, opacity, transition };
            }
        }

        this.state.metadata.filter((element, index) => {
            if (element.nombre.sabores) {
                const array = element.nombre.sabores.map((element, index) => {

                    return { value: element.nombre, label: element.nombre }

                })
                array.unshift({ value: null, label: "TODAS" })
                this.optionsSabores = array

            }
        })

        this.state.metadata.filter((element, index) => {
            if (element.nombre.para) {
                const array = element.nombre.para.map((element, index) => {

                    return { value: element.nombre, label: element.nombre }

                })
                array.unshift({ value: null, label: "TODAS" })
                this.optionsPara = array
            }
        })

        this.state.metadata.filter((element, index) => {
            if (element.nombre.momentos) {
                const array = element.nombre.momentos.map((element, index) => {

                    return { value: element.nombre, label: element.nombre }

                })
                array.unshift({ value: null, label: "TODAS" })
                this.optionsMomentos = array
            }
        })

        this.state.metadata.filter((element, index) => {
            if (element.nombre.dificultades) {
                const array = element.nombre.dificultades.map((element, index) => {

                    return { value: element.nombre, label: element.nombre }

                })
                array.unshift({ value: null, label: "TODAS" })
                this.optionsDificultades = array;
            }
        })



        const params = {
            slidesPerView: 'auto',
            spaceBetween: 5,
            centeredSlides: true,
            initialSlide: 2
        }
        const that = this;

        return (
            <>
                {this.props.match.params.post_id ?

                    <>
                        {this.state.meta && <Helmet>
                            <meta charSet="utf-8" />
                            <title>{this.state.meta.metatitle}</title>
                            <meta name="description" content={this.state.meta.metadescription} />
                        </Helmet>
                        }
                    </>
                    :
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Recetas de cocina ¡Fáciles y prácticas! | McCORMICK México</title>
                        <meta name="description" content="¿Por qué hacerla sólo en salsa verde o roja, cuando puedes preparar esta rica combinación de sabores? Pechugas de Pollo Encacahuatadas." />
                    </Helmet>

                }


                <seccion className="recetas-desk">


                    <div id="scene" style={{ width: "100%", position: "relative", justifyContent: "center", alignItems: "center", display: "flex", marginTop: "6px" }}>


                        <img style={{ width: "95.5%", height: "auto" }} src={banner} />

                        <div id="banner-receta-platillo" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", zIndex: "50", top: "38%", left: "13%" }}>
                            <img style={{ maxWidth: "52%", height: "auto", animation: "8s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={platillo} />
                        </div>



                        <div id="banner-receta-hoja" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", zIndex: "50", top: "45%", left: "86%" }}>
                            <img style={{ maxWidth: "100%", height: "auto", animation: "8s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={hoja} />
                        </div>

                        <div id="banner-receta-menta" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", zIndex: "50", top: "9%", left: "31%" }}>
                            <img style={{ maxWidth: "100%", height: "auto", animation: "8s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={menta} />
                        </div>

                        <div id="banner-receta-chicharo" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", zIndex: "50", top: "7%", left: "5%" }}>
                            <img style={{ maxWidth: "100%", height: "auto", animation: "8s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={chicharo} />
                        </div>

                        <div id="banner-receta-calabaza" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", zIndex: "50", top: "38%", left: "66%" }}>
                            <img style={{ maxWidth: "100%", height: "auto", animation: "8s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} src={calabaza} />
                        </div>


                        <label id="banner-recetas-text" className="UN-SINFN-DE-RECETAS">SABOR QUE SE AJUSTA A TU ANTOJO</label>


                        <div className="banner-recetas-button-desk" >
                            <div>

                                {(() => {

                                    if (this.state.categoriaRecetas) {

                                        return this.state.categoriaRecetas.map((element, index) => {
                                            if (index <= 5) {
                                                if (element.nombre.toLowerCase() === (this.state.catgoriaSelected ? this.state.catgoriaSelected.toLowerCase() : this.state.catgoriaSelected)) {
                                                    return (
                                                        <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="btn-receta-static">{element.nombre}</button>
                                                    )
                                                } else {
                                                    return (
                                                        <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                                                    )
                                                }

                                            }
                                        })
                                    }

                                })()}
                            </div><br></br>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(() => {

                                    if (this.state.categoriaRecetas) {
                                        return this.state.categoriaRecetas.map((element, index) => {
                                            if (index > 5) {
                                                if (element.nombre.toLowerCase() === (this.state.catgoriaSelected ? this.state.catgoriaSelected.toLowerCase() : this.state.catgoriaSelected)) {
                                                    return (
                                                        <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ background: "#ffbd00", color: "black", transform: "scale(1.15)", marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                                                    )
                                                } else {
                                                    return (
                                                        <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                                                    )
                                                }
                                            }

                                        })
                                    }

                                })()}
                            </div>
                        </div>






                    </div>
                    <div className="filtro-recetas" >
                        <Fade left> <p ><b>FILTRA Y ENCUENTRA QUÉ TIPO DE RECETAS QUIERES PREPARAR</b></p></Fade>

                        <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                            <Select
                                styles={customStyles}
                                value={this.state.selectedOption}
                                onChange={this.handleChangeSabor}
                                options={this.optionsSabores}
                                placeholder="SABOR"
                                isSearchable={false}
                            />
                            <Select
                                styles={customStyles}
                                value={this.state.selectedOption}
                                onChange={this.handleChangeMomento}
                                options={this.optionsMomentos}
                                placeholder="MOMENTO DEL DÍA"
                                isSearchable={false}
                            />
                            <Select
                                styles={customStyles}
                                value={this.state.selectedOption}
                                onChange={this.handleChangePara}
                                options={this.optionsPara}
                                placeholder="PARA"
                                isSearchable={false}
                            />
                            <Select
                                styles={customStyles}
                                value={this.state.selectedOption}
                                onChange={this.handleChangeDificultad}
                                options={this.optionsDificultades}
                                placeholder="DIFICULTAD"
                                isSearchable={false}
                            />






                        </div>

                    </div>


                    <div style={{ width: "94%", left: "3%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "62px", position: "relative" }}>
                        <div style={{ width: "96%", display: "flex", flexWrap: "wrap" }} >
                            {(() => {
                                return this.state.recetas_show.map((element, index) => {
                                    return (
                                        <div style={{ border: "0px solid transparent" }} onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.url}`) }} className="card col-4">
                                            <div style={{ overflow: "hidden" }}>
                                                {element.imagen && <img alt={element.alt_imagen} className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />}
                                            </div>
                                            <div style={{ padding: "20px", fontFamily: "AvenirNextLTProMedium" }}>
                                                <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br /></Fade>
                                                <Fade left>  <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    )
                                })
                            })()}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <button className=" btn-carousel" onClick={() => this.loadRecipes()} style={{ display: (this.state.recetas_show.length === this.props.data.recetas.length || this.state.limit > this.state.recetas_show.length) ? 'none' : 'block' }}  >CARGAR MÁS</button>
                    </div>

                    {/*
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                        <div style={{ width: "80%" }} >


                            <Fade left>   <b className="LAS-RECETAS-MS-POPU" >TE PUEDE INTERESAR</b></Fade><br />


                        </div>
                    </div>

*/}


                    <TePuedeInteresar></TePuedeInteresar>
                </seccion>

                <seccion className="recetas-mobile">
                    <div style={{ width: "100%", position: "relative", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                        <img className='tam-img' src={bannermobile} />
                        {/*------Switch para menú de recetas------
                            Se reemplazaron las variables de button y buttonMas para añadir los botones inline
                        */}
                        {this.state.recetamenumovil ?
                            <>
                                <img className='tam-iconoX' onClick={this.controlMenuMovil} src={iconoX} />
                                <div className="RECETAS-BANNER2">
                                    <>
                                        {<button className="BTN-TODAS2" onClick={() => { this.controlMenuMovil(); this.setState({ categoriaelegida: "TODOS" }) }}>{this.state.categoriaelegida}</button>}
                                        {this.state.categoriaRecetas.map((element, index) => {
                                            if (index <= 5) {
                                                return (
                                                    <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                                                )
                                            }
                                        })}

                                        {this.state.categoriaRecetas.map((element, index) => {
                                            if (index > 5) {
                                                return (
                                                    <button onClick={() => { window.scrollTo(0, 0); window.location.href = (`/recetas/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ marginRight: "20px", cursor: "pointer" }} className="btn-recetas">{element.nombre}</button>
                                                )
                                            }
                                        })}
                                    </>
                                </div>
                            </>
                            :
                            <div className="RECETAS-BANNER">


                                <label className="UN-SINFN-DE-RECETAS">UN SINFÍN DE<br /> RECETAS</label>
                                <label className="SABOR-TRADICIONAL">¡El sabor tradicional de las<br /> familias mexicanas!</label>
                                <button className="BTN-TODAS" onClick={this.controlMenuMovil}>{this.state.catgoriaSelected ? this.state.catgoriaSelected : "TODOS"}</button>

                            </div>
                        }


                    </div>

                    <div className='div-filtrar-por'>
                        <div className='divSon-filtrar-por'>
                            <ExpansionPanel bordes={true} titulo="FILTRA POR" classes="ajustes-filtrar-por"
                                descripcion={
                                    <>
                                        <Select
                                            styles={customStylesMobile}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangeSabor}
                                            options={this.optionsSabores}
                                            placeholder='SABOR'
                                            isSearchable={false}
                                        />
                                        <Select
                                            styles={customStylesMobile}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangeMomento}
                                            options={this.optionsMomentos}
                                            placeholder='MOMENTO DEL DÍA'
                                            isSearchable={false}
                                        />
                                        <Select
                                            styles={customStylesMobile}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangePara}
                                            options={this.optionsPara}
                                            placeholder='PARA'
                                            isSearchable={false}
                                        />
                                        <Select
                                            styles={customStylesMobile}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangeDificultad}
                                            options={this.optionsDificultades}
                                            placeholder='DIFICULTAD'
                                            isSearchable={false}
                                        />

                                    </>
                                } />

                        </div>

                    </div>


                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", paddingTop: "10%" }}>
                        {/*RecetasMobile
                                Las funciones para cargar las recetas en las variables del state ya no son llamadas,
                                por lo mismo se realiza el mapeo de la variable de origen en la línea que debe de aparecer
                                en lugar de mandar llamar la variable en la que se almacenaba el mapeo.
                            */}
                        {this.state.recetas_show.length > 0 && this.state.recetas_show.map((element, index) => {
                            return (
                                <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent" }} className="card col-12">
                                    <div style={{ overflow: "hidden" }}>
                                        <Fade>
                                            {element.imagen && <img alt={element.alt_imagen} className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />}
                                        </Fade>
                                    </div>
                                    <div style={{ fontFamily: "AvenirNextLTProMedium", width: "100%", marginTop: "23px", marginBottom: "23px" }}>
                                        <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br /></Fade>

                                        <Fade left>  <label style={{ fontFamily: "AvenirNextLTProMedium" }} >{element.descripcion}</label></Fade>

                                        <br /> <br />
                                    </div>
                                </div>
                            )


                        })
                        }
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <button className=" btn-carousel" onClick={() => this.loadRecipes()} style={{ display: (this.state.recetas_show.length === this.props.data.recetas.length || this.state.limitM > this.state.recetas_show.length) ? 'none' : 'block' }}  >CARGAR MÁS</button>
                    </div>


                    <TePuedeInteresarMobile></TePuedeInteresarMobile>

                </seccion>
            </>
        )
    }
}



const mapStateToProps = state => ({

    data: { ...state, recetas: state.recetas.recetas }
});

export default connect(mapStateToProps)(Recetas)