import IconTeJengibreLimon from '../assets/sobre_jengibre.png'
import IconTeVerde from '../assets/sobre_verde.png'
import IconTeVerdeMango from '../assets/sobre_verdeMango.png'
import IconTeVerdeMandarina from '../assets/sobre_verdeMandarina.png'
import TazaTeJengibreLimon from '../assets/TazaJengibre.png'
import TazaTeVerde from '../assets/TazaVerde.png'
import TazaTeVerdeMango from '../assets/TazaVerdeMango.png'
import TazaTeVerdeMandarina from '../assets/TazaVerdeMandarina.png'
import Jengibre from '../assets/jengibre.png'
import HojaTeVerde from '../assets/hojaTeVerde.png'
import Mango from '../assets/mango.png'
import Mandarina from '../assets/mandarina.png'

export default[
    {
        "id": 1,
        "iconProducto": IconTeJengibreLimon,
        "producto": "Té de jengibre con limón",
        "descripcion": "Tiene poder antinflamatorio y favorece el sistema digestivo. Gracias a la vitamina C es un gran antioxidante y ayuda en la retencion de liquidos.",
        "imagenProducto": TazaTeJengibreLimon,
        "imagenIngredientes": Jengibre,
        "fgColorTe": "#FFAC18",
        "bgColorTe": "#f8a717",
    },
    {
        "id": 2,
        "iconProducto": IconTeVerde,
        "producto": "Té verde",
        "descripcion": "Sinónimo de salud. Nos ayuda a combatir los radicales libres, manteniendo nuestro sistema inmune sano y fuerte.",
        "imagenProducto": TazaTeVerde,
        "imagenIngredientes": HojaTeVerde,
        "fgColorTe": "#DDBE01",
        "bgColorTe": "#e5c80c",
    },
    {
        "id": 3,
        "iconProducto": IconTeVerdeMango,
        "producto": "Té verde con mango",
        "descripcion": "Quemador de grasa y acelerador del metabolismo. Aporta un exquisito sabor y estimula la digestion.",
        "imagenProducto": TazaTeVerdeMango,
        "imagenIngredientes": Mango,
        "fgColorTe": "#D5AB01",
        "bgColorTe": "#dfb711",
    },
    {
        "id": 4,
        "iconProducto": IconTeVerdeMandarina,
        "producto": "Té verde con mandarina",
        "descripcion": "Contiene fibra, vitamina C. Equilibra los niveles de azucar en la sangre y disminuye el riesgo de desarrollar diabetes tipo 2.",
        "imagenProducto": TazaTeVerdeMandarina,
        "imagenIngredientes": Mandarina,
        "fgColorTe": "#FF9D1F",
        "bgColorTe": "#f19117",
    }
]