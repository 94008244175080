import React from 'react';

import cocinaMolecular from './img/IMAGEN - COCINA MOLECULAR - .jpg'
import mayodip from './img/Nota Mayodipea tu antojo WEBP.webp'
import notaAderezo from './img/Nota Aderezo.jpg'
import notasMermeladas from './img/Nota Mermeladas.webp'
import notaMayonesas from './img/Nota Mayonesa.png'
import notaMostazas from './img/Nota Mostaza.jpg'
import notaRegreso from './img/Nota Regreso a clases con Conservas con Semillas.jpg'

import notanavidad from './img/Nota Navidad.jpg'
import notaespecias from './img/Nota especias fiestas mexicanas.png'
import notadulceregreso from './img/Nota haz dulce el regreso a clases.png'
import cat1 from "./img/COCINANDO EN CASA.jpg"
import cat2 from "./img/PRUEBA ALGO NUEVO.jpg"
import cat3 from "./img/TODO SOBRE POSTRES.jpg"
import cat4 from "./img/VIDA EN BALANCE.jpg"
import cat5 from "./img/PARRILLADAS.jpg"
import tip from './img/Enmascarar grupo 25.png'
import tipRadio from './img/Grupo 1482.png'
import './index.css'
import instaimg from './img/Costillas.png'
import instaimg1 from './img/te.png'
import instaimg2 from './img/Alitas.png'
import instaimg3 from './img/Elotes.png'
import instaimg4 from './img/Balance.png'
import instaimg5 from './img/Camaroncitos.png'
import instaimg6 from './img/Mostaza.png'
import instaimg7 from './img/Hamburguesa.png'
import instaimg8 from './img/Nota descubre la especia.jpg'
import notaDetox from './img/Nota detox.jpg'
import notaVersion from  './img/Nota vive la mejor version.jpg'
import notaNorte from './img/Nota sabor del norte.jpg'
import notaNFL from './img/Nota empieza la temporada de NFL.png'
import notaplaya from './img/Nota La playa también sabea balance.png'
import notaComodesintoxicar from './img/Nota Rutina Detox.webp'
import notaInvernal from './img/Nota disfruta la temporada invernal.png'
import notaNavideña from './img/Nota cómo organizar una cena navideña.png'

//Blogs 2022
import notaMejor from './img/Nota Comienza a sentirte mejor.webp'
import notaSorprende from './img/Soprende a tus pequeños.webp'
import notaAderezos from './img/Nota Aderezos con mayonesa.webp'
import notasTes from './img/Nota Regalos con té .webp'
import notaMatch from './img/NotaMatcha.webp'
import notaNflAlitas from './img/Nota NFL alitas caseras.webp'
import notaNavideña2022 from './img/Nota Fiestas Navideñas.webp'
const Dummy = {

    instaLink: [
        {
            descripcion: "Prepara unas ricas costillitas con el toque picosito de la Mermelada de Frutos Rojos con Chile Morita. Pide la receta en comentarios. #FrutosRojos #Costillas #Comida #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CDusR2KDEIX/",
            imagen: instaimg
        },
        {
            descripcion: "Selecciona tu Té McCormick favorito y prepara un delicioso smoothie. ¡Ánimate a probalo!.....#tésmccormick  #smoothierecipes #smoothie #fruit #foodie #fresh #healthyfood #tea #tealover  #weekendvibes",
            link: "https://www.instagram.com/p/B9cndv2Hx7E/",
            imagen: instaimg1

        },
        {
            descripcion: "¡Dale un sabor diferente a las alitas! Agrégale el sabor picosito de la Mermelada de Frutos Rojos con Chile Morita McCormick. #MermeladasConChile #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CG1JSTXBeXe/",
            imagen: instaimg2

        },
        {
            descripcion: "Unos deliciosos elotitos con Mayonesa McCormick se hicieron para disfrutarse todos los días. #Elote #Tasty #VivaMexico #15septiembre #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CFIxbKzBIWC/",
            imagen: instaimg3

        },
        {
            descripcion: "Disfruta de un desayuno equilibrado y rico con el toque especial de Mayonesa McCormick Balance con aceite de Aguacate. 🥑#MayonesaBalance #AceiteAguacate #Healthy #Fit #healthyfood #healthylifestyle #healthyeating #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CF92ubznr-Z/",
            imagen: instaimg4

        },
        {
            descripcion: "Al preparar unos ricos camaroncitos, agrégales Pimienta con Limón McCormick al gusto y descubre su sabor. ....#Camarones #Pimienta #Sabor #Comida #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CBjq5LAFtD6/",
            imagen: instaimg5

        },
        {
            descripcion: "¡La botana perfecta sí existe! Disfruta de unos deditos de queso con Mostaza McCormick.     #mostazamccormick #botanas #foodie #foodporn #foodphotography #tasty #tastyfood #tasteofhome #home #homemade #homemadefood #encasa",
            link: "https://www.instagram.com/p/CC9uTuCF4sA/",
            imagen: instaimg6

        },
        {
            descripcion: "¿Buscas transformar el sabor de las hamburguesas? Hazlo con la Mayonesa sabor Guacamole McCormick. 😋 #Mayosabores #McCormick #Guacamole #Burger #foodie #foodporn #foodphotography #tasty #tastyfood",
            link: "https://www.instagram.com/p/CDh_p53hM0q/",
            imagen: instaimg7

        }
    ],
    categoriaBlog: [
        {
            nombre: "COCINANDO EN CASA",
            imagen: cat1,
            notas: [
                {
                    url:"menu-navideno-facil",
                    nombre: "Cocina un menú navideño fácil y disfruta esta temporada",
                    descripcion: "Ya viene diciembre y con él, la temporada de cocinar platillos deliciosos para deleitar a todos tus invitados. En McCormick lo sabemos, por eso te compartimos estas deliciosas recetas fáciles de preparar para que no te la vivas en la cocina y disfrutes la sobre mesa con tus seres queridos.",
                    imagen: notaNavideña2022,
                },
                {
                    url:"organizar-cena-navidena-tradicional",
                    nombre: "Cómo organizar una cena navideña tradicional ",
                    descripcion: "La Navidad es la mejor época para dejar volar la creatividad y experimentar en la cocina. Ya sea siguiendo la receta tradicional o bien creando una nueva receta, no cabe duda que hay tantas Navidades como sabores cuando mezclas tus especias favoritas, un rico té y sorprendes a todos con un pavo glaseado con mermelada.",
                    imagen: notaNavideña,
                },
                {
                    url:"especias-fiestas-patrias-mexicanas",
                    nombre: "Las especias en las fiestas patrias mexicanas",
                    descripcion: "En este mes muy mexicano, no hay nada como preparar los auténticos platillos de nuestro país con el toque único de las especias McCormick, que realzan el sabor de todos tus platillos y le dan el toque que le encantará a tus comensales. Desde el orégano, las sales con hierbas, especias, chiles y mucho más este mes será todo un viaje de sabor.",
                    imagen: notaespecias,
                },
                {
                    url:"platillos-navidenos-faciles-cena-familia",
                    nombre: "Platillos navideños fáciles para una cena en familia",
                    descripcion: "Sabemos que la Navidad es la mejor época para reunirnos a la mesa y conservar las tradiciones de cada familia, por eso este año te compartimos algunas recetas con el toque tradicional de McCormick para que todo sepa a Navidad.",
                    imagen: notanavidad,
                },
                {
                    url:"mayonesa-limon-acompanante-ideal-botanas",
                    nombre: "Mayonesa con limón: el acompañante ideal para botanas",
                    descripcion: "Conoce las deliciosas botanas que puedes preparar con Mayonesa McCormick® con jugo de limón. Para esos días en los que tienes un antojo incontrolable y muchas ganas de disfrutar una película, te dejamos algunas recetas para acompañar el momento con el mejor sabor.",
                    imagen: notaMayonesas,
                },
                {

                    url:"como-usar-mostaza-comida",
                    nombre: "¿Cómo usar mostaza en la comida?",
                    descripcion: "Tu comida jamás volverá a ser aburrida, agrégale un toque de Mostaza McCormick®, le dará todo el sabor que te gusta sin añadirle calorías extra. Conoce todos estos tips aquí.",
                    imagen: notaMostazas,
                },
                {
                    url:"aderezos-mayonesa-pescados-mariscos",
                    nombre: "Aderezos con mayonesa para acompañar pescados y mariscos",
                    descripcion:"¿Con que acompañas tus mariscos? En esta época de calor refrescamos el sabor de tu mariscadas, pescados, cócteles, filetes y más con las deliciosas combinaciones que puedes preparar tú mismo con Mayonesa McCormick. Aprende a hacerlos fácil y rápido.",
                    imagen: notaAderezos,
                },
                {
                    url:"especias-para-antojitos-mexicanos",
                    nombre: "Ponle sazón a tu Fiesta Mexicana con especias McCormick",
                    descripcion: "Te enseñamos a utilizar las especias McCORMICK para preparar y confimentar estas deliciosas flautas ahogadas callejeras. ¡Deléitate con su sabor!",
                    imagen: require("./img/Nota Fiesta Mexicana con especias McCormick.jpg"),
                },
            ]
        },
        {
            nombre: "PRUEBA ALGO NUEVO",
            imagen: cat2,
            notas: [
                {
                    url:"mayodipea-antojo-detona-sabor-botanas",
                    nombre: "Mayodipea a tu antojo y detona el sabor de tus botanas",
                    descripcion: "Conoce la nueva línea de Mayodips de McCormick. Dips de tres diferentes sabores perfectos para untar y sumergir tu botana preferida mientras disfrutas de una película, haces homeoffice o estás en una reunión con tus amigos. ¡Te encantarán!",
                    imagen: mayodip,
                },
                {
                    url:"cocina-molecular-4-tecnicas-casa",
                    nombre: "Cocina molecular: 4 Técnicas que puedes hacer en casa",
                    descripcion: "Prepárate para los experimentos más increíbles de cocina molecular desde la cocina de tu casa con McCormick®. Descubre con estas increíbles recetas el mundo de posibilidades que te ofrece la cocina molecular y lánzate a preparar estos increíbles y divertidos platillos. Nunca dejes de experimentar en la cocina.",
                    imagen: cocinaMolecular,
                },
                {
                    url:"temporada-invernal-sabor-tes",
                    nombre: "Disfruta la temporada invernal con el delicioso sabor de los tés",
                    descripcion: "Comienza la temporada de frío y no hay nada mejor para entrar en calor y activar a nuestro sistema inmune que una taza de té. Te contamos sobre sus sus propiedades, sobre las tendencias alrededor del mundo y te decimos cómo preparar unos postres terroríficos para tu fiesta de disfraces. ",
                    imagen: notaInvernal,
                },
                {

                    url:"curcuma-especia-sabor-unico",
                    nombre: "Cúrcuma: La especia que da un sabor único",
                    descripcion: "La cúrcuma llegó para quedarse y para darle un giro de sabor a todos tus platillos, con ella puedes preparar desde la famosa “leche dorada” hasta recetas dulces o saladas, siempre con un toque único que a todos les encantará.",
                    imagen: instaimg8
                },
                {
                    url:"regalos-te-maestro",
                    nombre: "Regalos con té para sacar un 10 con tu maestro",
                    descripcion: "¿Aún no tienes regalo para el día del maestro? ¡Descuida! Con estas originales propuestas de regalos hechos con Tés McCormick, regala-té un 10 en todas tus materias.",
                    imagen: notasTes,
                }
            ]
        },
        {
            nombre: "TODO SOBRE POSTRES",
            imagen: cat3,
            notas: [
                // {
                //     url:"haz-dulce-regreso-a-clases",
                //     nombre: "Haz dulce este regreso a clases",
                //     descripcion: "Este regreso a clases encuentra deliciosas maneras de sorprender a tu familia, con postres que llenen sus días de sabor y que a ti te dejen tranquila de darles lo mejor de la fruta hecha mermelada como las de McCormick. Prueba diferentes recetas y haz que sin importar si las clases son en la escuela o en casa, sienta tu amor cerca.",
                //     imagen: notadulceregreso,
                // },
                {
                    url:"postres-te-matcha-fiesta",
                    nombre: "Postres con té matcha para tu fiesta de disfraces",
                    descripcion: "¡Sustos que dan gusto! En esta temporada de sustos, agrégale sabor a tus fiestas con estos deliciosos postres hechos con Té Matcha de McCormick, el té que matcha a tu medida. Con esta receta, serás el mejor anfitrión de esta escalofriante temporada.",
                    imagen: notaMatch,
                },
                {
                    url:"aderezo-mayonesa-ingrediente-postres",
                    nombre: "Aderezo de mayonesa: el ingrediente perfecto para postres",
                    descripcion: "El secreto mejor guardado para la repostería es hacerlos con Aderezo de Mayonesa McCormick®, si quieres llevar tus postres a otro nivel, te compartimos los mejores tips para volverte un experto.",
                    imagen: notaAderezo,
                },
                {
                    url:"usos-mermeladas-postre",
                    nombre: "Usos que no conocías de las mermeladas en un postre",
                    descripcion: "La mejor manera de conservar el sabor y los beneficios de la fruta es incluirla en más momentos de tu vida. Porque existen múltiples maneras de combinarlas en platillos que van más allá del desayuno o su consumo tradicional. Conoce más opciones deliciosas para compartir con tu familia y descubrir un universo de colores y sabores frutales.",
                    imagen: notasMermeladas,
                },
                {
                    url:"soprende-postres-saludables-mermelada",
                    nombre: "Sorprende a tu pequeño con estos postres saludables con mermelada",
                    descripcion: "Sabemos que los más pequeños del hogar siempre tienen lugar para el postre. Sabemos también que te preocupas por su salud y lo que come. ¡Dale algo nutritivo! Descubre estos deliciosos y saludables postres hechos con mermeladas McCormick.",
                    imagen: notaSorprende,
                },
                {
                    url:"postres-para-ninos",
                    nombre: "Recetas de postres con conservas de semillas para el Regreso a Clases",
                    descripcion: "Aprende a preparar deliciosos postres con conservas de semillas McCORMICK para este regreso a clases y llena de energía a tus peques.",
                    imagen: notaRegreso,
                }
            ]
        },
        {
            nombre: "VIDA EN BALANCE",
            imagen: cat4,
            notas: [
                {
                    url:"desintoxicar-recetas-plan-detox",
                    nombre: "Cómo desintoxicar tu cuerpo incluyendo estas deliciosas recetas en tu plan Detox",
                    descripcion: "Durante las vacaciones también podemos cuidarnos sin perder el sabor de las delicias del mar. Con la línea Balance que McCormick tiene para ti es posible, porque cualquier platillo puedes acompañarlo una de las 3 presentaciones de Mayonesa Balance, hechas de aceites naturales, para que tú disfrutes sin culpa. ",
                    imagen: notaComodesintoxicar,
                },
                {
                    url:"playa-sabe-plataforma-balance",
                    nombre: "La playa también sabe a balance",
                    descripcion: "Comienza tu rutina Detox con estas deliciosas recetas.¡No hay pretextos! Inicia tu proceso de desintoxicación sin romper la dieta con estas riquísimas recetas hechas con Tés de la familia Detox de McCormick que seguro querrás preparar más de una vez. ¡Perfectas para compartir con toda la familia!",
                    imagen: notaplaya,
                },
                {
                    url:"detox-natural-tes",
                    nombre: "Haz un detox natural con tés McCormick",
                    descripcion: "Conoce los beneficios que le trae a tu cuerpo un detox. Descubre si ya necesitas uno y cómo llevarlo a cabo con el delicioso sabor de Tés McCormick®, dale a tu cuerpo ese break que necesita y cambia tus días por completo.",
                    imagen: notaDetox,
                },
                {
                    url:"mejora-habitos-alimenticios-disfruta-vida-en-balance",
                    nombre: "Disfruta una vida en balance y mejora tus hábitos alimenticios",
                    descripcion: "En la vida el balance es esencial y a veces necesitamos ese algo que nos ayude a estar de nuevo en sintonía. Descubre cómo encontrarlo con la línea Balance de McCormick® y nuestros tips para vivir la mejor versión de ti, no esperes más.",
                    imagen: notaVersion,
                },
                {
                    url:"sentirte-mejor-mayonesas-mccormick-balance",
                    nombre: "Comienza a sentirte mejor este 2022 con las Mayonesas McCormick Balance",
                    descripcion: "Nunca es tarde para cumplir tus propósitos del año. Mayonesas McCormick Balance te acompañan en tu camino a una vida más saludable y en armonía contigo mismo. Descubre cómo nuestra línea de mayonesas te ayuda a mantener un estilo de vida mientras disfrutas del rico sabor de sus aceites de aguacate, ajonjolí y canola.",
                    imagen: notaMejor,
                }
            ]
        },
        {
            nombre: "PARRILLADAS",
            imagen: cat5,
            notas: [
                {
                    url:"como-preparar-alitas-caseras",
                    nombre: "NFL: Cómo preparar las alitas caseras perfectas",
                    descripcion: "¡La NFL llega a México! Ponle sabor al juego con nuestra gran variedad de productos McCormick. Con estas deliciosas recomendaciones te enseñamos a preparar las botanas y bebidas perfectas para que no falte en tu mesa el sabor y la emoción de la NFL.Sigue nuestra receta especial y anotarás un touchdown en cada partido.",
                    imagen: notaNflAlitas,
                },
                {
                    url:"especias-mexicanas-norte-sabor-unico",
                    nombre: "Especias mexicanas del norte que dan un sabor único",
                    descripcion: "Las especias le ponen sabor a la vida. Y en el norte de nuestro país hay sabores deliciosos que nos gustaría probar todos los días. Ahora, ya puedes hacerlo gracias a Grill Mates de McCormick® y Sazonadores del Norte McCormick®, conoce los mejores tips y recetas para llevar tu asado a otro nivel.",
                    imagen: notaNorte,
                },
                {
                    url:"tips-disfrutar-nfl-mucho-sabor",
                    nombre: "Empieza la temporada de NFL y viene con todo el sabor",
                    descripcion: "Llegó la temporada más esperada del año, donde tu equipo favorito será el pretexto perfecto para reunirte a preparar unas botanas rápidas utilizando lo que tienes en tu refri junto con un poco de especias, mayonesa y mostaza. Elige tu favorita y sigue los tips que te damos para apoyar con tus amigos y familiares a tu equipo favorito.",
                    imagen: notaNFL,
                },
                {
                    url:"especias-condimentos-para-parrilladas",
                    nombre: "Parrillada para celebrar en familia con Grill Mates de McCormick",
                    descripcion: "Conoce las mejores especias y condimentos de Grill Mates McCORMICK para sazonar tu carne asada. Conviértete en un parrillero experto con estas recetas.",
                    imagen: require('./img/Nota Parrillada para celebrar en familia con Grill Mates.jpg'),
                },
                {
                    url:"recetas-con-mayonesas-de-sabores",
                    nombre: "En estas vacaciones, transforma el sabor de tus antojos con Mayosabores McCORMICK",
                    descripcion: "Con estas recetas aprenderás cómo darle un toque especial a tus alimentos con los deliciosos aderezos de mayonesa de sabores McCORMICK.",
                    imagen: require('./img/Nota transforma el sabor de tus antojos con Mayosabores.png'),
                }
            ]
        }
    ],
    tips: [
        {
            imagen: tip,
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
            titulo: "Porque comer delicioso es lo mejor",
            imagenR: tipRadio

        },
        {
            imagen: tip,
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
            titulo: "Porque comer delicioso es lo mejor",
            imagenR: tipRadio
        },
        {
            imagen: tip,
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
            titulo: "Porque comer delicioso es lo mejor",
            imagenR: tipRadio
        }, {
            imagen: tip,
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
            titulo: "Porque comer delicioso es lo mejor",
            imagenR: tipRadio
        }
    ]

}
export default Dummy;