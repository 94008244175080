const OBTENER_METADATA_RECETAS = "OBTENER_METADATA_RECETAS";


let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_METADATA_RECETAS:
            return { ...state, metadata: action.metadata }

        default:
            return state;
    }

}

export default reducer;