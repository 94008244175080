import React from 'react'
import esquites from './img/esquites.png'



export default[ {
    "id":1,
    "sabor":"sabor1",
    "momento":"momento1",
    "para" :"para1",
    "dificultad":"dificultad1",
    "popular":true,
    "idCategoria":1,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":2,
    "sabor":"sabor2",
    "momento":"momento2",
    "para" :"para2",
    "dificultad":"dificultad2",
    "popular":true,
    "idCategoria":2,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":3,
    "sabor":"sabor3",
    "momento":"momento3",
    "para" :"para3",
    "dificultad":"dificultad3",
    "popular":true,
    "idCategoria":3,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":4,
    "sabor":"sabor4",
    "momento":"momento4",
    "para" :"para4",
    "dificultad":"dificultad4",
    "popular":true,
    "idCategoria":4,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":5,
    "sabor":"sabor5",
    "momento":"momento5",
    "para" :"para5",
    "dificultad":"dificultad5",
    "popular":false,
    "idCategoria":5,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":6,
    "sabor":"sabor6",
    "momento":"momento6",
    "para" :"para6",
    "dificultad":"dificultad6",
    "popular":false,
    "idCategoria":6,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
}
,
{
    "id":7,
    "sabor":"sabor6",
    "momento":"momento6",
    "para" :"para6",
    "dificultad":"dificultad6",
    "popular":false,
    "idCategoria":7,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":8,
    "sabor":"sabor8",
    "momento":"momento8",
    "para" :"para8",
    "dificultad":"dificultad8",
    "popular":false,
    "idCategoria":8,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
    "id":9,
    "sabor":"sabor9",
    "momento":"momento9",
    "para" :"para9",
    "dificultad":"dificultad9",
    "popular":false,
    "idCategoria":5,
    "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
    "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
    "tiempo":"23 min",
    "personas":"12 personas",
    "horno":"30 min",
    "imagen":esquites,
    "ingredientes": <ul style={{ margin: "0px" }} >
    <li>2 bolsas de nachos </li>
    <li>500 g de frijoles refritos</li>
    <li>Mayonesa de Guacamole McCormick.</li>
    <li>1 bolsa de queso fundido para nachos</li>
    <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
    <li>Rajas Herdez</li>
  </ul>,
    "preparacion": <p >
    -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
    -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
    -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

    *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
    "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
},
{
  "id":10,
  "sabor":"sabor9",
  "momento":"momento9",
  "para" :"para9",
  "dificultad":"dificultad9",
  "popular":false,
  "idCategoria":5,
  "nombre":"ROLLITO DE VEGETALES CON QUESO Y MOSTAZA",
  "descripcion":"Sirve este excelente rollito para botanear el fin de semana. Comienza con un base de crema, queso y dale un toque con la tradicional Mostaza McCormick®.",
  "tiempo":"23 min",
  "personas":"12 personas",
  "horno":"30 min",
  "imagen":esquites,
  "ingredientes": <ul style={{ margin: "0px" }} >
  <li>2 bolsas de nachos </li>
  <li>500 g de frijoles refritos</li>
  <li>Mayonesa de Guacamole McCormick.</li>
  <li>1 bolsa de queso fundido para nachos</li>
  <li>Salsa Búfalo Botanera o clásica líquida al gusto</li>
  <li>Rajas Herdez</li>
</ul>,
  "preparacion": <p >
  -En un refractario coloca la mitad de los nachos al fondo, la mitad de los frijoles encima y después la mitad del queso. Mételo al horno por 5 minutos a 180 ºC. No dejes que se quemen, solo se debe conseguir que se funda el queso.<br />
  -Saca el refractario del horno cuando el queso se haya fundido y coloca el resto de los ingredientes. Igual que la primera vez, lo metes al horno nuevamente hasta que se funda el queso que acabas de poner. <br />
  -Saca el refractario cuando el queso este fundido, acompaña con Mayonesa de Guacamole McCormick, Salsa Búfalo al gusto y Rajas Herdez.<br />

  *Puedes agregar trozos de arrachera o pollo a la parrilla sazonada con Grill Mates Chipotle & Roasted Garlic.
</p>,
  "video":"https://herdezstorage.blob.core.windows.net/publicresources/recetas-grupo/videos/HoyToca%20Tostadas%20de%20picadillo.mp4"
}
]