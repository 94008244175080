import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper'


import banner1 from './img/1mermelada gde.png'
import banner2 from './img/2tes gde.png'
import banner3 from './img/3especias gde.png'
import banner4 from './img/4balance_desktop.png'
import banner5 from './img/5BBQ2_desktop.png'



import bannerm1 from './img/1mermelada chico.png'
import bannerm2 from './img/2tes chico.png'
import bannerm3 from './img/3especias chico.png'
import bannerm4 from './img/4balance_mobile.png'
import bannerm5 from './img/5BBQ2_mobile.png'

import isq from './img/isq.svg'
import der from './img/der.svg'
import isqBlanco from './img/isq - copia.svg'
import derBlanco from './img/der - copia.svg'
import insta from './img/insta.svg'
import face from './img/face.svg'
import './index.css'
import axios from 'axios'
import Dummy from './Dummy'
import { Helmet } from 'react-helmet'

export default class Blog extends PureComponent {
  constructor() {
    super()
    this.state = {
      Dummy,
      instagram: [],
      facebook: [],
      banner: [],
      menu: [],
      blogs: []
    }
  }

  loadData = async () => {

    const resFace = await axios.get(process.env.REACT_APP_API_URL_NEW + "/redes_facebook");
    const resInst = await axios.get(process.env.REACT_APP_API_URL_NEW + "/redes_instagram");
    const banner = await axios.get(process.env.REACT_APP_API_URL_NEW + "/banners_blogs?fields=*.*");
    const blog = await axios.get(process.env.REACT_APP_API_URL_NEW + "/blogs?fields=*.*&filter[estatus]=published");
    const blogFiltros = await axios.get(process.env.REACT_APP_API_URL_NEW + "/Filtros_blogs");
    this.setState({
      instagram: resInst.data.data,
      facebook: resFace.data.data,
      banner: banner.data.data,
      menu: blogFiltros.data.data,
      blogs: blog.data.data
    })

  }
  nextBlogfm = () => {

    const swiperContainer = document.getElementsByClassName("swiper-blog-facebook-m")[0].children;

    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  componentDidMount() {
    this.loadData()
  }
  prevBlogfm = () => {
    const swiperContainer = document.getElementsByClassName("swiper-blog-facebook-m")[0].children;

    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }

  nextBlogf = () => {

    const swiperContainer = document.getElementsByClassName("swiper-blog-facebook")[0].children;

    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  prevBlogf = () => {
    const swiperContainer = document.getElementsByClassName("swiper-blog-facebook")[0].children;

    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }
  nextBlog = () => {

    const swiperContainer = document.getElementsByClassName("swiper-blog")[0].children;

    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  prevBlog = () => {
    const swiperContainer = document.getElementsByClassName("swiper-blog")[0].children;

    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }
  nextBlogm = () => {

    const swiperContainer = document.getElementsByClassName("swiper-blog-mobile")[0].children;

    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  handleCategoriaNota = (nombre) => {

    this.state.Dummy.categoriaBlog.map((element, index) => {
      element.notas.map((elementn, indexn) => {
        if (nombre === elementn.nombre) {
          window.scrollTo(0, 0);
          window.location.href = ("/blog/" + element.nombre.replace(/ /g, "-").replace(/'/g, "").replace(/,/g, "").toLowerCase() + "/" + nombre.replace(/ /g, "-").replace(/'/g, "").replace(/,/g, "").toLowerCase())
        }
      })
    })

  }
  prevBlogm = () => {
    const swiperContainer = document.getElementsByClassName("swiper-blog-mobile")[0].children;

    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }

  render() {
    const params = {
      slidesPerView: 1,
      // spaceBetween: 0,
      // loop: true
    }
    const paramsf = {
      slidesPerView: 3,
      spaceBetween: 10,
      loop: false
    }
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ideas para cada ocasión | Blog McCormick</title>
          <meta name="description" content="En nuestro blog aprenderás a usar los últimos productos de McCormick en parrilladas, postres, alimentos saludables y más." />
        </Helmet>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div style={{ position: "relative", width: "96%" }} className="swiper-blog">
            {this.state.banner.length > 0 && <Swiper {...params}>
              {this.state.banner.map((e, i) => {
                return (
                  <div className="banner-desk" style={{ position: "relative" }}>
                    <img alt={e.alt_imagen} src={process.env.REACT_APP_CMS_ASSETS + e.imagen.id} className="imagen-banner-blog" />
                  </div>
                )
              })}
            </Swiper>
            }
            <img onClick={() => this.prevBlog()} src={isqBlanco} className="isq-blog" />
            <img onClick={() => this.nextBlog()} src={derBlanco} className="der-blog" />
          </div>
          <div style={{ position: "relative", width: "96%" }} className="swiper-blog-mobile">
            {this.state.banner.length > 0 && <Swiper {...params}>
              {this.state.banner.map((e, i) => {
                return (
                  <div className="banner-mobile" style={{ position: "relative" }}>
                    <img src={process.env.REACT_APP_CMS_ASSETS + e.imagen.id} className="imagen-banner-blog" />
                  </div>
                )
              })}
            </Swiper>
            }
            <img onClick={() => this.prevBlogm()} src={isqBlanco} className="isq-blog" />
            <img onClick={() => this.nextBlogm()} src={derBlanco} className="der-blog" />
          </div>
          <div className="container-menu-blog"  >
            <label className="que-se-antoja" style={{ marginLeft: "4%", fontSize: "1.7em" }} >¿Qué se te antoja hoy?</label>
            <div style={{ display: "flex" }}>
              {this.state.menu.length > 0 && this.state.menu.map((element, index) => {
                return <div onClick={() => { window.location.href = ("/blog/" + element.url.replace(/ /g, "-").toLowerCase()) }} style={{ cursor: 'pointer' }} className="container-menu-item">
                  <div className="container-foto" >
                    <div style={{ position: "absolute" }} class="lds-dual-ring"></div>
                    <img className="hvr-float-shadow image-menu" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                  </div>
                  <label style={{ marginTop: "5%" }}>{element.titulo}</label>
                </div>
              })
              }
            </div>
          </div>
          <div className="container-notas"  >
            {this.state.blogs.length > 0 && (() => {
              return this.state.blogs.map((element, index) => {
                if (element.filtro.url === this.props.match.params.categoria) {
                  return (
                    <div onClick={() => { window.scrollTo(0, 0); window.location.href = ("/blog/" + element.filtro.url + "/" + element.url) }} className="container-tip">
                      <div className="container-tips">
                        <img alt={element.alt_banner} src={process.env.REACT_APP_CMS_ASSETS + element.banner.id} className="image" style={{ maxWidth: "100%", height: "auto" }} />
                        <div className="overlay">
                          <div className="text">{element.resumen}</div>
                        </div>
                      </div>
                      <label className="title-blog" >{element.titulo}</label>
                    </div>
                  )
                }
                if (!this.props.match.params.categoria && element.pagina_principal) {
                  return (
                    <div onClick={() => { window.scrollTo(0, 0); window.location.href = ("/blog/" + element.filtro.url + "/" + element.url) }} className="container-tip">
                      <div className="container-tips">
                        <img alt={element.alt_banner} src={process.env.REACT_APP_CMS_ASSETS + element.banner.id} className="image" style={{ maxWidth: "100%", height: "auto" }} />
                        <div className="overlay">
                          <div className="text">{element.resumen}</div>
                        </div>
                      </div>
                      <label className="title-blog" >{element.titulo}</label>
                    </div>
                  )
                }
              })
            })()}
          </div>
          <div style={{ width: "95%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "10%" }}>
            <div style={{ width: "88%" }}>
              <label className="redes-text">Síguenos en redes</label><br />
              <label style={{ fontFamily: "AvenirBook" }}>Síguenos en nuestras redes sociales y encuentra inspiración para más recetas.</label><br />
              <div onClick={() => window.open("https://www.instagram.com/mccormick_mx/", "_blank")} style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", padding: "2%" }}>
                <img className="insta-img" src={insta} /><label className="title-redes">McCormick_Mx</label>
              </div>
            </div>
            <div style={{ display: "flex", width: "95%", position: "relative", flexWrap: "wrap", justifyContent: "center" }} >
              {this.state.instagram.length > 0 && (() => {
                return this.state.instagram.map((element, index) => {
                  return (
                    <div onClick={() => window.open(element.url_publicacion, "_blank")} className="insta-container" >
                      <div className="container-tips">
                        <img src={process.env.REACT_APP_CMS_ASSETS + element.imagen} className="image" style={{ maxWidth: "100%", height: "auto", borderRadius: "17px" }} />
                        <div style={{ borderRadius: "17px", background: "#d6d8d6" }} className="overlay">
                          <div style={{ color: "#2a1512" }} className="text">{element.texto}</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              })()}
            </div>
          </div>
          <div className="container-facebook" >
            <div style={{ width: "85%" }}>
              <div onClick={() => window.open("https://www.facebook.com/McCormickMexico", "_blank")} style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", padding: "2%" }}>
                <img src={face} className="insta-img" /><label className="title-redes">McCormick México</label>
              </div>
            </div>
            <div className="facebook-desk">
              <div style={{ position: "relative", width: "96%" }} className="swiper-blog-facebook">
                {this.state.facebook.length > 0 && <Swiper {...paramsf}>
                  {(() => {
                    return this.state.facebook.map((element, index) => {
                      return (
                        <div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }} >
                          <iframe class="fb-post" data-show-text="true" data-width="100%" src={element.url_publicacion}></iframe>
                        </div>
                      )
                    })
                  })()}
                </Swiper>}
                <img onClick={() => this.prevBlogf()} src={isq} className="isq-blog" />
                <img onClick={() => this.nextBlogf()} src={der} className="der-blog" />
              </div>
            </div>
            <div className="facebook-mobile">
              <div style={{ position: "relative", width: "85%" }} className="swiper-blog-facebook-m">
                {this.state.facebook.length > 0 && <Swiper {...params}>
                  {(() => {
                    return this.state.facebook.map((element, index) => {
                      return (
                        <div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }} >
                          <iframe class="fb-post" data-show-text="true" data-width="100%" src={element.url_publicacion}></iframe>
                        </div>
                      )
                    })
                  })()}
                </Swiper>}
                <img onClick={() => this.prevBlogfm()} src={isq} style={{ left: "-11%", maxWidth: "10%" }} className="isq-blog" />
                <img onClick={() => this.nextBlogfm()} src={der} style={{ right: "-11%", maxWidth: "10%" }} className="der-blog" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}