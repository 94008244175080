import React, { PureComponent } from 'react'
import axios from 'axios'
import Swiper from 'react-id-swiper'
import Fade from 'react-reveal/Fade'
import history from '../../history'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import { connect } from 'react-redux'
class TePuedeInteresar extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            categoriasRelacionadas: []
        }
    }
    componentDidMount() {
        this.LoadRecetas()
    }
    nextswiper = () => {
        const swiperContainer = document.getElementsByClassName("swiper-recetas-p")[0].children;
        var mySwiper = swiperContainer[0].swiper
        mySwiper.slidePrev()
    }
    nextprev = () => {
        const swiperContainer = document.getElementsByClassName("swiper-recetas-p")[0].children;
        var mySwiper = swiperContainer[0].swiper
        mySwiper.slideNext();
    }
    LoadRecetas = async () => {
        const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/tags?fields=*.*,recetas.recetas_id.*&filter[nombre]=Te puede interesar`);
        const dataPublic = this.props.data ? this.props.data.filter((e) => { return (e.estatus === "published") }) : null
        const recPublic = categoriasRelacionadas.data.data[0].recetas.filter((e) => { return (e.recetas_id.estatus === "published") })
        this.setState({
            categoriasRelacionadas: this.props.data ? this.props.data.length > 0 ? dataPublic : recPublic.map((e) => { return (e.recetas_id) }) : recPublic.map((e) => { return (e.recetas_id) })
        })
    }
    render() {
        const paramsRecetasplatillo = {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 30,
            loop: false,
            autoplay: {
                delay: 3200,
                disableOnInteraction: false
            },
            loopFillGroupWithBlank: false,
        }
        return (
            <>
                <div style={{ width: "95%", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", position: "relative", zIndex: "1" }}>
                    <div style={{ width: "95%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "95%", paddingLeft: "15px", marginTop: "30px" }} >

                            <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">{this.props.titulo ? this.props.titulo : "TE PUEDE INTERESAR"}</label><br />
                        </div>
                        <img src={isq} onClick={() => { this.nextswiper() }} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", left: "-6px", cursor: "pointer" }} />
                        <div style={{width:'100%',position:'relative'}}>
                        {
                           this.state.categoriasRelacionadas.length > 0 &&
                                    <Swiper {...paramsRecetasplatillo}>
                                        {
                                            this.state.categoriasRelacionadas.map((element, index) => {
                                                return (
                                                    <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.url}`) }} style={{ border: "0px solid transparent", cursor: "pointer" }} >
                                                        <div style={{ overflow: "hidden" }}>
                                                            <img className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                                                        </div>
                                                        <div style={{ width: "100%", fontFamily: "AvenirNextLTProMedium", marginTop: "23px", marginBottom: "23px" }}>
                                                            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                                                            <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label>
                                                            <br /> <br />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Swiper>
                        }
                        </div>
                        <img onClick={() => { this.nextprev() }} src={der} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", right: "-6px", cursor: "pointer" }} />
                    </div>
                </div>
            </>
        )
    }
}


export default (TePuedeInteresar)