import React,{PureComponent, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'


export default class TerminosModal extends PureComponent {
    constructor(props, context) {
      super(props, context);

  
      this.state = {
        show: false,
      };
    }
  
    render() {
      return (
        <Fragment>
  
          <Modal size="lg" show={this.props.modal} onHide={this.props.handleModal}>
            <Modal.Header closeButton>
              <Modal.Title>TÉRMINOS Y CONDICIONES LEGALES</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Está usted accediendo al <b>Sitio</b> de <b>McCormick de México, S.A. de C.V.</b> (en lo sucesivo <b>“McCormick”</b>).<br/><br/>

            Por favor lea cuidadosamente los Términos y Condiciones Legales que regulan el acceso y uso por parte de los usuarios 
            (el <b>“Usuario”</b> o <b>“Usuarios”</b>) del sitio web de <b>McCORMICK DE MÉXICO, S.A. DE C.V.</b> (en lo sucesivo <b>“McCormick”</b>), cuyo 
            dominio es <a href="https://www.mccormick.mx/" target="_blank">www.mccormick.mx</a> y/o afiliadas y/o subsidiarias y/o cualquier página y/o aplicación de las marcas 
            pertenecientes a McCormick (en adelante el <b>“Sitio”</b>), ya que el acceso y uso del <b>Sitio</b> atribuye la condición de 
            Usuario del mismo y constituye el entendimiento y la aceptación de los Términos y Condiciones Legales aquí establecidos. <br/><br/>


            Este <b>Sitio</b> puede ser visitado por cualquier <b>Usuario</b> de forma libre y gratuita sujeto a los presentes Términos y Condiciones Legales.<br/><br/>

            El contenido de este <b>Sitio</b> tiene como finalidad dar a conocer al <b>Usuario</b>, información general relacionada con <b>McCormick</b>, lo cual no implica ni garantiza que 
            la <b>Información</b> aquí contenida pueda satisfacer los requisitos de precisión, detalle o exactitud requerida a juicio del Usuario. En tal virtud, el contenido 
            es de índole informativo, por lo que la interpretación es personal de cada <b>Usuario.</b><br/><br/>

            <b>McCormick</b> no asume responsabilidad alguna, derivada de que la <b>Información</b> disponible en este Sitio no sea precisa o completa y se reserva el derecho de modificar 
            el contenido del <b>Sitio</b> en cualquier momento y sin previo aviso al <b>Usuario</b>, incluyendo los presentes Términos y Condiciones Legales. Las modificaciones que se 
            hagan producirán efecto en forma inmediata a su incorporación en el <b>Sitio.</b><br/><br/> 


            La información de este <b>Sitio</b> es en relación a los productos e información exclusiva en los Estados Unidos Mexicanos y <b>McCormick</b> no garantiza que la misma cumpla 
            con la legislación de otros países, debiendo considerarse solamente como un <b>Sitio</b> de visita para <b>Usuarios</b> de cualquier otra parte del mundo, por lo que, el <b>Usuario</b> es el único responsable de cumplir con las leyes locales que le apliquen si ingresa al <b>Sitio</b> desde cualquier lugar fuera de la República Mexicana <br/><br/>



            Al accesar a este <b>Sitio</b>, el <b>Usuario</b> acepta y reconoce que <b>McCormick</b> no es responsable en ningún momento por la incomunicación, interrupción, falla o inhabilitación 
            del <b>Sitio</b> por causas no imputables a <b>McCormick</b>; así como que no será tampoco responsable de cualquier daño o perjuicio, de manera enunciativa pero no limitativa, por fallas 
            técnicas de cualquier tipo, incluyendo daños en el equipo personal de cómputo de cualquier persona relacionada con o resultante de su ingreso al Sitio o por experimentar 
            o apreciar los materiales relacionados con el mismo, por intervención humana no autorizada, por retrasos, distorsiones o interferencias en las transmisiones telefónicas 
            o de computadora u otros errores de cualquier tipo, ya sea humanos, electrónicos o mecánicos. En el mismo sentido, <b>McCormick</b> no tendrá responsabilidad alguna por cualquier 
            pérdida causada por virus que puedan infectar a su computadora u otra propiedad por razón de usar, ingresar o bajar cualquier material de este <b>Sitio.</b> Si el <b>Usuario</b> elige 
            descargar materiales del <b>Sitio</b> siempre será bajo su propio riesgo y cualquier mal uso o uso incorrecto de la información será exclusivamente su responsabilidad.<br/><br/>


            
          Toda la información como pueden ser, de forma enunciativa mas no limitativa: Imágenes, Videos, Textos, Marcas Registradas, Productos, Avisos Comerciales, Nombres de 
          Dominio y Diseños, Reportes, Políticas, Comunicados de Prensa, Historia de <b>McCormick</b> y demás análogos (referidos en el presente documento como <b>“Información”</b>) 
          contenidas en este <b>Sitio</b>, pueden ser propiedad de <b>McCormick</b>, sus titulares y/o licenciatarios, estando por ende protegida por los convenios internacionales y la 
          legislación mexicana en materia de propiedad industrial e intelectual.<br/><br/>


          Ningún contenido de este <b>Sitio</b> debe interpretarse como un otorgamiento de licencia de uso o derecho de uso de la <b>Información</b> mostrada, por lo que se prohíbe 
          cualquier uso, reproducción total o parcial de la <b>Información</b> o cualquier contenido del mismo<br/><br/>


          
          El <b>Sitio</b> puede o no contener una sección dedicada a un “blog” o foro, en donde se podrán exponer diversos asuntos de interés en relación a actividad de <b>McCormick</b>, 
          por lo que <b>McCormick</b> únicamente actuará de moderador dentro del “blog” o foro y las opiniones, comentarios y/o escritos de los Usuarios, reflejarán únicamente sus 
          opiniones personales y/o criterios, por lo que <b>McCormick</b> no es responsable de los mismos. No obstante, <b>McCormick</b> se reserva el derecho de borrar comentarios 
          ofensivos, vulgares, racistas o de cualquier calidad que pueda ofender a otras personas, a <b>McCormick</b> y/o a sus marcas.<br/><br/>


          El usuario acepta tácita y expresamente al momento de registrarse, que las recetas que suba al <b>sitio</b> y/o proporcione a <b>McCormick</b>, son de su autoría y cuentan con todos 
          los derechos para poder utilizarla, liberando a <b>McCormick</b> de cualquier controversia y obligándose a que, en caso de controversia, sacará en paz y a salvo a <b>McCormick</b>, 
          resarciendo en su caso los gastos que ésta última erogue con motivo de su defensa.   En este tenor, el <b>usuario</b> al momento mismo de subir, transmitir y/o registrar una 
          o varias recetas, está cediendo totalmente a <b>McCormick</b> los derechos para su utilización y/o publicación por cualquier medio de comunicación impresa, visual, auditiva, 
          electrónica y/o por cualquier tipo de tecnología que se encuentre dentro de mercado en un futuro.<br/><br/>



          El <b>Usuario</b> se obliga a usar el Sitio, sus contenidos y/o servicios de forma diligente y de conformidad con los presentes Términos y Condiciones Legales; así como 
          con la ley, la moral, el orden público y las buenas costumbres.<br/><br/>



          <b>McCormick</b> se reserva el absoluto derecho de denegar o retirar el acceso al <b>Sitio</b> y/o a los servicios aquí contenidos, en cualquier momento y sin necesidad de previo 
          aviso, al <b>Usuario</b> que no cumpla con lo establecido en los presentes Términos y Condiciones Legales.<br/><br/>


          Asimismo, <b>McCormick</b> se reserva el derecho de restringir, finalizar o terminar el ingreso a este <b>Sitio</b>, al <b>Usuario</b> que intente dañar o alterar deliberadamente el <b>Sitio </b> 
          o cualquiera de sus secciones, estropear, modificar o impedir la legítima operación del mismo, por lo que constituye una violación a las Leyes Federales y Locales, 
          reservándose <b>McCormick</b> el derecho de actuar por la vía Civil y/o Penal y/o cualquier otra, con la finalidad de reclamar la reparación del daño y la correspondiente 
          indemnización por Daños y Perjuicios.

            </Modal.Body>
            <Modal.Footer>
              <button  className="btn mr-auto ml-auto btn-carousel" variant="primary" onClick={this.props.handleModal}>
                Cerrar
              </button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }
  }