import React, { PureComponent } from 'react'
import './index.css'
import CloseCrossImg from './img/close.png'
import Fade from 'react-reveal/Fade';
import lupa from '../Resource/iconos/lupa.svg'
import axios from 'axios'
import Tilt from 'react-parallax-tilt';
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux'
class InModalMC extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            userInput: "",
            resultados:null,
            hoverProductosData: null,
            categoriaRecetas: null,
            categorias: null,
            categoriasRelacionadas: []
        };
    }

    componentDidMount() {
      this.loadCatRecetas()
    }

    loadCatRecetas = async () => {

      const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL + `/tags?nombre=Te puede interesar`);
      const result = await axios.get(process.env.REACT_APP_API_URL + "/categorias");
    
     
   
      this.setState({
        categoriasRelacionadas: categoriasRelacionadas.data,
        categorias:  result.data
      });
  
    }
  
    onChange = async (value) => {

      if(value.length >= 3){
        const result = await axios.get(process.env.REACT_APP_API_URL + "/buscador?valor=" + value);
     
    
    
    
    
        this.setState({
          resultados: result.data
        })
      }else{
        this.setState({
          resultados:null
        })
      }
    
    
       
      }
    render() {
      const paramsRecetasplatillo = {
        slidesPerView: 2,
        slidesPerGroup:1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
            delay: 3200,
            disableOnInteraction: false
        },
        loopFillGroupWithBlank: false,
    }
        return (
          <div>
            <Fade duration={150}  >
              <div className="header-menu p-2">
              <div style={{ width: "100%",display:"flex" }}>
                   
                   
                   <img  src={lupa} style={{ padding: "4px", width: "25px", height: "25px", cursor: "pointer" }} />
              
                   <input autocomplete="off" aria-expanded={this.state.Open} aria-controls="example-collapse-text" name="payload" onChange={(e) => this.onChange(e.target.value)}  style={{ border: "0px", outline: "none", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px", fontFamily: "AvenirBook",width:"100%" }} placeholder="Buscar"></input>
            
                 
                   <img style={{cursor:"pointer",height:"20px",width:"20px"}} onClick={() => this.props.handleBuscadorMobil()} src={CloseCrossImg} />
                
              

             </div>


              </div>
              <div className="child-container">
                    {(() => {

if (this.state.resultados != null) {
 
 
  const  recetas = this.state.resultados.recetas.map((element, index) => {
      return (
        <>
          <div className="col-4"  onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{display:"flex",position:"relative",flexDirection:"column",justifyContent:"center",alignItems:"center",cursor:"pointe"}}>
            <img src={element.imagen} style={{ position: "relativw", maxWidth: "100%", width: "100%", top: "5px" }}></img>
            <label style={{fontFamily:"AvenirBook"}} >{element.nombre}</label>
         

          </div>
        </>
      )
    })
  
  

 
  const productos = this.state.resultados.productos.map((element, index) => {
      return (
        <>
           <div className="col-4"  onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{display:"flex",position:"relative",flexDirection:"column",justifyContent:"center",alignItems:"center",cursor:"pointe"}}>
           <img src={element.imagen} style={{ position: "relativw", maxHeight: "80px", width: "auto", top: "5px" }}></img>
            <label style={{fontFamily:"AvenirBook"}}>{element.nombre}</label>
          

          </div>
        </>
      )
    })
 

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "top",flexDirection:"column",padding:"10px" }}>
          {productos.length > 0 ? <label style={{ fontFamily: "AvenirBlack" }} >Productos</label> :  <label style={{ fontSize: "10px", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label>}
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap",maxHeight:"210px",overflowY:"auto" ,overflowX:"hidden" }}>
      {productos.length > 0 ? productos :
                              <>
                                {(() => {

                                  if (this.state.categorias != null) {
                              
                                    const cat = this.state.categorias.filter((element, index) => {
                                      if (element.nombre === this.state.categoriName) {

                                      } else {
                                        return element
                                      }
                                    })



                                    const productos = []
                                    cat.filter((element, index) => {
                                      element.productos.map((element, index) => {
                                        if (element.estrella) {
                                          productos.push(element)
                                        }
                                      })
                                    })

                                    if (productos.length === 3) {
                                      this.setState({
                                        swiperFlechas: false
                                      })
                                    }


                                    const prod = productos.map((element, index) => {

                                      return (
                                        <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} className="col-6" style={{ position: "relative" }} >

                                          <Tilt
                                            className="parallax-effect-img"
                                            tiltMaxAngleX={10}
                                            tiltMaxAngleY={10}
                                            perspective={500}
                                            transitionSpeed={1500}
                                            scale={1}
                                            gyroscope={true}
                                          >
                                            <img src={element.imagenFondo} style={{ maxHeight: "120px", width: "auto", }} />
                                          </Tilt>
                                          <img src={element.imagen} style={{ maxWidth: "50%", maxHeight: "100px", position: "absolute", top: "10%", zIndex: "1", left: "25%" }} />
                                          <div style={{ marginTop: "3%", textAlign: "center" }}>
                                            <b style={{ fontSize: "10px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                                            <label style={{ fontSize: "10px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                                          </div>
                                        </div>
                                      )


                                    })

                                    return (
                                      <>


                                        <Swiper {...paramsRecetasplatillo}>
                                          {prod}
                                        </Swiper>

                                      </>
                                    )


                                  }

                                })()}
                                </>
                              }

      </div>
      {recetas.length > 0 ? <label style={{ fontFamily: "AvenirBlack" }} >Recetas</label> :  <label style={{ fontSize: "10px", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label>}
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap",maxHeight:"250px",overflowY:"auto" ,overflowX:"hidden" }}>
      {recetas.length > 0 ? recetas : 
                              <>
                                {(() => {
                                    if (this.state.categoriasRelacionadas.length > 0) {
                                      console.log("productos")
                                      console.log(this.state.categoriasRelacionadas)
                                      const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                                        return element.recetas.map((element, index) => {


                                          return (
                                            <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent", cursor: "pointer",paddingLeft:"5px",paddingRight:"5px" }} className="card col-6" >
                                              <div style={{ overflow: "hidden" }}>

                                                <img className="recetas-anim" style={{ width: "100%", height: "auto" }} src={element.imagen} />

                                              </div>
                                              <div style={{ width: "100%", fontFamily: "AvenirNextLTProMedium", marginTop: "23px", marginBottom: "23px" }}>
                                                <label style={{ fontFamily: "CocogooseProRegular", fontSize: "10px" }}>{element.nombre}</label><br />

                                                <label style={{ fontFamily: "AvenirBook", fontSize: "10px" }} >{element.descripcion}</label>

                                                <br /> <br />
                                              </div>
                                            </div>
                                          )
                                        }
                                        )


                                      })
                                      return (
                                        <>
                                          <Swiper {...paramsRecetasplatillo}>
                                            {recetas}
                                          </Swiper>

                                        </>

                                      )
                                    }

                                  })()}
                              </>
                              }
      </div>
    </div>
  )



}else{
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "top", flexDirection: "column" }}>
      <div style={{ width: "100%", marginTop: "5px", marginBottom: "5px",paddingLeft:"15px" }} >

        <label style={{ fontSize: "10px", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label><br />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", maxHeight: "245px" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", width: "100%" }}>
          <div className="col-12" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
           

          

        

              {(() => {

                if (this.state.categorias != null) {
                  console.log("productos")
                  console.log(this.state.categorias)
                  const cat = this.state.categorias.filter((element, index) => {
                    if (element.nombre === this.state.categoriName) {

                    } else {
                      return element
                    }
                  })



                  const productos = []
                  cat.filter((element, index) => {
                    element.productos.map((element, index) => {
                      if (element.estrella) {
                        productos.push(element)
                      }
                    })
                  })

                  if (productos.length === 3) {
                    this.setState({
                      swiperFlechas: false
                    })
                  }


                  const prod = productos.map((element, index) => {

                    return (
                      <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} className="col-6" style={{ position: "relative" }} >

                        <Tilt
                          className="parallax-effect-img"
                          tiltMaxAngleX={10}
                          tiltMaxAngleY={10}
                          perspective={500}
                          transitionSpeed={1500}
                          scale={1}
                          gyroscope={true}
                        >
                          <img src={element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
                        </Tilt>
                        <img src={element.imagen} style={{ maxWidth: "50%", maxHeight: "120px", position: "absolute", top: "10%", zIndex: "1", left: "25%" }} />
                        <div style={{ marginTop: "3%", textAlign: "center" }}>
                          <b style={{ fontSize: "10px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                          <label style={{ fontSize: "10px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                        </div>
                      </div>
                    )


                  })

                  return (
                    <>
                     

                        <Swiper {...paramsRecetasplatillo}>
                          {prod}
                        </Swiper>
                
                    </>
                  )


                }

              })()}

     

           
          </div>

        </div>

      </div>



      <div style={{ width: "95%", paddingLeft: "15px", marginTop: "3px" }} >

        <label style={{ fontSize: "10px", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label><br />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", maxHeight: "245px",marginTop:"10px", overflowY: "auto", overflowX: "hidden" }}>
        <div style={{ width: "95%", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", position: "relative", zIndex: "1" }}>
          <div style={{ width: "95%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir", justifyContent: "center", alignItems: "center" }}>
          

           



            {(() => {
              if (this.state.categoriasRelacionadas.length > 0) {
                console.log("productos")
                console.log(this.state.categoriasRelacionadas)
                const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                  return element.recetas.map((element, index) => {


                    return (
                      <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent", cursor: "pointer",paddingLeft:"5px",paddingRight:"5px" }} className="card col-6" >
                        <div style={{ overflow: "hidden" }}>

                          <img className="recetas-anim" style={{ width: "100%", height: "auto" }} src={element.imagen} />

                        </div>
                        <div style={{ width: "100%", fontFamily: "AvenirNextLTProMedium", marginTop: "23px", marginBottom: "23px" }}>
                          <label style={{ fontFamily: "CocogooseProRegular", fontSize: "10px" }}>{element.nombre}</label><br />

                          <label style={{ fontFamily: "AvenirBook",fontSize:"10px" }} >{element.descripcion}</label>

                          <br /> <br />
                        </div>
                      </div>
                    )
                  }
                  )


                })
                return (
                <>
                  <Swiper {...paramsRecetasplatillo}>
                    {recetas}
                  </Swiper>
             
                  </>

                )
              }

            })()}


           
          </div>

        </div>
      </div>
    </div>
  )

}
})()}
                        {this.props.children}
                    </div>
                </Fade>
            </div>
        )
    }
}

class ModalMC extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            show: props.show
        }




    }
    handleSearchView() {
        this.setState({ show: !this.state.show })
    }



    render() {
        return (
            <>
                {(!this.props.show != !this.state.show) ?
                    <InModalMC handleBuscadorMobil={this.props.handleBuscadorMobil} title={this.props.title} closeSearch={this.handleSearchView.bind(this)}>
                        
                    </InModalMC>
                    :
                    null}
            </>
        )
    }

}

export default ModalMC

