const OBTENER_PROMOCIONES_TODAS = "OBTENER_PROMOCIONES_TODAS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_PROMOCIONES_TODAS:
            return { ...state, promociones: action.promociones }

        default:
            return state;
    }

}

export default reducer;