

import useMediaQuery from '@material-ui/core/useMediaQuery';
const styles = theme => ({
  desk:{
    display:"flex !important"
  },
  mobile:{
    display:"none !important"
  },
  ["@media screen and (max-width: 687px), (max-height: 325px) "]:{
    desk:{
      display:"none !important"
    },
    mobile:{
      display:"flex !important"
    }
  },
  desk:{
    display:"none"
  },
    root: {
      width: '100%',
      zIndex: 2,
      border: "0px solid rgb(255, 215, 102)",
      width: "-webkit-fill-available",
      position: "relative",
      top: "1%",
      
      boxShadow: "none"
    },
    detail: {
      width: "inherit",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    expansionPanelSummaryContent: {
      "& > :last-child": {
        transform: "rotate(0deg) !important"
      }
    },
    expansionPanelSummaryExpandIcon: {
      transform: "rotate(0deg) !important"
    }
  
  });

  export default styles