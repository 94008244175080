import TeSorprendemos from '../TeSorprendemos/index'
import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux'
import timer from '../Resource/iconos/timer.svg'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import './index.css'
import history from '../../history'
import Select from 'react-select'
import Tilt from 'react-parallax-tilt';
import GetProductsByCategory from "../redux/actions/GetProductsByCategory"
import recipesRelated from "../redux/actions/GetRelatedRecipes"
import axios from 'axios'
import fondo from "../redux/data/img/productosCategorias/fondo/fondo_fresa.png"
import TeSorprendemosMobile from '../TeSorprendemosMobile/index'
import Fade from 'react-reveal/Fade'
import { Helmet } from "react-helmet";
import { useRef } from 'react'
import sello from './img/sello.svg'
import BannerDetox from './BannerDetox/index'
function productoEstrella(mobile, element) {
  if (element.estatus === "draft") {
    return
  }
  if (mobile) {
    return (
      <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-12" style={{ position: "relative" }} >

        <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto", paddingLeft: "10%" }} />
        <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "35%", maxHeight: "300px", position: "absolute", bottom: "27%", zIndex: "1", left: "35%" }} />
        <div style={{ marginTop: "10%", textAlign: "center" }}>
          <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
          <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
        </div>
      </div>
    )

  }
  return (
    <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-12" style={{ position: "relative" }} >
      <Tilt
        className="parallax-effect-img"
        tiltMaxAngleX={10}
        tiltMaxAngleY={10}
        perspective={500}
        transitionSpeed={1500}
        scale={1}
        gyroscope={true}
      >
        <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto" }} />
      </Tilt>
      <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "18%", zIndex: "1", left: "24%" }} />
      <div style={{ textAlign: "center", marginRight: "16%" }}>
        <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
        <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
      </div>
    </div>
  )

}

function productos(mobile, element, categoriName) {
  if (element.estatus === "draft") {
    return
  }
  if (mobile) {
    return (
      <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-6" style={{ position: "relative" }} >
        {element.subcategoria === 22 ? <img src={sello} style={{ maxWidth: "21%", position: "absolute", top: "-3%", right: "2%", zIndex: 1 }} /> : ""}
        {element.id === 556 ? <img src={sello} style={{ maxWidth: "21%", position: "absolute", top: "-7%", right: "2%", zIndex: 1 }} /> : ""}
        <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
        {categoriName === "tes-sobres" ?
          <img alt={element.alt_imagen} onClick={() => { window.scrollTo(0, 0); history.push("/detalle-producto") }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxHeight: "105px", maxWidth: "43%", position: "absolute", top: "20%", zIndex: "1", left: "30%" }} />
          :
          <img alt={element.alt_imagen} onClick={() => { window.scrollTo(0, 0); history.push("/detalle-producto") }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxHeight: "130px", maxWidth: "35%", position: "absolute", top: "10%", zIndex: "1", left: "30%" }} />
        }

        <div style={{ marginTop: "10%", textAlign: "center" }}>
          <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
          <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
        </div>
      </div>
    )
  } else {

    return (
      <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-3" style={{ position: "relative" }} >
        {element.subcategoria === 22 ? <img src={sello} style={{ maxWidth: "21%", position: "absolute", top: "-7%", right: "2%", zIndex: 1 }} /> : ""}
        {element.id === 556 ? <img src={sello} style={{ maxWidth: "21%", position: "absolute", top: "-2%", right: "6%", zIndex: 1 }} /> : ""}
        <Tilt
          className="parallax-effect-img"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={500}
          transitionSpeed={1500}
          scale={1}
          gyroscope={true}
        >
          <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
        </Tilt>
        {categoriName === "gelatinas" ?
          <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "20%", zIndex: "1", left: "20%" }} />
          : categoriName === "mermeladas" ?
            <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "20%", zIndex: "1", left: "22%" }} />
            : categoriName === "tes-sobres" ?
              <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "30%", zIndex: "1", left: "25%" }} />
              : categoriName === "mayonesas" ?
                <img alt={element.alt_imagen} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "40%", maxHeight: "300px", position: "absolute", top: "13%", zIndex: "1", left: "30%" }} />
                : categoriName === "especias-y-condimentos" ?
                  <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "220px", position: "absolute", bottom: "30%", zIndex: "1", left: "30%" }} />
                  :
                  <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "220px", position: "absolute", bottom: "30%", zIndex: "1", left: "20%" }} />}

        <div style={{ marginTop: "20%", textAlign: "center" }}>
          <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
          <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
        </div>
      </div>
    )
  }

}



class DetalleProductosCategoria extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      categoria_productos_show: [],
      subcategorias: null,
      categorias: [],
      categoriName: null,
      subcategoriaId: null,
      swiperFlechas: true,
      subcategoriaName: null,
      swiperFlechasRecetas: true,
      categoriasRelacionadas: null,
      arrayref: [],
      metadataPeticion: [],
      selectedOption: '',
      metadata: [
        {
          nombre: "mostaza-amarilla",
          meta_titulo: "Mostaza amarilla | McCORMICK México",
          meta_description: "UN CONDIMENTO MÁGICO EN INNUMERABLES GUISOS La mostaza es, con permiso de la mayonesa y la salsa catsup, la salsa favorita del mundo."
        },
        {
          nombre: "mayonesas",
          meta_titulo: "Mayonesas | McCORMICK México",
          meta_description: "Mayonesas McCormick: Prueba los diferentes sabores como: mayonesa con limón, chipotle, guacamole, habanero y aderezo de mayonesa."
        },
        {
          nombre: "especias-y-condimentos",
          meta_titulo: "Especias y condimentos | McCORMICK México",
          meta_description: "Especias y condimentos McCORMICK: Conoce la variedad de ingredientes para sazonar tus comidas como: cúrcuma, pimienta y más."
        },
        {
          nombre: "gelatinas",
          meta_titulo: "Gelatinas en polvo | McCORMICK México",
          meta_description: "Gelatinas en polvo McCormick hechas a base de grenetina con frutas, diferentes colores y brillos ¡Descubre los nuevos sabores!"
        },
        {
          nombre: "tes-sobres",
          meta_titulo: "Tés en sobres | McCORMICK México",
          meta_description: "Tés en sobres McCORMICK: Prueba el delicioso sabor de los tés naturales y funcionales como té chai, verde, de jengibre o negro."
        },
        {
          nombre: "mermeladas",
          meta_titulo: "Mermeladas | McCORMICK México",
          meta_description: "Mermeladas McCORMICK: Conservas en versión light o natural con diferentes sabores como mermelada de chile, fresa, piña o mango."
        },




      ],
      metadataSubcat: [
        {
          nombre: "basicas-para-tu-cocina",
          meta_titulo: "Especias y condimentos básicos | McCORMICK México",
          meta_description: "Especias y condimentos básicos para cocinar y sazonar tus comidas con pimienta negra, comino molido o hierbas de olor como laurel"
        },
        {
          nombre: "del-mundo",
          meta_titulo: "Especias y condimentos del mundo | McCORMICK México",
          meta_description: "Especias y condimentos del mundo: Encuentra cúrcuma, jengibre, pimienta blanca, curry en polvo, albahaca y paprika ¡aquí!"
        },
        {
          nombre: "gourmet",
          meta_titulo: "Especias y condimentos gourmet | McCORMICK México",
          meta_description: "Especias y condimentos gourmet: Usa productos orgánicos con sabor a nuez moscada, canela molida, cebolla u orégano en polvo."
        },
        {
          nombre: "para-parrilla",
          meta_titulo: "Especias y condimentos para parrilla | McCORMICK México",
          meta_description: "Especias y condimentos para parrilla: Prepara una auténtica parrillada con estos sazonadores para marinar carne para asar ¡Úsalos!"
        },
        {
          nombre: "dulces-postres",
          meta_titulo: "Especias y condimentos dulces para postres | McCORMICK México",
          meta_description: "Especias y condimentos dulces para postres. Usa cremor tártaro, nuez moscada, canela molida o entera y colorantes artificiales"
        },
        {
          nombre: "ajos-seleccion-especial",
          meta_titulo: "Especias y condimentos con ajo | McCORMICK México",
          meta_description: "Especias y condimentos con ajo sabor a queso parmesano, chile chipotle, con mantequilla y mezcla de finas hierbas ¡Pruébalas! "
        },
        {
          nombre: "molinillos",
          meta_titulo: "Molinillos de especias y condimentos | McCORMICK México",
          meta_description: "Molinillos de especias y condimentos: Mezcla de pimientas y sazonadores hechos de granos secos, semillas y hierbas para moler"
        },
        {
          nombre: "para-bebidas-y-botanas",
          meta_titulo: "Sazonadores para botanas y bebidas | McCORMICK México",
          meta_description: "Sazonadores para botanas y bebidas: Combina verduras, cócteles y frituras con las mezclas de sal, chile, apio y pimienta con limón"
        },

      ]

    }
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    window.scrollTo(0, 0)
    window.location.href = (`/productos/${selectedOption.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-")}`)


  }


  handleSubcategoriaSelected = (nombre, e, id) => {
    window.location.href = "/productos/" + this.state.categoriName.replace(/ /g, "-") + "/" + nombre.replace(/ /g, "-").toLowerCase()


  }

  nextrecetas = () => {
    const swiperContainer = document.getElementsByClassName("swiper-recetas-d")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  prevrecetas = () => {
    const swiperContainer = document.getElementsByClassName("swiper-recetas-d")[0].children;
    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }


  nextproductos = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos-d")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  prevproductos = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos-d")[0].children;
    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }
  nextproductosMobile = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos-mobile")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  prevproductosMobile = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos-mobile")[0].children;
    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }

  nextswiper = () => {
    const swiperContainer = document.getElementsByClassName("swiplatilloper-platillo")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();

  }
  nextprev = () => {
    const swiperContainer = document.getElementsByClassName("swiper-platillo")[0].children;
    var mySwiper = swiperContainer[0].swiper

    mySwiper.slidePrev();
  }
  getRecetas = () => {
    const recetas = this.props.data.recipesRelated.map((element, index) => {

      return (
        <div style={{ width: "33.3%", border: "0px" }} className="card col-4">
          <img alt={element.alt_imagen} style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
          <div style={{ padding: "20px" }}>
            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
            <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
            <br /> <br />
            <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
              <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
            </div>
          </div>
        </div>
      )

    })
    this.setState({
      recetas
    })

  }
  handleLoadProducts = () => {
    const productos = this.props.data.getproductsbycategory[0].subcategoria.map((element, index) => {


      if (index > 0) {
        if (element.subgrupo) {
          const subgrupos = element.subgrupo.map((element, index) => {
            const productos = element.productos.map((element, index) => {
              return (
                <div className="col-3" style={{ position: "relative" }} >
                  <Tilt
                    className="parallax-effect-img"
                    tiltMaxAngleX={10}
                    tiltMaxAngleY={10}
                    perspective={500}
                    transitionSpeed={1500}
                    scale={1}
                    gyroscope={true}
                  >
                    <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.fondo} style={{ maxWidth: "100%", height: "auto", }} />
                  </Tilt>
                  <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", height: "auto", position: "absolute", top: "25%", zIndex: "1", left: "25%" }} />
                  <div style={{ marginTop: "20%", textAlign: "center" }}>
                    <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                    <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                  </div>
                </div>
              )
            })
            return (
              <>
                <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", padding: "40px" }}>
                  <label className="Especiales" >{element.nombre}</label>
                </div>
                <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                  {productos}
                </div>
              </>
            )
          })
          return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
              <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                <label className="undefined"> {element.descripcion} </label><br /><br />
              </div>

              {subgrupos}

            </div>
          )
        } else {

          const producto = element.productos.map((element, index) => {
            return (
              <div className="col-3" style={{ position: "relative" }} >
                <Tilt
                  className="parallax-effect-img"
                  tiltMaxAngleX={10}
                  tiltMaxAngleY={10}
                  perspective={500}
                  transitionSpeed={1500}
                  scale={1}
                  gyroscope={true}
                >
                  <img alt={element.alt_imagen_fondo} src={process.env.REACT_APP_CMS_ASSETS + element.fondo} style={{ maxWidth: "100%", height: "auto", }} />
                </Tilt>
                <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", height: "auto", position: "absolute", top: "25%", zIndex: "1", left: "25%" }} />
                <div style={{ marginTop: "20%", textAlign: "center" }}>
                  <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                  <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />

                </div>
              </div>
            )
          })
          return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                <label className="undefined"> {element.descripcion} </label><br /><br />

              </div>
              <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                {producto}
              </div>

            </div>
          )
        }
      }


    })

    this.setState({
      productos
    })


  }
  nextswiperHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos")[0].children;

    var mySwiper = swiperContainer[1].swiper

    mySwiper.slideNext();

  }
  nextprevHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos")[0].children;

    var mySwiper = swiperContainer[1].swiper

    mySwiper.slidePrev();
  }
  /* UNSAFE_componentWillUpdate(nexProps) {
     if (nexProps.data.getproductsbycategory != this.props.data.getproductsbycategory) {
         this.props.data.getproductsbycategory =nexProps.data.getproductsbycategory
         this.handleLoadProducts()
     }
     console.log("props updating")
     console.log(this.props.data.getproductsbycategory)
   }*/

  handleRutas = () => {
    const home = document.getElementById("home")
    const productos = document.getElementById("productos")
    const promociones = document.getElementById("promociones")
    switch (window.location.pathname) {
      case "/":


        home.style.borderBottom = "5px solid red"
        home.style.transition = ".3s"


        break;
      case "/productos":

        productos.style.borderBottom = "5px solid red"
        productos.style.transition = ".3s"

        home.style.borderBottom = "5px solid  transparent"
        promociones.style.borderBottom = "5px solid transparent"
        break;
      case "/recetas":
        const recetas = document.getElementById("recetas")
        recetas.style.borderBottom = "5px solid red"
        recetas.style.transition = ".3s"




        home.style.borderBottom = "5px solid  transparent"



        productos.style.borderBottom = "5px solid transparent"



        promociones.style.borderBottom = "5px solid transparent"

        break;
      case "/promociones":

        promociones.style.borderBottom = "5px solid red"
        promociones.style.transition = ".3s"
        break;
      case "/contacto":
        const contacto = document.getElementById("contacto")
        contacto.style.borderBottom = "5px solid red"
        contacto.style.transition = ".3s"
        break;
      case "/nosotros":
        const nosotros = document.getElementById("nosotros")
        nosotros.style.borderBottom = "5px solid red"
        nosotros.style.transition = ".3s"
        break;

      default:
        home.style.borderBottom = "5px solid  transparent"



        productos.style.borderBottom = "5px solid red"



        promociones.style.borderBottom = "5px solid transparent"
        break;
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.data.recetas != this.props.data.recetas) {
      this.handleRutas()
      //this.props.recipesRelated()
      if (this.props.match.params.post_id) {
        this.props.GetProductsByCategory(this.props.match.params.post_id)
      }


      //carga el  menu de botones en especias
      if (this.props.data.getproductsbycategory[0].categoria.nombre === "Especias") {

        this.handleSubcategoriaProducto(this.props.data.getproductsbycategory[0].subcategoria[1].nombre)
      } else {

        this.handleLoadProducts()
      }



      //vista inferiores


      this.getRecetas()

    }
  }


  componentDidMount() {


    this.obtenerCategorias();
  }
  obtenerCategorias = async () => {
    var nombreCategoria = this.props.match.params.post_id;
    const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias?fields=*.*,recetas_relacionadas.receta_id.*,productos.subcategoria.*&filter[estatus]=published");
    this.props.dispatch({ type: "OBTENER_PRODUCTOS_POR_CATEGORIAS", categoriaProductos: result.data.data });
    const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/categorias?fields=*.*,recetas_relacionadas.receta_id.*,productos.subcategoria.*&filter[estatus]=published&filter[url]=${nombreCategoria}`);
    this.props.dispatch({ type: "OBTENER_CATEGORIAS_RELACIONADAS", categoriasRelacionadas: categoriasRelacionadas.data.data });
    const subcategorias = await axios.get(process.env.REACT_APP_API_URL_NEW + `/subcategorias?fields=*,productos.*&filter[estatus]=published&filter[categoria][url]=${nombreCategoria}`);
    this.props.dispatch({ type: "OBTENER_SUBCATEGORIAS_POR_CATEGORIAS", subcategorias: subcategorias.data.data });
    let array = []
    this.props.data.getproductsbycategory.categoriaProductos.map((element, index) => {
      if (element.url) {
        if (element.url === nombreCategoria.toLowerCase()) {
          array.push(element)
        }
      }
    })
    let arraySubcategorias = []
    this.props.data.categorias.subcategorias.map((element, index) => {
      arraySubcategorias.push(element)
    })
    if (arraySubcategorias.length > 0 && !this.props.match.params.post_sub_cat) {
      this.setState({
        subcategoriaName: arraySubcategorias[0].nombre.replace(/ /g, "-").toLowerCase()
      })
    } else {
      var subCat = this.props.match.params.post_sub_cat
      switch (this.props.match.params.post_sub_cat) {
        case "del-mundo":
          subCat = "especias-del-mundo"
          break;
        case "gourmet":
          subCat = "especias-gourmet"
          break;
        case "para-parrilla":
          subCat = "grill-mates"
          break;
        case "basicas-para-cocinar":
          subCat = "basicas-para-tu-cocina"
          break;
        case "dulces-postres":
          subCat = "ideales-para-tus-postres"
          break;
        case "bebidas-botanas":
          subCat = "para-bebidas-y-botanas"
          break;

        case "molinillos":
          subCat = "molinillos"
          break;
        case "ajos-seleccion-especial":
          subCat = "ajos-seleccion-especial"
          break;
        default:
        // code block
      }
      this.setState({
        subcategoriaName: subCat,
      })
    }
    this.setState({
      categorias: this.props.data.getproductsbycategory.categoriaProductos,
      subcategorias: arraySubcategorias,
      categoria_productos_show: array,
      selectedOption: this.props.match.params.post_id,
      categoriName: nombreCategoria,
      categoriasRelacionadas: this.props.data.categoriasRelacionada.categoriasRelacionadas,
    })
    if (this.props.match.params.post_id && this.props.match.params.post_sub_cat && this.props.match.params.post_id != 'especias-y-condimentos') {
      if (this.state.subcategorias.length > 0) {
        this.state.subcategorias.map((e, i) => {
          if (this.props.match.params.post_sub_cat === e.url) {
            document.getElementById(e.url).scrollIntoView()
            document.getElementById(e.url + 'm').scrollIntoView()
          }
        })
      }
    }
  }
  render() {
    const paramsButtonMovil = {
      slidesPerView: 3,
      spaceBetween: 0,
      noSwiping: false,
      on: {
        slidePrevTransitionStart: () => {
          const text = document.getElementsByClassName("swiperbuttonmovilprod")[0].children[0].children[0].children
          for (var i = 0; i < text.length; i++) {
            if (text[i].classList.value === "swiper-slide swiper-slide-next") {

              // window.location.href="/productos/"+this.state.categoriName+"/"+text[i].children[0].value.replace(/ /g, "-").toLowerCase()
              this.setState({ subcategoriaName: text[i].children[0].value })
            }
          }
        },
        slideNextTransitionStart: () => {
          const text = document.getElementsByClassName("swiperbuttonmovilprod")[0].children[0].children[0].children
          for (var i = 0; i < text.length; i++) {
            if (text[i].classList.value === "swiper-slide swiper-slide-next") {
              const button = text[i].nextSibling

              if (button.children.length === 0) {

              } else {
                this.setState({ subcategoriaName: button.children[0].value })
              }

            }
          }
        }
      }

    }
    const paramsRecetasplatilloMobile = {
      slidesPerView: 1,
      spaceBetween: "-4%",
      loop: true
    }
    const paramsProductosplatilloMobile = {
      slidesPerView: 1,
      loop: true
    }


    const paramsinicio = {
      speed: 600,
      parallax: true,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false
      },
      slidesPerView: 1,

      // loop: true,
      // autoplay: {
      //   delay: 6000,
      //   disableOnInteraction: false
      // },

    }
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {

        return {
          ...styles,
          backgroundColor: "transparent",
          color: "gray",
          ':hover': {
            ...styles[':hover'],
            backgroundColor: "transparent",
            color: "red"
          },
        };
      },
      container: () => ({
        width: "30%",
        border: "4px solid #ffd766",
        position: "relative",
        zIndex: 100

      }),
      control: () => ({
        outline: "none",
        display: "flex"
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "100%",
        borderBottom: '1px dotted pink',
        border: " 3px solid #ffd766",
        color: state.selectProps.menuColor,
        padding: 20,

      }),



      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';


        return { ...provided, opacity, transition };
      }
    }


    const customStylesM = {

      placeholder: (defaultStyles) => { return { ...defaultStyles, color: '#000000', marginLeft: '38%', letterSpacing: '5px' } },

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {

        return {
          ...styles,
          backgroundColor: "transparent",
          color: "gray",
          ':hover': {
            ...styles[':hover'],
            backgroundColor: "transparent",
            color: "red"
          },
        };
      },
      container: () => ({
        width: "90%",
        border: "4px solid #ffd766",
        position: "relative",
        zIndex: 100

      }),
      control: () => ({
        outline: "none",
        display: "flex"
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "100%",
        borderBottom: '1px dotted pink',
        border: " 3px solid #ffd766",
        color: state.selectProps.menuColor,
        padding: 20,

      }),



      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';


        return { ...provided, opacity, transition };
      }
    }
    const paramsRecetasplatillo = {
      slidesPerView: 3,
      spaceBetween: 0,
      loop: true,
    }


    const options = this.state.categorias.map((element, index) => {
      return { value: element.url, label: element.nombre }
    })
    const that = this;


    return (
      <>
        {this.state.categoriasRelacionadas && <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.categoriasRelacionadas[0].metatitle}</title>
          <meta name="description" content={this.state.categoriasRelacionadas[0].metadescription} />
        </Helmet>
        }
        <seccion className="detalle-productos-desk ">
          {(() => {
            if (that.state.categoria_productos_show[0])
              return (
                <div style={{ marginLeft: "30px", marginRight: "30px", marginTop: "6px", position: "relative" }} className="swiper-productos">
                  <Swiper {...paramsinicio}>
                    {
                      this.props.match.params.post_id === "tes-sobres" ?
                        <BannerDetox dataDetox={that.state.categoria_productos_show[0].banner[0]} option="desk"></BannerDetox>
                        :
                        // that.state.categoria_productos_show[0].banner.map((element, index) => {
                        //   if(index === 0){
                        //   return (
                        <div style={{ position: "relative", margin: "0px" }}>
                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_fondo} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_fondo} style={{ width: "100%", height: "auto" }}></img>

                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_producto} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_producto} style={{ maxWidth: "36%", height: "auto", position: "absolute", top: "5%", right: "0%" }}></img>
                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_comida} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_comida} style={{ maxWidth: "36%", height: "auto", position: "absolute", top: "0%", left: "0%" }}></img>


                          <div className="info-productos">
                            <label className="MS-ENERGA">{that.state.categoria_productos_show[0].banner[0].titulo}</label><br />
                            <label className="El-sabor-tradiciona" >{that.state.categoria_productos_show[0].banner[0].subtitulo}</label><br />
                          </div>
                        </div>
                      //   )
                      // }
                      // })
                    }
                  </Swiper>
                </div>
              )
          })()}
          <div style={{ marginTop: "50px" }}>
            <div className="filtro-productos-detalle" >
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <p className="QUIERO-VER" >QUIERO VER</p>

                <Select
                  styles={customStyles}
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  placeholder={this.state.selectedOption.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ")}
                />

              </div>
            </div>

            {(() => {
              if (that.state.categoria_productos_show[0]) {
                switch (that.state.categoriName) {
                  case "especias-y-condimentos":
                    const menuBotones = that.state.subcategorias.map((element, index) => {
                      if (element.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-").toLowerCase() === that.state.subcategoriaName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
                        return (
                          <button style={{ border: "3px solid #ffbd00", color: "block", background: "#ffbd00", transform: "0.5s" }} onClick={(e) => { this.handleSubcategoriaSelected(element.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-"), e, element.id) }} className="btn-detalle-producto" >{element.nombre}</button>
                        )
                      } else {
                        return (
                          <button onClick={(e) => { this.handleSubcategoriaSelected(element.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-"), e, element.id) }} className="btn-detalle-producto" >{element.nombre}</button>
                        )
                      }
                    })
                    const subcategorias = that.state.subcategorias.map((element, index) => {
                      if (element.url === that.state.subcategoriaName) {
                        const productosEs = element.productos.map((element, index) => {
                          return productos(false, element, that.state.categoriName)

                        })
                        return (
                          <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            {(() => {
                              return this.state.metadataSubcat.map((element, index) => {
                                if (element.nombre === this.props.match.params.post_sub_cat) {

                                  return (
                                    <Helmet>
                                      <meta charSet="utf-8" />
                                      <title>{element.meta_titulo}</title>
                                      <meta name="description" content={element.meta_description} />
                                    </Helmet>
                                  )
                                }
                              })
                            })()}
                            <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                              <label style={{ marginTop: "13px" }} className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                              <label className="undefined"> {element.descripcion} </label><br /><br />
                            </div>

                            <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                              {productosEs}
                            </div>

                          </div>
                        )

                      }

                    })
                    const estrella = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(false, element)
                      }
                    })

                    return (<>
                      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "60%" }}>
                          <div className="col-9">
                            {that.state.categoria_productos_show[0] ?
                              <>
                                {/* <label style={{ marginLeft: "0px" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br /> */}
                                <label style={{ marginLeft: "0px" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                <label style={{ fontSize: "4vw", fontFamily: "SignPainter" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                              </>
                              :
                              <></>
                            }
                          </div>
                        </div>
                        <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
                          {estrella}
                        </div>
                      </div>
                      <div style={{ width: "100%", padding: "100px", paddingBottom: "20px", justifyContent: "center", alignItems: "center", flexWrap: "wrap", display: "flex" }}>
                        {menuBotones}
                      </div>
                      {subcategorias}
                    </>
                    )
                    break;
                  case "mayonesas":
                    const subcategoriasmayo = this.state.subcategorias.map((element, index) => {
                      const productosmayo = element.productos.map((element, index) => {
                        return productos(false, element, that.state.categoriName)

                      })
                      return (
                        <div id={element.url} style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                            <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                            <label className="undefined"> {element.descripcion} </label><br /><br />
                          </div>

                          <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            {productosmayo}
                          </div>

                        </div>
                      )
                    })
                    const estrellamayo = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(false, element)
                      }
                    })

                    return (
                      <>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <div className="col-12" style={{ paddingLeft: "6%", paddingRight: "6%", paddingBottom: "4%" }}>
                              {that.state.categoria_productos_show[0] ?
                                <>
                                  <label style={{ marginLeft: "0px" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br />
                                  <label style={{ marginLeft: "0px" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                  <label style={{ fontSize: "4vw", fontFamily: "SignPainter" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                                </>
                                :
                                <></>
                              }
                            </div>
                          </div>

                          <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>

                            {estrellamayo}
                          </div>
                        </div>
                        {subcategoriasmayo}
                      </>
                    )

                    break;
                  default:
                    const subcategoriasdef = this.state.subcategorias.map((element, index) => {
                      const productosdef = element.productos.map((element, index) => {
                        return productos(false, element, that.state.categoriName)
                      })
                      return (
                        <div id={element.url} style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                            <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                            <label className="undefined"> {element.descripcion} </label><br /><br />
                          </div>
                          <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            {productosdef}
                          </div>
                        </div>
                      )
                    })
                    const estrelladef = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(false, element)
                      }
                    })
                    return (
                      <>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ width: "60%" }}>
                            <div className="col-9">
                              {that.state.categoria_productos_show[0] ?
                                <>
                                  <label style={{ marginLeft: "0px" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br />
                                  <label style={{ marginLeft: "0px" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                  <label style={{ fontSize: "4vw", fontFamily: "SignPainter" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                                </>
                                :
                                <></>
                              }
                            </div>
                          </div>

                          <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>

                            {estrelladef}
                          </div>
                        </div>
                        <div style={{ width: "100%", padding: "100px", paddingBottom: "20px", justifyContent: "center", alignItems: "center", flexWrap: "wrap", display: "flex" }}>
                          {menuBotones}
                        </div>

                        {subcategoriasdef}
                      </>
                    )
                }
              }

            })()}




            <TeSorprendemos></TeSorprendemos>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", marginTop: "30px" }}>
              <div className="col-9" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                <div style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >


                  <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular">RECETAS</b><br />
                  <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">ECHA UN VISTAZO A LAS<br /> RECETAS RELACIONADAS</label><br />

                </div>
                {/*recetasRelacionadas*/}

                <img src={isq} onClick={() => { this.prevrecetas() }} style={{ display: this.state.swiperFlechasRecetas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", left: "-10%", cursor: "pointer" }} />
                <div style={{ width: "inherit" }} >
                  {(() => {

                    if (this.state.categoriasRelacionadas) {
                      const recetas = [];
                      this.state.categoriasRelacionadas.map((element, index) => {

                        if (element.recetas_relacionadas.length === 3) {
                          this.setState({
                            swiperFlechasRecetas: false
                          })
                        }
                        return element.recetas_relacionadas.map((element, index) => {
                          recetas.push(element.receta_id)
                        }
                        )
                      })

                      const recePublic = recetas.filter((e) => { return (e.estatus === "published") })
                      const rec = recePublic.map((element, indes) => {
                        return (
                          <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent", position: "relative" }} className="card col-4">
                            <div style={{ overflow: "hidden" }}>
                              <Fade>
                                <img alt={element.alt_imagen} className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                              </Fade>
                            </div>
                            <div style={{ fontFamily: "AvenirNextLTProMedium", width: "100%", marginTop: "23px", marginBottom: "23px" }}>
                              <Fade left><label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br /></Fade>

                              <Fade left>  <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label></Fade>

                              <br /> <br />
                              {/*
                              <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                  <img src={timer} style={{ width: "20px", height: "20px", marginRight: "11px" }} />{element.tiempo + "  " + element.personas}
                              </div>
                              */}
                            </div>
                          </div>
                        )
                      })

                      return (
                        <div className="swiper-recetas-d">
                          <Swiper {...paramsRecetasplatillo}>
                            {rec}
                          </Swiper>
                        </div>

                      )
                    }

                  })()}
                </div>

                <img onClick={() => { this.nextrecetas() }} src={der} style={{ display: this.state.swiperFlechasRecetas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", right: "-10%", cursor: "pointer" }} />
              </div>
              <button onClick={(e) => { history.push("/recetas") }} className=" btn-carousel menos">VER RECETAS</button>
            </div>




            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff" }}>
              <div className="col-9" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                <div style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >
                  <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular">PRODUCTOS</b><br />
                  <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">CONOCE OTROS<br /> PRODUCTOS</label><br />
                </div>

                <img src={isq} onClick={() => { this.prevproductos() }} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", left: "-10%", cursor: "pointer" }} />

                <div style={{ width: "inherit" }} >

                  {(() => {

                    if (this.state.categorias.length) {
                      const cat = this.state.categorias.filter((element, index) => {
                        if (element.url === this.state.categoriName) {
                        } else {
                          return element
                        }
                      })



                      const productos = []
                      cat.filter((element, index) => {
                        element.productos.map((element, index) => {
                          if (element.estrella) {
                            productos.push(element)
                          }
                        })
                      })

                      if (productos.length === 3) {
                        this.setState({
                          swiperFlechas: false
                        })
                      }
                      const prodPublic = productos.filter((e) => { return (e.estatus === "published") })
                      const prod = prodPublic.map((element, index) => {
                        return (
                          <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-4" style={{ position: "relative" }} >

                            <Tilt
                              className="parallax-effect-img"
                              tiltMaxAngleX={10}
                              tiltMaxAngleY={10}
                              perspective={500}
                              transitionSpeed={1500}
                              scale={1}
                              gyroscope={true}
                            >
                              <img alt={element.alt_imagen_fondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
                            </Tilt>
                            <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "20%", zIndex: "1", left: "25%" }} />
                            <div style={{ marginTop: "20%", textAlign: "center" }}>
                              <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                              <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                            </div>
                          </div>
                        )


                      })

                      return (
                        <>
                          <div className="swiper-productos-d">

                            <Swiper {...paramsRecetasplatillo}>
                              {prod}
                            </Swiper>
                          </div>
                        </>
                      )


                    }






                  })()}




                </div>



                <img onClick={() => { this.nextproductos() }} src={der} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", right: "-10%", cursor: "pointer" }} />
              </div>

            </div>
          </div>
        </seccion>
        <seccion className="detalle-productos-mobile">
          {/*banner*/}

          {(() => {
            if (that.state.categoria_productos_show[0])
              return (
                <div style={{ marginTop: "6px", width: "100%", position: "relative" }} className="swiper-recetas">
                  <Swiper {...paramsinicio}>
                    {
                      this.props.match.params.post_id === "tes-sobres" ?
                        <BannerDetox option="mobile" dataDetox={that.state.categoria_productos_show[0].banner[0]} ></BannerDetox>
                        :
                        // that.state.categoria_productos_show[0].banner.map((element, index) => {
                        //   if(index === 0){
                        //   return (
                        <div style={{ position: "relative" }}>
                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_fondo} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_fondo} style={{ width: "auto", height: "100%" }}></img>

                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_producto} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_producto} style={{ maxWidth: "55%", maxHeight: "300px", position: "absolute", top: "50%", right: "-6%" }}></img>
                          <img alt={that.state.categoria_productos_show[0].banner[0].alt_imagen_comida} src={process.env.REACT_APP_CMS_ASSETS + that.state.categoria_productos_show[0].banner[0].imagen_comida} style={{ maxWidth: "36%", maxHeight: "300px", position: "absolute", top: "64%", left: "0%" }}></img>


                          <div className="info-productos" style={{ top: "29%", left: "46%" }}>
                            <label className="MS-ENERGA " style={{ fontSize: "44px", lineHeight: "1.2" }}>{that.state.categoria_productos_show[0].banner[0].titulo}</label><br />
                            <label className="El-sabor-tradiciona" style={{ fontSize: "32px", lineHeight: "1" }}>{that.state.categoria_productos_show[0].banner[0].subtitulo}</label><br />
                          </div>
                        </div>
                      //   )
                      //   }
                      // })
                    }
                  </Swiper>
                </div>
              )
          })()}




          <div style={{ marginTop: "50px" }}>


            <div className="filtro-productos-detalle" >
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>

                <Select
                  styles={customStylesM}
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  placeholder={this.state.categoriName && this.state.categoriName.replace(/-/g, " ")}
                />

              </div>
            </div>
            {/*estrella*/}
            {(() => {
              if (that.state.categoria_productos_show[0]) {

                switch (that.state.categoriName) {

                  case "especias-y-condimentos":


                    const menuBotones = that.state.subcategorias.map((element, index) => {
                      return (

                        <button onClick={() => { this.setState({ subcategoriaName: element.url.normalize("NFD").replace(/[\u0300-\u036f]/g, "") }) }} className="btn-detalle-producto" >{element.nombre}</button>
                      )
                    })


                    const subcategorias = that.state.subcategorias.map((element, index) => {
                      if (element.url.replace(/ /g, "-").toLowerCase() === that.state.subcategoriaName) {
                        const productosM = element.productos.map((element, index) => {
                          return productos(true, element)
                        })
                        return (
                          <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "block", width: "auto", height: "75px", textAlign: "center" }}>
                              <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                              <label className="undefined"> {element.descripcion} </label><br /><br />
                            </div>

                            <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start" }}>
                              {productosM}
                            </div>

                          </div>
                        )

                      }

                    })
                    const estrella = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(true, element)
                      }
                    })

                    return (<>
                      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "100%" }}>
                          <div className="col-12">
                            {that.state.categoria_productos_show[0] ?
                              <>
                                <label style={{ marginLeft: "0px", fontSize: "25px", textAlign: "initial" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br />
                                <label style={{ marginTop: "3%", marginLeft: "0px", fontSize: "20px", textAlign: "initial" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                <label style={{ fontSize: "25px", fontFamily: "SignPainter", textAlign: "initial" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                              </>
                              :
                              <></>
                            }
                          </div>
                        </div>


                      </div>

                      <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>

                        {estrella}
                      </div>

                      <div className="mt-4 col-12" style={{}}>
                        <div className="content-opacity">
                          <div className="opacity-izq-mov" >
                          </div>
                          <div className="opacity-der-mov" >
                          </div>
                        </div>
                        <div className="swiperbuttonmovilprod"  >
                          {this.state.subcategorias ?
                            <Swiper {...paramsButtonMovil}>

                              <div style={{ width: "65px", height: "24px" }}>
                              </div>
                              {this.state.subcategorias.map((element, index) => {
                                return (
                                  <div data-index-number={element.id} style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <button value={element.nombre.replace(/ /g, "-").toLowerCase()} className="button-not-focus button-plato-swiper-mv" >{element.nombre}</button>
                                  </div>
                                )
                              })}
                              <div style={{ width: "65px", height: "24px" }}>
                              </div>
                            </Swiper>
                            :
                            null
                          }
                        </div>
                      </div>

                      {subcategorias}



                    </>
                    )
                    break;
                  case "mayonesas":
                    const subcategoriasmayo = this.state.subcategorias.map((element, index) => {
                      const productosmayo = element.productos.map((element, index) => {
                        return productos(true, element)
                      })
                      return (
                        <div id={element.url + 'm'} style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                            <label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br />
                            <label className="undefined"> {element.descripcion} </label><br /><br />
                          </div>

                          <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start" }}>
                            {productosmayo}
                          </div>

                        </div>
                      )
                    })
                    const estrellamayo = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(true, element)
                      }
                    })

                    return (
                      <>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <div className="col-12">
                              {that.state.categoria_productos_show[0] ?
                                <>
                                  <label style={{ marginLeft: "0px", fontSize: "25px", textAlign: "initial" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br />
                                  <label style={{ marginLeft: "0px", fontSize: "20px", textAlign: "initial" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                  <label style={{ fontSize: "25px", fontFamily: "SignPainter", textAlign: "initial" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                                </>
                                :
                                <></>
                              }
                            </div>
                          </div>

                          <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>

                            {estrellamayo}
                          </div>
                        </div>
                        {subcategoriasmayo}
                      </>
                    )

                    break;
                  default:

                    const subcategoriasdef = this.state.subcategorias.map((element, index) => {
                      const productosdef = element.productos.map((element, index) => {
                        return productos(true, element, that.state.categoriName)
                      })


                      return (
                        <div id={element.url + 'm'} style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                            {this.state.categoriName === "mostaza-amarilla" ? "" : <><label className="NUESTROS-PRODUCTOS" > {element.nombre}</label><br /></>}

                            <label className="undefined"> {element.descripcion} </label><br /><br />
                          </div>




                          {this.state.categoriName === "mostaza-amarilla" ? "" : <div style={{ width: "100%", position: "relative", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            {productosdef}
                          </div>
                          }

                        </div>
                      )

                    })
                    const estrelladef = that.state.categoria_productos_show[0].productos.map((element, index) => {
                      if (element.estrella) {
                        return productoEstrella(true, element)
                      }
                    })
                    //todos los demas prods//
                    return (
                      <>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ width: "auto" }}>
                            <div className="col-12">
                              {that.state.categoria_productos_show[0] ?
                                <>
                                  <label style={{ marginLeft: "0px", fontSize: "25px", textAlign: "initial" }} className="NUESTROS-PRODUCTOS" >{that.state.categoria_productos_show[0].titulo}</label><br /><br />
                                  <label style={{ marginLeft: "0px", fontSize: "20px", textAlign: "initial" }} className="undefined"> {that.state.categoria_productos_show[0].descripcion} </label><br /><br />
                                  <label style={{ fontSize: "25px", fontFamily: "SignPainter", textAlign: "initial" }} >{that.state.categoria_productos_show[0].frase}</label><br />
                                </>
                                :
                                <></>
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "85%", display: "flex", justifyContent: "center", flexDirection: "column" }}>

                          {estrelladef}
                        </div>
                        {/* <div style={{width:"100%",padding:"100px",paddingBottom:"20px",justifyContent:"center",alignItems:"center",flexWrap:"wrap",display:"flex"}}>
                    
                    </div> */}
                        {menuBotones}


                        {subcategoriasdef}

                      </>
                    )



                }
              }

            })()}




            <TeSorprendemosMobile />

            <div style={{ width: "100%", textAlign: "center", marginTop: "35px", marginBottom: "15px" }} >

              <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">TE PUEDE INTERESAR</label><br />
            </div>


            {/*recetasRelacionadas*/}
            <div>

              {(() => {
                if (this.state.categoriasRelacionadas) {
                  const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                    return element.recetas_relacionadas.map((element, index) => {
                      return (
                        <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.receta_id.url.replace(/ /g, "-").toLowerCase()}`) }} className="contain-swip" >
                          <img alt={element.alt_imagen} style={{ width: "93%", height: "auto", marginLeft: "10px" }} src={process.env.REACT_APP_CMS_ASSETS + element.receta_id.imagen} />
                          <div style={{ padding: "20px", fontFamily: "Avenir-Medium", paddingBottom: "0px" }}>
                            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.receta_id.nombre}</label><br />
                            <label style={{ fontFamily: "AvenirBook" }} ></label>{element.receta_id.descripcion}
                            <br /> <br />
                          </div>
                        </div>
                      )
                    }
                    )
                  })
                  return (
                    <div style={{ width: "inherit" }} className="swiper-recetas-d">
                      <Swiper {...paramsRecetasplatilloMobile}>
                        {recetas}
                      </Swiper>
                    </div>
                  )
                }
              })()}
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
              <button onClick={(e) => { history.push("/recetas") }} className=" btn-carousel menos">VER RECETAS</button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff" }}>
            <div className="col-10" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
              <div style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular">PRODUCTOS</b><br />
                <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">CONOCE OTROS<br /> PRODUCTOS</label><br />
              </div>



              <div style={{ width: "inherit" }} className="swiper-platillo">
                <img src={isq} onClick={() => { this.prevproductosMobile() }} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "55%", left: "-7%", cursor: "pointer" }} />


                {(() => {
                  if (this.state.categorias.length) {
                    const cat = this.state.categorias.filter((element, index) => {
                      if (element.nombre === this.state.categoriName) {

                      } else {
                        return element
                      }
                    })



                    const productos = []
                    cat.filter((element, index) => {
                      element.productos.map((element, index) => {
                        if (element.estrella) {
                          productos.push(element)
                        }
                      })
                    })


                    const prod = productos.map((element, index) => {
                      return (
                        <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.nombre.replace(/ /g, "-").toLowerCase()}`) }} style={{ position: "relative", width: "100%" }} >

                          <Tilt
                            className="parallax-effect-img"
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={10}
                            perspective={500}
                            transitionSpeed={1500}
                            scale={1}
                            gyroscope={true}
                          >
                            <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS + element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
                          </Tilt>
                          <img alt={element.alt_imagen} className="product-hover" src={process.env.REACT_APP_CMS_ASSETS + element.imagen} style={{ maxWidth: "50%", maxHeight: "150px", position: "absolute", bottom: "20%", zIndex: "1", left: "30%" }} />
                          <div style={{ marginTop: "20%", textAlign: "center" }}>
                            <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                            <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                          </div>
                        </div>
                      )


                    })

                    return (
                      <>
                        <div className="swiper-productos-mobile">

                          <Swiper {...paramsProductosplatilloMobile}>
                            {prod}
                          </Swiper>
                        </div>
                      </>
                    )
                  }
                })()}
                <img onClick={() => { this.nextproductosMobile() }} src={der} style={{ "block": "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "55%", right: "-7%", cursor: "pointer" }} />
              </div>

            </div>

          </div>

        </seccion>
      </>
    )
  }
}
const mapStateToProps = state => ({

  data: { ...state, categoriaProductos: state.categoriaProductos }
});


export default connect(mapStateToProps)(DetalleProductosCategoria)


