const OBTENER_PRODUCTOS_TODAS = "OBTENER_PRODUCTOS_TODAS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_PRODUCTOS_TODAS:
            return { ...state, productos: action.productos }

        default:
            return state;
    }

}

export default reducer;