import { type as modal } from '../actions/modal';
import React from 'react'

const defaultState = [
   false
];

function reducer(state = defaultState, { type,open }) {
    switch (type) {
        case modal: {
        
      
        return open
        }
      
        default:
       return false
        
    }
}

export default reducer;
