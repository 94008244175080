import React, { PureComponent } from "react";
import Tilt from "react-parallax-tilt";
import der from "../Resource/iconos/arrow-slide-der.svg";
import isq from "../Resource/iconos/arrow-slide-izq.svg";
import Swiper from "react-id-swiper";
import "./index.css";
import { Helmet } from "react-helmet";
import Dummy from "./dummy";
import axios from 'axios'
export default class Tips extends PureComponent {
  constructor() {
    super();
    this.state = {
      Dummy,
      blogs: null,
      width:'',
    }
  }
  

  loadData = async () => {
    const blog = await axios.get(process.env.REACT_APP_API_URL_NEW + "/blogs?fields=*.*&filter[url]=" + this.props.match.params.nota+'&filter[estatus]=published');
    this.setState({
      blogs: blog.data.data[0]
    })

  }
  componentDidMount() {

    this.loadData() 
    this.setState({width:window.innerWidth})
     window.addEventListener("resize",()=>this.setState({width:window.innerWidth}));
  }
  nextproductosMobile = () => {
    const swiperContainer = document.getElementsByClassName(
      "swiper-productos-mobile-tips"
    )[0].children;
    var mySwiper = swiperContainer[0].swiper;
    mySwiper.slideNext();
  };
  prevproductosMobile = () => {
    const swiperContainer = document.getElementsByClassName(
      "swiper-productos-mobile-tips"
    )[0].children;
    var mySwiper = swiperContainer[0].swiper;

    mySwiper.slidePrev();
  };
  
  render() {
    const paramsProductosplatilloMobile = {
      slidesPerView: 1,
      spaceBetween: 2,
      loop: true,
    };
    return (
      <>
        {this.state.blogs &&<Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.blogs.meta_titulo}</title>
          <meta name="description" content={this.state.blogs.meta_descripcion} />
          <meta name="keywords" content={this.state.blogs.keywords} />
        </Helmet>}
        {this.state.blogs &&
          <>
            <div className="container-swiper-tips">
              <img alt={this.state.blogs.alt_banner} src={process.env.REACT_APP_CMS_ASSETS + this.state.blogs.banner.id} className="banner-tips"></img>
            </div>

            <div className="tips-container">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="CocogooseProRegular title-tips">
                  {this.state.blogs.titulo}
                </h1>
              </div>
              <div style={{ fontFamily: "AvenirBook", fontSize: "16px" }}>
                <pre dangerouslySetInnerHTML={{ __html: this.state.blogs.contenido }} style={{ fontFamily: "AvenirBook", position: "relative", top: "1px", whiteSpace: "pre-wrap" }}>
                </pre>
              </div>
            
              {  console.log(this.state.width)}
              {this.state.width <= 767?
                 <div className="productos-mobile-tips">
                 <div style={{ width: "inherit" }} className="swiper-tips">
                   <img
                     src={isq}
                     onClick={() => {
                       this.prevproductosMobile();
                     }}
                     style={{
                       maxWidth: "100%",
                       height: "auto",
                       zIndex: "10",
                       position: "absolute",
                       top: "20%",
                       left: "0%",
                       cursor: "pointer",
                     }}
                   />
                   <div
                     style={{
                       width: "100%",
                       display: "flex",
                       justifyContent: "center",
                     }}
                   >
                     <div className="swiper-productos-mobile-tips">
                       {this.state.blogs.receta.length > 0 &&
                         <Swiper {...paramsProductosplatilloMobile}>
                           {this.state.blogs.receta.map((element, index) => {
                             return (
                               <div
                                 style={{
                                   position: "relative",
                                   width: "100%",
                                 }}
                               >
                                 <img
                                   alt={element.alt_imagen}
                                   src={process.env.REACT_APP_CMS_ASSETS + element.imagen}
                                   style={{
                                     width: "100%",
                                     height: "auto",
                                   }}
                                 />
 
                                 <div
                                   style={{
                                     textAlign: "inherit",
                                     marginTop: "5%",
                                   }}
                                 >
                                   <label
                                     style={{
                                       fontSize: "16px",
                                       fontFamily: "AvenirBook",
                                       marginTop: "5%",
                                     }}
                                   >
                                     <pre dangerouslySetInnerHTML={{ __html: element.contenido }} style={{ fontFamily: "AvenirBook", position: "relative", top: "1px", whiteSpace: "pre-wrap" }}>
                                     </pre>
                                   </label>
                                   <br />
                                 </div>
                               </div>
                             );
                           })
                           }
                         </Swiper>}
                     </div>
                   </div>
                   <img
                     onClick={() => {
                       this.nextproductosMobile();
                     }}
                     src={der}
                     style={{
                       block: "none",
                       maxWidth: "100%",
                       height: "auto",
                       zIndex: "10",
                       position: "absolute",
                       top: "20%",
                       right: "0%",
                       cursor: "pointer",
                     }}
                   />
                 </div>
               </div>
               :
               <div className="productos-desk-tips">
               <div
                 style={{
                   width: "100%",
                   display: "flex",
                   justifyContent: "center",
                   flexWrap: "wrap",
                 }}
               >
                 {this.state.blogs.receta.map((element, index) => {
                   return (
                     <div
                       className="col-5"
                       style={{
                         position: "relative",
                         marginTop: "3%",
                         paddingLeft: "4%",
                         paddingRight: "4%",
                       }}
                     >
                       {this.props.match.params.nota ===
                         "mejora-habitos-alimenticios-disfruta-vida-en-balance" ||
                         this.props.match.params.nota ===
                         "detox-natural-tes" ? (
                         <div
                           style={{
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center",
                           }}
                         >
                           <img

                             alt={element.alt_imagen}
                             src={process.env.REACT_APP_CMS_ASSETS + element.imagen}
                             style={{ width: "50%", height: "auto" }}
                           />
                         </div>
                       ) : (
                         <Tilt
                           className="parallax-effect-img"
                           tiltMaxAngleX={10}
                           tiltMaxAngleY={10}
                           perspective={500}
                           transitionSpeed={1500}
                           scale={1}
                           gyroscope={true}
                         >
                           {this.props.match.params.nota ===
                             "haz-dulce-regreso-a-clases" ? (
                             <img
                               alt={element.alt_imagen}
                               src={process.env.REACT_APP_CMS_ASSETS + element.imagen}
                               style={{
                                 width: "100%",
                                 padding: "1% 22% 0% 22%",
                                 height: "auto",
                               }}
                             />
                           ) : (
                             <img
                               alt={element.alt_imagen}
                               src={process.env.REACT_APP_CMS_ASSETS + element.imagen}
                               style={{
                                 width: "100%",
                                 height: "auto",
                               }}
                             />
                           )}
                         </Tilt>
                       )}
                       <div style={{ textAlign: "inherit" }}>
                         <div
                           style={{
                             fontSize: "16px",
                             fontFamily: "AvenirBook",
                             marginTop: "5%",
                             textAlign: "justify",
                           }}
                         >
                           <pre dangerouslySetInnerHTML={{ __html: element.contenido }} style={{ fontFamily: "AvenirBook", position: "relative", top: "1px", whiteSpace: "pre-wrap" }}>
                           </pre>
                         </div>
                       </div>
                     </div>
                   );
                 })}
               </div>
             </div>
              }
           
            </div>
          </>
        }

      </>

    )
  }
}
