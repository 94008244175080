import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Provider } from 'react-redux'
import configureStore from './Components/redux/store'
import { PersistGate } from 'redux-persist/integration/react'
const { persistor, store } = configureStore()

const onBeforeLift = () => {
    // take some action before the gate lifts
  }
const rootElement = document.getElementById('root')
ReactDOM.render(
    <ParallaxProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
           
                <App />
               
            </PersistGate>
        </Provider>
        </ParallaxProvider>
 ,
    rootElement
)


