import React, { PureComponent } from "react"
import style from './TeaAnimation.module.css'
import ImgTaza from './assets/Taza.png'

function TeaAnimation({ fgColor, bgColor }) {
    return (
        <div className={style.TeaAnimation}>

            <div style={{backgroundColor: fgColor }} className={style.DivPolygon} > 
                <div style={{backgroundColor: bgColor }} className={style.circuloBg }/>
                <div className={style.circuloRelleno}/>
            </div>
            <div className={style.wrapImgTaza}>
                <img className={style.ImgTaza} src={ImgTaza} />
            </div>

        </div>
    )
}

export { TeaAnimation }