import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper';
import Fade from 'react-reveal/Fade'
import history from '../../history'

//imagenes del banner secundario 
import productoBanner1 from './img/banner1/producto.png'
import aguacateBanner1 from './img/banner1/aguacate.png'
import hojaBanner1 from './img/banner1/hoja.png'
import perejilBanner1 from './img/banner1/perejil.png'

import productoBanner2 from './img/banner2/producto.png'
import condimentoBanner2 from './img/banner2/condimento.png'
import hojaBanner2 from './img/banner2/hoja.png'

import productoBanner3 from './img/banner3/producto.png'
import hojaJamaicaBanner3 from './img/banner3/hojaJamaica.png'
import hojaBanner3 from './img/banner3/hoja.png'
import axios from 'axios'

export default class TeSorprendemosMobile extends PureComponent {
    constructor() {
        super()
        this.state = {
            bannerData: []
        }

    }
    obtenerbanner = async () => {
        const banner = await axios.get(process.env.REACT_APP_API_URL_NEW + "/te_sorprendemos_home");
        this.setState({
            bannerData: banner.data.data
        })
        console.log('banner')
        console.log(banner)
    }
    componentDidMount() {
        //Almacenar objeto swiper en la variable del componente.

        this.obtenerbanner()
    }
    render() {
        const params = {
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 600,
            parallax: true,
            parallaxEl: {
                el: '.parallax-bg',
                value: '-23%'
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false
            },
            slidesPerView: 1,
            spaceBetween: 65,
            loop: false,
            centeredSlide: true
        }

        return (
            <>
                <div style={{ width: "100%", marginTop: "30px", fontFamily: "Avenir" }}>
                    <div className="sombra" >
                        {this.state.bannerData.length > 0 && <Swiper {...params}>
                            {this.state.bannerData.map((e, i) => {
                                return (
                                    <div style={{ height: "723px", backgroundImage: `url(${process.env.REACT_APP_CMS_ASSETS}${e.imagen_fondo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} onClick={() => { window.scrollTo(0, 0); window.location.href = (e.url_producto) }}>
                                        <Fade left> <label className="CocogooseProRegular" style={{ fontSize: "22px", margin: "0px", marginLeft: "22px", marginBottom: "14px", marginTop: "24px" }}>TE SORPRENDEMOS</label></Fade>
                                        {/* <div style={{ position: "relative", zIndex: "50", left: "48%", top: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay="1.5s" style={{ maxWidth: "35%", height: "auto" }} src={aguacateBanner1} />
                                </div> */}
                                        {/* <div style={{ position: "absolute", zIndex: "50", bottom: "6%", left: "4%" }}>
                                    <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay="1.5s" style={{ maxWidth: "60%", height: "auto" }} src={hojaBanner1} />
                                </div> */}
                                        {/* <div style={{ position: "relative", zIndex: "50", bottom: "4%", left: "90%" }}>
                                    <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay=".95s" style={{ maxWidth: "20%", height: "auto" }} src={perejilBanner1} />
                                </div> */}
                                        <div style={{ position: "absolute", zIndex: "50", bottom: "5%", left: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img alt={e.alt_imagen_producto} className="wow " data-wow-iteration="1" data-wow-offset="1" data-wow-delay=".5s" style={{ maxWidth: "72%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_producto} />
                                        </div>
                                        <div className="info-te-sorprendemos col-5" style={{ marginTop: "30px" }} >
                                            <Fade left> <b style={{ fontSize: "15px" }} className="CocogooseProRegular">{e.titulo}</b><br /><br /></Fade>
                                            <Fade left><label style={{ fontSize: "20px" }} className="CocogooseProRegular">{e.texto}</label><br /><br /></Fade>
                                            <Fade left><a style={{ fontFamily: "AvenirBook", fontSize: "14px" }}>{e.frase}</a><br /><br /></Fade>
                                            <button className=" btn-carousel menos">{e.boton_copy}</button>
                                        </div>
                                    </div>
                                )
                            })}

                        </Swiper>}
                    </div>
                </div>
            </>
        )
    }
}