import React, { useContext } from 'react'
import './ButtonLiquid.css'
import simbolMas from '../assets/mas.svg'

const ButtonLiquid = (props) => {

    return (
        <div className="ButtonLiquid-Container" onClick={() => { props.onClick() }}>
            <div className="ButtonLiquid-Layer">
                <div className="ButtonLiquid-Balls">
                    <div
                        className="ButtonLiquid-Button"
                        style={{ background: props.BackButton ? props.BackButton : "#de0034" }}
                    />
                    <div className="ButtonLiquid-ball ButtonLiquid-ball-1" style={{ background: props.BackBall ? props.BackBall : "#de0034" }} />
                    <div className="ButtonLiquid-ball ButtonLiquid-ball-2" style={{ background: props.BackBall ? props.BackBall : "#de0034" }} />
                    <div className="ButtonLiquid-ball ButtonLiquid-ball-3" style={{ background: props.BackBall ? props.BackBall : "#de0034" }} />
                    <div className="ButtonLiquid-ball ButtonLiquid-ball-4" style={{ background: props.BackBall ? props.BackBall : "#de0034" }} />
                </div>
            </div>
            <div className="ButtonLiquid-SimbolMas">
                <img src={simbolMas} />
            </div>
        </div>
    )
}

export default ButtonLiquid