import React, { PureComponent } from 'react'
import AvisoPrivacidad from '../Avisoprivacidadmodal/index'
import Terminos from '../TerminosModal/index'
import Switch from '@material-ui/core/Switch';
import slada from './img/fondo.png'
import sladamobile from './img/fondomobile.png'
import mas from './img/expands.png'
import menos from './img/menos.png'
import productos from './img/productos.png'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import Swiper from 'react-id-swiper'
import TePuedeInteresar from '../TePuedeInteresar/index'
import TePuedeInteresarMobile from '../TePuedeInteresarMobile/index'
import axios from 'axios'
import { Helmet } from 'react-helmet';
import TeSorprendemos from '../TeSorprendemos/index'
import Tilt from 'react-parallax-tilt'
import flechaRegresar from '../Promociones/img/flecha-hacia-abajo.svg'
import TePodriaGustar from '../TePodriaGustar/index'

import prod1 from './img/prod1.png'
import prod2 from './img/prod2.png'
import prod3 from './img/prod3.png'
import prod4 from './img/prod4.png'
import prod5 from './img/prod5.png'
import timer from '../Resource/iconos/timer.svg'
import flecha from './img/arrow-slide-izq.svg'
import { connect } from 'react-redux'
import history from '../../history'
import './index.css'
import datosCategoriaProductos from '../redux/data/categoriasProductos'
import { TabletAndroid } from '@material-ui/icons';

function separTitle(titulo) {
    let namearray = titulo.split(" ")
    let secname = namearray.slice(1, namearray.length)
    let result = secname.join(" ")
    return result
}

const initialState = {
    metatitle: 'McCORMICK México ¡Póngale lo sabroso!',
    metadescription: 'McCORMICK México crea productos con sabores únicos para las familias, colocando en sus despensas mayonesas, especias, tés y más.',
    url: 'https://www.mccormick.mx/',
}


class DetalleProducto extends PureComponent {
    constructor() {
        super()
        this.state = {
            producto: [],
            tablaNutri: [],
            listPresent: [],
            metaProductos: initialState,
            controlopen: false,
            displayNutri: null,
            recetas:[],
            productos:[]
        }
        this.validateNutri = false
        this.objectNutri = {}

    }

    getReceta = async () => {

        /**
         * Agregué exepciones para la regla de los guiones. Se tiene que tomar en 
         * cuenta que es una solución que sólo afecta a dos elementos; si en
         * el futuro se pretende añadir más productos con guiones en el nombre, 
         * será necesario reimplementar el proceso y buscar una alternativa a la 
         * manera en la que hacemos y procesamos las URLs. Lo ideal sería crear 
         * otro campo con la URL, así no tendríamos que depender del nombre. 
         */

        let consulta = ""
        consulta = this.props.match.params.post_id
     
                                                                                
        const ResultProd = await axios.get(process.env.REACT_APP_API_URL_NEW + "/productos?fields=*.*&filter[estatus]=published&filter[url]=" + consulta);
        console.log(ResultProd)
        const relacionados = await axios.get(process.env.REACT_APP_API_URL_NEW + "/productos?fields=*,recetas.recetas_id.*,productos_relacionados.producto_relacionado.*&filter[estatus]=published&filter[url]=" + consulta);
        const ResultNutri = await axios.get(process.env.REACT_APP_API_URL_NEW + "/tabla_nutrimental?fields=*.*.*&[_and][producto][estatus]=published&filter[producto][id]=" + ResultProd.data.data[0].id);
        const ResultPresent = await axios.get(process.env.REACT_APP_API_URL_NEW + "/presentaciones_productos?fields=*.*.*[_and][producto][estatus]=published&filter[producto][id]=" + ResultProd.data.data[0].id);
        this.setState({
            producto: ResultProd.data.data,
            tablaNutri: ResultNutri.data.data[0],
            listPresent: ResultPresent.data.data,
            recetas:relacionados.data.data[0].recetas.map((e)=>{return(e.recetas_id)}),
            productos:relacionados.data.data[0].productos_relacionados.map((e)=>{return(e.producto_relacionado)})
        })
      
        if (this.functValNutri(this.state.tablaNutri)) {
            this.setState({
                displayNutri: this.formatTablaNutri(this.state.tablaNutri)
            })
        }

    }

    validNut = (text) => {
        if (text && text !== "N/A") {
            this.validateNutri = true;
            return true;
        }
        return false;
    }

    functValNutri = (tabla) => {
        this.objectNutri.tamano_de_la_porcion = this.validNut(tabla.tamano_de_la_porcion)
        this.objectNutri.numero_de_porciones = this.validNut(tabla.numero_de_porciones)
        this.objectNutri.por_porcion = this.validNut(tabla.por_porcion)
        this.objectNutri.contenido_energetico = this.validNut(tabla.contenido_energetico)
        this.objectNutri.proteinas = this.validNut(tabla.proteinas)
        this.objectNutri.grasas = this.validNut(tabla.grasas)
        this.objectNutri.grasa_saturada = this.validNut(tabla.grasa_saturada)
        this.objectNutri.omega_3 = this.validNut(tabla.omega_3)
        this.objectNutri.grasa_poliinsaturada = this.validNut(tabla.grasa_poliinsaturada)
        this.objectNutri.grasa_monoinsaturada = this.validNut(tabla.grasa_monoinsaturada)
        this.objectNutri.acidos_grasos_trans = this.validNut(tabla.acidos_grasos_trans)
        this.objectNutri.colesterol = this.validNut(tabla.colesterol)
        this.objectNutri.carbohidratos = this.validNut(tabla.carbohidratos)
        this.objectNutri.de_los_cuales = this.validNut(tabla.de_los_cuales)
        this.objectNutri.azucares = this.validNut(tabla.azucares)
        this.objectNutri.fibra_dietetica = this.validNut(tabla.fibra_dietetica)
        this.objectNutri.sodio = this.validNut(tabla.sodio)
        this.objectNutri.vitamina_e = this.validNut(tabla.vitamina_e)
        this.objectNutri.vitamina_a = this.validNut(tabla.vitamina_a)
        this.objectNutri.vitamina_b1 = this.validNut(tabla.vitamina_b1)
        this.objectNutri.vitamina_b6 = this.validNut(tabla.vitamina_b6)
        this.objectNutri.vitamina_d = this.validNut(tabla.vitamina_d)
        this.objectNutri.hierro = this.validNut(tabla.hierro)
        this.objectNutri.zinc = this.validNut(tabla.zinc)

        return this.validateNutri
    }

    formatTablaNutri = (tabla) => {
        return (
            <>
                <table style={{ width: "100%" }}>
                    {this.objectNutri.tamano_de_la_porcion ?
                        <tr>
                            <td>Tamaño de la porción</td>
                            <td >{tabla.tamano_de_la_porcion}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.numero_de_porciones ?
                        <tr>
                            <td>Número de porciones</td>
                            <td >{tabla.numero_de_porciones}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.por_porcion ?
                        <tr>
                            <td>Por porcion</td>
                            <td>{tabla.por_porcion}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.contenido_energetico ?
                        <tr>
                            <td>Contenido energético</td>
                            <td>{tabla.contenido_energetico}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.proteinas ?
                        <tr>
                            <td>Proteínas</td>
                            <td>{tabla.proteinas}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.grasas ?
                        <tr>
                            <td>Grasas (lípidos)</td>
                            <td>{tabla.grasas}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.grasa_saturada ?
                        <tr>
                            <td>Grasas saturadas</td>
                            <td>{tabla.grasa_saturada}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.omega_3 ?
                        <tr>
                            <td>Omega 3</td>
                            <td>{tabla.omega_3}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.grasa_poliinsaturada ?
                        <tr>
                            <td>Grasa poliinsaturada</td>
                            <td>{tabla.grasa_poliinsaturada}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.grasa_monoinsaturada ?
                        <tr>
                            <td>Grasa monoinsaturada</td>
                            <td>{tabla.grasa_monoinsaturada}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.acidos_grasos_trans ?
                        <tr>
                            <td>Ácidos grasos trans</td>
                            <td>{tabla.acidos_grasos_trans}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.colesterol ?
                        <tr>
                            <td>Colesterol</td>
                            <td>{tabla.colesterol}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.carbohidratos ?
                        <tr>
                            <td>Carbohidratos</td>
                            <td>{tabla.carbohidratos}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.de_los_cuales ?
                        <tr>
                            <td>De los cuales</td>
                            <td>{tabla.de_los_cuales}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.azucares ?
                        <tr>
                            <td>Azúcares</td>
                            <td>{tabla.azucares}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.fibra_dietetica ?
                        <tr>
                            <td>Fibra dietética</td>
                            <td>{tabla.fibra_dietetica}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.sodio ?
                        <tr>
                            <td>Sodio</td>
                            <td>{tabla.sodio}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.vitamina_e ?
                        <tr>
                            <td>Vitamina E</td>
                            <td>{tabla.vitamina_e}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.vitamina_a ?
                        <tr>
                            <td>Vitamina A</td>
                            <td>{tabla.vitamina_a}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.vitamina_b1 ?
                        <tr>
                            <td>Vitamina B1</td>
                            <td>{tabla.vitamina_b1}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.vitamina_b6 ?
                        <tr>
                            <td>Vitamina B6</td>
                            <td>{tabla.vitamina_b6}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.vitamina_d ?
                        <tr>
                            <td>Vitamina D</td>
                            <td>{tabla.vitamina_d}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.hierro ?
                        <tr>
                            <td>Hierro</td>
                            <td>{tabla.hierro}</td>
                        </tr>
                        :
                        null
                    }
                    {this.objectNutri.zinc ?
                        <tr>
                            <td>Zinc</td>
                            <td>{tabla.zinc}</td>
                        </tr>
                        :
                        null
                    }
                </table>

            </>
        )


    }


    GetCategoriesProduct = () => {

        const prod = datosCategoriaProductos.map((element, index) => {

            if (index === 1) {
                const columProd = element.map((element, index) => {
                    return (
                        <div className="col-4" style={{ position: "relative" }} >
                            <Tilt
                                className="parallax-effect-img"
                                tiltMaxAngleX={10}
                                tiltMaxAngleY={10}
                                perspective={500}
                                transitionSpeed={1500}
                                scale={1}
                                gyroscope={true}
                            >
                                <img src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ maxWidth: "100%", height: "auto", boxShadow: "0px 10px 13px -7px #000000, -3px 9px 17px 8px rgba(0,0,0,0)" }} />
                            </Tilt>
                            <img className="product-hover" src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "50%", height: "auto", position: "absolute", top: "25%", zIndex: "1", left: "25%" }} />
                            <div style={{ marginTop: "20%", textAlign: "center" }}>
                                <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                                <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />

                            </div>
                        </div>
                    )
                })

                return (
                    <div style={{ width: "100%", position: "relative", display: "flex" }}>
                        {columProd}
                    </div>
                )
            }
        })

        const prodMov = datosCategoriaProductos[1].map((element, index) => {
            return (
                <div style={{ position: "relative", maxWidth: "80%" }} >
                    <Tilt
                        className="parallax-effect-img"
                        tiltMaxAngleX={10}
                        tiltMaxAngleY={10}
                        perspective={500}
                        transitionSpeed={1500}
                        scale={1}
                        gyroscope={true}
                    >
                        <img src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ maxWidth: "100%", height: "auto" }} />
                    </Tilt>
                    <img className="product-hover" src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "50%", height: "auto", position: "absolute", top: "25%", zIndex: "1", left: "25%" }} />
                    <div style={{ marginTop: "20%", textAlign: "center" }}>
                        <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                        <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />

                    </div>
                </div>
            )
        })



        this.setState({
            getallcategoriesproduct: prod,
            getallcategoriesproductmov: prodMov
        })
    }
    nextswiper = () => {
        const swiperContainer = document.getElementsByClassName("swiper-detalle")[0].children;
        var mySwiper = swiperContainer[0].swiper
        mySwiper.slideNext();

    }
    nextprev = () => {
        const swiperContainer = document.getElementsByClassName("swiper-detalle")[0].children;
        var mySwiper = swiperContainer[0].swiper

        mySwiper.slidePrev();
    }

    componentDidMount() {
        this.getReceta()

        this.getRecetasPopulares()
        this.GetCategoriesProduct()
        this.peticionProductos()

    }
    getRecetasPopulares = () => {
        const recetasPopulares = this.props.data.recetasFavoritas.map((element, index) => {
            if (element.popular) {
                return (
                    <div key={index} style={{ maxWidth: "300px", border: "0px solid transparent" }} >
                        <div style={{ overflow: "hidden", width: "300px" }}>
                            <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS +element.imagen} />
                        </div>
                        <div style={{ fontFamily: "AvenirNextLTProMedium", width: "300px" }}>
                            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px", marginTop: "13px" }}>{element.nombre}</label>
                            <br />
                            <label style={{ fontFamily: "AvenirBook" }} >{element.descripcion}</label>

                            <br /> <br />
                            {/*<div style={{ display: "flex", fontFamily: "AvenirNextLTProMedium", font: "message-box", fontStretch: "extra-expanded"}}>
                                <img src={timer} style={{ width: "20px", height: "20px", marginRight: "11px" }} />{element.tiempo + "  " + element.personas}
                            </div>*/}
                        </div>
                    </div>

                )
            }
        })
        this.setState({
            recetasPopulares
        })

    }

    hadleIngredientesmas = (expandible, e) => {

        if (expandible === "ingredientes") {
            e.target.style.display = "none"
            e.target.nextSibling.style.display = "block"
            var plegable = document.getElementsByClassName("content-ingredientes")[0];
            var plegablem = document.getElementsByClassName("content-ingredientes-mobile")[0];
            if (plegable.style.display === "none") {
                plegable.style.display = "block"
                plegablem.style.display = "block"
            } else {
                plegable.style.display = "none"
                plegablem.style.display = "none"
            }
        }

        if (expandible === "presentacion") {
            e.target.style.display = "none"
            e.target.nextSibling.style.display = "block"
            var plegable = document.getElementsByClassName("presentacion")[0];
            var plegablem = document.getElementsByClassName("presentacion-mobile")[0];
            if (plegable.style.display === "none") {
                plegable.style.display = "block"
                //plegablem.style.display = "block"
            } else {
                plegable.style.display = "none"
                plegablem.style.display = "none"
            }
        }

        if (expandible === "nutrimentos") {
            e.target.style.display = "none"
            e.target.nextSibling.style.display = "block"
            var plegable = document.getElementsByClassName("content-nutrimentos")[0];
            var plegablem = document.getElementsByClassName("content-nutrimentos-mobile")[0];
            if (plegable.style.display === "none") {
                plegable.style.display = "block"
                plegablem.style.display = "block"
            } else {
                plegable.style.display = "none"
                plegablem.style.display = "none"
            }
        }

    }
    hadleIngredientesmenos = (expandible, e) => {

        if (expandible === "ingredientes") {
            e.target.style.display = "none"
            e.target.previousSibling.style.display = "block"
            var plegable = document.getElementsByClassName("content-ingredientes")[0];
            var plegablem = document.getElementsByClassName("content-ingredientes-mobile")[0];
            if (plegable.style.display === "none") {
                plegable.style.display = "block"
                plegablem.style.display = "block"
            } else {
                plegable.style.display = "none"
                plegablem.style.display = "none"
            }
        }
        if (expandible === "presentacion") {
            e.target.style.display = "none"
            e.target.previousSibling.style.display = "block"
            var plegable = document.getElementsByClassName("presentacion")[0];
            var plegablem = document.getElementsByClassName("presentacion-mobile")[0];
            if (plegable.style.display === "none") {
                plegable.style.display = "block"
                plegablem.style.display = "block"
            } else {
                plegable.style.display = "none"
                //                plegablem.style.display = "none"
            }
        }



        if (expandible === "nutrimentos") {
            e.target.style.display = "none"
            e.target.previousSibling.style.display = "block"
            var plegableNutrimentos = document.getElementsByClassName("content-nutrimentos")[0]
            var plegablem = document.getElementsByClassName("content-nutrimentos-mobile")[0];
            if (plegableNutrimentos.style.display === "none") {
                plegableNutrimentos.style.display = "block"
                plegablem.style.display = "block"
            } else {
                plegableNutrimentos.style.display = "none"
                plegablem.style.display = "none"
            }

        }

    }

    peticionProductos = async () => {
        let cateProducto = this.props.match.params.post_id;
        cateProducto = cateProducto.replaceAll('-', ' ');
        const data = (await axios.get(process.env.REACT_APP_API_URL + "/productos/" + cateProducto)).data;
        if (data.metaRecetas !== undefined) {
            if (data.metaRecetas.length > 0 || data.metaRecetas[0].metatitle !== '') {
                this.setState({
                    metaProductos: data[0],
                })
            }
            else {
                this.setState({
                    metaReceta: initialState,
                })
            }
        }
        else {
            this.setState({
                metaReceta: initialState,
            })
        }
    }

    render() {
        const paramsRecetasplatillo = {
            slidesPerView: 3,
            spaceBetween: 10,

            on: {

                init: () => {

                },

            }
        };
        const paramsSwipeMov = {
            slidesPerView: 'auto',
            spaceBetween: 5,
            centeredSlides: true,
            initialSlide: 2

        }

        return (
            this.state.producto.length > 0 ?
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.producto[0].metatitle}</title>
                        <meta name="description" content={this.state.producto[0].metadescription} />
                        <meta name="keywords" content={this.state.producto[0].keywords} />
                    </Helmet>
                    <seccion className="detalle-desk">
                        <div style={{ marginTop: "100px" }}>
                            <div onClick={() => { window.scrollTo(0, 0); window.location.href = ("/productos/" + this.state.producto[0].categoria.nombre.toLowerCase()) }} className="container-regresar">
                                <img src={flechaRegresar} style={{ cursor: "pointer", width: "20px", height: "auto", marginRight: "10px", transform: "rotate(90deg)" }}></img>
                                <label className="REGRESAR-A-MAYONESAS" >REGRESAR A {this.state.producto[0].categoria.nombre[this.state.producto[0].categoria.nombre.length - 1] === "s" ? this.state.producto[0].categoria.nombre : this.state.producto[0].categoria.nombre.toUpperCase() + "S"}</label>
                            </div>
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start" }}>
                                <div style={{ width: "50%", position: "sticky", bottom: "0", alignSelf: "flex-end" }}>
                                    <div style={{ width: "100%", position: "static" }}>

                                        <img alt={this.state.producto[0].alt_imagenDetalle} src={process.env.REACT_APP_CMS_ASSETS +this.state.producto[0].imagenDetalle.id} className="producto-fondo" />
                                        <div className="producto-div-container">
                                            <img alt={this.state.producto[0].alt_imagen} src={process.env.REACT_APP_CMS_ASSETS +this.state.producto[0].imagen.id} className="producto" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "50%", padding: "30px", display: "flex", justifyContent: "flex-end" }}>
                                    <div style={{ width: "70%" }}>
                                        <label className="MAYONESA-CON-CHIPOTL" style={{ color: "black" }}>{this.state.producto[0].nombre.split(" ")[0].toUpperCase()} </label><br />
                                        <label className="MAYONESA-CON-CHIPOTL">{
                                            separTitle(this.state.producto[0].nombre).toUpperCase()
                                        }
                                        </label><br />
                                        <label style={{ fontFamily: "AvenirBook" }}>{this.state.producto[0].sabias_que}</label><br />
                                        <hr style={{ background: "#d41245" }} ></hr>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <label className="collapsible"  >INGREDIENTES</label>
                                            <img src={mas} onClick={(e) => { this.hadleIngredientesmas("ingredientes", e) }} style={{ width: "20px", height: "20px", cursor: "pointer", display: "block" }}></img>
                                            <img src={menos} onClick={(e) => { this.hadleIngredientesmenos("ingredientes", e) }} style={{ maxWidth: "100%", height: "5px", cursor: "pointer", display: "none" }}></img>
                                        </div>
                                        <div style={{ display: "none" }} className="content-ingredientes">
                                            <p>{this.state.producto[0].ingredientes}</p>
                                        </div>
                                        {this.state.displayNutri !== null ?
                                            <>
                                                <hr style={{ background: "#d41245" }} ></hr>
                                                <div style={{ width: "100%", display: "flex" }}>
                                                    <label className="collapsible"  >TABLA NUTRIMENTAL POR PORCIÓN</label>
                                                    <img src={mas} onClick={(e) => { this.hadleIngredientesmas("nutrimentos", e) }} style={{ width: "20px", height: "20px", cursor: "pointer", display: "block" }}></img>
                                                    <img src={menos} onClick={(e) => { this.hadleIngredientesmenos("nutrimentos", e) }} style={{ maxWidth: "100%", height: "5px", cursor: "pointer", display: "none" }}></img>
                                                </div>
                                                <div style={{ display: "none" }} class="content-nutrimentos">
                                                    {this.state.displayNutri}
                                                </div>
                                            </>
                                            :
                                            null}
                                        <hr style={{ background: "#d41245" }} ></hr>
                                        <label className="collapsible">PRESENTACIONES</label>
                                        <div style={{ display: "block", width: "100%" }}>
                                            {this.state.listPresent.map((element, key) => {
                                                if (!element.solo_texto) {
                                                    return (
                                                        <img src={process.env.REACT_APP_CMS_ASSETS +element.imagen} title={element.gramaje} style={{ verticalAlign: "bottom", marginRight: "5%" }} />
                                                    )
                                                }
                                            })}
                                            <img src={mas} onClick={(e) => { this.hadleIngredientesmas("presentacion", e) }} style={{ float: "right", width: "20px", height: "20px", cursor: "pointer", display: !this.state.controlopen ? "block" : "none" }}></img>
                                            <img src={menos} onClick={(e) => { this.hadleIngredientesmenos("presentacion", e) }} style={{ float: "right", maxWidth: "100%", height: "5px", cursor: "pointer", display: this.state.controlopen ? "block" : "none" }}></img>
                                        </div>
                                        <div style={{ display: "none", marginTop: "10px" }} className="presentacion">
                                            {this.state.listPresent.map((element, key) => {
                                                return (
                                                    <>
                                                        <label>{element.gramaje}</label><br />
                                                    </>
                                                )
                                            })}
                                        </div>
                                        {/*
                                        <button style={{ cursor: "pointer" }} onClick={() => { window.open("www.youtube.com", "_blank") }} className="btn-carousel">DONDE COMPRAR</button>
                                        */}
                                    </div>
                                </div>
                            </div>
                            <TePuedeInteresar data={this.state.recetas}></TePuedeInteresar>
                            <TePodriaGustar data={this.state.productos}></TePodriaGustar>
                        </div>
                    </seccion>
                    {/*Sección móvil */}
                    <seccion className="detalle-mobile">
                        <div style={{ width: "100%", position: "relative" }}>
                            <div onClick={() => { window.scrollTo(0, 0); window.location.href = ("/productos/" + this.state.producto[0].categoria.nombre.toLowerCase()) }} className="container-regresar-mov">
                                <img src={flechaRegresar} style={{ cursor: "pointer", width: "20px", height: "auto", marginRight: "10px", transform: "rotate(90deg)" }}></img>
                                <label className="REGRESAR-A-MAYONESAS" >REGRESAR A {this.state.producto[0].categoria.nombre[this.state.producto[0].categoria.nombre.length - 1] === "s" ? this.state.producto[0].categoria.nombre : this.state.producto[0].categoria.nombre.toUpperCase() + "S"}</label>
                            </div>
                            <div style={{ position: "relative", width: "100%", minHeight: "400px" }}>
                                <img src={process.env.REACT_APP_CMS_ASSETS +this.state.producto[0].imagenDetalle.id} style={{ position: "absolute", zIndex: "-1", maxWidth: "150%", height: "auto", maxHeight: "400px", right: "5%", bottom: "20px" }} />
                                <img src={process.env.REACT_APP_CMS_ASSETS +this.state.producto[0].imagen.id} style={{ position: "absolute", right: "2%", bottom: "0", maxWidth: "50%", maxHeight: "400px" }} />
                            </div>
                        </div>
                        <div style={{ width: "100%", padding: "30px", display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ width: "100%" }}>
                                <label className="MAYONESA-CON-CHIPOTL" style={{ color: "black" }}>{this.state.producto[0].nombre.split(" ")[0].toUpperCase()} </label><br />
                                <label className="MAYONESA-CON-CHIPOTL">{
                                    separTitle(this.state.producto[0].nombre).toUpperCase()
                                }</label><br />
                                <label style={{ fontFamily: "AvenirBook" }}>{this.state.producto[0].sabias_que}</label><br />
                                <hr style={{ background: "#d41245" }} ></hr>
                                <div style={{ width: "100%", display: "flex" }}>
                                    <label className="collapsible"  >INGREDIENTES</label>
                                    <img src={mas} onClick={(e) => { this.hadleIngredientesmas("ingredientes", e) }} style={{ width: "20px", height: "20px", cursor: "pointer", display: "block" }}></img>
                                    <img src={menos} onClick={(e) => { this.hadleIngredientesmenos("ingredientes", e) }} style={{ maxWidth: "100%", height: "5px", cursor: "pointer", display: "none" }}></img>
                                </div>
                                <div style={{ display: "none" }} className="content-ingredientes-mobile">
                                    <p>{this.state.producto[0].ingredientes}</p>
                                </div>
                                {this.state.displayNutri !== null ?
                                    <>
                                        <hr style={{ background: "#d41245" }} ></hr>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <label className="collapsible"  >TABLA NUTRIMENTAL POR PORCIÓN</label>
                                            <img src={mas} onClick={(e) => { this.hadleIngredientesmas("nutrimentos", e) }} style={{ width: "20px", height: "20px", cursor: "pointer", display: "block" }}></img>
                                            <img src={menos} onClick={(e) => { this.hadleIngredientesmenos("nutrimentos", e) }} style={{ maxWidth: "100%", height: "5px", cursor: "pointer", display: "none" }}></img>
                                        </div>
                                        <div style={{ display: "none" }} class="content-nutrimentos-mobile">
                                            {this.state.displayNutri}
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <hr style={{ background: "#d41245" }} ></hr>
                                <label className="collapsible">PRESENTACIONES</label>
                                <div style={{ display: "block", width: "100%" }}>
                                    {this.state.listPresent.map((element, key) => {
                                        if (!element.solo_texto) {
                                            return (
                                                <img src={process.env.REACT_APP_CMS_ASSETS +element.imagen}  title={element.gramaje} style={{ verticalAlign: "bottom", marginRight: "5%" }} />
                                            )
                                        }
                                    })}
                                    <img src={mas} onClick={(e) => { this.setState({ controlopen: !this.state.controlopen }) }} style={{ float: "right", width: "20px", height: "20px", cursor: "pointer", display: !this.state.controlopen ? "block" : "none" }}></img>
                                    <img src={menos} onClick={(e) => { this.setState({ controlopen: !this.state.controlopen }) }} style={{ float: "right", maxWidth: "100%", height: "5px", cursor: "pointer", display: this.state.controlopen ? "block" : "none" }}></img>
                                </div>
                                <div style={{ display: this.state.controlopen ? "block" : "none", marginTop: "10px" }} className="presentacion">
                                    {this.state.listPresent.map((element, key) => {
                                        return (
                                            <>
                                                <label>{element.gramaje}</label><br />
                                            </>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                        <TePuedeInteresarMobile  data={this.state.recetas}></TePuedeInteresarMobile>
                        <TePodriaGustar data={this.state.productos}></TePodriaGustar>
                    </seccion>
                </>
                :
                <div style={{ width: "100%", height: "100vh" }} ></div>
        )
    }
}
const mapStateToProps = state => ({
    data: state,
});
export default connect(mapStateToProps)(DetalleProducto)