import {type as nosotrosInfo} from '../actions/nosotrosInfo'
import producto1 from '../../Nosotro/animacion/mc_web_timeline_50_s.png'
import adorno1 from '../../Nosotro/animacion/LIMONES.webp'
import adorno4 from '../../Nosotro/animacion/limones_recorte_3.png'
import mayonesa from '../../Nosotro/animacion/MAYO-LIMONES copia.webp'
import fresa from '../../Nosotro/animacion/FRESAS copia.webp'
import gelatina from '../../Nosotro/animacion/AGUACATE-TILA.webp'
import adorno2 from '../../Nosotro/animacion/MAYOSABORES.webp'
import limon from '../../Nosotro/animacion/AGUACATE.webp'
import producto4 from '../../Nosotro/animacion/BALNCE-TE-MAYO copia.webp'
import
 mayonesamermelada from '../../Nosotro/animacion/MERM-MOSTAZA.webp'


import React from 'react'
const nosotrosInfoData = [
    {
        "id":1,
        "titulo":"UN SABOR PARA SIEMPRE",
        "descripcion":"¿Sabías que en 1947 nace McCormick de México? Creamos la Mayonesa con Jugo de Limones con una receta única que se volvió la favorita de todos.",
        "año":"1947",
        "producto":producto1,
        "adorno":adorno4
    },
    {
        "id":2,
        "titulo":"Póngale lo sabroso",
        "descripcion":"Nace la reconocida frase Póngale lo sabroso, un ícono de la marca que chicos y grandes hemos cantado a través de los años.",
        "año":"1960",
        "producto":mayonesa,
        "adorno":adorno1
    },
    {
        "id":3,
        "titulo":"Sabor hecho tradición",
        "descripcion":"El delicioso sabor de productos como Mayonesa, Mermelada y Mostaza logró que McCormick estuviera presente en las mesas y recetas de los mexicanos hasta volvernos una marca de tradición.",
        "año":"1970",
        "producto":mayonesamermelada,
        "adorno":fresa
    },
    {
        "id":4,
        "titulo":"Sabor hecho tradición",
        "descripcion":"Nacen las Mayonesas de Sabores, como una oferta diferenciada para seguir consintiendo a los mexicanos.",
        "año":"90´s",
        "producto":adorno2,
        "adorno":limon
    },
    {
        "id":5,
        "titulo":"Innovación a cada paso",
        "descripcion":"Seguimos innovando día a día para ofrecer sabores únicos y en tendencia. Nuevos integrantes continúan uniéndose a la familia McCormick todos los años,incluyendo la nueva línea de Mayonesas Balance con aceites de semillas naturales.",
        "año":"2020",
        "producto":producto4,
        "adorno":gelatina
    }
];

const defaultState=[
]

function reducer(state = defaultState,{type,id}){
    switch (type) {
        case nosotrosInfo: {
          
            return nosotrosInfoData.filter((element,index)=>{
                return (element.id === id)
            })
        }

        default:
            return nosotrosInfoData
    }
}
export default reducer;
