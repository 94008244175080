import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import SendContacto from '../redux/actions/SendContacto'
import mail from './img/noun-mail.png'
import history from '../../history'
import axios from 'axios'
import Swal from 'sweetalert2'
import { withStyles } from '@material-ui/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import './index.css'
import { extname } from 'path';
import {Helmet} from "react-helmet";

const styles = theme => ({
  textField: {
    '& label.Mui-focused': {
      color: '#00529f',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00529f',
      color: '#00529f',
    },
    
    '& .MuiOutlinedInput-root': {
      fontFamily: 'AvenirBook',
      boxShadow: '0px 3px 6px #00000029',
      '& fieldset': {
        borderColor: '#C2C2C2',
        color: '#00529f',
      },
      '&:hover fieldset': {
        borderColor: '#00529f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00529f',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px',
      fontFamily: 'AvenirBook'
    },
    
    width: '100%',
    marginBottom: '10px',
    fontFamily: 'AvenirBook',
    fontSize: '13px',
    background: '#ffffff'
  },
  form:{
    width: '100%',
  }
});

class Contacto extends PureComponent {
  constructor() {
    super()
    this.state = {
      nombre: "",
      correo: "",
      telefono: "",
      mensaje: "",
      email: false,
      allFieldsValid: false,
    }
    //this.handleInput = this.handleInput.bind(this)
    //this.handleContacto = this.handleContacto.bind(this)
  }

  /*handleContacto(e) {
    e.preventDefault()

    const nombre = document.getElementsByName("nombre")[0]
    const correo = document.getElementsByName("correo")[0]
    const telefono = document.getElementsByName("telefono")[0]
    const mensaje = document.getElementsByName("mensaje")[0]
    const alert = document.getElementsByName("alert")[0]

    if (nombre.value.length === 0) {
      console.log("nombre")
      alert.style.display = "flex"
      nombre.style.border = "3px solid #d41245"
    } else if (correo.value.length === 0) {
      console.log("correo")
      alert.style.display = "flex"
      correo.style.border = "3px solid #d41245"
    } else if (telefono.value.length === 0) {
      console.log("telefono")
      alert.style.display = "flex"
      telefono.style.border = "3px solid #d41245"
    } else if (mensaje.value.length === 0) {
      console.log("mensaje")
      alert.style.display = "flex"
      mensaje.style.border = "3px solid #d41245"
    }
    else {
      alert.style.display = "none"
      const that=this

      axios.post(process.env.REACT_APP_API_URL+"/contactos", {
        nombre: this.state.nombre,
        correo: this.state.correo,
        telefono:this.state.telefono,
        mensaje:this.state.mensaje
      })
      .then(function (response) {
        if(response.status === 200){
          that.setState({
            email: true
          })
        }else{
          Swal.fire('Por el momento no se puede enviar tu mensaje, intenta más tarde.')
        }
      })
      .catch(function (error) {
        Swal.fire('Por el momento no se puede enviar tu mensaje, intenta más tarde.')
        console.log(error);
      });
      this.props.SendContacto(this.state.nombre, this.state.correo, this.state.telefono, this.state.mensaje)
    
    }


  }*/

  handleSubmit = (e) => {
    e.preventDefault()
    const that = this;
    axios.post(process.env.REACT_APP_API_URL_NEW + "/contactos", {
      nombre: this.state.nombre,
      correo: this.state.correo,
      telefono: this.state.telefono,
      mensaje: this.state.mensaje
    })
      .then(function (response) {
        if (response.status === 200) {
          that.setState({
            email: true
          })
        } else {
          Swal.fire('Por el momento no se puede enviar tu mensaje, intenta más tarde.')
        }
      })
      .catch(function (error) {
        Swal.fire('Por el momento no se puede enviar tu mensaje, intenta más tarde.')
        console.log(error);
      });
    this.props.SendContacto(this.state.nombre, this.state.correo, this.state.telefono, this.state.mensaje)
  }

  /* handleInput(e) {
    const { value, name } = e.target;
    if (value.length > 0) {
      e.target.style.border = "1px solid gray"
      document.getElementsByName("alert")[0].style.display = "none"

    }
    this.setState({
      [name]: value
    })
  }*/
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="contacto-container" >
          <Helmet>
              <meta charSet="utf-8" />
              <title>Contáctanos | McCormick México</title>
              <meta name="description" content="Tu opinión es muy valiosa para nosotros, por eso te invitamos a que nos contactes y con gusto leeremos tus mensajes."/>
          </Helmet>
        <label className="CONTACTO">CONTACTO</label><br/><br/>
          <label style={{fontFamily:"AvenirBook",display: this.state.email ? "none" : "flex",marginLeft:"5.2%"}}>Queremos conocerte y saber qué opinas de nosotros. ¡Contáctanos!</label>
          <div style={{ width: "100%", display: this.state.email ? "none" : "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="form-contacto">
              <ValidatorForm
                ref="form"
                className={classes.form}
                onSubmit={this.handleSubmit}
                onError={errors => console.log(errors)}>
               
                <TextValidator
                  className={classes.textField}
                  label="Nombre Completo"
                  variant="outlined"
                  type="text"
                  validators={['required']}
                  onChange={e => this.setState({ nombre: e.target.value })}
                  errorMessages={['Este campo no puede ser vacío']}
                  value={this.state.nombre} />
                <TextValidator
                  className={classes.textField}
                  label="Teléfono"
                  variant="outlined"
                  validators={['required']}
                  onChange={e => this.setState({ telefono: e.target.value })}
                  errorMessages={['Ingrese un número de teléfono válido']}
                  value={this.state.telefono} />
                <TextValidator
                  className={classes.textField}
                  label="Correo Electrónico"
                  variant="outlined"
                  validators={['required', 'isEmail']}
                  onChange={e => this.setState({ correo: e.target.value })}
                  errorMessages={['Ingrese un correo electrónico', 'Ingrese un correo electrónico válido']}
                  value={this.state.correo} />
                <TextValidator
                  className={classes.textField}
                  multiline rows="4"
                  label="Mensaje"
                  variant="outlined"
                  validators={['required']}
                  onChange={e => this.setState({ mensaje: e.target.value })}
                  errorMessages={['Ingrese el mensaje que desea enviar']}
                  value={this.state.mensaje} />
                <div className="row">
                  <button className="btn-carousel mx-auto" type="submit" /*onClick={(e) => { history.push("/enviado") }}*/>ENVIAR</button>
                </div>
              </ValidatorForm>
            </div>
            {/*<form className="form-contacto">
              <div style={{ width: "100%", display: "none" }} name="alert" className="alert alert-danger" role="alert">Completa los campos faltantes</div>
              <input onChange={(e) => { this.handleInput(e) }} type="text" name="nombre" placeholder="Nombre completo" className="imput-contacto" style={{ width: "100%", marginTop: "20px" }} />
              <input onChange={(e) => { this.handleInput(e) }} type="email" name="correo" placeholder="Correo electrónico" className="imput-contacto" style={{ width: "100%", marginTop: "20px" }} />
              <input onChange={(e) => { this.handleInput(e) }} type="tel" name="telefono" placeholder="Teléfono" className="imput-contacto" style={{ width: "100%", marginTop: "20px" }} />
              <textarea onChange={(e) => { this.handleInput(e) }} rows="6" name="mensaje" placeholder="Mensaje" className="imput-contacto" style={{ width: "100%", marginTop: "20px" }} ></textarea>
              <button onClick={(e) => { this.handleContacto(e) }} placeholder="Mensaje" className="btn-carousel" >ENVIAR  </button>
            </form>*/}
          </div>


          <div style={{ width: "100%", display: this.state.email ? "flex" : "none", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "50px" }}>
            <img src={mail} style={{ maxWidth: "100%", height: "auto" }} ></img>
            <label className="Se-envio-tu-mensaje" >SE ENVIÓ TU MENSAJE</label>
            <label style={{fontFamily:"AvenirBook"}} >¡Gracias por contactarnos! En breve nos comunicaremos contigo.</label>
            <button onClick={() => { window.scrollTo(0, 0); history.push("/") }} placeholder="Mensaje" style={{ marginTop: "50px" }} className="btn-carousel" >IR AL HOME</button>
          </div>

        </div>

      </>
    )
  }
}


const mapStateToProps = state => ({
  data: state,
});

const mapDispatchToProps = {
  SendContacto
};

export default (connect(mapStateToProps, mapDispatchToProps), withStyles(styles, { withTheme: true }))(Contacto)