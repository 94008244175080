import React,{ PureComponent } from "react";
import'../index.css'
import Parallax from 'parallax-js'
import te_jengibre from "./img/60fd8e329c6d761545c4dbd9a9e10731@2x.png"
import jengibre from "./img/Imagen 108@2x.png"
import te_verde from "./img/Imagen 97@2x.png"
import mango from "./img/Imagen 109@2x.png"
import fondo_detox from "./img/Grupo 4919@2x.png"
// import download from 'downloadjs'
import bannerMobile from './img/Grupo 4920.png'
import pdf from './img/Recetario_comprimido 2MB.pdf'
import  axios from 'axios'
export default class BannerDetox extends PureComponent {
    constructor(props) {
        super(props)
        this.sstate = {
        }
    }
    myAwesomeCallback = () => {
        // document.getElementById('te-jengibre').style.top = "10vw"
        // document.getElementById('te-jengibre').style.left = "1%"
        // document.getElementById('jengibre').style.top = "4vw"
        // document.getElementById('jengibre').style.left = "12%"
        // document.getElementById('te-verde').style.top = "18vw"
        // document.getElementById('te-verde').style.left = "1%"
        // document.getElementById('mango').style.top = "25vw"
        // document.getElementById('mango').style.left = "12%"
    }
    componentDidMount() {
        console.log('detox')
        console.log(this.props.dataDetox.imagen_fondo_mobile)
        var scene = document.querySelectorAll('.scene-detox');
       
    }
    render(){
        if(this.props.option ==="desk"){
            return(
                <>
                    <div  style={{ position: "relative" }}>
                        <div className="scene-detox" >
                            <img src={process.env.REACT_APP_CMS_ASSETS +this.props.dataDetox.imagen_fondo} style={{ width: "100%", height: "auto" }}></img>
                            <div id="te-jengibre" data-depth-x="0.30" data-depth-y="-0.30"  style={{ position: "absolute", zIndex: "51", top: "20%", left: "4%" }}>
                                <img src={process.env.REACT_APP_CMS_ASSETS +this.props.dataDetox.imagen_producto} style={{ maxWidth: "40%", height: "auto", position: "relative", top: "5%", left: "1%" }}></img>
                            </div>
                            {/* <div id="jengibre" data-depth-x="0.30" data-depth-y="-0.30"  style={{ position: "absolute", zIndex: "51", top: "20%", left: "35%" }}>
                            <img src={jengibre} style={{ maxWidth: "31%", height: "auto", position: "relative", top: "32%", left: "12%" }}></img>
                            </div> */}
                            {/* <div id="te-verde" data-depth-x="0.30" data-depth-y="-0.30"  style={{ position: "absolute", zIndex: "51", top: "20%", left: "35%" }}>
                            <img src={te_verde} style={{ maxWidth: "40%", height: "auto", position: "relative", top: "44%", left: "1%", animation: "9s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }}></img>
                            </div> */}
                            {/* <div id="mango" data-depth-x="0.30" data-depth-y="-0.30"  style={{ position: "absolute", zIndex: "51", top: "20%", left: "35%" }}>
                            <img src={mango} style={{ maxWidth: "30%", height: "auto", position: "relativa", top: "73%", left: "3%" }}></img>
                            </div> */}
                        </div>
                        <div  className="cont-btn-detox">
                            <button onClick={() => { document.location.href = this.props.dataDetox.boton_url }} className="btn-detox" style={{ marginRight: "15px",marginBottom:"10px" }}>VER PLAN DETOX</button>
                            <button onClick={() => { window.open(this.props.dataDetox.boton_url_2 , '_blank') }}  className="btn-detox" style={{ marginRight: "15px",marginBottom:"10px" }}>DESCARGAR RECETARIO</button>
                            <br />
                        </div>
                    </div>
                </>
            )
        }else{
            return(
                <>
                    <div  style={{ position: "relative" }}>
                        <div  >
                            {console.log(process.env.REACT_APP_CMS_ASSETS+this.props.dataDetox.imagen_fondo_mobile)}
                            <img src={process.env.REACT_APP_CMS_ASSETS+this.props.dataDetox.imagen_fondo_mobile} style={{ width: "100%", height: "auto" }}></img>
        
                        </div>
                        <div  className="cont-btn-detox">
                            <button onClick={() => { document.location.href = this.props.dataDetox.boton_url }} className="btn-detox" style={{ marginRight: "15px",marginBottom:"10px" }}>VER PLAN DETOX</button>
                            <button onClick={() => { window.open(this.props.dataDetox.boton_url_2 , '_blank') }}  className="btn-detox" style={{ marginRight: "15px",marginBottom:"10px" }}>DESCARGAR RECETARIO</button>
                            <br />
                        </div>
                    </div>
                </>
            )
        }
      
    }

    
}