import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles';
import styles from './style'
import { connect } from 'react-redux'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import Swiper from 'react-id-swiper'
import Tilt from 'react-parallax-tilt';
import axios from 'axios'
class TePodriaGusta extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      productos: [],
      swiperFlechas: false,
      swiperFlechasMov: false
    }
    this.refMovSwiper = React.createRef()
  }
  componentDidMount() {
    if (this.props.data) {
    }
    this.loadsProduct()
  }
  nextswiper = () => {
    if (this.refMovSwiper.current.children[0].swiper) {
      this.refMovSwiper.current.children[0].swiper.slideNext()
    }
  }
  nextprev = () => {
    if (this.refMovSwiper.current.children[0].swiper) {
      this.refMovSwiper.current.children[0].swiper.slidePrev()
    }
  }
  nextswiperM = () => {
    const swiperContainer = document.getElementsByClassName("tepodriagustarM")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slideNext();
  }
  nextprevM = () => {
    const swiperContainer = document.getElementsByClassName("tepodriagustarM")[0].children;
    var mySwiper = swiperContainer[0].swiper
    mySwiper.slidePrev()
  }
  loadsProduct = async () => {
    // const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/tags?fields=*.*,recetas.recetas_id.*&filter[nombre]=Te puede interesar`);
    // const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/tags`);
    // console.log('gustar')
    // console.log(categoriasRelacionadas.data)
    this.setState({
      productos: this.props.data && this.props.data.filter((e)=>{return(e.estatus ==="published")})
    });


  }
  render() {
    const paramsRecetasplatillo = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      }
    }
    const paramsRecetasplatilloMobile = {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 15,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
    }
    const { classes } = this.props;
    return (
      <>
        {this.props.data &&
          <>
            <div className={classes.mobile} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", position: "relative" }}>
              <div style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir", padding: "10px" }}>
                <div style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >
                  <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular">PRODUCTOS</b><br />
                  <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">TAMBIÉN TE PODRÍA<br /> GUSTAR</label><br />
                </div>
                <img src={isq} alt="FlechasMovil" onClick={() => { this.nextprev() }} style={{ display: this.state.swiperFlechasMov ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "2", position: "absolute", top: "55%", left: "2%", cursor: "pointer" }} />
                <div style={{ width: "inherit" }} >
                  {(() => {
                    if (this.props.data.length > 0) {
                      if (this.props.data.length > 1) {
                        this.setState({
                          swiperFlechasMov: true
                        })
                      } else {
                        this.setState({
                          swiperFlechasMov: false
                        })
                      }
                      const prod = this.props.data.map((element, index) => {
                      
                        return (
                          <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ position: "relative", width: "100%" }} >
                            <img src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ maxWidth: "80%", height: "auto", marginLeft: "13%" }} />
                            <img className="product-hover" src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "38%", maxHeight: "300px", position: "absolute", bottom: "25%", zIndex: "1", left: "31%" }} />
                            <div style={{ marginTop: "20%", textAlign: "center" }}>
                              <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                              <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                            </div>
                          </div>
                        )
                      })
                      return (
                        <>
                          <div ref={this.refMovSwiper}>

                            <Swiper {...paramsRecetasplatilloMobile}>
                              {prod}
                            </Swiper>
                          </div>
                        </>
                      )

                    }
                  })()}
                </div>
                <img onClick={() => { this.nextswiper() }} src={der} style={{ display: this.state.swiperFlechasMov ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "2", position: "absolute", top: "55%", right: "2%", cursor: "pointer" }} />
              </div>
            </div>
            <div className={classes.desk} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff" }}>
              <div className="col-11" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                <div style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >
                  <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular">PRODUCTOS</b><br />
                  <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">TAMBIÉN TE PODRÍA<br /> GUSTAR</label><br />
                </div>
                <img src={isq} onClick={() => { this.nextprevM() }} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", left: "-1%", cursor: "pointer" }} />
                <div style={{ width: "inherit" }} >
                  {(() => {
                    if (this.props.data.length > 0) {
                      if (this.props.data.length > 3) {
                        this.setState({
                          swiperFlechas: true
                        })
                      } else {
                        this.setState({
                          swiperFlechas: false
                        })
                      }
                      const prod = this.props.data.map((element, index) => {
                        return (
                          <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-4" style={{ position: "relative" }} >
                            <Tilt
                              className="parallax-effect-img"
                              tiltMaxAngleX={10}
                              tiltMaxAngleY={10}
                              perspective={500}
                              transitionSpeed={1500}
                              scale={1}
                              gyroscope={true}
                            >
                              <img src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ width: "100%", height: "auto", }} />
                            </Tilt>
                            <img className="product-hover" src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "50%", maxHeight: "300px", position: "absolute", bottom: "20%", zIndex: "1", left: "25%" }} />
                            <div style={{ marginTop: "20%", textAlign: "center" }}>
                              <b style={{ fontSize: "16px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                              <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                            </div>
                          </div>
                        )
                      })
                      return (
                        <>
                          <div className="tepodriagustarM">
                            <Swiper {...paramsRecetasplatillo}>
                              {prod}
                            </Swiper>
                          </div>
                        </>
                      )
                    }
                  })()}
                </div>
                <img onClick={() => { this.nextswiperM() }} src={der} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "50%", right: "-1%", cursor: "pointer" }} />
              </div>
            </div>
          </>
        }
      </>

    )
  }
}
TePodriaGusta.propTypes = {
  classes: PropTypes.object.isRequired,
};



const tepodriagusta = withStyles(styles, { withTheme: true })(TePodriaGusta);


export default (tepodriagusta)