import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import recetas from './reducers/getallrecipes';
import categorias from './reducers/getallcategorias'
import categoriesproduct from  './reducers/getallcategoriesproduct'
import  getMetadataRecipes from './reducers/getmetadatarecipes'
import getrecipesfavorites from './reducers/getrecipesfavorites'
import getproductsbycategory from './reducers/getproductsbycategory'
import getrecipeswhitmayonesa from './reducers/getrecipeswhitmayonesa'
import getrelatedrecipes from './reducers/getrelatedrecipes'
import getallpromotions from './reducers/getallpromotions'
import menucontroller from './reducers/menucontroller'
import modal from  './reducers/modal'
import MenuController from './reducers/menucontroller';
import BuscadorHeader from './reducers/buscadorheader'
import productos from './reducers/getallproduct'
import categoriaRecetas from './reducers/getrecipesbycategory'
import   nosotrosInfo from './reducers/nosotrosinfo'
import categoriaTag from './reducers/getalltags'
import categoriasRelacionada from './reducers/getcategoriasrelacionadas'
import productosRelacionados from './reducers/getproductosrelacionadosconproductos'
const reducer = combineReducers({
  productosRelacionados,
    recetas,
    categorias,
    categoriasRelacionada,
    getMetadataRecipes,
    categoriesproduct,
    recetasFavoritas:getrecipesfavorites,
    getproductsbycategory,
    recipesWhitMayonesas:getrecipeswhitmayonesa,
    recipesRelated:getrelatedrecipes,
    getallpromotions,
    modal,
    menu:MenuController,
    BuscadorHeader,
    productos,
    categoriaTag,
    categoriaRecetas,
    nosotrosInfo
});
const persistConfig = {
    key: 'root',
    storage,
    whitelist: [  productosRelacionados,categoriasRelacionada, nosotrosInfo,categoriaRecetas,categoriaTag,productos,getallpromotions,modal,MenuController, BuscadorHeader,getproductsbycategory,getMetadataRecipes] // navigation will not be persisted
  }
  const persistedReducer = persistReducer(persistConfig, reducer)
export default () => {
    let store = createStore(persistedReducer)
    let persistor = persistStore(store)
    return { store, persistor }
  }
