import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper';
import Fade from 'react-reveal/Fade'
import './index.css'
import axios from 'axios'
import der from '../Resource/iconos/arrow-slide-der.svg'
import izq from '../Resource/iconos/arrow-slide-izq.svg'
export default class TeSorprendemos extends PureComponent {
    constructor() {
        super()
        this.state = {
            swiperFlechas: true,
            bannerData: []
        }
        // Variables necesarias para acceder al swiper a través de código
        this.refSwiper = React.createRef();// Referencia a contenedor del Swiper
        this.swiperprincipal = null //Variable para almacenar objeto swiper
    }
    obtenerbanner = async () => {
        const banner = await axios.get(process.env.REACT_APP_API_URL_NEW + "/te_sorprendemos_home");
        this.setState({
            bannerData: banner.data.data
        })
        console.log('banner')
        console.log(banner)
    }
    componentDidMount() {
        //Almacenar objeto swiper en la variable del componente.

        this.obtenerbanner()
    }
    nextswiper = () => {
        this.swiperprincipal = this.refSwiper.current.children[0]
        if (this.swiperprincipal.swiper) {
            if (this.swiperprincipal.swiper.activeIndex === 2) {
                this.swiperprincipal.swiper.slideTo(0);
            }
            else {
                this.swiperprincipal.swiper.slideNext();
            }
        }
    }
    prevswiper = () => {
        this.swiperprincipal = this.refSwiper.current.children[0]
        if (this.swiperprincipal.swiper) {
            if (this.swiperprincipal.swiper.activeIndex === 0) {
                this.swiperprincipal.swiper.slideTo(2);
            }
            else {
                this.swiperprincipal.swiper.slidePrev();
            }
        }
    }
    render() {
        const params = {
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            speed: 600,
            parallax: true,
            parallaxEl: {
                el: '.parallax-bg',
                value: '-23%'
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false
            },
            slidesPerView: 1,
            spaceBetween: 30,
            loop: false,
            /*navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }*/
        }
        return (
            <>
                <div style={{ width: "100%", marginTop: "30px", fontFamily: "Avenir", position: "relative" }}>
                    <img src={izq} onClick={() => { this.prevswiper() }} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "45%", left: "2%", cursor: "pointer" }} />
                    <img src={der} onClick={() => { this.nextswiper() }} style={{ display: this.state.swiperFlechas ? "block" : "none", maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "45%", right: "2%", cursor: "pointer" }} />
                    <div ref={this.refSwiper} className="sombra" >
                        {this.state.bannerData.length > 0 && <Swiper {...params}>
                            {this.state.bannerData.map((e, i) => {
                                return (
                                    <div style={{ backgroundImage: `url(${process.env.REACT_APP_CMS_ASSETS}${e.imagen_fondo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "656px", cursor: "pointer" }} onClick={() => { window.scrollTo(0, 0); window.location.href = (e.url_producto) }}>
                                        <Fade left> <label className="CocogooseProRegular" style={{ fontSize: "42px", margin: "0px", marginLeft: "70px", marginTop: "24px" }}>TE SORPRENDEMOS</label></Fade>
                                        {/* <div style={{ position: "absolute", zIndex: "50", top: "1%", right: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay="1.5s" style={{ maxWidth: "20%", height: "auto" }} src={elemen1Banner1} />
                                        </div> */}
                                        {/* <div style={{ position: "absolute", zIndex: "50", bottom: "14%", left: "37%" }}>
                                            <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay="1.5s" style={{ maxWidth: "35%", height: "auto" }} src={elemen2Banner1} />
                                        </div> */}
                                        {/* <div style={{ position: "absolute", zIndex: "50", top: "13%", left: "5%" }}>
                                            <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay=".95s" style={{ maxWidth: "70%", height: "auto" }} src={elemen3Banner1} />
                                        </div> */}
                                        <div style={{ position: "absolute", zIndex: "50", top: "25%", left: "35%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img alt={e.alt_imagen_producto} style={{ maxWidth: "70%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_producto} />
                                        </div>
                                        <div style={{ position: "absolute", top: "25%", left: "12%" }}>
                                            <img alt={e.alt_imagen_comida} className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay=".75s" style={{ width: "25vw", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_comida} />
                                        </div>
                                        {/* <div style={{ position: "absolute", zIndex: "50", bottom: "5%", left: "2%" }}>
                                            <img className="wow fadeInDown " data-wow-iteration="1" data-wow-offset="1" data-wow-delay=".5s" style={{ maxWidth: "35%", height: "auto" }} src={elemen5Banner1} />
                                        </div> */}
                                        <div className="info-te-sorprendemos col-5" style={{ marginTop: "30px" }}>
                                            <Fade left> <b style={{ fontSize: "28px" }} className="CocogooseProRegular">{e.titulo}</b><br /><br /></Fade>
                                            <Fade left><label style={{ fontSize: "28px" }} className="CocogooseProRegular">{e.texto}</label><br /><br /></Fade>
                                            <Fade left><a style={{ fontFamily: "AvenirBook", fontSize: "18px" }}>{e.frase}</a><br /><br /><br /></Fade>
                                            {e.boton_copy && <button className=" btn-carousel menos">{e.boton_copy}</button>}
                                        </div>
                                    </div>
                                )
                            })}
                        </Swiper>}
                    </div>
                </div>
            </>
        )
    }
}