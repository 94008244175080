const OBTENER_PRODUCTOS_POR_CATEGORIAS = "OBTENER_PRODUCTOS_POR_CATEGORIAS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_PRODUCTOS_POR_CATEGORIAS:
            return { ...state, categoriaProductos: action.categoriaProductos }

        default:
            return state;
    }

}

export default reducer;