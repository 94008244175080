import React, { PureComponent } from 'react'
import buttonAnimation from './animacion/button.png'
import nosotrosInfo from '../redux/actions/nosotrosInfo'
import {Helmet} from "react-helmet";
import { connect } from 'react-redux'
import './index.css'
import { element } from 'prop-types';

class Nosotros extends PureComponent {
    constructor() {
        super()
        this.state = {
            initialPositionx: null,
            initialPositiony: null,
            id: "",
            titulo: "Sabor hecho tradición",
            descripcion: "",
            año: "",
            producto: "",
            adorno: "",
            value: 25
        }
    }

    handleChange = value => {
        this.setState({ value });
    };

    handleChangeRange = event => {
        this.setState({
            value: event.target.valueAsNumber,
        });
    };

    handleRotation = (y, x, e, rotation) => {


        let deviceWidth = document.documentElement.clientWidth;

        const containerWidth = document.getElementsByClassName("contrainer-animation")[0].clientWidth;
        const velocidad = (210) / containerWidth; // 190 es el maximo de angulo permitido divido por el ancho del contenedor paro obtener la velocidad adecuando entre diferentes dispositivos

        const currentDegrees = parseFloat(e.target.parentNode.style.transform.replace("rotate(", "").replace("deg)", ""));

        if ( (x >= this.state.initialPositionx && currentDegrees <= 190 ) || currentDegrees == 0 )  {
            const number = parseFloat(e.target.parentNode.style.transform.replace("rotate(", "").replace("deg)", ""))
            e.target.parentNode.style.transform = `rotate(${number + velocidad}deg)`
        } else {
            const number = parseFloat(e.target.parentNode.style.transform.replace("rotate(", "").replace("deg)", ""))
            e.target.parentNode.style.transform = `rotate(${number - velocidad}deg)`
        }

    }
    handleUpdateInfo = () => {
        console.log(this.props.nosotrosInfoData)
        this.props.nosotrosInfoData.map((eleme, index) => {
            if (index === 0) {
                this.setState({
                    id: eleme.id,
                    titulo: eleme.titulo,
                    descripcion: eleme.descripcion,
                    año: eleme.año,
                    producto: eleme.producto,
                    adorno: eleme.adorno
                })
            }
        })
    }

    componentDidMount() {
        this.handleUpdateInfo()
        const img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        img.onload = () => this.setState({ dragImg: img });
    }

    imageCustom = (e) => {
        if (e.type === 'dragstart') {
            e.dataTransfer.setDragImage(this.getDragImage(), 0, 0);
        }

    }

    getDragImage() {
        let img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        return img;
    }

    goToPoint = (e) => {

        const x = this.currentPositionx;
            const rotacion = e.target.parentNode.style.transform;
            const rotation = parseFloat(rotacion.replace("rotate(", "").replace("deg)", ""))
            if (rotation >= 0 && rotation < 37) {

                if (x < this.state.initialPositionx)
                    e.target.parentNode.style.transform = "rotate(-0.5deg)";
                else {
                    e.target.parentNode.style.transform = "rotate(37deg)";
                }
            }


            if (rotation > 37 && rotation < 90) {

                if (x < this.state.initialPositionx)
                    e.target.parentNode.style.transform = "rotate(37deg)";
                else {
                    e.target.parentNode.style.transform = "rotate(90deg)";
                }
            }


            if (rotation > 90 && rotation < 140) {

                if (x < this.state.initialPositionx)
                    e.target.parentNode.style.transform = "rotate(90deg)";
                else {
                    e.target.parentNode.style.transform = "rotate(140deg)";
                }
            }


            if (rotation > 140 && rotation < 190) {

                if (x < this.state.initialPositionx)
                    e.target.parentNode.style.transform = "rotate(140deg)";
                else {
                    e.target.parentNode.style.transform = "rotate(190deg)";
                }
            }

        

    }

    cancelable = (e) => {
        // e.preventDefault();
    }

    rotate = (e) => {
        var x = window.event.clientX;
        var y = window.event.clientY;
        if (e.type === 'touchmove') {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }

        else {

            x = window.event.clientX;
            y = window.event.clientY;

        }


        const rotacion = e.target.parentNode.style.transform;
        const rotation = parseFloat(rotacion.replace("rotate(", "").replace("deg)", ""))
        if (rotation >= 0 && rotation < 22) {
            this.props.nosotrosInfo(1)
            this.handleUpdateInfo()
        }
        if (rotation >= 22 && rotation < 60) {
            this.props.nosotrosInfo(2)
            this.handleUpdateInfo()
        }
        if (rotation >= 60 && rotation < 115) {
            this.props.nosotrosInfo(3)
            this.handleUpdateInfo()
        }
        if (rotation >= 115 && rotation < 165) {
            this.props.nosotrosInfo(4)
            this.handleUpdateInfo()
        }
        if (rotation >= 165 && rotation < 195) {
            this.props.nosotrosInfo(5)
            this.handleUpdateInfo()
        }
        if (rotation < 0) {
            e.target.parentNode.style.transform = `rotate(${0}deg)`
        } else {
            this.handleRotation(y, x, e, rotation)
        }
        if (rotation > 195) {
            e.target.parentNode.style.transform = `rotate(${195}deg)`
        } else {
            this.handleRotation(y, x, e, rotation)
        }
    }
    render() {
        return (
            <>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Nuestra Historia | McCormick México</title>
                        <meta name="description" content="Conoce más sobre la historia de McCormick, un sabor que llegó a México en 1947 con la clásica mayonesa con jugo de limones."/>
                    </Helmet>
                    <div className="nosotros-container" >
                        <div className="col-12" style={{ paddingLeft: "8%" }}>
                            <label style={{ fontSize: "42px" }} className="titulo-nosotrosH">Descubre Cómo Nació<br />McCormick en México</label>
                            <label className="texto-nosotros">Desde nuestra llegada al país hace más de 70 años, ha sido nuestra misión crear sabores únicos para las familias mexicanas</label>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>

                <div className="animation-section">
                    <div className="contrainer-animation" >
                        <img className="img-product-desk" src={this.state.producto} style={{ height: "180px", position: "absolute", bottom: "7%", left: "0%", pointerEvents: "none" }} />
                        <img className="img-product-mob" src={this.state.producto} style={{ height: "90px", position: "absolute", bottom: "7%", left: "0%", pointerEvents: "none" }} />
                        <img src={this.state.adorno} style={{ maxWidth: "30%", position: "absolute", bottom: "7%", right: "10%", pointerEvents: "none" }} />
                        <div style={{ width: "57%", zIndex: "2", transform: "translate(-50%, -50%)", top: "44%", left: "45%", position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <label className="titulo-nosotros" >{this.state.titulo}</label>
                            <label className="año-nosotros" >{this.state.año}</label>
                            <label className="descripcion-nosotros" >{this.state.descripcion}</label>
                        </div>
                        <div style={{ transform: "rotate(0deg)" }} className="linea-nosotros img-product-desk">
                            <img
                                onDragStart={(e) => this.imageCustom(e)}
                                ondragover={(e) => { e.preventDefault(); e.dataTransfer.setDragImage(<p></p>, 0, 0); }}
                                onDrag={(e) => { e.preventDefault(); this.rotate(e); if(window.event.clientX !=0 )this.currentPositionx = window.event.clientX; }}
                                onMouseDown={() => { this.setState({ initialPositionx: window.event.clientX, initialPositiony: window.event.clientY }) }}
                                onDragEnd={(e) => { e.preventDefault(); this.goToPoint(e) }}
                                draggable="true"
                                alt=""
                                className="image-plato"
                                src={buttonAnimation} style={{ position: "absolute", zIndex: "50", top: "41%", left: "5%", zIndex: 1 }}></img>

                        </div>
                        <div style={{ transform: "rotate(0deg)" }} className="linea-nosotros img-product-mob">
                            <img
                                draggable="true"
                                alt=""
                                onDragStart={(e) => { e.preventDefault(); this.imageCustom(e) }}
                                onDrag={(e) => { e.preventDefault(); this.rotate(e) }}
                                onTouchStart={(e) => { e.preventDefault(); this.setState({ initialPositionx: e.touches[0].clientX, initialPositiony: e.touches[0].clientY }) }}
                                onTouchMove={(e) => { e.preventDefault(); this.rotate(e); this.currentPositionx = e.touches[0].clientX; }}
                                onTouchEnd={(e) => { this.goToPoint(e) }}
                                onTouchCancel={(e) => this.cancelable(e)}
                                onDragEnd={(e) => { this.goToPoint(e) }}
                                className="image-plato"
                                src={buttonAnimation} style={{ position: "absolute", zIndex: "50", top: "39%", left: "21px", height: "10%", zIndex: 1, touchAction: "none" }}></img>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    nosotrosInfoData: state.nosotrosInfo,
});

const mapDispatchToProps = {
    nosotrosInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(Nosotros)