import React, { PureComponent } from 'react'
import Receta from './img/recetas404.svg'
import history from '../../history'
import Fondo from './img/fondo404.jpg'
import mermelada from './img/mermelada.png'
import './index.css'

export default class FoundPage extends PureComponent {
    render() {
        return (
            <>
                <div className="container-found-page"  style={{display:"flex",flexDirection:"column",marginTop:"50px"}} >
                    <div className="found-page">
                        <div className="div-image" >
                            <img style={{ height: "auto", maxWidth: "50%", cursor: "pointer" }} onClick={() => {window.scrollTo(0,0); history.push("/")}} src={mermelada} />

                        </div>
                        <div className="texto-container" >
                            <p className="Error-404" >Error 404</p>
                            <p className="No-encontramos-tu-s">¡No encontramos tu sabor!</p>
                            <p className="texto-info">No te preocupes, solo da clic en botón y <br/>
                            podrás seguir disfrutando de nuestros <br/>
                            productos McCormick.</p>

                        </div>
                       
                    </div>
                    <button onClick={()=>history.push("/")} className="btn-carousel mt-4">REGRESAR AL HOME</button>
                </div>

               
            </>
        )
    }
}