import React, { PureComponent } from 'react'
import Terminos from '../TerminosModal/index'
import AvisoDePrivacidada from '../Avisoprivacidadmodal/index'
import facebook from '../../Components/Resource/ICONOSREDES/REDESSOCIALES/facebook.svg'
import instagram from '../../Components/Resource/ICONOSREDES/REDESSOCIALES/instagram.svg';
import twitter from '../../Components/Resource/ICONOSREDES/REDESSOCIALES/twitter.svg';
import youtube from '../../Components/Resource/iconos/youtube.svg';
import subir from '../Resource/subir.png'
import mermelada from '../Home/images/mermeladasmobile.png'
import herdez from "./img/Grupo 4591.svg"
import './index.css'
import history from '../../history'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
export default class Footer extends PureComponent {
    constructor() {
        super()
        this.state = {
            modal: document.location.pathname.toLowerCase().indexOf("terminos-y-condiciones") !== -1 ? true : false,
            avisomodal: document.location.pathname.toLowerCase().indexOf("aviso-de-privacidad") !== -1 ? true : false
        }
        this.handleAvisoModal = this.handleAvisoModal.bind(this)
        this.handleModal = this.handleModal.bind(this)
    }
    handleModal() {
        this.setState({
            modal: !this.state.modal
        })
    }

    handleAvisoModal() {
        this.setState({
            avisomodal: !this.state.avisomodal
        })
    }
    render() {
        return (
            <>
                <div className="footer" style={{ transition: "all 0.3s linear 0s" }}>

                    <div className="footer-mobile">

                        <div className="mermeladaM">
                            <img src={mermelada} className="animation-up-downd" style={{ position: "absolute", bottom: "-136%", maxWidth: "100%", height: "auto", maxHeight: "392px" }}></img>
                        </div>

                        <div className="container">

                            <div className="row CocogooseProRegular" style={{ fontSize: "13px", lineHeight: "3.5" }}>
                                <div className="col-8" style={{ paddingTop: "4%", textAlign: "initial" }} >

                                    <label alt="conexión lenta" style={{ cursor: "pointer" }} className="footerlinks">MCCORMICK GLOBAL</label><br />

                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://www.mccormickcorporation.com/es-sv/responsibility', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">RESPONSABILIDAD</label><br />

                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://grupoherdez.com.mx/unete-a-la-familia/', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">EMPLEOS</label><br />

                                    <label onClick={() => { history.push("/historia"); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }} className="footerlinks">HISTORIA</label><br />

                                    <label onClick={() => { history.push("/contacto"); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }} className="footerlinks">CONTACTO</label><br />

                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://www.sabernutrir.com.mx/', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">SABER NUTRIR</label><br />

                                </div>


                                <div className="col-4">
                                    <div >
                                        <img style={{ cursor: "pointer", maxWidth: "70%", height: "auto", }} onClick={() => window.scrollTo(0, 0)} src={subir} />
                                    </div>

                                </div>
                            </div>

                            <Row>
                                <div style={{ width: "50%" }}>

                                    <img alt="conexión lenta" onClick={(e) => { window.open('https://www.facebook.com/McCormickMexico', ' "_blank"') }} className="icon-footer" src={facebook} />
                                    <img alt="conexión lenta" onClick={(e) => { window.open('https://www.instagram.com/mccormick_mx/?hl=es-la', ' "_blank"') }} className="icon-footer" src={instagram} />
                                    <img alt="conexión lenta" onClick={(e) => { window.open('https://www.youtube.com/user/McCORMICKMX', ' "_blank"') }} className="icon-footer" src={youtube} />

                                </div>
                            </Row>


                            <Row>
                                <Col style={{ textAlign: "left", marginTop: "2%" }}>
                                    <label><u style={{ cursor: "pointer" }} onMouseDown={() => { this.handleAvisoModal() }}>Aviso de privacidad</u></label>
                                    <label>Todos los derechos  reservados @McCormick</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "left" }}>
                                    <label style={{ width: "auto" }}><u style={{ cursor: "pointer" }} onMouseDown={() => { this.handleModal() }}>Términos y Condiciones</u></label>
                                </Col>
                            </Row>
                            <div style={{ width: "100%", display: "flex", justifyContent: "enter", alignItems: "center" }}>
                                <div onClick={()=> window.open('https://grupoherdez.com.mx/','_blank')} style={{ display: "flex", width: "80%",cursor:"pointer" }}>
                                    <img src={herdez} style={{ maxWidth: "20%", height: "auto" }} />
                                </div>
                            </div>
                           

                        </div>



                    </div>

                    <div className="footer-desk">
                        <div style={{ width: "100%", display: "flex" }}>

                            <div style={{ display: "flex", width: "40%", marginLeft: "2%",marginTop:"1%" }}>
                                <div style={{ marginRight: "4%", textAlign: "initial", width: "40%" }}>
                                    <label alt="conexión lenta" onClick={(e) => {
                                        //  window.open('https://www.mccormickcorporation.com/es-sv', ' "_blank"')
                                    }} style={{ cursor: "pointer" }} className="footerlinks">MCCORMICK GLOBAL</label><br />
                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://www.mccormickcorporation.com/es-sv/responsibility', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">RESPONSABILIDAD</label><br />
                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://grupoherdez.com.mx/unete-a-la-familia/', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">EMPLEOS</label><br />
                                    <label style={{}}><u style={{ cursor: "pointer" }} onMouseDown={() => { this.handleAvisoModal() }}>Aviso de privacidad</u></label><br></br>
                                    <label style={{}}><u style={{ cursor: "pointer" }} onMouseDown={() => { this.handleModal() }}>Términos y Condiciones</u></label>

                                </div>

                                <div style={{ textAlign: "initial", width: "70%" }}>
                                    <label onClick={() => { history.push("/historia"); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }} className="footerlinks">HISTORIA</label><br />
                                    <label onClick={() => { history.push("/contacto"); window.scrollTo(0, 0) }} style={{ cursor: "pointer" }} className="footerlinks">CONTACTO</label><br />
                                    <label alt="conexión lenta" onClick={(e) => { window.open('https://www.sabernutrir.com.mx/', ' "_blank"') }} style={{ cursor: "pointer" }} className="footerlinks">McCormick apoya al programa Saber Nutrir</label><br />
                                    <label >Todos los derechos  reservados @McCormick</label>
                                </div>
                            </div>

                            <div style={{ width: "25%" }}>

                                <img alt="conexión lenta" onClick={(e) => { window.open('https://www.facebook.com/McCormickMexico', ' "_blank"') }} className="icon-footer" src={facebook} />
                                <img alt="conexión lenta" onClick={(e) => { window.open('https://www.instagram.com/mccormick_mx/?hl=es-la', ' "_blank"') }} className="icon-footer" src={instagram} />
                                <img alt="conexión lenta" onClick={(e) => { window.open('https://www.youtube.com/user/McCORMICKMX', ' "_blank"') }} className="icon-footer" src={youtube} />

                            </div>
                            <div style={{ width: "15%", display: "flex", justifyContent: "enter", alignItems: "center" }}>
                                <div  onClick={()=> window.open('https://grupoherdez.com.mx/','_blank')}  style={{ display: "flex", flexDirection: "column", width: "85%",cursor:"pointer",alignItems:"center",justifyContent:"center" }}>
                                    <img src={herdez} style={{ maxWidth: "45%", height: "auto" }} />
                                </div>
                            </div>

                            <Col style={{ right: "1%" }}>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <img style={{ cursor: "pointer", maxWidth: "70%", height: "auto", }} onClick={() => window.scrollTo(0, 0)} src={subir} />
                                </div>

                            </Col>




                        </div>
                    </div>


                </div>

                <Terminos
                    modal={this.state.modal}
                    handleModal={() => this.handleModal()}
                />
                <AvisoDePrivacidada
                    modal={this.state.avisomodal}
                    handleModal={() => this.handleAvisoModal()}
                />
            </>
        )
    }
}