const OBTENER_CATEGORIAS_PRODUCTOS = "OBTENER_CATEGORIAS_PRODUCTOS";



let initialState = {categoriaProductos:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_CATEGORIAS_PRODUCTOS:
            return { ...state, categoriaProductos: action.categoriaProductos}

        default:
            return state;
    }

}

export default reducer;