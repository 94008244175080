import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import timer from '../Resource/iconos/timer.svg'
import banner from './img/banner.png'
import productos from './img/productos.png'
import Select from 'react-select'
import history from '../../history'
import Fade from 'react-reveal/Fade'
import './index.css'
import adornos from './img/group-2@2x.png'
import prodmayo from './img/group_3.png'
import TeSorprendemos from '../TeSorprendemos/index'
import TePuedeInteresar from '../TePuedeInteresar/index'
import TePuedeInteresarMobile from '../TePuedeInteresarMobile/index'
import axios from 'axios'
import { Helmet } from "react-helmet"



import Parallax from 'parallax-js'
import GetProductsByCategory from "../redux/actions/GetProductsByCategory"
import GetRecipesWhitMayonesa from '../redux/actions/GetRecipesWhitMayonesa'


import limon from './img/limon-2-copy.png'
import pepino from './img/pepinos.png'
import cebolla from './img/rose-fg-copy-4.png'
import cebollamasgrande from './img/rose-fg-copy-5.png'
import hojazoom from './img/grancuvee-bg.png'
import lechuga from './img/rrrr.png'


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import fondoproductos from './img/productosCategorias/fondo.png'

//especias 
import producto from './img/productosCategorias/pimienta-limon-1-copy.png'
import adorno from './img/productosCategorias/layer-2.png'
import adorno2 from './img/productosCategorias/pimienta.png'

//tés
import adornote from './img/productosCategorias/shutterstock-1015035208-1.png'
import manzanilla from './img/productosCategorias/manzanilla-51.png'
import adorno3 from './img/productosCategorias/grancuvee-bg.png'
//TésMov
import adornot from './img/productosCategorias/shutterstock11.png'
import manzanill from './img/productosCategorias/HojasManzanilla.png'


//mayonesa
import mayonesaproduct from './img/productosCategorias/bitmap-copy-2.png'
import limonder from './img/productosCategorias/layer-4.png'
import limonisq from './img/productosCategorias/layer-3.png'
import mayonesamitad from './img/productosCategorias/mc-cormick-mayonesa-limones-006-ok-1501.png'
import hojadown from './img/productosCategorias/grancuvee-bg-copy.png'


import gelalimon from './img/productosCategorias/gela-limon.png'
import mostaza from './img/productosCategorias/mostazabtl.png'
import fresa from './img/productosCategorias/merm-fre.png'
import fondo from './img/productosCategorias/fondo/limon-fresco.png'
import fondo1 from './img/productosCategorias/fondo/fondo-mostaza.png'
import fondo3 from './img/productosCategorias/fondo/fresa.png'

import Tilt from 'react-parallax-tilt';
import { POINT_CONVERSION_COMPRESSED } from 'constants';
const paramsRecetasplatilloMobile = {
  slidesPerView: 1,
  spaceBetween: 0,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  },

  loop: true,

}
class Productos extends PureComponent {
  constructor() {
    super()
    this.state = {
      categoriaDiseñogrupal: [],
      categoriaDiseñogrupalM: [],
      categoriesproduct: null,
      selectedOption: null,
      categoriasRelacionadas: [],
      banner:[],
      productos:[]
    }

  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    window.scrollTo(0, 0)
    window.location.href = (`/productos/${selectedOption.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-")}`)
    this.GetCategoriesProduct()



  }
  getRecetas = () => {
    const recetas = this.props.data.recipesWhitMayonesas.map((element, index) => {

      return (
        <div style={{ width: "33.3%", border: "0px" }} className="card col-4">
          <div style={{ overflow: "hidden" }}>
            <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={element.imagen} />
          </div>
          <div style={{ padding: "20px" }}>
            <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
            <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
            <br /> <br />
            <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
              <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
            </div>
          </div>
        </div>
      )

    })
    this.setState({
      recetas
    })

  }


  GetCategoriesProduct() {
    this.props.data.categoriesproduct.categoriaProductos.map((element, index) => {
      
      switch (element.id) {
        case 1:
          this.state.categoriaDiseñogrupal.push(element)

          break;
        // case "Gelatinas":
        // this.state.categoriaDiseñogrupal.unshift(element)
        //   break;

        case 2:
          this.state.categoriaDiseñogrupal.push(element)
          break;
        default:
        // code block
      }


    })





    const prod = this.state.productos.map((element, index) => {
      switch (element.id) {
        case 1:
          return (<>
            <div style={{ height: "467px", width: "100%", position: "relative", marginTop: "10%", marginBottom: "10%" }}>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} className="animation-up-downd-prod-tes" style={{ zIndex: "3", maxWidth: "23%", position: "absolute", left: "70%", top: "50%", transform: "translate(-50%, -50%)" }} ></img>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "2", maxWidth: "18%", position: "absolute", left: "91%", top: "45%", transform: "translate(-50%, -50%)" }} ></img>
              <div className="info-categorias-productos col-5" >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
                <label style={{ fontSize: "28px" }} className="CocogooseProRegular">{element.subtitulo}</label><br />
                <button onClick={() => { window.scrollTo(0, 0); history.replace(element.boton_url) }} className=" btn-carousel menos">{element.boton_texto}</button>
              </div>
            </div>
          
          </>
          )
          break;
          case 2:
            return(
            <div style={{ width: "100%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
             <div className="col-4 " style={{ position: "relative" }} >
                    <Tilt
                      className="parallax-effect-img"
                      tiltMaxAngleX={10}
                      tiltMaxAngleY={10}
                      perspective={500}
                      transitionSpeed={1500}
                      scale={1}
                      gyroscope={true}
                    >
        
                      <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ maxWidth: "100%", height: "auto" }} />
                    </Tilt>
                    <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} style={{ maxWidth: "40%", height: "auto", position: "absolute", top: "25%", zIndex: "1", left: "30%" }} />
                    <div style={{ marginTop: "18%", textAlign: "center" }}>
                      <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.titulo}</b><br />
                      <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.subtitulo}</label><br />
                      <button onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} className=" btn-carousel menos">{element.boton_texto}</button>
                    </div>
                  </div>
            </div> 
            )
            break;
        case 3:
          return (
            <div style={{ height: "467px", width: "100%", position: "relative", marginTop: "10%" }}>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} className="animation-up-downd-prod-especia" style={{ zIndex: "1", maxWidth: "20%", position: "absolute", left: "70%", top: "50%", transform: "translate(-50%, -50%)" }} ></img>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "0", maxWidth: "50%", position: "absolute", left: "70%", top: "50%", transform: "translate(-50%, -50%)" }} ></img>
              {/* <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "0", maxWidth: "50%", position: "absolute", left: "14%", top: "80%", transform: "translate(-50%, -50%)" }} ></img> */}
              <div className="info-categorias-productos col-5" >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
                <label style={{ fontSize: "28px" }} className="CocogooseProRegular">{element.subtitulo}</label><br />
                <button className=" btn-carousel menos" onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} >{element.boton_texto}</button>
              </div>
            </div>
          )
          break;
        case 4:
          return (
            <div style={{ height: "467px", width: "100%", position: "relative", marginTop: "10%" }}>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} className="animation-up-downd-prod-tes" style={{ zIndex: "1", maxWidth: "20%", position: "absolute", left: "70%", top: "50%", transform: "translate(-50%, -50%)" }} ></img>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "2", maxWidth: "50%", position: "absolute", right: "36%", top: "80%", transform: "translate(-50%, -50%)" }} ></img>
              <div className="info-categorias-productos col-5" >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
                <label style={{ fontSize: "28px" }} className="CocogooseProRegular">{element.subtitulo}</label><br />
                <button onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} className=" btn-carousel menos">{element.boton_texto}</button>
              </div>
            </div>
          )
          break;
        default:
        // code block
      }
    })
    this.setState({
      categoriesproduct: prod
    })
  }
  GetCategoriesProductM() {
   

    const prod = this.state.productos.map((element, index) => {
      switch (element.id) {
        case 1:
          return (
            <div style={{ height: "550px", width: "100%", position: "relative", marginTop: "10%" }}>
            <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} style={{ zIndex: "1", maxWidth: "52%", position: "absolute", left: "50%", top: "71%", transform: "translate(-50%, -50%)", zIndex: "2" }} ></img>
            <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "0", maxWidth: "49%", position: "absolute", left: "26%", top: "61%", transform: "translate(-50%, -50%)" }} ></img>
            <div style={{ position: "relative" }} className="info-categorias-productos col-12" >
              <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
              <label style={{ fontSize: "18px" }} className="CocogooseProRegular">{element.subtitulo}</label><br />
              <button className=" btn-carousel menos" onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} >{element.boton_texto}</button>
            </div>
          </div>
          )
          break;
          case 2:
            return(
              <div className="col-12 " style={{ position: "relative" }} >
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ maxWidth: "100%", height: "auto" }} />
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} style={{ maxWidth: "40%", height: "auto", position: "absolute", bottom: "35%", zIndex: "1", left: "30%" }} />
              <div style={{ marginTop: "20%", textAlign: "center" }}>
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.titulo}</b><br />
                <label style={{ fontSize: "16px", fontFamily: "AvenirBook" }} >{element.subtitulo}</label><br />
                <button onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} className=" btn-carousel menos">{element.boton_texto}</button>
              </div>
            </div>
            )
          break;
        case 3:
          return (
            <div style={{ height: "550px", width: "100%", position: "relative", marginTop: "10%" }}>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} style={{ zIndex: "1", maxWidth: "35%", position: "absolute", left: "50%", top: "71%", transform: "translate(-50%, -50%)", zIndex: "2" }} ></img>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "0", maxWidth: "89%", position: "absolute", left: "38%", top: "61%", transform: "translate(-50%, -50%)" }} ></img>
              <div style={{ position: "relative" }} className="info-categorias-productos col-12" >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
                <label style={{ fontSize: "18px" }} className="CocogooseProRegular">{element.subtitulo}</label><br />
                <button className=" btn-carousel menos" onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} >{element.boton_texto}</button>
              </div>
            </div>
          )
          break;
          break;
        case 4:
          return (
            <div style={{ height: "550px", width: "100%", position: "relative" }}>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.imagen} style={{ zIndex: "1", maxWidth: "44%", position: "absolute", left: "52%", top: "69%", transform: "translate(-50%, -50%)" }} ></img>
              <img src={process.env.REACT_APP_CMS_ASSETS+element.fondo} style={{ zIndex: "2", maxWidth: "45%", position: "absolute", right: "1%", top: "80%", transform: "translate(-50%, -50%)" }} ></img>
              <div className="info-categorias-productos col-12" >
                <b style={{ fontSize: "16px", color: "rgb(212, 18, 70)" }} className="CocogooseProRegular">{element.titulo}</b><br />
                <label style={{ fontSize: "18px" }} className="CocogooseProRegular">{element.subtitulo}</label>
                <button onClick={() => { window.scrollTo(0, 0); history.push(element.boton_url) }} className=" btn-carousel menos">{element.boton_texto}</button>
              </div>

            </div>
          )
          break;

        default:
        // code block
      }

    })




    this.setState({
      categoriesproductM: prod
    })

  }

  myAwesomeCallback = () => {
    document.getElementById('banner-productos-limon').style.top = "10%"

    document.getElementById('banner-productos-limon').style.top = "10%"
    document.getElementById('banner-productos-limon').style.left = "4%"
   

    document.getElementById('banner-productos-principal').style.top = "30%"
    document.getElementById('banner-productos-principal').style.left = "25%"




  }
  handleRutas = () => {
    const home = document.getElementById("home")
    const productos = document.getElementById("productos")
    const promociones = document.getElementById("promociones")
    switch (window.location.pathname) {
      case "/":


        home.style.borderBottom = "5px solid red"
        home.style.transition = ".3s"


        break;
      case "/productos":

        productos.style.borderBottom = "5px solid red"
        productos.style.transition = ".3s"

        home.style.borderBottom = "5px solid  transparent"
        promociones.style.borderBottom = "5px solid transparent"
        break;
      case "/recetas":
        const recetas = document.getElementById("recetas")
        recetas.style.borderBottom = "5px solid red"
        recetas.style.transition = ".3s"




        home.style.borderBottom = "5px solid  transparent"



        productos.style.borderBottom = "5px solid transparent"



        promociones.style.borderBottom = "5px solid transparent"

        break;
      case "/promociones":

        promociones.style.borderBottom = "5px solid red"
        promociones.style.transition = ".3s"
        break;
      case "/contacto":
        const contacto = document.getElementById("contacto")
        contacto.style.borderBottom = "5px solid red"
        contacto.style.transition = ".3s"
        break;
      case "/nosotros":
        const nosotros = document.getElementById("nosotros")
        nosotros.style.borderBottom = "5px solid red"
        nosotros.style.transition = ".3s"
        break;

      default:
        //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
  }
  componentDidMount() {
    var parallax;
    var scenesSelector = document.getElementById('scene');
    parallax = new Parallax(scenesSelector, {
      onReady: this.myAwesomeCallback
    });
    this.obtenerCategoriaProductos();
  }
  obtenerCategoriaProductos = async () => {
    const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias?fields=*.*,productos.subcategoria.*&filter[estatus]=published");
    const banner = await axios.get(process.env.REACT_APP_API_URL_NEW + "/banner_productos");
    const productos = await axios.get(process.env.REACT_APP_API_URL_NEW + "/nuestros_productos");
   console.log(productos.data.data)
    this.setState({
      banner:banner.data.data,
      productos:productos.data.data
    })
    this.props.dispatch({ type: "OBTENER_CATEGORIAS_PRODUCTOS", categoriaProductos: result.data.data });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data.categoriesproduct != this.props.data.categoriesproduct) {
      this.handleRutas()
      this.GetCategoriesProduct()
      this.GetCategoriesProductM()
      this.getRecetas()
    }
  }
  filterProduct = (categori) => {
    window.scrollTo(0, 0);
    window.location.href = (`/productos/${categori.value.toLowerCase()}`)

  }
  nextswiperHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos")[0].children;
    var mySwiper = swiperContainer[1].swiper
    mySwiper.slideNext();
  }
  nextprevHome = () => {
    const swiperContainer = document.getElementsByClassName("swiper-productos")[0].children;
    var mySwiper = swiperContainer[1].swiper
    mySwiper.slidePrev();
  }
  render() {
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: "transparent",
          color: "gray",
          ':hover': {
            ...styles[':hover'],
            backgroundColor: "transparent",
            color: "red"
          },
        };
      },
      container: () => ({
        width: "30%",
        border: "4px solid #ffd766",
        position: "relative",
        zIndex: 100

      }),
      control: () => ({
        outline: "none",
        display: "flex"
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "100%",
        borderBottom: '1px dotted pink',
        border: " 3px solid #ffd766",
        color: state.selectProps.menuColor,
        padding: 20,

      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }
    const customStylesM = {
      placeholder: (defaultStyles) => { return { ...defaultStyles, color: '#000000', marginLeft: '38%', letterSpacing: '5px' } },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: "white",
          color: "gray",
          ':hover': {
            ...styles[':hover'],
            backgroundColor: "white",
            color: "red",
          },
        };
      },
      container: () => ({
        width: "95%",
        border: "4px solid #ffd766",
        position: "relative",
        zIndex: 100,
        textAlign: "center"
      }),
      control: () => ({
        outline: "none",
        display: "flex"
      }),
      menu: (provided, state) => ({
        ...provided,
        // width: "50%",
        borderBottom: '1px dotted pink',
        border: " 3px solid #ffd766",
        color: state.selectProps.menuColor,
        padding: 20,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }
    const options = this.props.data.categoriesproduct.categoriaProductos.map((element, index) => {
      return { value: element.url, label: element.nombre }
    })
    return (
      <>
        <seccion className="productos-desk">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Productos de comida procesada | McCORMICK México</title>
            <meta name="description" content="Productos de comida procesada McCORMICK: Obténlos con la calidad y mezcla de alimentos saludables en especias, mayonesas y más." />
          </Helmet>
          <div style={{ marginLeft: "30px", marginRight: "30px", marginTop: "6px", width: "inherit", display: "flex", position: "relative" }} className="swiper-productos">
            {this.state.banner &&
            <div id="scene">
              <img src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.fondo} style={{ width: "100%", height: "auto" }}></img>
            <img id="banner-productos-principal" data-depth-x="0.10" data-depth-y="-0.10" src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.productos} style={{ maxWidth: "50%", position: "absolute", top: "50%", left: "50%", zIndex: "1", transform: "translate(-50%,-50%)" }} />
            <div id="banner-productos-limon" data-depth-x="0.35" data-depth-y="-0.35" style={{ position: "absolute", top: "10%", left: "4%", zIndex: "0" }} >
              <img src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.ingredientes} style={{ maxWidth: "100%", animation: "10s cubic-bezier(.455,.03,.515,.955) infinite alternate swing" }} />
            </div>
          </div>
        
            }
            
            <div className="filtro-productos" >
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <p className="QUIERO-VER" >QUIERO VER</p>
                <Select
                  placeholder="TODAS"
                  styles={customStyles}
                  value={this.state.selectedOption}
                  label={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={options}
                />
              </div>
            </div>
          </div>
          <label className="NUESTROS-PRODUCTOS" >NUESTROS PRODUCTOS</label><br />
          <label className="undefined">Descubre nuestra variedad de productos que consienten<br></br> a tu paladar desde hace más de 130 años. </label>
        {this.state.categoriesproduct}
          
        </seccion>
        <seccion className="productos-mobile">
          <Container fluid={true}>
            <Row>
              <Col className="p-0">
              {this.state.banner &&
              <>
                <img style={{ width: "100%", height: "137%" }} src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.fondo}></img>
                <img src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.productos} style={{ maxWidth: "74%", position: "absolute", top: "50%", left: "50%", zIndex: "1", transform: "translate(-50%,-50%)" }} />
                <img src={process.env.REACT_APP_CMS_ASSETS+this.state.banner.ingredientes} style={{ maxWidth: "100%", position: "absolute", top: "50%", left: "50%", zIndex: "0", transform: "translate(-50%,-50%)" }} />
              </>
              }
                <div className="filtro-productos" >
                  <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Select
                      styles={customStylesM}
                      value={this.state.selectedOption}
                      onChange={this.handleChange}
                      options={options}
                      placeholder="TODOS"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="NUESTROS-PRODUCTOS" >NUESTROS PRODUCTOS</label>
                <label className="undefined" style={{ marginTop: "22%" }}>Descubre nuestra variedad de productos que consienten<br></br> a tu paladar desde hace más de 130 años. </label>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.categoriesproductM}
              </Col>
            </Row>
            <TePuedeInteresarMobile></TePuedeInteresarMobile>
          </Container>
        </seccion>
      </>
    )
  }
}

/*
const mapDispatchToProps = {
  GetProductsByCategory,
  GetRecipesWhitMayonesa
};*/

const mapStateToProps = state => ({

  data: { ...state, getallcategoriesproduct: state.categoriesproduct.categoriaProductos }
});
export default connect(mapStateToProps)(Productos)