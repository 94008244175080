const BUSCADOR = "BUSCADOR";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case BUSCADOR:
            return { ...state, resultados: action.resultados }

        default:
            return state;
    }

}

export default reducer;