import { type as MenuController } from '../actions/MenuController';
import React from 'react'

const defaultState = [
   false
];

function reducer(state = defaultState, { type,open }) {
    switch (type) {
        case MenuController: {
        
      
        return open
        }
      
        default:
       return false
        
    }
}

export default reducer;
