import React from 'react';
import fondo1 from "./img/IMG_2648.jpg";
import fondo2 from "./img/IMG_2649.jpg";
import fondo3 from "./img/IMG_2650.jpg";
import fondo4 from "./img/IMG_2651.jpg";
import imgbnrPostres from "../Blog/img/Nota Regreso a clases con Conservas con Semillas.jpg";
import recetaBarras from "./img/Barritas energéticas de piña con linaza.jpg";

import recetasimg18 from "./img/Pasta de coditos con mayonesa.png";
import recetasimg19 from "./img/Pavo glaseado.png";
import recetasimg20 from "./img/Ponche navideño.png";

import recetasimg21 from "./img/leche dorada.jpg";
import recetasimg22 from "./img/arroz amarillo.jpg";
import recetasimg23 from "./img/guisado con curry.jpg";

import recetasimg1 from "./img/Tostadas con mayonesa.jpg";
import recetasimg2 from "./img/Sándwich de pollo.jpg";
import recetasimg3 from "./img/Dip chile Serrano.jpg";
import recetasimg4 from "./img/Dip Chipotle.jpg";

import recetasimg5 from "./img/Pastel de chocolate.jpg";
import recetasimg6 from "./img/Pastel de naranja.jpg";
import recetasimg7 from "./img/Cupcakes.jpg";
import recetasimg8 from "./img/Galletas.jpg";

import recetasimg9 from "./img/Ensalada.jpg";
import recetasimg10 from "./img/Pollo a la mostaza.jpg";
import recetasimg12 from "./img/Vegetales.jpg";
import recetasimg13 from "./img/Rajas con crema.jpg";

import recetasimg14 from "./img/Cocción al Vacio.jpg";
import recetasimg15 from "./img/Esferificaciones.jpg";
import recetasimg16 from "./img/Mousse de jamón.jpg";
import recetasimg17 from "./img/Aire de leche.jpg";

import recetasimg24 from "./img/bisteces.jpg";
import recetasimg25 from "./img/hamburguesa.jpg";
import recetasimg26 from "./img/camarones.jpg";

import recetasimg27 from "./img/AjonjoliBalance.png";
import recetasimg28 from "./img/Balance Aguacate.png";
import recetasimg29 from "./img/Balance Soya.png";

import recetasimg30 from "./img/Jengibre.png";
import recetasimg31 from "./img/TeVerde.png";
import recetasimg32 from "./img/TeVerdeMango.png";

import recetasimg33 from "./img/MERMELADA DE FRESA  270 sin octagono.png";
import recetasimg34 from "./img/MERMELADA DE PIÑA  270 sin octagono (1).png";
import recetasimg35 from "./img/Sundae.png";

import recetasimg36 from "./img/Quesabirria.png";
import recetasimg37 from "./img/Tostadas.png";
import recetasimg38 from "./img/Horchata.png";
import recetasimg39 from "./img/Cuadritos de Avena.png";

import recetasimg40 from "./img/Balance Aguacate.png";
import recetasimg41 from "./img/Balance Soya.png";
import recetasimg42 from "./img/AjonjoliBalance.png";

import recetasimg43 from "./img/balance antigripal 2021.png";
import recetasimg44 from "./img/balance energía 2021.png";
import recetasimg45 from "./img/balance buenas noches 2021.png";

import recetasimg46 from "./img/Raspadito del plátano verde.png";
import recetasimg47 from "./img/Shots Cadavéricos.png";
import recetasimg48 from "./img/Doni Monstruos.png";

import recetasimg49 from "./img/Bolitas de pollo búfalo.png";
import recetasimg50 from "./img/Dip para tus botanas.png";
import recetasimg51 from "./img/Tiras de pollo a la parrilla.png";

import recetasimg52 from "./img/Puerquitos de piloncillo.png";
import recetasimg53 from "./img/Ponche antigripal con té y fruta.png";
import recetasimg54 from "./img/Pollo navideño a la sidra.png";

import recetasimg55 from "./img/Esquites con mayonesa.webp";

import imgbnr6 from "./img/Nota Navidad.jpg";
import imgbnr5 from "./img/Nota Cocina Molecular.jpg";
import imgbnr4 from "./img/Nota Mostaza.jpg";
import imgbnr3 from "./img/Nota Aderezo.jpg";
import imgbnr2 from "./img/Nota Mayonesa.png";
import imgbnr1 from "./img/Nota Gelatinas.png";
import imgbnr7 from "./img/Nota Cúrcuma.jpg";
import imgbnr8 from "./img/Nota sabor del norte.jpg";
import imgbnr9 from "./img/Nota vive la mejor version.jpg";
import imgbnr10 from "./img/Nota detox.jpg";
import imgbnr11 from "./img/Nota haz dulce el regreso a clases.png";
import imgbnr12 from "./img/Nota especias fiestas mexicanas.png";
import imgbnr13 from "./img/Nota La playa también sabea balance.png";
import imgbnr14 from "./img/Nota disfruta la temporada invernal.png";
import imgbnr15 from "./img/Nota empieza la temporada de NFL.png";
import imgbnr16 from "./img/Nota cómo organizar una cena navideña.png";
import imgbnr17 from "./img/Nota Mayodipea tu antojo WEBP.webp";

import imgbnr18 from "./img/carlota.webp";
import imgbnr19 from "./img/Cremoso de mango.webp";
import imgbnr20 from "./img/Peras en conserva.webp";
import imgbnr21 from "./img/Nota Mermeladas.webp";
import mostritos from "./img/Monstruitos.webp";

//Blogs 2022
import imgbnr22 from "./img/Nota Comienza a sentirte mejor.webp";
import imgbnr23 from "./img/Pechugas de Pollo con Aderezo de Mayonesa.webp";
import imgbnr24 from "./img/FrittataconAderezo.webp";
import imgbnr25 from "./img/Ensalada Keto de Camarones con Aderezo.webp";
import imgbnr26 from "./img/Mini pastelitos.webp";
import imgbnr27 from "./img/bisquets caseros.webp";
import imgbnr28 from "./img/Monstruos de mousse.webp";
import imgbnr29 from "./img/Sopes de camaron.webp";
import imgbnr30 from "./img/Tacos de Pescado y Aderezo Verde.webp";
import imgbnr31 from "./img/Limonada de Fresa con Té de Limón.webp";
import imgbnr32 from "./img/Tazón de Desayuno con Té Matcha.webp";
import imgbnr33 from "./img/Sangría de Té de Jamaica y Jengibre.webp";
import Baritasfrank from "./img/Barritas de Frankenstein.webp";
import imgbnr34 from "./img/Nota NFL alitas caseras.webp";
import imgbnr35 from "./img/Alitas caseras.webp";
import imgbnr36 from "./img/Matcha con coco y piña.webp";
import imgbnr37 from "./img/Gin de fresa con chía.webp";
import imgbnr38 from "./img/Té helado con ron y amaretto.webp";
import imgbnr39 from "./img/Vodka con fresa y chía.webp";
import imgbnr40 from "./img/Nota Fiestas Navideñas.webp";
import imgbnr41 from "./img/Cerdo asado con mostaza.webp";
import imgbnr42 from "./img/Pavo BBQ.webp";
import imgbnr43 from "./img/Buñuelos rellenos.webp";
import imgbnr44 from "./img/Nota Rutina Detox.webp";

import imgbnr48 from "./img/Frappé de Té Verde con Coco.webp";
import imgbnr45 from "./img/Smoothie de Té Verde con Arándanos.webp";
import imgbnr46 from "./img/Jengibre y Frambuesa Espumoso.webp";
import imgbnr47 from "./img/Té Helado de Mango con Fresas.webp";


import recetasimg58 from "./img/Soprende a tus pequeños.webp";
import recetasimg59 from "./img/Nota Aderezos con mayonesa.webp";
import recetasimg60 from "./img/Nota Regalos con té .webp";
import recetasimg61 from "./img/Nota Comienza a sentirte mejor.webp";
import recetaBrocheta from "./img/Brocheta.jpg";

import recetaPechuga from "./img/PechugasdePolloconAderezodeMayonesa.webp";
import recetasFritata from "./img/FrittataconAderezodeMayonesa.webp";
import recetasSentirseMejor from "./img/Nota Comienza a sentirte mejor copy.webp";
import notaMatch from './img/NotaMatcha.webp'
const Dummy = {
    parrafo: false,
    parrafoMovil: [],
    notas: [
        {
            nombre: "desintoxicar-recetas-plan-detox",
            titulo: "Cómo desintoxicar tu cuerpo incluyendo estas deliciosas recetas en tu plan Detox",
            imagen: imgbnr44,
            descripcion: (
                <div>
                    Segundo mes del año. A estas alturas, ya pusimos en marcha nuestros propósitos de año nuevo y estamos esforzándonos por cumplirlos.
                    Y ahora, llevarlos a cabo es más fácil con la compañía de la familia <a target='_blank' href=' https://www.mccormick.mx/productos/tes-sobres/plan-detox/te-de-jengibre-con-limon'>Tés Detox</a> <b>de McCormick.</b>
                    <br />
                    <br />
                    <h2> ¿Qué es un Detox? </h2>
                    <br />
                    Iniciar un <b>proceso de desintoxicación</b> es una tendencia que se ha vuelto muy popular en los últimos años, ya que puede ayudarte a mejorar distintos aspectos de tu vida diaria y aunque nuestra rutina puede ser algo acelerada, llena de estrés, cansancio, comidas fuera de su horario o mala alimentación, los <b>Tés Detox McCormick</b> te ayudan a contrarrestar los efectos de un estilo de vida acelerado, eliminando las impurezas, residuos o toxinas de tu cuerpo. Además, te ayudarán a relajarte, mejorando así tu estado físico y anímico.
                    <br />
                    <br />
                    <h2> Tés funcionales para tu iniciar un Detox </h2>
                    <br />
                    Algunos beneficios de nuestra línea son, por ejemplo, los antioxidantes que contiene el <a target='_blank' href='https://www.mccormick.mx/productos/tes-sobres/plan-detox/te-verde'>Té Verde McCormick</a>, sus propiedades antiinflamatorias, la regulación en los niveles de glucosa y la estimulación del metabolismo, por lo que, como podrás adivinar, es un fantástico digestivo para tomar después de comer.
                    <br />
                    <br />
                    Por otro lado, nuestro <a target='_blank' href='https://www.mccormick.mx/productos/tes-sobres/plan-detox/te-de-jengibre-con-limon'>Té de Jengibre Limón McCormick</a> tiene un efecto relajante, reduce el colesterol en la sangre, tiene poderosos efectos antiinflamatorios, mejora la digestión y también potencia el metabolismo.
                    <br />
                    <br />
                    Sobra decir que es una forma práctica y deliciosa de hacerte este saludable y necesario “reset”  que tu cuerpo pide, con el rico sabor al que <b>McCormick</b> nos tiene acostumbrados. Entre los sabores que conforman la línea Detox se encuentran; <b>Té de Jengibre Limón, Té Verde</b>, <a target='_blank' href='https://www.mccormick.mx/productos/tes-sobres/plan-detox/te-verde-con-mango'>Té Verde sabor  Mango</a>, <a target='_blank' href='https://www.mccormick.mx/productos/tes-sobres/plan-detox/te-verde-con-mandarina'>Té Verde sabor Mandarina</a> y <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-de-jengibre,-lim%C3%B3n-sabor-frambuesa'>Té de Jengibre Limón sabor Frambuesa</a>, todos y cada uno tan deliciosos como saludables.
                    <br />
                    <br />
                    Por eso, cuando hablamos de nuestra línea de <b>Tés Detox McCormick</b> te decimos <b>Déjalo Ir</b>; deja ir esos malos hábitos, eso que no te hace bien, que le quita energía a tu cuerpo.
                    <br />
                    <br />
                    Basta con 3 tazas al día para iniciar tu proceso <b>Detox</b>, y lo mejor de todo es que es un té muy versátil y puedes tomarlo frío o caliente ¡como más se te antoje!
                    <br />
                    <br />
                    <h2> Bebidas frías y saludables con té </h2>
                    <br />
                    Pensando en ello, hemos preparado para ti algunas recetas deliciosas hechas con los tés de la familia <b>Detox McCormick</b> para que te des un momento de relajación y dejes ir aquello que ya no sirve a la vez que disfrutas de un delicioso sabor que te recompense por tu esfuerzo.
                    <br />
                    <br />
                    ¡Que las disfrutes!

                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr48,
                    texto: (
                        <div>
                            <h3> Frappé de Té Verde con Coco  </h3>
                            <br />
                            <b>Rinde:</b> 1 porción<br/>
                            <b>Ingredientes:</b><br/>
                            2 sobres de <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-verde'>Té Verde McCormick® </a>  <br />
                            100ml de agua caliente <br/>
                            100ml de leche de coco <br/>
                            45 ml de crema de coco<br/> 
                            15ml de jarabe natural  <br/>
                            200g de hielo <br/>
                            Hojas de menta<br/>
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Infusionar en agua caliente el Té Verde McCormick® por 5 minutos.</li>
                                <li>Licuar el Té con todos los ingredientes hasta obtener un frappé.</li>
                                <li>Servir en una copa y decorar con hojas de menta.</li>
                            </ul>
                            <br />
                        </div>
                    ),
                },
                {
                    imagen: imgbnr45,
                    texto: (
                        <div>
                            <h3>Smoothie de Té Verde con Arándanos</h3>
                            <br />
                            <b>Rinde:</b> 2 porción<br/>
                            <b>Ingredientes:</b><br />
                            3/4 de taza leche evaporada <br />
                            1 sobre de <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-verde-ar%C3%A1ndano'>Té Verde Arándano McCormick® </a><br />
                            1 cucharadita azúcar <br />
                            3 cucharadas arándanos deshidratados <br />
                            5 piezas hielo
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Infusionar en agua caliente el Té Verde Arándano McCormick® por 5 minutos.  </li>
                                <li>Licúa la leche evaporada con el Té Verde Arándano McCormick®, el azúcar y los arándanos.</li>
                                <li>Sirve en un vaso con hielos</li>
                                <li>Decora con crema batida (opcional).</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr46,
                    texto: (
                        <div>
                            <h3>Jengibre y Frambuesa Espumoso </h3>
                            <br />
                            <b>Rinde:</b> 2 porción<br/>
                            <b>Ingredientes:</b><br />
                            <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-de-jengibre,-lim%C3%B3n-sabor-frambuesa'> Té Jengibre Limón sabor Frambuesa McCormick® </a><br />
                            300 ml de vino rosado espumoso <br />
                            1 oz / 30 ml de jarabe natural <br />
                            180 ml de ginger ale<br />
                            1 oz / 30 ml de Controy <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li><b>Infusionar</b> en agua caliente el Té Jengibre Limón sabor Frambuesa McCormick® por 5 minutos y dejar enfriar.</li>
                                <li>En una copa, verter todos los líquidos y mezclar.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr47,
                    texto: (
                        <div>
                            <h3>Té Helado de Mango con Fresas</h3>
                            <br />
                            <b>Rinde:</b> 7 porción<br/>
                            <b>Ingredientes:</b><br />
                            15 sobres de  <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-verde-mango'>Té Verde sabor Mango McCormick® </a><br />
                            15 fresas en rebanadas <br />
                            1 litro de agua caliente  <br />
                            100g de azúcar <br />
                            Hielo<br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Infusionar los sobres de Té Verde Sabor Mango Mccormick® por 5 minutos. </li>
                                <li>Agregar azúcar y mezclar hasta disolver.</li>
                                <li>Incorporar las 15 fresas rebanadas, el hielo y servir.</li>
                            </ul>
                        </div>
                    ),
                },

            ],
            foter: <div></div>,
        },
        {
            nombre: "menu-navideno-facil",
            titulo: "Cocina un menú navideño fácil y disfruta esta temporada",
            imagen: imgbnr40,
            descripcion: (
                <div>
                    Esta temporada queremos que pases más tiempo con los tuyos y no en el arduo día a día de la cocina, por eso
                    te traemos este <b>menú navideño</b> para preparar <b>recetas deliciosas fácil y rápido</b>.
                    <br />
                    <br />
                    Por fin estamos en diciembre, temporada de fríos, frazadas y chocolate caliente. De cantar villancicos y sentarte
                    a ver maratones navideños. Es el momento perfecto para hacer team back y disfrutar un té con las amigas o una
                    tarde con tu familia acurrucados en el sofá de la sala.
                    <br />
                    <br />
                    Sabemos cómo te gusta pasar esta temporada, porque somos expertos en ponerle sabor para que se vuelvan inolvidables.
                    <br />
                    <br />
                    <h2> Preparaciones para recibir a tus invitados en estas fiestas navideñas </h2>
                    <br />
                    Para platicar y ponerse al día con las amigas en estas épocas, nada mejor que un delicioso chocolate caliente
                    bien espumoso, espolvoreado con nuestra siempre confiable <a target='_blank' href='https://www.mccormick.mx/producto/canela-molida'>Canela Molida McCormick</a> o nuestro riquísimo
                    <a target='_blank' href='https://www.mccormick.mx/producto/matcha-puro'> Té verde Matcha McCormick</a>, acompañado con pan tostado untado con un poco de nuestra saludable
                    <a target='_blank' href='https://www.mccormick.mx/producto/conserva-de-fresa-con-ch%C3%ADa'> Conserva de Fresa con Chía</a>. Seguro pasarás una reunión inolvidable.
                    <br />
                    <br />
                    O si el plan es quedarte con la familia, quizás una buena opción para ti sea preparar una tarta rellena de las
                    deliciosas <a target='_blank' href='https://www.mccormick.mx/productos/mermeladas'>Mermeladas Sabores de México McCormick</a> que nunca falla en darle ese sabor dulcecito
                    <br />
                    <br />
                    ¿Hambre? No hay nada mejor para estar en compañía de la familia que una deliciosa sopa de pasta aderezada con
                    la clásica e inigualable <a target='_blank' href='https://www.mccormick.mx/producto/mayonesa-con-jugo-de-lim%C3%B3n'> Mayonesa con jugo de limones McCormick</a>. O si lo tuyo, lo tuyo, son las ensaladas,
                    nada las acompaña mejor que el <a target='_blank' href='https://www.mccormick.mx/producto/aderezo-de-mayonesa'>Aderezo de Mayonesa McCormick</a>.
                    <br />
                    <br />
                    <h2> Bebidas navideñas sin alcohol para acompañar esta temporada </h2>
                    <br />
                    Y para las bebidas hay muchas opciones; si buscas algo frío que acompañe los snacks, una buena opción son nuestras <b>Infusiones en Frío de
                        McCormick</b>, que puedes preparar con agua natural o mineral con el sabor que más te guste
                    (<a target='_blank' href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-frutos-rojos'>Frutos rojos</a>, <a target='_blank' href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-lim%C3%B3n-jengibre'>Limón - Jengibre</a> o <a target='_blank' href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-durazno-jengibre'>Durazno - Jengibre</a>).
                    <br />
                    <br />
                    Ahora que si la noche pinta fría, nuestra amplia gama de <a target='_blank' href='https://www.mccormick.mx/productos/tes-sobres'>Tés McCormick</a> nunca falla en calentarte un poco, desde los tés clásicos como
                    <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-de-manzanilla'> Manzanilla</a> y <a target='_blank' href='https://www.mccormick.mx/producto/t%C3%A9-de-hierbabuena'>Hierbabuena</a>, hasta tés de especialidad como lo son el <a target='_blank' href='https://www.mccormick.mx/producto/chai'>Té Chai</a> o <b>Matcha</b>.
                    <br />
                    <br />
                    Ya que si prefieres irte por un clásico, el café de olla preparado con nuestra <a target='_blank' href='https://www.mccormick.mx/producto/canela-entera'>Canela Entera de McCormick</a>, es sencillamente irresistible para
                    cualquier paladar.
                    <br />
                    <br />
                    <h2> Sigue estas recetas para cocinar la cena navideña </h2>
                    <br />
                    Como ves, hay muchas opciones para disfrutar de esta temporada. Pero a continuación te daremos un menú navideño fácil y delicioso para que disfrutes la temporada sin tener que vivir en la cocina.
                    <br />
                    <br />
                    Pon mucha atención.

                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr41,
                    texto: (
                        <div>
                            <h3> Cerdo Asado con Mostaza McCormick® </h3>
                            <br />
                            <b>Ingredientes:</b><br />
                            2 filetes de cerdo enteros  <br />
                            3 cucharadas de <a target='_blank' href='https://www.mccormick.mx/producto/aderezo-de-mostaza-con-miel'>Aderezo de Mostaza con miel McCormick®</a>  <br />
                            2 cucharadas de aceite de oliva  <br />
                            1 cucharada de romero picadito  <br />
                            1 cebolla grande  <br />
                            1 cucharadita de salsa picante  <br />
                            1 cucharada de <a target='_blank' href='https://www.mielcarlota.com.mx/'>Miel Carlota</a>  <br />
                            Sal al gusto  <br />
                            <a target='_blank' href='https://www.mccormick.mx/producto/pimienta-negra-molida'>Pimienta molida McCormick®</a> al gusto  <br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Cortamos la cebolla en rodajas y las ponemos en un refractario de vidrio largo.</li>
                                <li>En un bowl, hacemos una mezcla donde incluiremos Aderezo de Mostaza con Miel McCormick®, el aceite de oliva, la salsa picante, el romero, una cucharada de Miel Carlota®, sal y Pimienta molida McCormick®.</li>
                                <li>Untamos bien la carne con nuestra mezcla y llevamos al horno precalentado a unos 200°C por aproximadamente 1 hora.</li>
                                <li>Vamos volteando la carne cada 20 minutos para que se impregne bien por todos lados.</li>
                                <li>Una vez que la carne esté en el punto deseado, sólo será necesario emplatar para poder disfrutarlo.</li>
                            </ul>
                            <br />
                        </div>
                    ),
                },
                {
                    imagen: imgbnr42,
                    texto: (
                        <div>
                            <h3>Pavo BBQ </h3>
                            <br />
                            <b>Ingredientes:</b><br />
                            1 pavo natural
                            <br />
                            <br />
                            <b>Para la mantequilla de hierbas:</b><br />
                            1 taza de mantequilla<br />
                            1 cucharada de Hojas de romero McCormick®<br />
                            1 cucharada de <a target='_blank' href='https://www.mccormick.mx/producto/tomillo-en-polvo'>Tomillo en polvo McCormick®</a><br />
                            1 cucharada de salvia fresca<br />
                            2 dientes de ajo<br />
                            <br />
                            <b>Para la reducción de BBQ:</b><br />
                            1 taza de salsa BBQ<br />
                            2 cucharadas de Miel Carlota®<br />
                            1 taza de azúcar mascabada<br />
                            1 cucharada de salsa tabasco<br />
                            4 cucharadas de salsa inglesa<br />
                            1/2 tazas de vinagre balsámico<br />
                            1/4 tazas de agua<br />
                            1 taza de cerveza<br />
                            3 dientes de ajo<br />
                            1/2 cebolla picada<br />
                            1/4 tazas de <a target='_blank' href='https://www.mccormick.mx/producto/mostaza'>Mostaza McCormick®</a><br />
                            1 cucharada de Tomillo en polvo McCormick®<br />
                            <br />
                            <b>Para el relleno:</b><br />
                            1 taza de apio   <br />
                            2 manzanas   <br />
                            1/2 tazas de arándanos   <br />
                            1/2 tazas de cebolla   <br />
                            2 tazas de pan, dorado y cortado en cubos   <br />
                            1 cucharada de <a target='_blank' href='https://www.mccormick.mx/producto/perejil-deshidratado'>Hojas de perejil McCormick®</a> <br />
                            <br />
                            <b>Para decorar:</b><br />
                            Suficientes Hojas de perejil McCormick®<br />
                            Suficientes cerezas<br />
                            Suficiente naranja cortada en medias lunas<br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>En una cacerola a fuego medio, derrite la mantequilla y agrega la cucharada de hojas de romero McCormick®, la cucharada de tomillo en polvo McCormick®, la salvia, el ajo y cocina por 5 minutos hasta que tengas una mantequilla aromatizada. Deja enfriar ligeramente y reserva.</li>
                                <li>Con ayuda de una jeringa para pavo, inyecta el pavo con la mantequilla aromatizada. Reserva.</li>
                                <li>Para la reducción, cocina la salsa BBQ con las dos cucharadas de Miel Carlota®, el azúcar mascabada, la salsa tabasco, la salsa inglesa, el vinagre balsámico, el agua, la cerveza, el ajo, la cebolla, la Mostaza McCormick® y la cucharada de tomillo en polvo McCormick® alrededor de 20 minutos o hasta que se haga una mezcla espesa. Deja enfriar y reserva.</li>
                                <li>Sobre una tabla de corte pela la manzana con ayuda de un pelador y corta en cubos medianos.</li>
                                <li>Para el relleno, en un bowl mezcla el apio, la manzana, los arándanos, la cebolla, el pan y la cucharada de hojas de perejil McCormick®. Rellena el pavo y amarra.</li>
                                <li>Precalienta el horno a 180°C.</li>
                                <li>Con ayuda de una brocha, barniza el pavo con la reducción anterior y hornea por 4 horas o hasta que esté cocido.</li>
                                <li>Decora con hojas de perejil McCormick®, cerezas y naranja en medias lunas.</li>
                                <li>Rebana el pavo y sirve acompañado de relleno.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr43,
                    texto: (
                        <div>
                            <h3>Buñuelos Rellenos de Mermelada McCormick® </h3>
                            <br />
                            <b>Ingredientes:</b><br />
                            1 taza de agua<br />
                            suficiente de cáscara de limón (sin la parte blanca)<br />
                            1/2 tazas de mantequilla, en cubos<br />
                            1 cucharadita de sal<br />
                            1 1/4 tazas de harina<br />
                            1 cucharadita de polvo para hornear<br />
                            4 huevos<br />
                            suficiente de aceite vegetal, para freír<br />
                            2 tazas de azúcar<br />
                            <a target='_blank' href='https://www.mccormick.mx/producto/mermelada-de-fresa-guayaba'>Mermelada de fresa y guayaba McCormick®</a> sabores de México al gusto<br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Coloca el agua en una olla junto con la cáscara de limón, la mantequilla, la sal y lleva a hervor a fuego medio. Una vez que tenga una burbuja pequeña, baja la flama y retira la cáscara de limón.</li>
                                <li>Agrega la harina y el polvo para hornear de un solo golpe y utiliza una palita de madera para mezclar constantemente hasta obtener una masa compacta y uniforme; no dejes de mover hasta que la obtengas. Una vez lista, retira del fuego.</li>
                                <li>Incorpora los huevos uno a uno mientras continúas moviendo la mezcla para que se enfríe, ya que esto ayudará a que la temperatura no afecte al huevo y se cocine antes.</li>
                                <li>Apoyándote de dos cucharas, vierte un poco de la mezcla en una sartén con suficiente aceite vegetal caliente; notarás cómo la mezcla comienza a obtener una forma circular y crecerá. Mantén los buñuelos a temperatura media-baja hasta que la bolita duplique su volumen y la masa comience a romperse, pues esto es señal de que el buñuelo comienza a ahuecarse. Retira del aceite y escurre sobre papel absorbente.</li>
                                <li>Aún caliente, baña las bolitas de buñuelo en azúcar para cubrirlas muy bien y haz una abertura lo suficientemente grande para rellenar.</li>
                                <li>Rellena las bolitas de buñuelo con la Mermelada de fresa y guayaba McCormick® sabores de México y sirve inmediatamente.</li>
                            </ul>
                        </div>
                    ),
                },

            ],
            foter: <div></div>,
        },
        {
            nombre: "como-preparar-alitas-caseras",
            titulo: "NFL: Cómo preparar las alitas caseras perfectas",
            imagen: imgbnr34,
            descripcion: (
                <div>
                    ¡La NFL llega a México! Ponle sabor a los juegos de fútbol americano con esta deliciosa <b>receta</b>
                    para hacer las <b>alitas de pollo caseras</b> perfectas.
                    <br />
                    <br />
                    <h2> Prepárate para el juego de la NFL en México desde casa </h2>
                    <br />
                    En México se han jugado 4 partidos de temporada regular y en esta ocasión, la emoción del juego hará vibrar a México, pues el 21 de noviembre de 2022 se celebrará el encuentro entre los Arizona Cardinals y los San Francisco 49ers en el mítico Estadio Azteca, uno de los recintos más representativos de país.
                    <br />
                    <br />
                    El emparrillado ya está listo y dispuesto para recibir en las gradas a las porras de ambos equipos, tanto mexicanos como extranjeros. Así que tú también prepara la parrilla en tu casa con todo tipo de snacks, tu mesa con refrescantes bebidas y convoca a tu familia y amigos para que disfruten juntos de este evento.
                    <br />
                    <br />
                    Porque, así como la NFL es experta en esta temporada de juegos, en McCormick somos expertos en acompañar todas tus comidas con este extra de sabor que te encanta.
                    <br />
                    La línea de Mayodips McCormick tiene tres diferentes sabores:
                    <br />
                    <br />
                    <h2> Elige tus snacks para ver el partido de la NFL en México </h2>
                    <br />
                    Se sabe que un partido sin <b>McCormick</b> no sabe, por eso, para la hora del juego no pueden faltar en tu mesa las icónicas costillitas preparadas con <a href='https://franksredhot.com.mx/' target='_blank' >Salsa Frank´s Red Hot</a> y las hamburguesas aderezadas con el inigualable <a href='https://www.mccormick.mx/producto/aderezo-de-mayonesa-light' target='_blank'>Aderezo de Mayonesa Light</a> (o quizás con el <a href='https://www.mccormick.mx/producto/aderezo-de-mayonesa-con-chipotle' target='_blank'> Aderezo de Mayonesa Chipotle</a> para un delicioso twist de sabor).
                    <br />
                    <br />
                    ¿Qué te parecen unas bolitas de queso acompañadas con <a href='https://www.mccormick.mx/producto/mermelada-de-pi%C3%B1a-con-chile-pasilla' target='_blank'>Mermelada de Piña con Chile Pasilla</a>? Las clásicas papas a la francesa salpimentadas con <a href='https://www.mccormick.mx/producto/sazonador-pimienta-con-lim%C3%B3n' target='_blank'>Pimienta con Limón</a> o unos Pizza Rolls especiados con nuestra amplia gama de <a href='https://www.mccormick.mx/productos/especias-y-condimentos' target='_blank'>Especias McCormick</a>.
                    <br />
                    Mayodipea con tu botana favorita como un chile toreado, aros de
                    cebolla o dedos de queso, ¡te va a encantar!
                    <br />
                    <br />
                    <h2> Bebidas frías con té para anotar un gol de campo </h2>
                    <br />
                    Así mismo, con nuestros tés e infusiones en frío puedes preparar desde un Frappé de <a href='https://www.mccormick.mx/producto/t%C3%A9-verde' target='_blank'>Té Verde </a>
                    con coco, <a href='https://www.mccormick.mx/producto/t%C3%A9-verde-mango' target='_blank'>Té verde sabor mango</a>  frío con fresas, hasta Margaritas preparadas con <a href='https://www.mccormick.mx/producto/t%C3%A9-de-jamaica' target='_blank'>Té de Jamaica</a>
                    y Mojitos hechos con <a href='https://www.mccormick.mx/producto/t%C3%A9-de-hierbabuena' target='_blank'>Té de Hierbabuena</a> para refrescar a los invitados.
                    <br />
                    <br />
                    ¿Buscas algo más sencillo para ahorrar tiempo en las bebidas?
                    ¡No hay problema! Con nuestras Infusiones en Frío puedes preparar deliciosas bebidas en
                    minutos y sin necesidad de calentar el agua, incluso puedes mezclarlo con agua mineral para
                    darle un toque burbujeante y fresco, que al combinarlo con sus diferentes sabores,
                    <a href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-frutos-rojos' target='_blank'>Frutos Rojos</a>, <a href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-lim%C3%B3n-jengibre' target='_blank'>Limón-Jengibre</a> y <a href='https://www.mccormick.mx/producto/infusi%C3%B3n-en-fr%C3%ADo-sabor-durazno-jengibre' target='_blank'>Durazno-Jengibre</a>, le quitan lo simple al agua y le dan carácter a tus bebidas.
                    <br />
                    <br />
                    <h2> Recetas fáciles paso a paso para la NFL  </h2>
                    <br />
                    Todas estas son opciones geniales para reunirte con tus amigos y familia y disfrutar esta temporada de NFL. Pero el día de hoy tenemos una receta especial con la que anotarán un touchdown en cada una de sus reuniones y disfrutarás al máximo el partido que se juega en tierra azteca.
                    <br />
                    <br />
                    Toda tu atención que aquí va la receta ganadora de todos tus partidos:

                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg55,
                    texto: (
                        <div>
                            <h3> Esquites con Mayonesa McCormick®: la botana del medio tiempo </h3>
                            <br />
                            <b>Rinde</b>: 4 porciones<br />
                            <b>Tiempo de preparación</b>: 20 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            500 gramos de granos de elote tierno<br />
                            1 rama de epazote<br />
                            1 cucharadita de <a href='https://www.mccormick.mx/producto/sazonador-sabroseador' target='_blank'>Sabroseador McCormick®</a><br />
                            1.5 litros de agua<br />
                            200 gramos de queso fresco tipo doble crema rallado<br />
                            ½ taza de <a href='https://www.mccormick.mx/producto/mayonesa-con-jugo-de-lim%C3%B3n' target='_blank'>Mayonesa McCormick con Jugo de Limón</a> <br />
                            1 chile serrano finamente picado<br />
                            2 cucharadas de hojas de cilantro picadas finamente<br />
                            Totopos para acompañar<br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>En un tazón, coloca los granos de elote, coloca agua hasta la superficie y deja hervir unos minutos para que salga todo el bagazo y hollejo. Retíralos con una cuchara o colador de cocina.</li>
                                <li>Escurre muy bien y coloca los granos de elote en 1.5 litros de agua junto con la rama de epazote. Cocina a fuego medio unos 30 minutos hasta que estén muy suaves. Escúrrelos del agua de cocción.</li>
                                <li>Mezcla los granos de elote con el Sabroseador McCormick®, la mitad del queso doble crema, la Mayonesa McCormick® con Jugo de limón o la Mayonesa McCormick® de tu sabor preferido.</li>
                                <li>Mezcla los granos de elote, añade chile serrano y cilantro, espolvorea con el resto del queso doble crema. Rectifica la sazón de sal.</li>
                                <li>Sirve con totopos.</li>
                            </ul>
                            <br />
                            <b>Tips: </b>
                            <ul>
                                <li>Para que los granos de elote o elotes queden suaves, cuando los cocines añade 1 cucharadita de polvo de hornear y no agregues sal para evitar que los granos se contraigan y no queden suaves.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr35,
                    texto: (
                        <div>
                            <h3>Alitas Caseras con McCormick®  </h3>
                            <br />
                            <b>Rinde</b>: 6 porciones<br />
                            <b>Tiempo de preparación</b>: 30 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            1 kilo de alitas de pollo limpias, sin plumas ni puntas<br />
                            ½ cucharadita de <a href='https://www.mccormick.mx/producto/pimienta-negra-molida' target='_blank'>Pimienta Negra molida McCormick®</a><br />
                            1 cucharada de aceite vegetal<br />
                            1 barrita de margarina o mantequilla<br />
                            1/2 taza de <b>Salsa Frank's Red Hot®</b><br />
                            ¼ de taza de pulpa de tamarindo<br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Calienta una cacerola con antiadherente o teflón a fuego medio alto con el aceite y la margarina o mantequilla.</li>
                                <li>Sazona con pimienta las alitas de pollo.</li>
                                <li>Agrega las alitas de pollo para que se sellen por cada lado, baja el fuego y añade la Salsa Frank's® y la pulpa de tamarindo.</li>
                                <li>Tapa la cacerola y cocina a fuego muy bajo hasta que se comiencen a reducir totalmente los jugos; voltea de vez en cuando las alitas de pollo, deberán quedar bien suaves y doradas.</li>
                                <li>Sírvelas calientitas y acompáñalas con más Salsa Frank's®.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr36,
                    texto: (
                        <div>
                            <h3>Touchdown de frescura con Té Matcha y Mermeladas McCormick®  </h3>
                            <br />
                            <b>Rinde</b>: 4 porciones<br />
                            <b>Tiempo de preparación</b>: 15 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            1 cucharada de <a href='https://www.mccormick.mx/producto/matcha-puro' target='_blank'>Té Matcha McCormick®</a> infusionado en 1 taza de agua caliente   <br />
                            1 ½ taza de agua de coco   <br />
                            ½ taza de <a href='https://www.mccormick.mx/producto/mermelada-de-pi%C3%B1a' target='_blank'>Mermelada de Piña McCormick® </a><br />
                            2 tazas de hielo en cubitos   <br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Coloca en el vaso de la licuadora la taza de Té Matcha McCormick®, el agua de coco, la Mermelada de Piña McCormick® y el hielo.</li>
                                <li>Muele a velocidad alta.</li>
                                <li>Sirve en 4 copas y disfruta con tus amigos y familiares de esta refrescante bebida.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr37,
                    texto: (
                        <div>
                            <h3>Gin con Conservas con Semillas McCormick®  </h3>
                            <br />
                            <b>Rinde</b>: 1 porciones<br />
                            <b>Tiempo de preparación</b>: 10 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            60 ml de ginebra <br />
                            30 ml de jugo de limón <br />
                            1 cucharadita de <a href='https://www.mccormick.mx/producto/conserva-de-fresa-con-ch%C3%ADa' target='_blank'>Conserva de fresa con chía McCormick®</a> <br />
                            1 sobre de azúcar moreno <br />
                            1 cucharada sopera de <b>Conserva de fresa con chía McCormick® </b><br />
                            Hielo picado al gusto <br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>En un vaso grande, mezcla todos los ingredientes. </li>
                                <li>En el mismo vaso, añade hielo y mezcla bien.</li>
                                <li>Llenar dos vasos con hielo picado y divide la mezcla anterior entre ambos vasos, colando con un colador.</li>
                                <li>Decorar con Conserva de fresa con chía McCormick®.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr38,
                    texto: (
                        <div>
                            <h3>Té de Verano con Infusión en frío McCormick®  </h3>
                            <br />
                            <b>Rinde</b>: 1 porciones<br />
                            <b>Tiempo de preparación</b>: 10 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            1 onza de ron sabor limón<br />
                            1 onza de amaretto<br />
                            1 onza de <b>Infusión en frío McCormick® sabor limón- jengibre</b><br />
                            Suficiente hielo<br />
                            1 rodaja de naranja<br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Coloca en una coctelera el hielo junto con el ron sabor limón, el amaretto y la Infusión en frío McCormick.</li>
                                <li>Agita durante unos segundos para poder integrar todos los ingredientes.</li>
                                <li>Sirve en un vaso estilo old fashioned.</li>
                                <li>Decorar con una rodaja de naranja.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr39,
                    texto: (
                        <div>
                            <h3>My Jam: Vodka con Conservas con Semillas McCormick®  </h3>
                            <br />
                            <b>Rinde</b>: 1 porciones<br />
                            <b>Tiempo de preparación</b>: 10 minutos
                            <br />
                            <br />
                            <b>Ingredientes:</b><br />
                            Suficiente hielo <br />
                            1½  onza de vodka <br />
                            ¾ de onza de jugo de limón <br />
                            ⅔ onza de jarabe de horchata de almendras <br />
                            ⅓ onza de clara de huevo <br />
                            2 cucharaditas de <b>Conserva de fresa y chía McCormick® </b><br />
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Llena una coctelera boston con hielo. </li>
                                <li>Añade todos los ingredientes a la coctelera. </li>
                                <li>Agitar y colar en un vaso bajo.</li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "mayodipea-antojo-detona-sabor-botanas",
            titulo: "Mayodipea a tu antojo y detona el sabor de tus botanas",
            imagen: imgbnr17,
            descripcion: (
                <div>
                    Te presentamos la nueva línea, <b>Mayodips McCormick</b>, un rico
                    dip para compartir con tus amigos o con las personas que amas para
                    cualquier momento al que le quieras poner sabor.
                    <br />
                    <br />
                    Ponle la rica consistencia característica de los productos
                    McCormick a tus botanas y mejora su sabor con cada “mayodipeada”.
                    <br />
                    <br />
                    <h2> 3 Diferentes sabores para “mayodipear” de mil formas</h2>
                    <br />
                    La línea de Mayodips McCormick tiene tres diferentes sabores:
                    <br />
                    <br />
                    <h3> Esquites </h3>
                    <br />
                    Este delicioso <b>mayodip</b> es lo que siempre habías soñado, el
                    sabor de McCormick combinado con los tradicionales y ricos
                    esquites ideales para sorprender a tu media naranja mientras ven
                    una peli. <br />
                    <br />
                    Mayodipea con tu botana favorita como un chile toreado, aros de
                    cebolla o dedos de queso, ¡te va a encantar!
                    <br />
                    <br />
                    <h3> Queso Parmesano y Hierbas </h3>
                    <br />
                    El <b>mayodip</b> que tu paladar va a agradecer, pues tiene el
                    sabor italiano del queso parmesano, la combinación con hierbas y
                    el característico estilo de McCormick que tanto amas. <br />
                    <br />
                    Mayodipea en banderillas, boneless, unas ricas tapas o en lo que
                    más se te antoje. Sorprende a tus amigos en la charla semanal o a
                    tus invitados en una pool party.
                    <br />
                    <br />
                    <h3> Crema y Cebolla </h3>
                    <br />
                    Este <b>mayodip</b> es para los amantes de la deliciosa mezcla
                    entre crema y cebolla y el sabor de McCormick que ya conoces, pues
                    la combinación hará una explosión en tu paladar.
                    <br />
                    Mayodipea con burritos, camarones, tu mejor selección de quesos o
                    con otras botanas. Compártelo con tus amigos mientras juegas
                    videojuegos o en un maratón de tu serie favorita, siempre quedarás
                    bien.
                </div>
            ),
            recetas: [],
            foter: <div></div>,
        },
        {
            nombre: "organizar-cena-navidena-tradicional",
            titulo: "Cómo organizar una cena navideña tradicional ",
            imagen: imgbnr16,
            descripcion: (
                <div>
                    Para muchos, la Navidad es el momento ideal para un reencuentro
                    familiar, para otros se ha convertido en una época de reflexión y
                    hasta de nostalgia. Pero sea como sea que tú vivas esta fecha tan
                    especial, queremos que te prepares para vivir una noche mágica,
                    llena de sabor y te aventures a preparar una maravillosa cena que
                    saque tu lado más creativo y sobre todo sorprenda a tus seres
                    queridos.
                    <br />
                    <br />
                    En México tenemos tradiciones que siempre van de la mano con el
                    sabor, pues todas ellas van acompañadas de especias, chiles, tés,
                    aguas de horchata, jamaica, ponche, mermeladas, postres y para
                    brindar nada como sidras, champagnes, cocteles o algún destilado
                    como mezcal, tequila, ginebra, whiskey o también cervezas
                    artesanales que maridan la gran variedad de sabores que tenemos.
                    <br />
                    <br />
                    Navidad no es la excepción, pues los platillos típicos son fruto
                    del sincretismo cultural y la diversidad que existe en nuestro
                    país, porque en cada familia se festeja de manera diferente, de
                    acuerdo a nuestro estado, contexto, gustos, familia, porque aquí
                    no hay una sola Navidad, hay tantas como sabores.
                    <br />
                    <br />
                    <h2> Cena navideña a la mexicana</h2>
                    <br />
                    Los <b>romeritos</b> se preparan con mole y se acompañan con
                    tortas de camarón seco y nopales. La receta original en la capital
                    del país se hacía con tortita de ahuautle (la hueva del mosco de
                    las islas del lago de Texcoco, que hoy en día es muy difícil de
                    conseguir).
                    <br />
                    <br />
                    Al <b>pavo</b> en México le llamamos guajolote y es la estrella en
                    bodas, cumpleaños y fiestas patronales. Debes saber que esta ave
                    fue domesticada por los pueblos originarios y llegó a otros
                    continentes con la Colonización.
                    <br />
                    <br />
                    El <b>ponche</b> se prepara con frutas de la temporada como
                    guayaba, tejocote, ciruela y piña cocida y mezclada con caña de
                    azúcar, piloncillo y <b>canela</b>. Dicen las abuelas que lo más
                    especial del ponche es que entre más hervores tenga, más se
                    concentra el dulzor. Lo cierto es que hay que cuidar la fruta para
                    que no se deshaga y termine siendo puré.
                    <br />
                    <br />
                    El <b>bacalao</b> que se cura en sal debe su origen a la necesidad
                    de los vikingos por conservar comida. Pero su uso en la cocina
                    para preparar la comida de Navidad se lo debemos a los vascos.
                    Ellos lo preparan con ingredientes como papas y aceite de oliva.
                    En México se come el llamado a la vizcaína que nada tiene que ver
                    con el de Vizcaya y esta versión lleva aceitunas, alcaparras,
                    chiles güeros, <b>orégano, pimienta</b> y jitomate. <br />
                    <br />
                    <h2> Bebidas navideñas para acompañar tu cena</h2>
                    En las calles de diferentes ciudades se ven camiones que venden
                    sidra durante diciembre. Un clásico que no puede faltar en las
                    mesas para alzar las copas y festejar esta temporada, además
                    orgullosamente hecha de manzanas nacionales.
                    <br />
                    <br />
                    En México otra tradición, son las posadas que nos recuerdan las
                    jornadas de viaje de la Virgen María y San José a Belén. Comienzan
                    nueve días antes de celebrar la Navidad y cada elemento es
                    simbólico. Como la fruta, el ponche, la clásica piñata y claro,
                    los peregrinos y letanías típicos de estas fiestas.
                    <br />
                    <br />
                    <h2> Menú navideño para preparar en casa</h2>
                    A continuación, te compartimos algunos tips y recetas sencillas
                    para cocinar en casa y disfrutar sabores navideños
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg52,
                    texto: (
                        <div>
                            <h3> Puerquitos de piloncillo </h3>
                            <br />
                            <b>Porciones</b>:24 piezas
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            225 g de piloncillo o panela
                            <br />
                            125 ml. de agua
                            <br />
                            400 g de harina
                            <br />
                            50 g de mantequilla
                            <br />
                            20 g de azúcar
                            <br />
                            100 g de manteca vegetal
                            <br />
                            1 yema de huevo
                            <br />
                            ¼ de cdita. de bicarbonato de sodio
                            <br />
                            1 cdita. de polvo de hornear
                            <br />¼ de cdita. de <b>Canela molida McCormick®</b>
                            <br />¼ de cdita. de <b>Jengibre en polvo McCormick®</b>
                            <br />
                            <b>Para trabajar la masa:</b>
                            <br />
                            2 cdas. de harina
                            <br />
                            1 huevo batido con una pizca de sal
                            <br />
                            1 cortador de galleta en forma de puerquito
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    Coloca en una olla, agua y piloncillo, cocina a fuego bajo
                                    hasta que se derrita y se forme una miel espesa. Retira
                                    del fuego y deja enfriar.
                                </li>
                                <li>
                                    En un tazón coloca mantequilla, azúcar, manteca vegetal y
                                    la yema de huevo, bate con la ayuda de una pala de madera.
                                </li>
                                <li>
                                    Agrega la miel de piloncillo y continúa mezclando hasta
                                    que todo esté bien integrado.
                                </li>
                                <li>
                                    Mezcla la harina con bicarbonato, polvo de hornear y todas
                                    las especias, tamiza con la ayuda de un colador.
                                </li>
                                <li>
                                    Agrega la harina a la mantequilla batida, mezcla y forma
                                    una masa.
                                </li>
                                <li>
                                    Envuelve tu masa en papel plástico adherente de cocina,
                                    aplasta un poco y refrigera por 30 minutos.
                                </li>
                                <li>
                                    Espolvorea harina en una superficie plana, extiende la
                                    masa con la ayuda de un rodillo, corta con el molde de
                                    puerquito.
                                </li>
                                <li>
                                    Acomoda los puerquitos en una charola para hornear
                                    cubierta con papel de alta temperatura o tapete de silicón
                                    (Si no tienes, puedes usar papel estraza untado con
                                    mantequilla y espolvoreado de harina)
                                </li>
                                <li>
                                    Barniza cada puerquito con el huevo batido y hornea en
                                    horno precalentado a 180°C por unos 15 minutos o hasta que
                                    estén dorados.
                                </li>
                                <li>
                                    Retiralos del horno y déjalos enfriar. Acompáñalos con una
                                    bebida caliente y mermelada de tu preferencia.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: recetasimg53,
                    texto: (
                        <div>
                            <h3>Ponche antigripal con té y fruta</h3>
                            <br />
                            <b>Porciones</b>:1 litro
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            1 litro de agua
                            <br />1 <b>Canela en rama McCormick®</b>
                            <br />4 sobres de <b>Té de jengibre McCormick®</b> o té rojo{" "}
                            <br />
                            1 guayaba sin semillas cortada en cubitos
                            <br />
                            2 tejocotes cortados en cubitos
                            <br />
                            2 cdas. de Miel de abeja Carlota®
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    Coloca todos los ingredientes en una olla y cocina a fuego
                                    medio unos 15 minutos, hasta que la fruta esté suave.
                                    Retira del fuego y desecha las bolsitas de té.
                                </li>
                                <li>Sirve este ponche calientito.</li>
                            </ul>
                            <b>Tip:</b> Consume este tipo de bebidas en tiempos de frío,
                            las especias, los cítricos y la miel sirven como expectorante
                            de las vías respiratorias junto con el consumo de frutas y
                            antioxidantes.
                        </div>
                    ),
                },
                {
                    imagen: recetasimg54,
                    texto: (
                        <div>
                            <h3> Pollo Navideño a la Sidra y Mermelada de Manzana </h3>
                            <br />
                            <b>Porciones</b>: 4<br />
                            <b>Ingredientes</b>
                            <br />
                            1 pollo entero <br />
                            ½ taza de cebolla blanca picada finamente
                            <br />
                            1 cdita. de sal
                            <br />½ cdita. de <b>Ajo en polvo McCormick®</b>
                            <br />½ cdita. de <b>Jengibre en polvo McCormick®</b>
                            <br />½ cdita. de <b>Pimienta negra molida McCormick®</b>
                            <br />
                            2 tallos de apio cortados en cuadritos
                            <br />
                            3 cda. de mantequilla o margarina
                            <br />½ taza de <b>Mermelada de manzana McCormick®</b>
                            <br />
                            1 taza de sidra si no tienes, puedes usar jugo de manzana
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>Lava bien el pollo y sécalo con papel de cocina.</li>
                                <li>
                                    Mezcla la sal y todas las especias, frota el pollo con
                                    esta preparación por dentro y por fuera. Rellena el pollo
                                    con cebolla y apio.
                                </li>
                                <li>
                                    Unta el pollo por dentro y por fuera con trocitos de
                                    mantequilla.
                                </li>
                                <li>Mezcla la mermelada con la sidra.</li>
                                <li>
                                    Coloca el pollo en una charola para hornear, vierte encima
                                    con la mermelada, tapa con papel de aluminio.
                                </li>
                                <li>Precalienta el horno a 180°C</li>
                                <li>Hornea por una hora y 45 minutos.</li>
                                <li>
                                    Retira el papel de aluminio y deja dorar el pollo por
                                    ambos lados. Cuela los jugos de cocción para servir con el
                                    pollo.
                                </li>
                                <li>
                                    Acompaña con una ensalada de pasta aderezada con mayonesa
                                    y vegetales.
                                </li>
                            </ul>
                            <b>Tip:</b> Puedes hacer esta receta con pavo, solo recuerda
                            que por cada kilo de pavo debes dar una 1 hora de cocción.
                            <br />
                            Para hacer la receta con pierna y muslo de pollo: sazona con
                            las especias, dora en un sartén con mantequilla;
                            posteriormente agrega el apio, vierte la mermelada con la
                            sidra y cocina tapado por 25 minutos a fuego bajo. Quedará
                            jugoso.
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "postres-para-ninos",
            titulo:
                "Este regreso a clases prepara postres deliciosos con Conservas con Semillas de McCormick",
            imagen: imgbnrPostres,
            descripcion: (
                <div>
                    <p><span>El regreso a clases est&aacute; a la vuelta de la esquina y es mejor darle un delicioso sabor con unos nutritivos </span><strong>postres caseros</strong><span> hechos con </span><a href="https://www.mccormick.mx/productos/mermeladas"><strong>Conservas con Semillas McCormick</strong></a><span>.</span></p>
                    <p><span>Entre trabajos, tareas y todo tipo de actividades, nuestros peques necesitan energ&iacute;a suficiente para estar atentos durante sus clases, pero sabemos que tambi&eacute;n necesitan la motivaci&oacute;n de comer algo delicioso que les guste y les haga bien.</span></p>
                    <p><span>Una buena opci&oacute;n para toda la familia son unas deliciosas </span><strong>crepas de pl&aacute;tano</strong><span> con</span><strong> Conserva de Fresa con Ch&iacute;a</strong><span> para una tarde de pelis en casa. O el cl&aacute;sico que nunca falla, un pan franc&eacute;s con la riqu&iacute;sima </span><strong>Conserva de Pi&ntilde;a con Linaza</strong><span> para el desayuno.&nbsp; &iexcl;Les encantar&aacute;!</span></p>
                    <h2> Conoce la nueva l&iacute;nea de mermeladas bajas en az&uacute;car </h2>
                    <p><span>Dale ese toque especial a tus alimentos favoritos y prepara tus postres con </span><strong>Conservas con semillas McCormick</strong><span>, ser&aacute;n irresistibles para todos.</span></p>
                    <p><span>Porque </span><strong>Conservas con Semillas McCormick</strong><span> une lo mejor de los dos mundos; el delicioso sabor que te encanta de una buena </span><strong>jalea</strong><span> y ese sentimiento de tranquilidad al saber que est&aacute;s cuidando tu cuerpo con un </span><strong>producto saludable</strong><span>, sin excesos y que incluye nutrientes importantes para tu d&iacute;a a d&iacute;a.</span></p>
                    <p><span>Esta nueva l&iacute;nea de </span><strong>conservas</strong><span> est&aacute; hecha con fruta </span><strong>reducida en az&uacute;car </strong><span>y adicionada con las propiedades de las </span><strong>semillas </strong><span>(en caso de la ch&iacute;a, su alto contenido de omega-3, as&iacute; como calcio, magnesio y boro, o las semillas de linaza, reconocidas como una buena fuente de fibra, prote&iacute;na vegetal y minerales como el magnesio y manganeso). Adem&aacute;s, las </span><strong>texturas</strong><span> de estas conservas son &uacute;nicas, ya que gracias a las semillas, comerlas es toda una experiencia sensorial en tu boca.</span></p>
                    <p><span>Todos estos usos de la variedad de </span><strong>Conservas con Semillas McCormick</strong><span> son ideales para nuestros peques, pero la receta de hoy est&aacute; pensada en la practicidad para el regreso a clases. En que sea r&aacute;pido para ti y llamativo para ellos.</span></p>
                    <p><span>Ya sea que busques sorprender a tus peques cuando regresen de su primer d&iacute;a de clases o por el simple hecho de consentir a toda la familia, estas opciones hechas con conservas no solo son deliciosas, tambi&eacute;n son una opci&oacute;n saludable y nutritiva.</span></p>
                    <h2> Dos postres saludables y nutritivos para los ni&ntilde;os </h2>
                    <p><span>Adem&aacute;s, todos estos riqu&iacute;simos </span><strong>postres saludables</strong><span> son rapid&iacute;simos y s&uacute;per f&aacute;ciles de preparar.</span></p>
                    <p><span>Salva el regreso a clases y consiente a los m&aacute;s peque&ntilde;os de la casa endulzando su paladar y siguiendo estos sencillos pasos:</span></p>
                </div>
            ),
            recetas: [
                {
                    imagen: recetaBarras,
                    texto: (
                        <div>
                            <h3>
                                Barritas Energéticas y Conserva con Semillas McCormick® de
                                Piña con Linaza
                            </h3>
                            <br />
                            <b>Rinde</b>:8 porciones
                            <br />
                            <b>Tiempo de preparación</b>:30 minutos
                            <br />
                            <b>Ingredientes</b>: ½ taza de Conserva con Semillas
                            McCormick® de Piña con Linaza
                            <br />
                            1 pieza de huevo
                            <br />
                            50 ml de aceite de oliva
                            <br />
                            1 taza de harina de avena (puedes moler 1 ¼ taza de
                            hojuelas de avena en la licuadora muy fino)
                            <br />
                            ½ taza de harina integral
                            <br />
                            ½ cucharadita de polvo de hornear
                            <br />
                            ¼ de taza de almendras picadas en trozos gruesos
                            <br />
                            Para la cubierta:
                            <br />
                            ½ taza de Conserva con Semillas McCormick® de Piña con Linaza.
                            <br />
                            ¼ de taza de coco deshidratado rallado
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    En un tazón, combina el huevo con el aceite; bate para que se integren.
                                </li>
                                <li>
                                    Incorpora la Conserva con Semillas McCormick® de Piña con Linaza, las harinas, el polvo de hornear y almendras.
                                </li>
                                <li>
                                    Mezcla muy bien hasta que se integre y forme una masa, de textura espesa y poco ligera.
                                </li>
                                <li>
                                    Engrasa un molde cuadrado o refractario de unos 20 cm x 20 cm y 4 cm de fondo aproximadamente con mantequilla o aceite.
                                </li>
                                <li>
                                    Vierte la mezcla y hornea a 170°C unos 25 minutos hasta que esté dorado. Retira del horno y deja enfriar.
                                </li>
                                <li>
                                    Vierte la Conserva con Semillas McCormick® de Piña con Linaza encima de la galleta horneada, espolvorea con el coco rallado.
                                </li>
                                <li>
                                    Corta cuadros o rectángulos.
                                </li>
                            </ul>
                            <b>Nota:</b> Puedes añadir más semillas, como nueces picadas o cacahuates tostados sin sal.
                            A la masa puedes añadirle 1 cucharadita de linaza o ajonjolí.
                        </div>
                    ),
                },
                {
                    imagen: recetaBrocheta,
                    texto: (
                        <div>
                            <h3> Brochetas de hotcakes y Conserva de Fresa con Chía McCormick® </h3>
                            <br />
                            <span></span><strong>Rinde:</strong><span> 4 porciones</span>
                            <br />
                            <strong>Tiempo de preparaci&oacute;n: </strong><span>30 minutos</span>
                            <br />
                            <strong>Ingredientes:</strong>
                            <br />
                            <span>1 taza de harina para hotcakes preparada</span>
                            <br />
                            <span>&frac12; pieza de pl&aacute;tano</span>
                            <br />
                            <span>2 cucharadas de </span><strong>Conserva de Fresa con Ch&iacute;a McCormick&reg;</strong>
                            <br />
                            <span>Para armar tus brochetas:</span>
                            <br />
                            <span>8 fresas bien limpias y desinfectadas</span>
                            <br />
                            <span>1 pieza pl&aacute;tano</span>
                            <br />
                            <span>1 Frasco de </span><strong>Conserva de Fresa con Ch&iacute;a McCormick&reg;</strong>
                            <br />
                            <span>4 brochetas de madera de unos 15-18 cm de largo</span>
                            <br />
                            <strong>Procedimiento:</strong>
                            <ul>
                                <li><span>Muele la preparaci&oacute;n de harina de hotcakes con el pl&aacute;tano y la Conserva de Fresa con Ch&iacute;a McCormick&reg;.</span></li>
                                <li><span>Prepara tus hotcakes miniatura o puedes hacerlos cuadrados y con la ayuda de un cortador de galletas. Cortarlos en tama&ntilde;o bocado.</span></li>
                                <li><span>Unta en cada hotcake con una porci&oacute;n generosa, de la Conserva de Fresa con Ch&iacute;a McCormick&reg;.&nbsp;</span></li>
                                <li><span>Inserta el hotcake en la brocheta, una rebanada de pl&aacute;tano, una rebanada de fresa y m&aacute;s conserva de Fresa con Ch&iacute;a McCormick&reg;; repite la operaci&oacute;n hasta terminar todas tus brochetas.</span></li>
                                <li><span>Acomoda en un recipiente con tapa para que puedas llevar a la escuela como lunch o a tu trabajo.</span></li>
                            </ul>
                            <strong>Tips:&nbsp;</strong>
                            <ul>
                                <li><span>Cuando utilices pl&aacute;tano como guarnici&oacute;n de fruta, exprime unas gotas de jugo de lim&oacute;n, esto ayudar&aacute; a detener un poco la oxidaci&oacute;n.&nbsp;</span></li>
                                <li><span>Cuando prepares mezcla para hotcakes, muele 1 fruta, o ralla manzana, pera o a&ntilde;ade ar&aacute;ndanos deshidratados, es una forma de que los ni&ntilde;os se coman la fruta, y acompa&ntilde;a con una conserva de fruta McCormick&reg; que les aportar&aacute; sabor.</span></li>
                            </ul>
                        </div>
                    ),
                },

            ],
            foter: <div></div>,
        },
        {
            nombre: "especias-condimentos-para-parrilladas",
            titulo:
                "PARRILLADA PARA CELEBRAR EN FAMILIA CON GRILL MATES® McCORMICK® ",
            imagen: require('../Blog/img/Nota Parrillada para celebrar en familia con Grill Mates.jpg'),
            descripcion: (
                <div>
                    <p><span >&nbsp;</span></p>
                    <p><span >Se acerca el</span><strong> D&iacute;a del Padre</strong><span >, la excusa perfecta para celebrar con un fest&iacute;n al calor de la parrilla. Y qu&eacute; mejor que con el sabor parrillero de </span><strong>Grill Mates&reg;</strong><span >, el que lleva tus asados al siguiente nivel para festejarlo como se merece.</span></p>
                    <p><span >Ya sea que pap&aacute; quiera ser el "Parrillero Experto" para lucirse con su toque de sabor o que lo vayas a sorprender con la mejor </span><strong>carne asada</strong><span >, aqu&iacute; les damos algunas recomendaciones y </span><strong>recetas</strong><span > para que su </span><strong>parrillada</strong><span > quede INCRE&Iacute;BLE con </span><a href="https://www.mccormick.mx/productos/especias-y-condimentos/para-parrilla"><strong>McCormick&reg; Grill Mates&reg;</strong></a><span >.&nbsp;</span></p>
                    <p><span >&nbsp;</span></p>
                    <h2> Especias para condimentar la carne &nbsp;</h2>
                    <br />
                    <p><span >No le busques tres pies al gato, si no tienes ni idea de lo que significan palabras como &ldquo;marinar&rdquo;, &ldquo;granar&rdquo; o &ldquo;ali&ntilde;ar&rdquo;, lo &uacute;nico que debes hacer es prender la </span><strong>parrilla</strong><span >, ir al supermercado de tu preferencia y comprar la amplia variedad de </span><strong>Especias McCormick&reg; Grill Mates&reg;</strong><span >, seguir las instrucciones de uso y listo. &iexcl;M&aacute;ster parrillero al instante!</span></p>
                    <p><span >Nuestras combinaciones de especias est&aacute;n preparadas para que solo tengas que sazonar la carne, vegetales o cualquier otra prote&iacute;na por unos minutos y ponerlas al fuego como todo un parrillero, &iexcl;as&iacute; de f&aacute;cil! Con estas </span><strong>especias </strong><span >tus cortes y platillos a la parrilla obtienen sabores &uacute;nicos como: </span><a href="https://www.mccormick.mx/producto/sazonador-con-chipotle-y-ajo-a-la-parrilla"><span >Chipotle y ajo asado</span></a><span >, </span><a href="https://www.mccormick.mx/producto/sazonador-para-carne-tipo-montreal-picante"><span >tipo Montreal </span></a><span >y adem&aacute;s tambi&eacute;n hay para sazonar </span><a href="https://www.mccormick.mx/producto/sazonador-para-hamburguesa"><span >hamburguesas</span></a><span >.</span></p>
                    <br />
                    <h2> Las mejores guarniciones para tu carne asada &nbsp;</h2>
                    <br />
                    <p><span >T&uacute; mismo puedes ser quien consienta a pap&aacute; en este d&iacute;a, pues lo &uacute;nico que necesitas para esto y para llevar tus </span><strong>parrilladas</strong><span > al siguiente nivel es, echarle un vistazo a las siguientes recetas que preparamos especialmente para esta celebraci&oacute;n y sorprenderlo con tus habilidades culinarias.</span></p>
                </div>
            ),
            recetas: [
                {
                    imagen: require("./img/Camarones a la parrilla.jpg"),
                    texto: (
                        <div>
                            <h3>
                                Camarones a la parrilla con Especias McCormick®
                            </h3>
                            <br />
                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>30 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>24 camarones medianos pelados y desvenados</span></span>
                            <br />
                            <span><span>2 cucharadas de </span><strong>Sazonador Grill Mates&reg; Chipotle y ajo asado McCormick&reg;</strong><span> (Funciona con cualquier otro sazonador de la familia Grill Mates).</span></span>
                            <br />
                            <span><span>El jugo de medio lim&oacute;n</span></span>
                            <br />
                            <span><span>Medio lim&oacute;n amarillo cortado en peque&ntilde;as rebanadas</span></span>
                            <br />
                            <span><span>3 cucharadas de aceite de oliva</span></span>
                            <br />
                            <span><span>&frac14; de taza de </span><a href="https://franksredhot.com.mx/"><span>Salsa Frank's Red Hot</span></a></span>
                            <br />
                            <span><span>4 brochetas de madera de aprox 20 cm cada una</span></span>
                            <br />
                            <span><span>2 cucharadas de cilantro limpio finamente picado</span></span>
                            <br />
                            <span><span>&nbsp;</span></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <ul>
                                <li>En un taz&oacute;n mezcla los camarones con el Sazonador Grill Mates&reg; Chipotle y ajo McCormick&reg;, el jugo de lim&oacute;n, el aceite de oliva y la salsa Frank's. D&eacute;jalos reposar unos minutos mientras enciendes tu asador o grill casero.</li>
                                <li>Inserta los camarones en cada brocheta en partes iguales.</li>
                                <li>Cuando est&eacute; bien caliente la parrilla, &uacute;ntala con un poco de aceite con la ayuda de una brocha.</li>
                                <li>Asa las brochetas de camar&oacute;n por cada lado, hasta que est&eacute;n doraditas.</li>
                                <li>Asa las rebanadas de lim&oacute;n amarillo.</li>
                                <li>Sirve espolvoreado de cilantro y acompa&ntilde;a con m&aacute;s salsa Frank's. Tambi&eacute;n puedes espolvorear con m&aacute;s Sazonador Grill Mates&reg; McCormick&reg; al retirarlas de la parrilla.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Pechugas de pollo a la parrilla.webp"),
                    texto: (
                        <div>
                            <h3>
                                Pechugas de pollo a la parrilla con Grill Mates con Chilpotle y
                                Ajo McCormick®
                            </h3>
                            <strong>Rinde:</strong>
                            <span> 4 porciones</span>
                            <br />
                            <strong>Tiempo de preparaci&oacute;n: </strong>
                            <span>30 minutos</span>
                            <br />
                            <strong>Ingredientes:</strong>
                            <br />
                            <span>
                                4 pechugas medianas con piel, sin hueso y partidas por la
                                mitad a lo largo
                            </span>
                            <br />
                            <span>2 cucharadas de </span>
                            <strong>
                                Sazonador Grill Mates&reg; con Chipotle y Ajo McCormick&reg;
                            </strong>
                            <br />
                            <span>&frac14; de taza de agua con 4 cucharadas de </span>
                            <a href="https://www.mccormick.mx/productos/mostaza-amarilla">
                                <strong>Mostaza preparada McCormick</strong>
                            </a>
                            <strong>&reg;</strong>
                            <br />
                            <span>4 cucharadas de aceite vegetal</span>
                            <br />
                            <span>&nbsp;</span>
                            <br />
                            <strong>Procedimiento:</strong>
                            <br />
                            <ul>
                                <li>
                                    Sazona por ambos lados las pechugas de pollo con el
                                    Sazonador Grill Mates con Chipotle y Ajo McCormick&reg;.
                                </li>
                                <li>
                                    Mezcla la mostaza con el agua y el aceite. Marina las
                                    pechugas por unos minutos mientras enciendes el asador o un
                                    sart&eacute;n tipo grill casero.
                                </li>
                                <li>
                                    Asa cada pechuga a fuego medio por cada lado hasta que la
                                    piel est&eacute; dorada y crujiente. De vez en cuando
                                    hidrata o barniza las pechugas con el resto de la marinada.
                                </li>
                                <li>
                                    Retira del asador y rebana en tiras cada pechuga.
                                </li>
                                <li>
                                    Sirve caliente acompa&ntilde;ado con alguna pasta
                                    fr&iacute;a, pur&eacute; de papa o alguna ensalada verde.
                                </li>
                            </ul>
                            <br />
                            <strong>Tip:</strong>
                            <br />
                            <ul>
                                <li>
                                    Puedes preparar esta receta con pescado, camarones, carne de
                                    cerdo o incluso verduras. Si no consigues el Sazonador Grill
                                    Mates&reg; con Chipotle y Ajo McCormick&reg;, puedes
                                    utilizar cualquier otro sazonador de la familia
                                    <strong> Grill Mates</strong>
                                    &reg; y ver&aacute;s c&oacute;mo las especias les
                                    dar&aacute;n un toque diferente y saludable a tus asados.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Costillar de cerdo.webp"),
                    texto: (
                        <div>
                            <h3>
                                Costillar de cerdo con Sazonador Grill Mates® Montreal Steak
                                McCormick®
                            </h3>
                            <br />
                            <strong>Rinde:</strong>
                            <span> 4 porciones</span>
                            <br />
                            <strong>Tiempo de preparaci&oacute;n: </strong>
                            <span>60 minutos</span>
                            <br />
                            <strong>Ingredientes:</strong>
                            <br />
                            <span>
                                1.5 kilos de costillas de cerdo peque&ntilde;as (puedes
                                pedirlo como pecho de cerdo con costillas)
                            </span>
                            <br />
                            <span>Suficiente agua</span>
                            <br />

                            <span>2 cucharadas de</span>
                            <strong>
                                {" "}
                                Sazonador Grill Mates&reg; Montreal Steak McCormick&reg;
                            </strong>
                            <br />
                            <span>
                                &frac12; taza de jugo de naranja natural sin semillas
                            </span>
                            <br />
                            <span>3 limones</span>
                            <br />
                            <span>1 cucharadita de </span>
                            <a href="https://www.mccormick.mx/producto/ajo-en-polvo">
                                <span>
                                    Ajo en Polvo McCormick&reg;
                                </span>
                            </a>
                            <br />
                            <span>1 cucharadita de </span>
                            <a href="https://www.mccormick.mx/producto/%C3%B3regano">
                                <span>
                                    Or&eacute;gano McCormick&reg;
                                </span>
                            </a>
                            <br />
                            <span>
                                3 cucharadas de aceite vegetal.
                            </span>
                            <br />
                            <span>&nbsp;</span>
                            <br />
                            <strong>Procedimiento:</strong>
                            <br />
                            <ul>
                                <li>
                                    Divide el costillar en dos partes y col&oacute;calos en una
                                    olla con agua hasta cubrir la carne.
                                </li>
                                <li>
                                    Cocina la carne por 40 minutos a fuego bajo y tapa la olla.
                                    Pasado este tiempo, desecha el agua.
                                </li>
                                <li>
                                    Espolvorea el costillar con Sazonador Grill Mates&reg;
                                    Montreal Steak McCormick&reg;, jugo de naranja, jugo de
                                    lim&oacute;n, ajo en polvo, or&eacute;gano y aceite vegetal.
                                </li>
                                <li>
                                    Deja reposar el costillar en este marinado mientras
                                    enciendes el asador, parrilla o un sart&eacute;n tipo grill
                                    casero.
                                </li>
                                <li>
                                    A fuego suave, asa las costillas por cada lado y de vez en
                                    vez barniza o unta un poco del marinado. Coc&iacute;nalas
                                    por ambos lados hasta que est&eacute;n bien doraditas.
                                </li>
                            </ul>
                            <br />
                            <strong>Tips:</strong>
                            <br />
                            <ul>
                                <li>
                                    Al cocinar en agua piezas grandes como el cerdo, no
                                    perder&aacute;n sabor; quedar&aacute;n suavecitas y jugosas
                                    a la hora de asarlas.
                                </li>
                                <li>
                                    Si tienes un asador profesional, entonces t&aacute;palas con
                                    papel aluminio, para que el calor seco genere vapor dentro
                                    de este y queden jugosas.
                                </li>
                            </ul>
                            <span>
                                De parte de la familia de{" "}
                            </span>
                            <strong>Especias McCormick&reg; Grill Mates&reg;</strong>
                            <span>
                                , les deseamos un Feliz D&iacute;a del Padre.&nbsp;
                            </span>
                            <br />
                        </div>
                    ),
                },
            ],
            foter: <div>
                <p><span>De parte de la familia de </span><strong>Especias McCormick&reg; Grill Mates&reg;</strong><span>, les deseamos un Feliz D&iacute;a del Padre. </span></p>
            </div>,
        },
        {
            nombre: "recetas-con-mayonesas-de-sabores",
            titulo:
                "En estas vacaciones, transforma el sabor de tus antojos con Mayosabores McCORMICK® ",
            imagen: require('../Blog/img/Nota transforma el sabor de tus antojos con Mayosabores.png'),
            descripcion: (
                <div>
                    <p><span>Se acerca el descanso de verano y con &eacute;l la oportunidad de salir de la rutina gastron&oacute;mica... y claro que en McCormick&reg; tenemos la opci&oacute;n para que disfrutes al m&aacute;ximo tus antojos veraniegos.</span></p>
                    <br />
                    <h2> Un mayosabor para cada ocasi&oacute;n &nbsp;</h2>
                    <br />
                    <p><span>Seguramente ya conoces nuestros, </span><strong>Mayosabores McCormick </strong><span>y si no, ac&aacute; te presentamos algunas opciones para que vayas viendo c&oacute;mo y con qui&eacute;n las probar&aacute;s:</span></p>
                    <p><span>Si quieres Sabor BBQ al instante con la combinaci&oacute;n perfecta entre dulce y ahumado, nuestro</span> <a href="https://www.mccormick.mx/producto/aderezo-de-mayonesa-sabor-bbq"><strong>Aderezo de Mayonesa sabor BBQ</strong> </a><span>es para ti. Ideal para darle sabor a las vacaciones.</span></p>
                    <p><span>&iquest;Tardecita para ponerse al tanto con las amigas o reuni&oacute;n para ver el partido con los amigos? P&oacute;nganle lo sabroso a la velada con </span><a href="https://www.mccormick.mx/producto/aderezo-de-mayonesa-sabor-guacamole"><strong>Aderezo de Mayonesa sabor Guacamole</strong></a><span> y acomp&aacute;&ntilde;enla con unos crujientes totopos. Te sorprender&aacute;s con su cremoso sabor.</span></p>
                    <p><span>&iquest;Te quedas en casa, pero quieres transformar el sabor de tus comidas? La respuesta es </span><a href="https://www.mccormick.mx/producto/aderezo-de-mayonesa-con-chilpotle"><strong>Aderezo de Mayonesa sabor</strong> <strong>Chilpotle</strong></a><span>. Reinventa la forma de disfrutar, pescado, carne, pastas o hasta alg&uacute;n snack con el saborcito picos&oacute;n del chile chipotle, pero con toda la cremosidad y textura que solo McCormick te puede dar. Estamos seguros que ser&aacute; tu nuevo amor de verano sin salir de tu hogar.</span></p>
                    <p><span>Y cuando se acaben las vacaciones, dale al s&aacute;ndwich de los ni&ntilde;os y a tus bocadillos un nuevo toque de sabor con la textura cremosita que </span><a href="https://www.mccormick.mx/producto/aderezo-de-mayonesa-para-tortas-y-s%C3%A1ndwiches"><strong>Aderezo de Mayonesa para Tortas y S&aacute;ndwiches</strong></a><span>, tiene para ti.</span></p>
                    <p><span>As&iacute; que ya lo sabes, estas vacaciones renueva sensaciones en tu cocina y transforma el sabor con </span><strong>Mayosabores McCormick&reg;</strong><span>, una nueva forma de comer tus platillos favoritos.</span></p>
                    <br />
                    <h2> Experimenta con cada mayosabor &nbsp;</h2>
                    <br />
                    <p><span>Aqu&iacute; te compartimos algunas recetas para que puedas disfrutar estas vacaciones:</span></p>
                </div>
            ),
            recetas: [
                {
                    imagen: require("./img/Pan Tostado a la Parrilla con Guacamole.webp"),
                    texto: (
                        <div>
                            <h3>
                                Pan Tostado a la Parrilla con Aderezo de Mayonesa McCormick® Guacamole
                            </h3>
                            <br />
                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>15 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>4 rebanadas de pan integral r&uacute;stico cortado en rebanadas</span></span>
                            <br />
                            <span><span>4 cucharaditas de mantequilla</span></span>
                            <br />
                            <span><span>1 frasco de 340 gr de </span><strong>Aderezo de Mayonesa McCormick&reg; Guacamole</strong></span>
                            <br />
                            <span><span>1 chile serrano finamente picado</span></span>
                            <br />
                            <span><span>1 cucharada de aceite de oliva</span></span>
                            <br />
                            <span><span>200 gr de champi&ntilde;ones rebanados (puedes utilizar hongos de temporada de verano como shiitake, cremini o portobello)</span></span>
                            <br />
                            <span><span>1 cucharada de salsa de soya baja en sodio</span></span>
                            <br />
                            <span><span>1 lim&oacute;n (el jugo)</span></span>
                            <br />
                            <span><span>1 cucharada de semillas de ajonjol&iacute; para espolvorear</span></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <ul>
                                <li><span>Unta cada rebanada de pan con mantequilla y col&oacute;calo en un sart&eacute;n tipo grill para que se tueste.</span></li>

                                <li><strong><span>Mezcla el Aderezo de Mayonesa McCormick&reg; Guacamole con el chile serrano, y unta cada rebanada de pan con una porci&oacute;n generosa.</span></strong></li>
                                <li><span>Calienta un sart&eacute;n con el aceite de oliva, saltea los champi&ntilde;ones, agrega la salsa de soya y el jugo de lim&oacute;n.</span></li>
                                <li><span>Sirve los champi&ntilde;ones distribuidos sobre cada rebanada de pan y espolvorea con ajonjol&iacute;.</span></li>
                            </ul>
                            <p><strong>Tip:</strong></p>
                            <ul>
                                <li><span>Le puedes a&ntilde;adir tiras de pollo a la parrilla, fajitas de carne de res, camarones a la parrilla o queso panela asado. Es ideal para que lo lleves a la playa o de paseo al campo.</span></li>
                            </ul>
                            <p><br /><br /></p>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Burrito de carne asada con Mayonesa.webp"),
                    texto: (
                        <div>
                            <h3>
                                Burrito de carne asada con Aderezo de Mayonesa McCormick® Tortas y Sándwiches
                            </h3>
                            <br />
                            <span><strong>Rinde:</strong><span > 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span >25 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span >4 tortillas de harina para burritos</span></span>
                            <br />
                            <span><span >4 bisteces de res muy suaves, aplanados y cortados en tiras delgadas</span></span>
                            <br />
                            <span><span >1 frasco de 345 gr de </span><strong>Aderezo de Mayonesa McCormick&reg; Tortas y S&aacute;ndwiches</strong></span>
                            <br />
                            <span><span >1 cebolla blanca mediana cortada en tiras delgadas</span></span>
                            <br />
                            <span><span >2 cucharadas de aceite vegetal</span></span>
                            <br />
                            <span><span >1 taza de queso tipo Oaxaca deshebrado</span></span>
                            <br />
                            <span><span >1 taza de hojas de lechuga cortadas en tiras delgadas</span></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <br />
                            <ul>
                                <li ><span >En un taz&oacute;n, combina 2 cucharadas del Aderezo de Mayonesa McCormick&reg; Tortas y S&aacute;ndwiches</span> <span >con la carne y la cebolla.</span></li>
                                <li ><span >Calienta el aceite en un sart&eacute;n y fr&iacute;e la carne con la cebolla hasta que est&eacute;n doradas.</span></li>
                                <li ><span >Agrega el queso para que se gratine.</span></li>
                                <li ><span >Calienta las tortillas de harina, coloca una porci&oacute;n generosa del Aderezo de Mayonesa McCormick&reg; Tortas y S&aacute;ndwiches,</span> <span >encima coloca la carne con el queso y la lechuga.&nbsp;</span></li>
                                <li ><span >Enrolla cada burrito y disfruta caliente o tibio.</span></li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Taquitos norteños con mayonesa.webp"),
                    texto: (
                        <div>
                            <h3>
                                Taquitos Norteños con Aderezo de Mayonesa BBQ McCormick®
                            </h3>
                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>25 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>8 tortillas de harina muy delgadas</span></span>
                            <br />
                            <span><span>8 rebanadas de queso tipo manchego o asadero</span></span>
                            <br />
                            <span><span>1 taza de carne de res cocida y deshebrada</span></span>
                            <br />
                            <span><span>1 Frasco de 345 gr de</span><strong> Aderezo de Mayonesa BBQ McCormick&reg;</strong></span>
                            <br />
                            <span><span>&frac12; taza de aceite para fre&iacute;r</span></span>
                            <br />
                            <span><span>&frac12; taza de lechuga cortada en tiras delgadas</span></span>
                            <br />
                            <span><span>4 rabanitos cortados en rodajas muy delgadas</span></span>
                            <br />
                            <span><span>&frac12; taza de queso fresco rallado para espolvorear</span></span>
                            <br />
                            <span><span>&frac14; de taza de crema &aacute;cida</span></span>
                            <br />
                            <span><span>Palillos: cantidad suficiente para sujetar los tacos</span></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <br />
                            <ul>
                                <li><span>Mezcla en un taz&oacute;n la carne deshebrada con 4 cucharadas del Aderezo de Mayonesa BBQ McCormick&reg;.</span></li>
                                <li><span>Rellena cada tortilla de harina con una porci&oacute;n de la preparaci&oacute;n de carne y una rebanada de queso. Enrolla y aprieta muy bien la tortilla, puedes sujetarla con un palillo para que no se abra cuando las fr&iacute;as. Repite la operaci&oacute;n hasta terminar todas las tortillas.</span></li>
                                <li><span>Calienta el aceite a fuego medio y fr&iacute;e los tacos por ambos lados hasta que est&eacute;n doraditos. Esc&uacute;rrelos en papel absorbente.</span></li>
                                <li><span>Mezcla la crema &aacute;cida con el resto del Aderezo de Mayonesa BBQ McCormick&reg;.</span></li>
                                <li><span>Sirve los tacos con lechuga, rabanitos, queso y una porci&oacute;n generosa del Aderezo de Mayonesa BBQ McCormick&reg;.</span></li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Pollo frito con Mayonesa de Chipotle.webp"),
                    texto: (
                        <div>
                            <h3>
                                Pollo frito con Aderezo de Mayonesa Chilpotle McCormick®
                            </h3>
                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>45 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>4 piernas de pollo con muslo corte americano con piel&nbsp;</span></span>
                            <br />
                            <span><span>&frac12; taza de jugo de naranja sin semillas</span></span>
                            <br />
                            <span><span>2 limones (el jugo)</span></span>
                            <br />
                            <span><span>1 cucharadita de </span><a href="https://www.mccormick.mx/producto/pimienta-negra-molido-grueso"><strong>Pimienta Negra Molida McCormick</strong></a><strong>&reg;</strong></span>
                            <br />
                            <span><span>1 cucharadita de Sal con </span><a href="https://www.mccormick.mx/producto/ajo-en-polvo"><strong>Ajo McCormick</strong></a><strong>&reg;</strong></span>
                            <br />
                            <span><span>1 Frasco de 345 gr de</span><strong> Aderezo de Mayonesa Chilpotle McCormick&reg;</strong></span>
                            <br />
                            <span><span>2 cucharadas de leche evaporada</span></span>
                            <br />
                            <span><span>500 ml de aceite para fre&iacute;r</span></span>
                            <br />
                            <span><span>1 cucharada de harina</span></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <br />
                            <ul>
                                <li><span>En un taz&oacute;n, combina el jugo de naranja, lim&oacute;n, pimienta negra, sal con ajo y 2 cucharadas soperas del Aderezo de Mayonesa Chilpotle McCormick&reg;.</span></li>
                                <li><span>A&ntilde;ade el pollo y d&eacute;jalos reposar 20 minutos.</span></li>
                                <li><span>Retira el l&iacute;quido y agrega la cucharada de harina.</span></li>
                                <li><span>Calienta el aceite a fuego medio y cuando est&eacute; caliente, fr&iacute;e el pollo por partes hasta que est&eacute; bien doradito y crujiente, aproximadamente unos 12 minutos de cada lado.</span></li>
                                <li><span>Escurre el pollo en papel absorbente.</span></li>
                                <li><span>Combina el resto del Aderezo de Mayonesa Chilpotle McCormick&reg;, con la leche evaporada, para que est&eacute; ligerito y puedas ba&ntilde;ar cada pieza de pollo.</span></li>
                                <li><span>Sirve con ensalada verde.</span></li>
                            </ul>
                            <p><strong>Tips:</strong><span>&nbsp;</span></p>
                            <ul>
                                <li><span>Cuando fr&iacute;as piezas de pollo o pescado, a&ntilde;ade harina o f&eacute;cula de ma&iacute;z, esto evitar&aacute; que te salpique por la humedad y queden crocantes.&nbsp;</span></li>
                                <li><span>Recuerda fre&iacute;r en aceite a fuego medio no muy caliente, de lo contrario quedar&aacute; dorado por fuera y crudo por dentro.&nbsp;</span></li>
                                <li><span>Tambi&eacute;n lo puedes hacer horneado en olla de aire caliente.</span></li>
                                <li><span>Si vas a la playa puedes envolver en papel de aluminio este pollo o guardarlo en una nevera de unicel, para que se conserve caliente a la hora de comer.</span></li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "especias-para-antojitos-mexicanos",
            titulo:
                "Ponle sazón a tu Fiesta Mexicana con especias McCormick",
            imagen: require('../Blog/img/Nota Fiesta Mexicana con especias McCormick.jpg'),
            descripcion: (
                <div>
                    <p><span>Las fiestas patrias est&aacute;n muy cerca. A nosotros los mexicanos nos gusta ponernos festivos y celebrar preparando todo tipo de </span><strong>antojitos</strong><span> en el mes de septiembre. En </span><strong>McCormick</strong><span> sabemos muy bien que uno de los secretos para una deliciosa </span><strong>cena mexicana</strong><span>, es dar la mejor saz&oacute;n a la comida.&nbsp;</span></p>
                    <h2> Especias que puedes usar en antojitos mexicanos &nbsp;</h2>
                    <p><span>Un condimentado adecuado puede hacer la diferencia entre una comida deliciosa y una experiencia inolvidable para los invitados. Por eso, no le restes importancia y acompa&ntilde;a todos tus platillos con nuestra variedad de </span><strong>Especias McCormick</strong><span>.</span></p>
                    <p><span>Por ejemplo, el sabor de una picosita y bien sazonada salsa puede potenciar el sabor de cualquier comida. Nada como el delicioso </span><strong>pozole </strong><span>que puedes acompa&ntilde;ar con nuestro sabroso y arom&aacute;tico </span><a href="https://www.mccormick.mx/producto/%C3%B3regano"><span>Or&eacute;gano McCormick</span></a><span>, el cual le dar&aacute; ese extra de sabor a uno de los platillos estrella de la noche.&nbsp;</span></p>
                    <p><span>&iquest;Qu&eacute; tal unas </span><strong>fajitas de pollo y arrachera </strong><span>preparadas con nuestro </span><a href="https://www.mccormick.mx/producto/sazonador-sabroseador"><span>Sazonador Sabroseador</span></a><span>? Ideales para preparar unos deliciosos tacos.&nbsp;</span></p>
                    <p><span>&iquest;O qu&eacute; tal una </span><strong>tinga </strong><span>bien sazonada con </span><a href="https://www.mccormick.mx/producto/pimienta-negra-molida"><span>Pimienta Negra </span></a><span>y nuestra inconfundible </span><a href="https://www.mccormick.mx/producto/sal-con-cebolla"><span>Sal con Cebolla</span></a><span>? Para satisfacer ese antojito de tostadas.</span></p>
                    <p><span>Las posibilidades son infinitas y es que la </span><strong>cocina mexicana </strong><span>lleva tradici&oacute;n impregnada en sus sabores y que nosotros experimentamos con el paladar y nos llevan a viajar por el tiempo. Como el tradicional y exquisito </span><strong>mole</strong><span>, que con su variedad de chiles, semillas y </span><strong>especias</strong><span> que nos transportan a la &eacute;poca prehisp&aacute;nica.</span></p>
                    <p><span>Otro m&aacute;s, es el ingenioso </span><strong>chile en nogada</strong><span>, inventado en el convento de Santa M&oacute;nica en Puebla, en el ya lejano 1821, por las monjas agustinas en honor del Ej&eacute;rcito Trigarante; un chile poblano relleno de picadillo y ba&ntilde;ado en salsa de nogada, que es una curiosa combinaci&oacute;n de crema, nuez y canela, acompa&ntilde;ado de perejil y granos de granada.</span></p>
                    <br />
                    <h2> La mejor receta mexicana con especias para celebrar &nbsp;</h2>
                    <br />
                    <p><span>Queremos que tu fiesta mexicana sea una experiencia gastron&oacute;mica inolvidable, que cuando disfrutes tu cena conozcas nuestras tradiciones, que lo que sea que prepares haga que tus invitados se chupen los dedos y pidan hasta para llevar. Por eso, te compartimos estas deliciosas recetas que puedes preparar f&aacute;cil, r&aacute;pido y que har&aacute;n que tus invitados quieran celebrar las </span><strong>fiestas patrias</strong><span> contigo cada a&ntilde;o.</span></p>
                    <br />
                    <p><span>Estos </span><strong>antojitos mexicanos</strong><span> puedes sazonarlos en su totalidad, por supuesto, con nuestra gran variedad de </span><a href="https://www.mccormick.mx/productos/especias-y-condimentos"><span>especias McCormick</span></a><span>.</span></p>
                </div>
            ),
            recetas: [
                {
                    imagen: require("./img/Flautas ahogadas callejeras.webp"),
                    texto: (
                        <div>
                            <h3>
                                Flautas ahogadas callejeras con Especias McCormick®
                            </h3>
                            <br />
                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>25 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>500 gr de arrachera marinada cortada en tiras muy delgadas</span></span>
                            <br />
                            <span><span>&frac12; cucharadita de </span><strong>Pimienta negra molida McCormick&reg;</strong></span>
                            <br />
                            <span><span>1 cucharadita de </span><strong>Or&eacute;gano McCormick&reg;</strong></span>
                            <br />
                            <span><span>1 cucharadita de </span><strong>Sazonador McCormick&reg; Sabroseador</strong></span>
                            <br />
                            <span><span>8 tortillas de ma&iacute;z</span></span>
                            <br />
                            <span><span>8 palillos de madera</span></span>
                            <br />
                            <span><span>&frac12; taza de aceite para fre&iacute;r</span></span>
                            <br />
                            <span><strong>Para la salsa verde:</strong></span>
                            <br />
                            <span><span>500 gr de tomate verde</span></span>
                            <br />
                            <span><span>1 cucharadita de </span><a href="https://www.mccormick.mx/producto/cebolla-en-polvo"><strong>Cebolla en polvo</strong> <strong>McCormick&reg;</strong></a></span>
                            <br />
                            <span><span>3 chiles serranos enteros sin tallo</span></span>
                            <br />
                            <span><span>2 tazas de agua</span></span>
                            <br />
                            <span><span>2 cucharadas de hojas de cilantro</span></span>
                            <br />
                            <span><span>1 cucharada de aceite vegetal</span></span>
                            <br />
                            <span><span>&frac12; cucharadita de </span><a href="https://www.mccormick.mx/producto/comino-molido"><strong>Comino molido McCormick&reg;</strong></a></span>
                            <br />
                            <span><span>1 cucharadita de </span><a href="https://www.mccormick.mx/producto/sal-con-ajo"><strong>Sal con ajo McCormick&reg;</strong></a></span>
                            <br />
                            <span><strong>Para servir:</strong></span>
                            <br />
                            <span><span>4 vasos desechables puedes usar t&eacute;rmicos o vasitos caseros.</span></span>
                            <br />
                            <span><span>&frac12; taza de queso blanco fresco rallado.</span></span>
                            <br />
                            <span><span>&frac12; taza de crema &aacute;cida.</span></span>
                            <br />
                            <span><span>1 cucharadita de </span><strong>Or&eacute;gano molido McCormick&reg;.</strong></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <br />
                            <ul>
                                <li>Coloca 1 cucharada de aceite en un sart&eacute;n y calienta a fuego medio.</li>
                                <li>Agrega la arrachera y sazona con Pimienta negra molida McCormick&reg;, Or&eacute;gano McCormick&reg; y Sazonador McCormick&reg; Sabroseador. Cocina hasta que est&eacute; dorada.&nbsp;</li>
                                <li>Retira del fuego y rellena cada tortilla con una porci&oacute;n, enrolla y sujeta con 2 palillos para evitar que se abran las flautas.</li>
                                <li>Fr&iacute;e las flautas en aceite bien caliente. Una vez doradas, escurre en papel absorbente.</li>
                            </ul>
                            <span><strong>Para la salsa verde:</strong></span>
                            <ul>
                                <li>Coloca en una olla los tomates, la cebolla, los chiles serranos y las 2 tazas de agua. Cocina hasta que est&eacute;n suaves los tomates y chiles.</li>
                                <li>Retira del fuego y deja enfriar unos minutos. Muele en la licuadora con el cilantro, Comino molido McCormick&reg;, Sal con ajo McCormick&reg;, hasta obtener una salsa verde bien molida.</li>
                            </ul>
                            <ul>
                                <li><span>Calienta el aceite de una olla y fr&iacute;e la salsa, deja hervir unos minutos.</span></li>
                            </ul>
                            <span><strong>Para servir:</strong></span>
                            <ul>
                                <li>Coloca en cada vaso una porci&oacute;n generosa de salsa verde, reparte en partes iguales las flautas, agrega crema, queso blanco rallado y espolvorea con el Or&eacute;gano molido McCormick&reg;</li>
                            </ul>
                            <p><strong>Tips:</strong></p>
                            <ul>
                                <li>Puedes rellenar tus flautas con pechuga de pollo cocida o picadillo de carne.</li>
                                <li>A&ntilde;ade comino y or&eacute;gano McCormick&reg; a tus antojitos mexicanos para darle un sabor m&aacute;s definido y arom&aacute;tico a las salsas mexicanas.</li>
                            </ul>
                            <p><br /><br /><br /><br /></p>
                        </div>
                    ),
                },
                {
                    imagen: require("./img/Quesadillas enchiladas.webp"),
                    texto: (
                        <div>
                            <h3>
                                Quesadillas enchiladas con Especias McCormick®
                            </h3>
                            <br />

                            <span><strong>Rinde:</strong><span> 4 porciones</span></span>
                            <br />
                            <span><strong>Tiempo de preparaci&oacute;n: </strong><span>30 minutos</span></span>
                            <br />
                            <span><strong>Ingredientes:</strong></span>
                            <br />
                            <span><span>500 gramos de masa de ma&iacute;z nixtamalizado</span></span>
                            <br />
                            <span><span>&frac14; de taza de harina de trigo</span></span>
                            <br />
                            <span><span>2 cucharadas de chile chipotle adobado molido</span></span>
                            <br />
                            <span><span>1 cucharadita de aceite vegetal</span></span>
                            <br />
                            <span><span>&frac12; cucharadita de </span><strong>Sazonador McCormick&reg; Sabroseado</strong></span>
                            <br />
                            <span><span>1 sobre de Guisado de At&uacute;n Herdez sabor Tinga&reg;</span></span>
                            <br />
                            <span><span>500 mililitros de aceite vegetal para fre&iacute;r</span></span>
                            <br />
                            <span><span>2 tazas de col cortada en tiras muy delgadas y desflemadas con agua hirviendo por 15 segundos</span></span>
                            <br />
                            <span><span>1 taza de cebolla morada cortada en tiras delgadas y desflemadas en agua hirviendo por 15 segundos</span></span>
                            <br />
                            <span><span>&frac14; de cucharadita de </span><strong>Pimienta negra molida McCormick&reg;</strong></span>
                            <br />
                            <span><span>&frac12; cucharadita de </span><strong>Or&eacute;gano molido McCormick&reg;</strong></span>
                            <br />
                            <span><strong>Procedimiento:</strong></span>
                            <br /><ul>
                                <li><span>En un taz&oacute;n combina la masa de ma&iacute;z, harina de trigo, chile chipotle adobado, la cucharadita de aceite vegetal y el Sazonador McCormick&reg; Sabroseador. Amasa muy bien hasta obtener una masa manejable. Si estuviera muy seca la masa, puedes a&ntilde;adir 2 cucharadas de agua a temperatura ambiente.</span></li>
                                <li><span>Forma tortillas peque&ntilde;as de unos 8 a 10 cm de di&aacute;metro, y rell&eacute;nalas con una porci&oacute;n del Guiso de At&uacute;n Herdez sabor Tinga&reg;.</span></li>
                                <li><span>Calienta el aceite vegetal a fuego medio, cuando est&eacute; bien caliente, fr&iacute;e poco a poco las quesadillas, hasta que est&eacute;n doradas. Esc&uacute;rrelas en papel absorbente.</span></li>
                                <li><span>Sirve calientes y acompa&ntilde;a con col y cebolla morada encurtida, guacamole y rabanitos.</span></li>
                            </ul>
                            <span><strong>Tips:</strong></span>
                            <ul>
                                <li><span>Agrega agua hirviendo a la col y la cebolla morada por 15 segundos, esc&uacute;rrelas y enju&aacute;galas con agua bien fr&iacute;a con hielo. De esta manera lograr&aacute;s sacarles el amargor y sobre todo los gases a la col. Exprime el jugo de un lim&oacute;n, sal, Pimienta Negra Molida McCormick&reg; y Or&eacute;gano Molido McCormick&reg;. Le dar&aacute;n un acompa&ntilde;amiento acidito, arom&aacute;tico y crujiente a tus antojitos mexicanos.</span></li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "tips-disfrutar-nfl-mucho-sabor",
            titulo: "Tips para disfrutar de la NFL con mucho sabor ",
            imagen: imgbnr15,
            descripcion: (
                <div>
                    El fútbol es de los deportes que casi nadie se pierde y, sin
                    importar si se es aficionado o no, éste siempre sirve de pretexto
                    para reunirnos con los amigos y familia. Pero también es un buen
                    motivo para sorprender, porque ya sea que la reunión se lleve a
                    cabo en tu casa o en la de alguien más, se puede preparar un
                    platillo que deje a todos los invitados con un buen sabor de boca.
                    <br />
                    <br />
                    En estos meses donde el año casi termina, abundan los deportes, no
                    sólo el fútbol, también está el americano, el béisbol y otros que
                    se pueden disfrutar con una botana para acompañar el momento. Hay
                    algunas botanas que ya se han vuelto clásicas, como el guacamole,
                    pero este mundo de antojos también abre espacio a la creatividad
                    para estimular el paladar y ponerle adrenalina a la cocina, como a
                    cada partido.
                    <br />
                    <br />
                    <h2> Los clásicos que te harán ganar </h2>
                    Entre las botanas más comunes, están infinidad de recetas de
                    alitas de pollo, preparadas con salsas o <b>mermeladas</b>, o
                    combinaciones como mango chipotle, piña con habanero, adobadas o
                    agridulces. También tenemos platillos como los dips, recetas de
                    verduras, papas, pico de gallo, brochetas, chicharrón, sándwiches
                    y un sinfín de preparaciones para todos los gustos. El chiste es
                    poner la creatividad en su máxima expresión, y jugar con{" "}
                    <b>combinaciones de especias</b>, aderezos e ingredientes que le
                    darán un toque único a cada reunión.
                    <br />
                    <br />
                    Otro lado clásico de los deportes son los asados, donde también
                    hay mucha variedad de platillos con los cuales experimentar y
                    existe cada vez más creatividad. Por ejemplo, un delicioso filete
                    a la parrilla con tomates asados y un{" "}
                    <b>sazonador con especias</b> y picante, ¡seguro ya se te antojó!
                    Lo mejor es que hay para todos los gustos y muchísimas técnicas
                    diferentes, como barbacoas americanas, asados, postres y claro,
                    diferentes especias y sazonadores para las estrellas de la
                    parrilla. El cielo es el límite.
                    <br />
                    <br />
                    <h2> Apoya a tu equipo como el mejor aficionado </h2> <br />
                    <br />
                    No hay deportes sin algo que botanear con los amigos y la familia,
                    son momentos de celebración y hasta nos vestimos con la camiseta
                    del equipo al que le vamos. Sin embargo, el apetito por un
                    bocadillo y una bebida no puede faltar. En algunas casas las
                    personas se preparan con antelación para comprar los ingredientes
                    y poder preparar los antojitos y la carne para el asado, y las
                    bebidas que le gustan a sus invitados.
                    <br />
                    <br />
                    Y si nuestro equipo predilecto gana, hay que festejar cocinando en
                    casa nuestro platillo favorito y hacer la sobremesa. No te
                    preocupes después de una semana de trabajo, vale la pena relajarte
                    viendo tu partido favorito en casa cocinando recetas fáciles. Solo
                    abre tu refrigerador, alacena y echa a volar tu imaginación.
                    <br />
                    <br />A continuación, te damos algunos tips y recetas sencillas
                    para que disfrutes todos los partidos de tu deporte favorito con
                    deliciosas botanas.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg51,
                    texto: (
                        <div>
                            <h3> Tiras de Pollo a la Parrilla </h3>
                            <br />
                            <b>Porciones</b>:4
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            4 milanesas de pollo aplanadas y cortadas en tiras de 4 cm de
                            grosor.
                            <br />
                            3 cdas. de aceite vegetal.
                            <br />1 cda. de <b>Mostaza amarilla McCormick®.</b>
                            <br />1 cda. de{" "}
                            <b>Sazonador para pollo tipo Montreal McCormick®.</b>
                            <br />
                            1 cda. de Miel de abeja Carlota.
                            <br />
                            1 cda. de salsa de soya baja en sodio.
                            <br />
                            2 cda. de jugo de limón.
                            <br />
                            4 cebollitas cambray con el tallo, limpias y cortadas en tiras
                            delgadas de 2cm de largo.
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    En un tazón combina el aceite, la mostaza, el sazonador,
                                    la miel, la salsa de soya y el jugo de limón.
                                </li>
                                <li>
                                    Agrega las tiras de pollo dentro de esta preparación y
                                    déjalas marinar por unos minutos. Mientras, calienta un
                                    sartén tipo grill para asar.
                                </li>
                                <li>
                                    Cocínalas a fuego medio-alto por ambos lados, barnizando
                                    de vez en cuando con la preparación de la marinada.
                                    Rectifica la sazón.
                                </li>
                                <li>
                                    Sírvelas calientes, espolvoreadas con la cebollita
                                    cambray.
                                </li>
                            </ul>
                            <b>Nota:</b> Recuerda que los sazonadores tienen sal y la
                            salsa de soya también, para que cuides de no salar tu comida.
                        </div>
                    ),
                },
                {
                    imagen: recetasimg50,
                    texto: (
                        <div>
                            <h3> Dip para Botana</h3>
                            <br />
                            <b>Porciones</b>:6
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            200 g de tocino bien dorado y picado finamente.
                            <br />
                            50 g de queso crema a temperatura ambiente.
                            <br />1 tz de{" "}
                            <b>
                                Mayonesa con jugo de limón McCormick® o Mayonesa Balance
                                McCormick® con aceite de ajonjolí.
                            </b>
                            <br />
                            1 cdita. de salsa tipo inglesa.
                            <br />
                            ¼ de tz de chiles jalapeños en vinagre, escurridos y picados
                            en cuadritos.
                            <br />
                            2 cebollitas cambray con tallo, limpias y picadas finamente.
                            <br />
                            200 g de queso amarillo tipo cheddar o queso Gouda rallado.
                            <br />
                            100 g de almendras enteras tostadas y picadas en trozos
                            regulares.
                            <br />¼ de cdita. de <b>Pimienta negra molida McCormick®.</b>
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    En un tazón, combina el queso crema con la mayonesa y
                                    mezcla muy bien para integrar.{" "}
                                </li>
                                <li>
                                    Agrega los demás ingredientes, mezcla bien y refrigera tu
                                    dip por 1 hora para que se concentren los sabores.
                                </li>
                                <li>
                                    Acompaña con papas fritas, tostadas de maíz o galletas
                                    saladas.
                                </li>
                            </ul>
                            <b>Nota:</b> Recuerda que los sazonadores tienen sal y la
                            salsa de soya también, para que cuides de no salar tu comida.
                        </div>
                    ),
                },
                {
                    imagen: recetasimg49,
                    texto: (
                        <div>
                            <h3> Bolitas de Pollo Búfalo </h3>
                            <br />
                            <b>Porciones</b>: 20
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            500 g de carne de pollo molida o una pechuga mediana sin hueso
                            picada finamente. <br />
                            ¾ de tz. de pan molido.
                            <br />½ cdita. de <b>Ajo en polvo McCormick®.</b>
                            <br />½ cdita. de <b>Pimienta negra molida McCormick®.</b>
                            <br />½ cdita. de <b>Cebolla en polvo McCormick®.</b>
                            <br />
                            2 cebollitas cambray con tallo picadas finamente.
                            <br />
                            ½ cdita. de sal. <br />
                            ½ cdita. de salsa inglesa.
                            <br />
                            3 cda. de aceite vegetal.
                            <br />
                            1 tz. de Mayonesa McCormick® light con jugo de limón.
                            <br />
                            1 tz. de Salsa Frank’s Red Hot Original.
                            <br />
                            20 palillos para bocadillos.
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    Mezcla la carne de pollo con el pan molido, el ajo en
                                    polvo, la pimienta negra molida, la cebolla en polvo, la
                                    cebollita cambray, sal y salsa inglesa. Mezcla muy bien
                                    para formar una masa.
                                </li>
                                <li>
                                    Unta tus manos con unas gotas de aceite y forma bolitas
                                    tamaño bocado.{" "}
                                </li>
                                <li>
                                    Calienta el aceite en una sartén antiadherente, y cocina
                                    las bolitas por cada lado hasta quedar doradas.
                                </li>
                                <li>
                                    Coloca todas las bolitas en la misma sartén y vierte la
                                    taza de salsa tipo búfalo. Tapa el sartén y deja hervir
                                    por unos 2 minutos a fuego bajo.
                                </li>
                                <li>
                                    Sirve calientes, colocando 1 palillo en cada bolita.
                                </li>
                                <li>
                                    Acompaña con{" "}
                                    <b>Mayonesa McCormick® light con jugo de limón.</b>
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "temporada-invernal-sabor-tes",
            titulo:
                "Disfruta la temporada invernal con el delicioso sabor de los tés ",
            imagen: imgbnr14,
            descripcion: (
                <div>
                    A lo largo de los últimos meses, la pandemia nos ha hecho poner un
                    mayor énfasis en lo que comemos y cómo eso repercute en nuestra
                    salud, por ello comenzamos a acelerar la tendencia hacia{" "}
                    <b>bebidas más saludables y de mayor calidad</b>, como lo es el
                    té.
                    <br />
                    <br />
                    Si bien, aunque cuidarse ya es parte de nuestra vida, siempre
                    buscamos el sabor, pues es algo que nos conecta con los sentidos.
                    El té es precisamente una bebida llena de sabor que despierta
                    muchas sensaciones y además es saludable; ahora que comienza el
                    otoño, podemos acompañar esta temporada con bebidas calientes y
                    postres con sabores como <b>canela, clavo</b>, calabaza, guayaba,
                    anís y <b>jengibre</b>, que son clásicos de estas fechas y ayudan
                    a fortalecer el sistema inmune.
                    <br />
                    <br />
                    <h2>Beneficios de consumir té</h2> <br />
                    Los tés, son una perfecta tradición para llenarnos de los
                    beneficios naturales y su poder funcional en nuestro cuerpo. Al
                    ser <b>elaborados con ingredientes botánicos</b>, se pueden
                    adaptar a las diversas necesidades de nuestro cuerpo y ayudarnos a
                    sentir bienestar. Es por ello que el té no sólo ayuda a nuestro
                    cuerpo, también nos hace sentir bien, es como un apapacho que{" "}
                    <b>
                        ayuda a nuestra salud digestiva, a la energía y hasta a nuestro
                        sistema inmune
                    </b>
                    , que en esta temporada necesitamos reforzarlo más.
                    <br />
                    <br />
                    Uno de los beneficios del té, es que puedes beberlo a cualquier
                    hora, frío o caliente, por la mañana o en la noche, en polvo, en
                    botella, con o sin azúcar. Es una bebida tan versátil, que va bien
                    a toda hora; por ello, según la FAO (Organización de las Naciones
                    Unidas para la Alimentación y la Agricultura), es la segunda
                    bebida más consumida en el mundo, después del agua. Es importante
                    mencionar que{" "}
                    <b>
                        el té no contiene grasa, pero sí muchos antioxidantes naturales
                    </b>
                    , lo que lo convierte en una gran opción para todos los días.
                    <br />
                    <br />
                    <h2>Los tés más famosos en redes sociales</h2>
                    <br />
                    Como ves, el té es una de las bebidas más fascinantes y que se ha
                    convertido en tendencia y moda alrededor del mundo. Lo vemos en
                    redes sociales, recomendado por especialistas, por fanáticos de
                    las infusiones que sin duda señalan que{" "}
                    <b>
                        “el té está avivando como nunca la escena gastronómica
                        internacional”
                    </b>
                    , y es una de las tendencias que más seguidores ha ganado, gracias
                    a las bombas de té, el té matcha y los flowering tea.
                    <br />
                    <br />
                    En 2020, cuando la gente pasó más tiempo en casa, las bombas de té
                    se viralizaron tanto, que hicieron que la gente de todo el mundo
                    bebiera té, y así millones de usuarios en TikTok compartieron
                    miles de recetas de cómo prepararlas. Estas <b>bombas de té</b> no
                    sólo son deliciosas, también se ven increíbles, haciendo más
                    glamouroso el beber té.
                    <br />
                    <br />
                    Otra de las tendencias que ha tomado fuerza desde hace algunos
                    años, ha sido el <b>té matcha</b>, que además es considerado como
                    un súper alimento, por ser un vasto compendio de vitaminas,
                    minerales, aminoácidos y antioxidantes, es usado para muchas
                    recetas. También se puede beber en nuevas y distintas formas, como
                    con lechadas vegetales, en matcha lattes o mezclados con vainilla,
                    cúrcuma, jengibre, cardamomo y todo tipo de <b>especias</b>, y por
                    si no fuera poco, ahora se cocinan postres, cocteles, barras y
                    cereales innovando su consumo.
                    <br />
                    <br />
                    Tampoco podemos dejar de lado el <b>flowering tea</b>, una de las
                    tendencias más adoradas en redes sociales en todo el mundo. La
                    idea de los usuarios, más allá de disfrutar una bebida placentera,
                    es crear piezas de arte con hojas de té, haciendo que tomar té sea
                    toda una experiencia.
                    <br />
                    <br />
                    <h2>Postres otoñales con té</h2>
                    <br />
                    Como puedes ver, el té, con sus raíces ancestrales, no pasa de
                    moda, y hoy más que nunca está en tendencia, innovando las
                    múltiples maneras en que puedes consumirlo. Para que tú puedas
                    vivir y experimentar con el té, te dejamos algunas recetas para
                    que disfrutes en esta temporada de frío.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg46,
                    texto: (
                        <div>
                            <h3> Raspadito de pantano verde </h3>
                            <br />
                            <b>Porciones</b>:6
                            <br />
                            <b>Ingredientes</b>
                            <br />4 cdas. de <b>Té matcha puro McCormick®</b> infusionado
                            en 2 tazas de agua hirviendo
                            <br />¼ de cdita. de <b>Jengibre en polvo McCormick®</b>
                            <br />¼ de cdita. de <b>Canela en polvo McCormick®</b>
                            <br />
                            ¼ de cdita. de cardamomo en polvo
                            <br />
                            ½ taza de leche condensada
                            <br />
                            1 taza de zarzamoras para decorar
                            <br />
                            1 taza de malvaviscos blancos
                            <br />
                            6 tazas de hielo bien molido
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    Infusiona el té matcha y deja enfriar; después mézclalo
                                    con las especias para que se integren.
                                </li>
                                <li>
                                    Coloca en 6 vasos el hielo molido en forma de montaña.
                                </li>
                                <li>
                                    Vierte en la mitad del hielo en el té matcha, la otra
                                    mitad báñala con leche condensada y decora con zarzamoras
                                    y malvaviscos.{" "}
                                </li>
                            </ul>
                            Tip: Esta receta es ideal para tu fiesta de Halloween o Día de
                            Muertos. Si te gusta el lichi puedes comprarlos en lata y
                            escurrirlos, también puedes decorar con culebritas de gomitas,
                            empanizadas en una mezcla de azúcar con canela y jengibre.
                        </div>
                    ),
                },
                {
                    imagen: recetasimg48,
                    texto: (
                        <div>
                            <h3> Doni Monstruos </h3>
                            <br />
                            <b>Porciones</b>: 6<br />
                            <b>Ingredientes</b>
                            <br />
                            6 donas naturales o regulares
                            <br />
                            500 ml de agua hirviendo inficionada con 4 bolsitas de{" "}
                            <b>Té de jengibre McCormick®</b>
                            <br />
                            4 guayabas
                            <br />
                            2 cdas. de fécula de maíz
                            <br />
                            ½ taza de leche condensada
                            <br />
                            50 g de mantequilla en cubitos o margarina
                            <br />
                            <b>Procedimiento:</b>
                            <ul>
                                <li>
                                    Muele en la licuadora el té de jengibre que infusionaste
                                    con las guayabas, la fécula y la leche condensada.{" "}
                                </li>
                                <li>
                                    Cuela la preparación y cocina en una olla a fuego bajo
                                    hasta que comience a espesar.
                                </li>
                                <li>
                                    Añade cubitos de mantequilla y continúa moviendo hasta que
                                    se integren.{" "}
                                </li>
                                <li>
                                    Retira del fuego y deja enfriar tapado con plástico
                                    adherente a contacto directo de la crema, esto evitará que
                                    se haga una nata.
                                </li>
                                <li>
                                    Divide en 5 porciones esta crema de guayaba y té de
                                    jengibre sobre cada dona.
                                </li>
                            </ul>
                            <u>
                                <b>Para decorar tus donas:</b>
                            </u>
                            <br />
                            1 kg. de pasta de fondant (la consigues en tiendas
                            especializadas de pastelería, si no puedes sustituir por 1
                            kilo de azúcar glass con té de jengibre frío y agregar poco a
                            poco hasta que se forme una masa tipo plastilina)
                            <br />
                            ½ kg. de pasta de fondant negra <br />
                            1 cajita de colorantes vegetales <br />
                            <ul>
                                <li>
                                    Agrega unas gotitas del colorante de tu preferencia a la
                                    pasta de fondant, mezcla con tus manos como si fuera
                                    plastilina, extiendela en forma delgada y corta círculos
                                    para cubrir tus donas encima de la crema de guayaba.
                                    Decora con un ojito, formando una bolita blanca y encima,
                                    unta una gotita de agua para que se pegue la bolita negra.
                                </li>
                                <li>
                                    Las rayitas las puedes hacer con la misma pasta de fondant
                                    de colores solamente añadiéndole un poco de agua o leche
                                    para que se vuelva líquido y hacer forma de hilos metiendo
                                    esta mezcla en una bolsita de plástico, haces un orificio
                                    en la punta de la bolsa para que salga la preparación y
                                    puedas decorar tus donas.
                                </li>
                                <li>
                                    Puedes personalizar cada Doni monstruos añadiendo
                                    etiquetas con nombres de tus invitados para tu mesa de
                                    dulces o postres.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: recetasimg47,
                    texto: (
                        <div>
                            <h3> Shots Cadavéricos </h3>
                            <br />
                            <b>Porciones</b>: 6<br />
                            <b>Ingredientes</b>
                            <br />
                            1 taza de agua hirviendo
                            <br />6 bolsitas de <b>Té rojo McCormick®</b>
                            <br />
                            ½ taza de jugo de granada
                            <br />¼ de cdita. de <b>Canela molida McCormick®</b>
                            <br />
                            6 vasitos tequileros o para shots
                            <br />
                            1 cda. de Miel de abeja Carlota
                            <br />
                            3 cda. de polvo de chamoy agridulce de tu preferencia
                            <br />
                            <ul>
                                <li>
                                    Unta un poco de miel el borde de cada vaso, escárchalo con
                                    chamoy en polvo y congélalos por media hora.
                                </li>
                                <li>
                                    Mezcla el té rojo infusionado con el jugo de granada y la
                                    canela.
                                </li>
                                <li>Vierte sobre cada vasito congelado.</li>
                                <li>Vierte sobre cada vasito congelado.</li>
                                <li>
                                    Sirve tu mesa de Halloween o Día de muertos con estos
                                    shots saludables y divertidos.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "playa-sabe-plataforma-balance",
            titulo:
                "La playa también sabe a Balance con la Plataforma Balance McCormick® ",
            imagen: imgbnr13,
            descripcion: (
                <div>
                    ¿Qué pasa si te digo que no tienes que sentirte culpable comiendo
                    rico y saludable para ir a la playa? ¡Incluso estando en ella!
                    <br /> <br />A todos (o a la mayoría) nos ha pasado que cuando
                    queremos ir a la playa tenemos retos que implican transformar
                    nuestro estilo de vida porque queremos sentirnos cómodos en traje
                    de baño. Y normalmente, adicional al ejercicio, nos ponemos a
                    dieta y nos quitan muchas cosas, sin embargo, lo más importante
                    son las porciones con <b>sabor a Balance.</b> <br />
                    <h2> Mayonesa balance y otros productos con sabor a balance </h2>
                    Por ejemplo, las <b>mayonesas</b> de nuestra{" "}
                    <b>plataforma Balance</b> pueden ser utilizarlas como prefieras
                    con lo que más te gusta, porque tienen los beneficios de las
                    semillas naturales de diversos aceites como el de{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-ajonjol%C3%AD"
                    >
                        ajonjolí
                    </a>
                    , el de{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-aguacate"
                    >
                        aguacate
                    </a>{" "}
                    y el de{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-soya"
                    >
                        soya
                    </a>
                    .<br />
                    <br />
                    <div className="cont-recetas-img">
                        <img className="recetas-img" styl src={recetasimg40} />
                        <img className="recetas-img" src={recetasimg41} />
                        <img className="recetas-img" src={recetasimg42} />
                    </div>
                    <br />
                    <br />
                    Por otro lado, nuestras <b>conservas</b>, además de tener sabores
                    súper deliciosos como lo son la fresa, la zarzamora, la piña y el
                    mango, se pueden disfrutar con todo lo que se te antoje por su
                    bajo nivel de azúcar. <br />
                    <br />
                    Y, por último, pero no menos importante, nuestros <b>tés</b> en la
                    Plataforma Balance. Son tés con mezclas pensadas no solo para que
                    los disfrutes por su sabor, sino también para que te cuides. Por
                    eso te recomendamos que si vas a la playa tomes nuestros tés.{" "}
                    <br />
                    <br />
                    Quizá puedas consumir el de{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/t%C3%A9-verde-y-pitahaya-(energ%C3%ADa)"
                    >
                        Energía
                    </a>{" "}
                    al amanecer para que rindas en todas tus actividades; por la tarde
                    el{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/t%C3%A9-de-eucalipto,-gordolobo-y-miel-(antigripal)"
                    >
                        Antigripal
                    </a>{" "}
                    para prevenir y proteger tu sistema inmune, y el{" "}
                    <a
                        target="_blank"
                        href="https://www.mccormick.mx/producto/t%C3%A9-de-frutos-rojos-(buenas-noches)"
                    >
                        Buenas Noches
                    </a>{" "}
                    para descansar como mereces porque sabemos que los buenos días
                    empiezan teniendo Buenas Noches. <br />
                    <br />
                    <div className="cont-recetas-img">
                        <img className="recetas-img" styl src={recetasimg43} />
                        <img className="recetas-img" src={recetasimg44} />
                        <img className="recetas-img" src={recetasimg45} />
                    </div>
                    <br />
                    <br />
                    Así que, ya sabes, el comer rico y cuidarte en la playa o en la
                    ciudad, no debe estar en contra cuando <b>SABE A BALANCE</b>.
                </div>
            ),
            recetas: [{}],
            foter: <div></div>,
        },
        {
            nombre: "especias-fiestas-patrias-mexicanas",
            titulo: "Las Especias en las Fiestas Patrias Mexicanas",
            imagen: imgbnr12,
            descripcion: (
                <div>
                    Este mes de la patria, México se viste de fiesta y hoy más que
                    nunca es el momento de celebrar en la cocina, un espacio divertido
                    y aventurero, acompañado de las <b>especias y sus mezclas</b> que
                    cobran un protagonismo especial.
                    <br />
                    <br />
                    La riqueza de la cocina mexicana, esta vez se enfoca en sus{" "}
                    <b>platillos típicos</b>: pozole, chiles en nogada, mole,
                    cochinita pibil, menudo, tacos, una gran variedad de carnes,
                    postres y bebidas tradicionales, que ponen en alto la cocina
                    nacional.
                    <br />
                    <br />
                    Dentro de las creaciones nacionales, es común el uso de las
                    especias tanto nativas como del mundo.
                    <br />
                    <br />
                    Los chiles son protagonistas de nuestra gastronomía, están de moda
                    en todas las cocinas del planeta, y sus presentaciones en salsas
                    son muy apreciadas. En México, los moles y los chiles en nogada
                    con especias como <b>clavo, pimienta, canela, anís, orégano</b>,
                    son una explosión de sabores, que le dan carácter a las recetas.
                    <br />
                    <br />
                    Por su parte, existen preparaciones que son una mezcla de especias
                    que le dan sabor a platillos muy mexicanos, como el achiote que
                    combina semillas de achiote, clavo, pimienta, canela y comino.
                    Esta preparación es absolutamente necesaria para cocinar la
                    cochinita pibil y muchos platillos con aves, carnes, mariscos y
                    pescados del sur de nuestro país, principalmente; pero ya los
                    encontramos por todo México.
                    <br />
                    <br />
                    Las bebidas no se quedan atrás, los sabores y la tradición llegan
                    en forma de jamaica, cítricos, horchata y tamarindo, a las que se
                    les añaden semillas de chía,{" "}
                    <b>jengibre, pimienta de cayena, paprika, canela y vainilla</b>,
                    que le imprimen un toque muy peculiar.
                    <br />
                    <br />
                    Los postres también ocupan un lugar privilegiado y las especias
                    son protagonistas del lado dulce de nuestra cocina, la{" "}
                    <b>canela</b>, el anís, <b>la nuez moscada</b>, la vainilla, son
                    los que saborizan buñuelos, arroz con leche, flanes, cajetas,
                    piezas clásicas de repostería y panadería así como cubiertas,
                    confituras y mermeladas que se han convertido en un postre
                    emblemático en los Estados Unidos, en especial en la ciudad de
                    Chicago.
                    <br />
                    <br />
                    <h2>¿Por qué es bueno utilizar especias?</h2>
                    Todas las especias y hierbas son un cúmulo de{" "}
                    <b>compuestos fenólicos y flavonoides</b>, muy útiles y
                    saludables, pues son antioxidantes naturales muy poderosos.
                    Muchos, como los chiles, achiote, y epazote, además, contienen
                    grandes cantidades de <b>vitaminas y minerales</b>. El orégano,
                    albahaca, romero, mejorana, tomillo y menta contienen ácido
                    rosmarínico, un compuesto fenólico, que puede prevenir
                    enfermedades vasculares, respiratorias y reducen los radicales
                    libres como la catequina, taninos y carotenoides en la canela y el
                    achiote o los flavonoides en los clavos y el comino.
                    <br />
                    <br />
                    En estos tiempos de pandemia, todo el mundo quiere estar saludable
                    y como somos lo que comemos, septiembre es el mes para sentirse
                    muy mexicanos, que mejor que acompañar nuestros platillos, bebidas
                    y postres con especias ya que además de aromatizar y saborizar los
                    platillos típicos mexicanos, nos aportan un sinfín de nutrientes
                    naturales.
                    <br />
                    <br />
                    Recuerda comer equilibrado y hacer ejercicio, ¡te sentirás mejor!
                    <br />
                    <br />
                    <h2>Recetas mexicanas con especias</h2>
                    Te compartimos algunos tips de recetas para que tus platillos y
                    antojitos tengan ese sabor de las especias y sean saludables para
                    estas fiestas patrias.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg36,
                    texto: (
                        <div>
                            <h3> Quesabirrias caseras</h3>
                            <b>Porciones</b>: 4 <br />
                            <b>Ingredientes</b>;<br />
                            600 g de carne de carnero en trozos o puedes usar falda de res
                            <br />
                            2 l de agua
                            <br />
                            3 chiles guajillos sin semillas remojados en 1 tz. de agua
                            caliente
                            <br />
                            1 cdta. de orégano
                            <br />
                            ¼ de cdta. de comino entero o molido
                            <br />
                            ½ cdta. de pimienta negra entera
                            <br />
                            3 pimientas gordas
                            <br />
                            1 cdta. de tomillo fresco o seco
                            <br />
                            1 cdta. de sal con ajo
                            <br />
                            2 cda. de aceite vegetal
                            <br />
                            8 tortillas de maíz
                            <br />
                            1 tz. de queso tipo manchego para derretir rallado
                            <br />
                            2 limones partidos
                            <br />
                            <ul>
                                <li>
                                    Muele los chiles con 1 ½ taza de agua y con todas las
                                    especias, cuela y calienta en una olla 1 cucharada de
                                    aceite, fríe la salsa unos minutos. Agrega la carne y
                                    cocina por 2 hrs. Aprox. Si tienes olla de presión cocina
                                    a partir de que suene la válvula, 20 minutos.{" "}
                                </li>
                                <li>
                                    Retira la carne, desmenúzala, forma tus quesabirrias con
                                    carne y queso, dobla la tortilla por la mitad y
                                    caliéntalas en una sartén o comal con un poco de aceite
                                    hasta que se derrita el queso.
                                </li>
                                <li>
                                    Sirve acompañadas del caldo con limones, cebolla, cilantro
                                    y orégano al gusto.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: recetasimg37,
                    texto: (
                        <div>
                            <h3>Tostadas veganas</h3>
                            <b>Porciones</b>: 4<br />
                            <b>Ingredientes</b>:<br />
                            8 tostadas de maíz de tu preferencia
                            <br />
                            2 aguacates maduros y firmes, machacados y sazonados con sal,
                            pimienta, 1 cucharada de aceite de oliva
                            <br />
                            1 chile serrano picado finamente
                            <br />
                            ½ tz. de granos de elote
                            <br />
                            ½ tz. de granos de frijol negro cocido entero escurrido
                            <br />
                            ½ tz. de granos de garbanzo cocido y escurrido
                            <br />
                            1 cda. de aceite de oliva
                            <br />
                            ½ cdita. de tomillo en polvo
                            <br />
                            ¼ de cdita. de comino en polvo
                            <br />
                            ½ cdita. de sal con ajo
                            <br />
                            ½ tz. de hojas verdes para decorar
                            <br />
                            <ul>
                                <li>
                                    Calienta el aceite de oliva en una sartén, fríe
                                    ligeramente todos los vegetales con las especias. Retira y
                                    deja enfriar.
                                </li>
                                <li>
                                    Unta cada tostada con guacamole, sirve encima los
                                    vegetales, decora con hojas verdes de tu preferencia,
                                    crema o queso fresco rallado.
                                </li>
                                <li>
                                    Puedes usar al gusto alguna mayonesa de sabor como
                                    chipotle o habanero.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: recetasimg38,
                    texto: (
                        <div>
                            <h3> Horchata de arroz con coco</h3>
                            <br />
                            <b>Porciones</b>: 1 litro
                            <br />
                            <b>Ingredientes</b>
                            <br />
                            ½ tz. de arroz enjuagado y escurrido
                            <br />
                            2 tz. de agua hirviendo
                            <br />
                            1 rama de unos 5 cm de canela asada ligeramente en un sartén
                            <br />
                            3 tz. de leche de coco <br />
                            Azúcar al gusto
                            <br />
                            1 cdita. de vainilla
                            <br />
                            ½ cdita. de nuez moscada
                            <br />
                            2 tz. de hielo en cubos
                            <br />
                            1 cdita. de canela en polvo para decorar
                            <br />
                            <ul>
                                <li>
                                    Remoja el arroz en el agua hirviendo durante una noche{" "}
                                </li>
                                <li>
                                    Al día siguiente muélelo con el agua de reposo, la rama de
                                    canela, azúcar al gusto, nuez moscada, y cuela con un
                                    colador de cedazo fino
                                </li>
                                <li>
                                    Mezcla la preparación anterior con la leche de coco,
                                    vainilla y hielos.
                                </li>
                                <li>
                                    Sirve bien fría y espolvorea al final canela en polvo.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "postres-te-matcha-fiesta",
            titulo: "Postres con Té Verde Matcha para tu fiesta de disfraces",
            imagen: notaMatch,
            descripcion: (
                <div>
                    Llegó octubre y con él, la temporada de sustos. Agrégale sabor y prepárate con estos
                    deliciosos <b>postres</b> hechos con <a href='https://www.mccormick.mx/producto/matcha-puro'> Té Verde Matcha de McCormick</a>.
                    <br />
                    <br />
                    <h2> ¿Cómo preparar postres con matcha?</h2>
                    Sabemos que el otoño es único y una opción perfecta para disfrutarlo al máximo es nuestro
                    <b> Té Verde Matcha de McCormick</b>, que va con los aromas y sabores de la temporada. Podrás
                    disfrutarlo en una rica taza caliente, o bien, en un delicioso frappé, mientras lees un buen
                    libro de suspenso o ves una película de terror.
                    <br />
                    <br />
                    Las opciones para disfrutar nuestro <b>Té Verde Matcha</b> son variadas, y no sólo para que pases un
                    momento delicioso cuando lo bebas, sino que también puedes usarlo para preparar ricos <b>postres </b>
                    que deleiten a tu familia e invitados, como por ejemplo <b>pan japonés de matcha</b>, el riquísimo y
                    refrescante <b>helado de matcha</b> o los <b>mochis de té verde matcha</b>.
                    <br />
                    <br />
                    <h2> Origen y propiedades del té verde </h2>
                    Este té originario de China, es preparado con la hoja entera con que se hace el <b>té verde</b>, lo cual aporta una mayor
                    cantidad de nutrientes y antioxidantes. También era utilizado por los practicantes del budismo Zen para realizar
                    meditaciones.
                    <br />
                    <br />
                    Además, a diferencia de otras infusiones, cuando bebes <b>Té Verde Matcha de McCormick</b> estás consumiendo la totalidad de
                    sus nutrientes. Aquí algunos ejemplos de lo que el <b>té matcha</b> hace por tu cuerpo.
                    <br />
                    <br />
                    <h3> Beneficios del té verde </h3>
                    <ul>
                        <li>Fortalece el sistema inmunológico.</li>
                        <li>Ayuda a eliminar líquidos retenidos.</li>
                        <li>Quema grasa natural, ya que acelera la termogénesis y acelera el metabolismo.</li>
                        <li>Es rico en antioxidantes.</li>
                        <li>Protege del cáncer gracias a su alto nivel de EGCG (Epigalocatequina galato), una enzima que, se ha comprobado, combate las patologías tumorales.</li>
                        <li>Es rico en potasio, vitaminas A, C, B1, B2, hierro, proteínas y calcio.</li>
                        <li>Reduce el colesterol en la sangre.</li>
                        <li>Controla la hipertensión.</li>
                        <li>Relaja tu cuerpo.</li>
                        <li>Estimula la mente.</li>
                        <li>Mejora tu estado de ánimo.</li>
                    </ul>
                    <br />
                    <h2> Postres fáciles para Halloween </h2>
                    Como ves, en cualquier ocasión, nuestro <b>Té Verde Matcha de McCormick</b> está a tu medida, así que si lo que buscas es tener la mejor mesa en
                    esta escalofriante temporada, prepárate para hacer los mejores <b>snacks dulces</b> con nuestras <b>recetas</b> especiales y ser el mejor anfitrión de
                    la fiesta de disfraces.
                </div>
            ),
            recetas: [
                {
                    imagen: mostritos,
                    texto: (
                        <div>
                            <h3> Monstruitos de Té Verde Matcha McCormick®</h3>
                            <br />
                            <b>Rinde</b>: 15 a 20 galletas <br />
                            <b>Tiempo de preparación</b>: 40 minutos<br />
                            <br />
                            <b>Ingredientes</b>:<br />
                            1 ½ taza de harina<br />
                            <b>1 cucharada de </b><a href='https://www.mccormick.mx/producto/matcha-puro'>Té Verde Matcha McCormick®</a><br />
                            3/4 de taza de azúcar mascabado<br />
                            ½ taza de mantequilla a temperatura ambiente<br />
                            1 cucharadita de polvo de hornear<br />
                            ¼ de cucharadita de sal<br />
                            1 cucharadita de esencia de vainilla<br />
                            1 huevo<br />
                            <br />
                            <b>Para decorar</b>:  <br />
                            Granillo de chocolate oscuro  <br />
                            Ojitos de azúcar, gomitas o lunetas de colores  <br />
                            <br />
                            <b>Procedimiento</b>:<br />
                            <ul>
                                <li>En un tazón, combina la mantequilla con el azúcar. Bate vigorosamente hasta que se acreme la mantequilla o doble su volumen.</li>
                                <li>Añade la esencia de vainilla, el huevo y continúa batiendo.</li>
                                <li>En otro tazón combina harina, polvo de hornear, sal y el Té Verde Matcha McCormick®. Agrega la preparación anterior y mezcla solamente para que se integren todos los ingredientes.</li>
                                <li>Coloca en plástico adherente la masa de galletas y refrigera por 20 minutos, mientras preparas tus charolas con papel y precalientas el horno a 175°C.</li>
                                <li>Extiende la masa en una hoja de papel para hornear con ½ cm de grosor aproximadamente.</li>
                                <li>Corta las galletitas en círculos y colócalas en una charola para hornear previamente cubierta con una hoja de papel para hornear. ¡Recuerda dejar un espacio entre galletas para evitar que al extenderse se peguen!</li>
                                <li>Repite hasta terminar la masa. Espolvorea el granillo de chocolate en cada galleta.</li>
                                <li>Hornea durante 8 minutos, retira del horno y déjalas enfriar muy bien. Pega los ojitos con una gotita de mermelada o de fondant blanco. </li>
                            </ul>
                            <b>Tip</b>:<br />
                            <ul>
                                <li>Cuando trabajes masa para galletas recuerda no manipularla mucho con tus manos, esto evitará que las galletas queden duras. </li>
                                <li> Si quieres un color verde más intenso puedes añadir colorante (unas 3 gotitas, cuando batas la mantequilla). </li>
                                <li> El <b>Té Matcha McCormick®</b> es saludable y es su color verde original.</li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: Baritasfrank,
                    texto: (
                        <div>
                            <h3> Barritas de Frankenstein con Té Verde Matcha McCormick® </h3>
                            <br />
                            <b>Rinde</b>: 15 a 20 barritas <br />
                            <b>Tiempo de preparación</b>: 30 minutos<br />
                            <br />
                            <b>Material</b>:
                            Hojas de papel de alta temperatura para hornear o papel encerado<br />
                            1 refractario grande o molde cuadrado de 20 X 30 cm, aproximadamente<br />
                            <br />
                            <b>Ingredientes</b>:<br />
                            2 barritas de mantequilla cortada en trozos regulares<br />
                            600 gramos malvavisco miniatura color blanco<br />
                            1 cucharada de <a href='https://www.mccormick.mx/producto/matcha-puro'>Té Verde Matcha McCormick®</a><br />
                            1/2 cucharadita de esencia de vainilla<br />
                            10 tazas de cereal inflado sabor natural<br />
                            <br />
                            <b>Para decorar</b>:
                            1/2 taza de cobertura de chocolate <br />
                            1 cucharadita de mantequilla o aceite vegetal <br />
                            Granillo de chocolate <br />
                            Ojitos de azúcar o Fondant blanco y negro <br />
                            1 tubo de icing o gel preparado color negro <br />
                            Bombones o cuadritos de chocolate para sus orejitas <br />
                            Palillos de madera para sostener orejitas <br />
                            <br />
                            <b>Procedimiento</b>:<br />
                            <ul>
                                <li>En una cacerola coloca la mantequilla, la esencia de vainilla, malvaviscos y el Té Verde Matcha McCormick®. (Sí deseas un color verde más intenso añade unas gotas de colorante verde).</li>
                                <li>Derrite a fuego bajo los malvaviscos, cuando estén derretidos, mueve vigorosamente, retira del fuego.</li>
                                <li>En un tazón grande, mezcla el cereal con los malvaviscos, para que se integren. Vierte en un molde cubierto con papel encerado o de alta temperatura.</li>
                                <li>Con la ayuda de un rodillo de repostería aplana muy bien el cereal para que se comprima y quede parejo.</li>
                                <li>Deja enfriar la preparación alrededor de 4 hrs en un lugar fresco.</li>
                                <li>Desmolda y corta cuadros o rectángulos del tamaño de tu agrado.</li>
                                <li>Derrite el chocolate junto con mantequilla o aceite en el horno de microondas 15 segundos hasta que se derrita y se haga líquido.</li>
                                <li>Sumerge la cuarta parte de cada barrita y colócalas en una charola forrada con papel encerado, espolvorea granillo de chocolate. Refrigerarlas unos minutos para que el chocolate se endurezca.</li>
                                <li>Decora con los ojitos; los puedes pegar con una gotita de chocolate líquido.</li>
                                <li>Las orejitas de bombones de chocolate las puedes sujetar con la mitad de 1 palillo.</li>
                                <li>Guarda tus Frankenstein de Té Verde Matcha McCormick® en bolsitas de celofán para que se conserven crujientes. Disfruta con los niños, familiares y amigos, en fiestas de Halloween.</li>
                            </ul>
                            <b>Tip</b>:<br />
                            <ul>
                                <li>Para evitar que se pegue el rodillo o tus manos con el malvavisco cuando extiendas el cereal en el molde, espolvorea el rodillo con azúcar glass, o con tus manos engrasadas con aceite vegetal.</li>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "sentirte-mejor-mayonesas-mccormick-balance",
            titulo: "Comienza a sentirte mejor este 2022 con las Mayonesas McCormick Balance",
            imagen: recetasSentirseMejor,
            descripcion: (
                <div>
                    Para muchos de nosotros, enero es una nueva oportunidad para comenzar a cuidarnos y hacer
                    conciencia de nuestra salud, y qué mejor forma de hacerlo que comenzando por nuestra
                    alimentación, porque como ya sabes, se dice que somos lo que comemos. Aunque se tenga
                    la creencia de que cuidarse significa renunciar al sabor, hoy con las <b>Mayonesas McCormick
                        Balance</b>, queremos demostrarte que eso no es verdad, pues puedes cuidarte y entrar en
                    balance con todo el sabor.
                    <br />
                    <br />
                    <h2> Comienza a vivir una vida más saludable</h2>
                    Comer bien y de manera balanceada, es fundamental para mantenerse sano y alejar todos los padecimientos crónicos que se
                    pueden generar a causa del sobrepeso o la obesidad, pero eso no significa renunciar a los postres o panes, sino saber
                    comer porciones adecuadas y sustituir ingredientes, como las azúcares refinadas, grasas o aceites para cocinar de manera
                    inteligente.
                    <br />
                    <br />
                    De esta manera, le damos a nuestro organismo la oportunidad de acostumbrarse a otros
                    sabores e ingredientes más saludables. Estos aceites se han convertido en una excelente
                    opción para las <a href="https://www.mccormick.mx/productos/mayonesas"> mayonesas </a>
                    , porque son saludables y pueden ir con cualquier comida:
                    pescados, mariscos, ensaladas, sándwiches y para gratinar platillos. Por eso McCormick
                    y su línea de <b>Mayonesas Balance</b>, te invitan a combinarlas con lo que quieras.
                    <br />
                    <br />
                    <h2> Comer saludable no significa estar a dieta</h2>
                    Si estás por empezar un plan alimenticio, debes saber que en muchos de ellos se recomienda reducir el
                    consumo de grasas para bajar de peso, sin embargo, está demostrado que los planes con baja ingesta de
                    carbohidratos son mejores, pues las pérdidas de grasa pueden ser de casi el doble. Además, de esta
                    manera, no solo cuidas tu peso, sino que también comienzas a <b>mejorar tus hábitos</b>, lo que se traducen
                    en mejoras para tu organismo, pues cuando aprendes a comer bien, tienes menor riesgo de un rebote.
                    <br />
                    <br />
                    Por ello es necesario cambiar nuestros hábitos alimenticios, y pensar en consumir alimentos que representen
                    una baja ingesta de azúcares o sacarosa, así como también pensar en consumir <b>grasas saludables</b>, grandes
                    cantidades de frutas y vegetales, y limitar el consumo de carnes rojas, sustituyéndolas por carnes magras
                    como el pescado y el pollo.
                    <br />
                    <br />
                    Otra gran opción para cuidar nuestra alimentación es a través de las <b>dietas cetogénicas</b> o las <b>keto</b>, que
                    consisten en cambiar los hábitos sin sacrificar el sabor y sin sentir hambre. Este tipo de dietas traen
                    <b>beneficios metabólicos</b>, relacionados con la insulina y hasta dermatológicos, ya que evitan el acné.
                    <br />
                    <br />
                    Y aunque estas dietas no limitan las grasas, lo más recomendable es que en tu día a día incluyas aceites
                    vegetales, como los que tienen las <b>Mayonesas McCormick Balance</b>, que te ayudarán como antioxidantes
                    naturales, mejorar la circulación y la piel. Como ves, no es nada difícil mantener un estilo de vida
                    saludable, sin perder el sabor, solo es cuestión de poner manos a la obra.
                    <br />
                    <br />
                    <h1> Las mejores combinaciones saludables con mayonesa </h1>
                    A continuación, te damos unos tips y recetas fáciles balanceadas para que cocines en 30 minutos y, que te lleves a la oficina,
                    trabajo o el lunch de los estudiantes, con <b>mayonesas, especias</b>, y mucho sabor McCormick®.
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: recetaPechuga,
                    texto: (
                        <div>
                            <h3> Pechugas de Pollo con Aderezo de Mayonesa McCormick® Balance Aceite de Aguacate</h3>
                            <br />
                            <b>Rinde</b>: 4 porciones <br />
                            <b>Ingredientes</b>:<br />
                            14 pechugas medianas sin hueso ni piel<br />
                            ½ cdita. de Sal de Mar con Hierbas Finas McCormick®<br />
                            ¼ cdita. de Pimienta Negra Molida McCormick®<br />
                            ½ cdita. de Ajo en Polvo McCormick®<br />
                            1 cda. de aceite de oliva<br />
                            <b>Para la salsa</b>:<br />
                            180 g de espinacas baby de bolsa o 3 tazas de espinacas limpias, cortadas en tiras delgadas <br />
                            1 pimiento rojo mediano, sin venas ni semillas, cortado en tiras delgadas<br />
                            1 tz. de <a href='https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-aguacate'>Aderezo de Mayonesa Balance Aceite de Aguacate McCormick®</a><br />
                            100 g de queso de cabra
                            <br />
                            <br />
                            <b>Procedimiento</b>:<br />
                            <ul>
                                <li>
                                    Sazona las pechugas por ambos lados con Sal de Mar con Hierbas Finas McCormick®, Pimienta Negra McCormick® y Ajo en Polvo McCormick®.
                                </li>
                                <li>
                                    Calienta el aceite de oliva en una sartén a fuego medio, dora las pechugas por ambos lados.
                                </li>
                                <li>
                                    Retiralas del fuego y en la misma sartén agrega los pimientos, revuelve para que se doren un poco, agrega espinacas, deja que se reduzcan, añade el <b>Aderezo de Mayonesa Balance Aceite de Aguacate McCormick®</b> y el queso de cabra. Mezcla todo para que se integre, regresa las pechugas, tapa la sartén y cocina por 15 minutos a fuego bajo.
                                </li>
                                <li>
                                    Sirve caliente.
                                </li>
                            </ul>
                            <b> Tip</b>:<br />
                            Puedes usar alguna crema baja en grasa en lugar de queso de cabra y prepararte un termo o vaso con té frío de la línea McCormick®, del sabor de tu preferencia. Solo añade ½ cucharadita de Jengibre en Polvo McCormick® o Canela Molida McCormick® y tus bebidas tendrán un sabor fresco que a la vez te ayudará a prevenir enfermedades respiratorias.
                        </div>
                    ),
                },
                {
                    imagen: recetasFritata,
                    texto: (
                        <div>
                            <h3> Frittata con Aderezo de Mayonesa McCormick® Balance con Aceite de Soya </h3>
                            <br />
                            <b>Rinde</b>: 4 porciones<br />
                            <b>Ingredientes</b>:<br />
                            6 huevos<br />
                            Sal al gusto<br />
                            ¼ cdita. de Pimienta Negra Molida McCormick®<br />
                            8 espárragos tiernos cortados por la mitad<br />
                            1 pimiento rojo mediano cortado en cubos pequeños<br />
                            1 cdita. de aceite de oliva<br />
                            150 g de queso de cabra natural<br />
                            4 cdas. de <a href='https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-soya'>Aderezo de Mayonesa McCormick® Balance con Aceite de Soya </a><br />
                            100 g de tocino ligeramente dorado y escurrido de grasa en papel absorbente<br />
                            <br />
                            <b>Procedimiento</b>:<br />
                            <ul>
                                <li> Calienta una sartén con aceite de oliva, dora ligeramente los espárragos con el pimiento y retira del fuego.</li>
                                <li>Mezcla en un tazón los huevos con sal y pimienta, y bate hasta que estén espumosos.</li>
                                <li>Mezcla el queso de cabra con el <b>Aderezo de Mayonesa McCormick® Balance con Aceite de Soya.</b></li>
                                <li> Mezcla los huevos batidos con las verduras y el queso. Vierte en una sartén con antiadherente, coloca encima las rebanadas de tocino, tapa y cocina a fuego muy bajo, hasta que se cocine el huevo.</li>
                                <li> Si tienes horno, vierte la mezcla en un refractario o molde para hornear con las rebanadas de tocino encima, hornea a 180°C por 15 minutos o hasta que esté dorado el tocino y la mezcla de huevo quede firme.</li>
                                <li>Sirve caliente y decora con jitomates cherry o los vegetales que tengas en casa.</li>
                            </ul>
                            <b>Tip</b>:<br />
                            Puedes usar cualquier verdura para tu frittata, o tortilla de huevo italiana, calabacita,
                            cebollas, pimientos, ejotes tiernos y puedes mezclar ½ taza de yogurt griego bajo en grasa y
                            sin azúcar con ¼ de taza de <b>Aderezo de Mayonesa McCormick® Balance con Aceite de Soya</b>. Esta
                            mezcla dejará cremosa tu tortilla de huevo y además será rico en proteína y sabor.
                        </div>
                    ),
                },
                {
                    imagen: imgbnr25,
                    texto: (
                        <div>
                            <h3> Ensalada Keto de Camarones con Aderezo de Mayonesa McCormick® Balance Aceite de Ajonjolí  </h3>
                            <br />
                            <b>Rinde</b>: 4 Porciones<br />
                            <b>Ingredientes</b>:<br />
                            200 g de camarones medianos pelados y limpios sin venas<br />
                            ½ cdita. de Pimienta Negra Molida McCormick®<br />
                            1 tz. de <a href='https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-ajonjol%C3%AD'>Aderezo de Mayonesa McCormick® Balance con Aceite de Ajonjolí</a><br />
                            1/2 tz. de jitomates cherry cortados por la mitad<br />
                            80 g de aceitunas verdes sin hueso <br />
                            2 cdas. de cilantro picado finamente<br />
                            1 aguacate mediano maduro, pero firme cortado en cubos<br />
                            2 tz. de kale o mezcla de lechugas desinfectadas y bien escurridas<br />
                            1 limón amarillo cortado en 4 partes<br />

                            <br />
                            <b>Procedimiento</b>:<br />
                            <ul>
                                <li>Espolvorea con pimienta los camarones, agrega 2 cucharadas de Aderezo de Mayonesa McCormick® Balance con Aceite de Ajonjolí.</li>
                                <li>Calienta una sartén o parrilla casera y asa los camarones por ambos lados hasta que estén dorados. Retiralos del fuego y déjalos enfriar.</li>
                                <li>En un tazón tritura con un tenedor 2 cucharadas de cubos de aguacate con el resto del Aderezo de Mayonesa McCormick® Balance con Aceite de Ajonjolí, mezcla e incorpora las hojas de kale o lechugas, jitomate, aceitunas, cilantro y cubos de aguacate.</li>
                                <li>Sirve en 4 platos y coloca encima los camarones.</li>
                                <li>Acompaña con gajos de limón.</li>
                            </ul>
                            <b>Tip</b>:<br />
                            La proteína de esta ensalada puedes sustituirla por cubos de salmón, tiras de pechuga de pollo o cubos de atún fresco. Marina las proteínas con el <b>Aderezo de Mayonesa McCormick® Balance con Aceite de Ajonjolí</b>, les aportará humedad y las dejará más jugosos a la hora de asarlos o cocinarlos.
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "usos-mermeladas-postre",
            titulo: "Usos que no conocías de las mermeladas en un postre",
            imagen: imgbnr21,
            descripcion: (
                <div>
                    <p>
                        Cuando se trata de encontrar algo que le guste a toda la familia
                        y que además aporte fibra, vitaminas, minerales y otros
                        nutrientes, no hay nada como la fruta, pues no solo hay una gran
                        variedad de sabores, texturas, colores y olores, también tiene
                        azúcares naturales como la glucosa y la fructosa, que ayudan a
                        nuestro cuerpo a mantenerse saludable.
                    </p>
                    <p>
                        Además de estas ventajas, las frutas son esos alimentos que
                        puedes consumir frescos o preparados en postres, como las
                        clásicas <b>mermeladas</b>. De éstas hay para todos los gustos y
                        para cada miembro de la familia: desde las personas que
                        prefieren las{" "}
                        <a href="https://www.mccormick.mx/productos/mermeladas">
                            mermeladas tradicionales
                        </a>{" "}
                        porque piensan que de otra forma no están consumiendo este
                        producto, pasando por las que tienen{" "}
                        <b>bajo contenido de azúcar</b> y mezclas de frutas, hasta las
                        que contienen <b>picante</b>.
                    </p>
                    <br />
                    <h2>Beneficios de consumir mermelada en tu día a día</h2>
                    <p>
                        Y lo bueno de las <b>mermeladas</b> es que son muy prácticas,
                        pues aportan energía ya sea que la comas antes de hacer
                        ejercicio, en un batido, o simplemente en un pan antes del
                        trabajo. Siempre podrás experimentar con un sinfín de alimentos
                        que descubrirás que van perfecto con mermeladas. Hay que
                        resaltar que contienen azúcares buenas porque están{" "}
                        <b>hechas de frutas naturales</b> como frambuesa, moras,
                        arándanos, ciruelas, higos, y tienen altos contenidos de{" "}
                        <b>antioxidantes</b>, por lo que, si las consumimos con bajo
                        contenido de azúcar o light, también son una gran alternativa.
                        Aunque si en tu casa son de sabores como naranja, mandarina,
                        pera, durazno o mango, también son una gran opción hecha
                        mermelada.
                    </p>
                    <br /> <h2>Elige la mermelada que vaya contigo</h2>
                    <p>
                        Pero si de estar en tendencia se trata, el mundo de lo picante
                        está de moda entre los más jóvenes y los que quieren
                        experimentar con los sabores, las{" "}
                        <a href="https://www.mccormick.mx/producto/mermelada-de-mango-con-chile-habanero">
                            mermeladas picositas
                        </a>
                        , mezcladas con diferentes tipos de chiles, son una gran
                        experiencia de sabor, además son tan versátiles que se pueden
                        combinar con platillos dulces o salados, que seguro hasta al más
                        incrédulo sorprenderá.
                    </p>
                    <p>
                        Si en tu casa son amantes de las frutas, seguro también les
                        encantan las <b>mermeladas</b>, porque no sólo se pueden
                        combinar con cualquier cosa, sino que también puedes tener tu
                        sabor favorito todo el año, sin importar la temporada, además
                        cualquier antojo es ideal para experimentar con recetas y
                        sabores.
                    </p>
                    <br />
                    <h2>Suma sabor a tus comidas</h2>
                    <p>
                        Por ejemplo, un <b>aperitivo</b> montado con queso y mermelada
                        de frutos rojos puede ser el mejor manjar. La mermelada acompaña
                        bien los quesos cremosos y fuertes, equilibrando su grasa. Se
                        puede añadir a salsas, para aderezar cremas o sopas o glasear
                        carnes y verduras. Pero, el mejor destino de las mermeladas son
                        los <b>postres</b>, con bases de masas quebradas, hechas en casa
                        o compradas, rápidamente tenemos tartas deliciosas o tartaletas,
                        se pueden rellenar donas, magdalenas, bizcochos o pastelillos.
                    </p>
                    <p>
                        Qué decir de los pasteles y las gelatinas, si nos inspiramos
                        podemos darle infinidad de usos a nuestras mermeladas cargadas
                        de antioxidantes y fibra.
                    </p>
                    <p>
                        Te damos recetas y tips para que puedas este mes de febrero
                        celebrar con tus seres queridos, fáciles y sin sentir culpa.
                    </p>
                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr20,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Peras y Conserva McCormick® Balance de Zarzamora
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>4 peras tipo bosc semi maduras muy firmes y sin piel</p>
                            <p>
                                1 cucharadita de <b>Canela molida McCormick®</b>
                            </p>
                            <p>
                                ½ cucharadita de <b>Jengibre en polvo McCormick®</b>
                            </p>
                            <p>
                                1 frasco de 235 g{" "}
                                <b>
                                    Conserva McCormick® Balance de Zarzamora o de Conserva
                                    McCormick® Balance de Fresa
                                </b>
                            </p>
                            <p>1 litro de agua</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    En una olla, mezcla las especias con la conserva
                                    McCormick® de zarzamora y el agua, cocina unos 10 minutos,
                                    agrega las peras y cocina otros 15 minutos más a fuego
                                    bajo.
                                </li>
                                <li>
                                    Retira las peras y cuela el almíbar de cocción, sirve las
                                    peras tibias o a temperatura ambiente bañadas con el
                                    almíbar de{" "}
                                    <b>
                                        Conserva McCormick® Balance de Zarzamora o de Conserva
                                        McCormick® Balance de Fresa
                                    </b>
                                </li>
                                <li>Decora con zarzamora o la fruta de tu preferencia</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Cuando peles las peras o manzanas, sumergelas en agua con
                                    un limón exprimido, ayudará a que no se oxiden.
                                </li>
                                <li>
                                    Puedes servir postres tibios o calientes acompañados de
                                    algún helado bajo en azúcar o crema batida.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr19,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Cremoso de Conserva McCormick® Balance de Mango
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>500 ml. de crema entera de leche para bati</p>
                            <p>1 taza de agua caliente</p>
                            <p>1 cucharadita de grenetina</p>
                            <p>
                                1 frasco de 235 g de{" "}
                                <b>Conserva McCormick® Balance de Mango</b>
                            </p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    En un tazón disuelve la grenetina con el agua caliente,
                                    deja enfriar un poco.
                                </li>
                                <li>
                                    Muele en el vaso de la licuadora la crema, el agua con la
                                    grenetina disuelta y la{" "}
                                    <b>Conserva McCormick® Balance de Mango</b>.
                                </li>
                                <li>Vierte en 4 vasitos o moldes para postre.</li>
                                <li>
                                    Decora con crema batida y un gajo de mango natural o la
                                    fruta que tengas en casa.
                                </li>
                                <li>Refrigera por espacio de dos horas. Sirve frío.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <p>
                                    Puedes preparar este postre con cualquier sabor de
                                    Conserva McCormick® Balance, si no quieres usar crema
                                    puedes sustituirla por yogurt griego bajo en azúcar
                                </p>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr18,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Carlota de Conserva McCormick® Balance de Piña
                            </h3>
                            <p>
                                <b>Porciones: 6</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>
                                1 paquete de galletas tipo Marías de 144 g molidas
                                pulverizadas
                            </p>
                            <p>1 barrita de mantequilla derretida o margarina (90 g)</p>
                            <p>180 g de queso crema a temperatura ambiente</p>
                            <p>½ taza de crema para batir</p>
                            <p>
                                1 frasco de Conserva <b>McCormick® Balance de Piña</b>
                            </p>
                            <p>
                                <b>Para decorar</b>
                            </p>
                            <p>2 cdas. de coco rallado tostado</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Mezcla la galleta molida con la mantequilla derretida,
                                    forma una masa arenosa, forra la superficie de un
                                    refractario o molde con la galleta y presiona muy bien con
                                    los dedos para que se adhiera.
                                </li>
                                <li>
                                    Muele en el vaso de la licuadora queso, crema y la de{" "}
                                    <b>Conserva McCormick® Balance de Piña</b>. Vierte en el
                                    molde forrado con la galleta.
                                </li>
                                <li>Espolvorea el coco rallado tostado</li>
                                <li>Refrigera por cuatro horas aproximadamente.</li>
                                <li>Refrigera por espacio de dos horas. Sirve frío.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <p>
                                    Puedes preparar este postre con cualquier sabor de
                                    Conserva McCormick® Balance, si no quieres usar crema
                                    puedes sustituirla por yogurt griego bajo en azúcar
                                </p>
                            </ul>
                        </div>
                    ),
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "detox-natural-tes",
            titulo: "Detox con tés: Desintoxica el cuerpo",
            imagen: imgbnr10,
            descripcion: (
                <div>
                    ¿Sabías que puedes hacer un detox con tés? <br />
                    Si bien, esto no es una dieta ni mucho menos te ayuda a adelgazar
                    como comúnmente se piensa, te ayuda a la desintoxicación en pocos
                    días.
                    <br />
                    Cualquier día es bueno para mejorar tu vida, siente la diferencia
                    con un Detox.
                    <br />
                    <h2> ¿Y qué es Detox?</h2>
                    El término Detox es un proceso en el que alguien se abstiene o
                    elimina del cuerpo sustancias tóxicas o poco saludables. La
                    tendencia a desintoxicarse se ha vuelto muy popular, ya que este
                    proceso puede ayudarte a mejorar diferentes aspectos de tu vida.
                    <br />
                    Actualmente vivimos un estilo de vida acelerado, lo que conlleva a
                    una mala alimentación, estrés y cansancio. Muchas veces no podemos
                    reducir el ritmo que llevamos, pero sí podemos ayudar a nuestro
                    organismo a que elimine impurezas, residuos o toxinas. Para esto,
                    hay una opción ideal que le dará a tu cuerpo una sensación de
                    alivio y liberación: los tés McCormick.
                    <br />
                    <h3>¿Cómo desintoxicar el cuerpo? </h3>
                    <br />
                    Hoy puede ser el día “D”, es tiempo de empezar a mejorar hábitos,
                    hacer ejercicio y desintoxicarse con el plan que McCormick tiene
                    para ti.
                    <br />
                    Sólo necesitas tomar tres 3 tazas de té al día; una por la mañana
                    para darle energía al cuerpo, una entre comidas que puede servir
                    para reemplazar bebidas azucaradas y otra por la noche para
                    completar y relajarte. <br />
                    <h2> Tés para hacer desintoxicar</h2>
                    <br />
                    Lo mejor es que puedes variar los distintos sabores de té durante
                    el día y prepararlos calientes o fríos si se te antoja algo
                    fresco. Tenemos una gran variedad de tés que te acompañarán en el
                    proceso de desintoxicar tu cuerpo a base de té verde y jengibre.
                    ¡Prueba con estos ejemplos!
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg32,
                    texto: (
                        <div>
                            <h3>Té verde mango y té verde mandarina</h3> <br />
                            El té de jengibre-limón y el té verde, con sus deliciosas
                            variedades verde-mango y verde-mandarina, te ayudarán a
                            relajarte y mejorar tu estado físico y anímico, además de ser
                            una manera fácil, práctica y rica para desintoxicarte.
                        </div>
                    ),
                    url: "",
                },
                {
                    imagen: recetasimg31,
                    texto: (
                        <div>
                            <h3>Té verde</h3>
                            <br />
                            El té verde tiene antioxidantes y propiedades
                            antiinflamatorias, estimula el metabolismo y regula los
                            niveles de glucosa en la sangre.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg30,
                    texto: (
                        <div>
                            <h3> Té de jengibre-limón </h3>
                            <br />
                            Por su parte, el té de jengibre-limón es uno de los
                            potenciadores del metabolismo, es excelente para desinflamar,
                            mejora la digestión, reduce el colesterol y tiene un efecto
                            relajante.
                        </div>
                    ),
                    url: recetasimg30,
                },
            ],
            foter: (
                <div>
                    Este Detox con tés, te ayudará a sentirte más liviano, saludable,
                    lleno de energía y vitalidad. Es una opción sencilla y rápida para
                    tener la mejor versión de ti ¡Pruébalos y siente la diferencia!
                </div>
            ),
        },
        {
            nombre: "mejora-habitos-alimenticios-disfruta-vida-en-balance",
            titulo:
                "Disfruta una vida en balance y mejora tus hábitos alimenticios",
            imagen: imgbnr9,
            descripcion: (
                <div>
                    Hoy puedes ser una mejor versión de ti mismo mejorando tus hábitos
                    alimenticios sin tanto esfuerzo. ¿Quieres saber cómo? Descubre
                    cómo con mayonesas balance y pequeñas acciones al modificar
                    ciertos aspectos de tu rutina.
                    <h2>¿Cómo iniciar para mejorar nuestros hábitos?</h2>
                    Recuerda que para iniciar o mejorar un hábito tienes que repetir
                    una y otra vez la misma acción para convertirlo en un
                    comportamiento automático, es decir que se genere por sí solo.
                    También la influencia de las personas que nos rodean y con quienes
                    nos desenvolvemos es un factor fundamental para construirlos. Por
                    ello recomendamos que sin importar si eres fan del ejercicio o
                    simplemente quieres llevar una alimentación equilibrada, empieces
                    con hábitos pequeños como cambiar la alimentación gradualmente con
                    opciones deliciosas y más sanas para darle balance a tus comidas y
                    no dejar de disfrutar el sabor de lo que más te gusta.
                    <h2>Inicia con Mayonesas Balance </h2>
                    Pensando en eso, se crearon las nuevas Mayonesas Balance
                    McCormick®, que tienen todos los beneficios del aceite de
                    ajonjolí, aguacate y soya.
                    <h3> Mayonesa Balance con Aceite de Ajonjolí</h3>
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg27,
                    texto: (
                        <div>
                            <h3>Mayonesa Balance con Aceite de Ajonjolí</h3> <br />
                            El aceite de ajonjolí es muy bueno para la digestión por lo
                            que ayuda a calmar la inflamación y además es rico en hierro y
                            calcio.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg28,
                    texto: (
                        <div>
                            <h3> Mayonesa Balance con Aceite de Aguacate</h3>
                            <br />
                            El aceite de aguacate, tiene todas las grasas buenas que tu
                            cuerpo necesita y además es ideal para mantener radiante la
                            piel y el cabello.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg29,
                    texto: (
                        <div>
                            <h3> Mayonesa Balance con Aceite de Soya</h3>
                            <br />Y por último el aceite de soya ayuda a regular los
                            niveles de colesterol en la sangre, por lo que mantiene tu
                            corazón en forma.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "especias-mexicanas-norte-sabor-unico",
            titulo: " Especias mexicanas del norte que dan un sabor único",
            imagen: imgbnr8,
            descripcion: (
                <div>
                    Conoce la gastronomía y todo el sabor del norte con Especias
                    McCormick®, el aliado perfecto para hacer de cualquier comida algo
                    extraordinario.
                    <h2> Cocina tradicional mexicana</h2> <br />
                    <br />
                    Nuestro México querido es un país increíblemente rico en cultura
                    gastronómica, su cocina se caracteriza por una amplia gama de
                    sabores, texturas, colores, influencias y por la gran variedad de
                    especias y chiles que utilizamos. La diversidad es la
                    característica esencial de nuestra comida, por eso queremos
                    contarte de una de las cocinas regionales más importantes. Mejor
                    conocidos como los maestros de las especias y la carne, daremos un
                    recorrido por la deliciosa comida del norte, donde se junta la
                    tradición con la innovación.
                    <h2> Nuevo León y la cocina Tex- Mex</h2>
                    <br />
                    <br />
                    Para hablar de cocina del norte podemos poner como ejemplo a Nuevo
                    León, cuyas comidas sorprenden por lo generoso de las porciones,
                    el gran tamaño de las típicas tortillas de harina de trigo, el uso
                    abundante de sazonadores y los grandes pedazos de carne en
                    platillos como cabrito, fajitas, agujas norteñas y tamales
                    norteños. Nuevo León al estar tan cerca de Estados Unidos, también
                    es representativo por la mezcla cultural entre dos países, cuyas
                    fronteras enriquecen su gastronomía. Esto ha originado
                    prácticamente una nueva cocina: la cocina Tex-Mex, que se
                    caracteriza por el empleo abundante de carne de res, tortillas de
                    maíz y de harina al estilo mexicano, nachos, tacos, fajitas de res
                    y pollo, chiles, salsa picante, etc.
                    <h2> Condimentos y especias: el secreto del norte</h2>
                    <br />
                    <br />
                    Para preparar estos deliciosos platillos y realzar todos los
                    sabores del norte, solo hay una opción, Sazonadores del Norte
                    McCormick®, que tienen la cantidad ideal de condimentos y especias
                    para darle ese toque especial a la comida y vienen en dos
                    variedades: Sazonador fajita de res McCormick® que se puede
                    utilizar en tacos de arrachera, asado de puerco, machacado, entre
                    otros y Sazonador para pollo McCormick® que se puede utilizar en
                    platillos como pollo asado, fajitas de pollo, discada de pollo y
                    mucho más.
                    <h2> Maestros de la parrilla</h2>
                    <br />
                    <br />
                    Se puede decir que la carne es el elemento central de la
                    gastronomía del norte, por lo que muchas personas son expertas en
                    el arte de usar sazonadores y especias para darle a la comida un
                    sabor único y especial. Son expertos en carne y parrilladas, por
                    lo que ellos ya saben que a la hora de enfrentarse al asador y
                    condimentar sus preparaciones no hay mejor aliado que Grill Mates
                    de McCormick®, que es la línea de sazonadores pensada para
                    aquellos que se consideran unos maestros del asador, ya que con
                    ellos puedes marinar a la perfección todos tus cortes de carne,
                    sus distintas variedades son deliciosas y muy prácticas de usar,
                    además que con ellas llevarás tu asado a otro nivel. Ahora que ya
                    sabes el secreto para preparar los más deliciosos platillos regios
                    con Sazonadores del Norte McCormick® y hacer una deliciosa carnita
                    asada con Grill Mates de McCormick® te compartimos algunas recetas
                    para que te lances ya a la cocina y todo te quede increíble.
                    <h2> Recetas con Sazonadores del Norte:</h2>
                    <br />
                    <br />
                    <h3> Tacos de pollo con aderezo de mango:</h3>
                    <br />
                    <br />
                    Con el Sazonador para pollo McCormick® prepara unos deliciosos y
                    agripicantes tacos de pollo con aderezo de mango. Solamente
                    necesitas tiras de pechuga de pollo marinadas con 1 cda. de salsa
                    tipo inglesa French´s, 1 cda. sopera del sazonador, 1 cda. de
                    aceite vegetal y ½ cebolla mediana rebanada. Dora el pollo en una
                    sartén y cuando esté a punto de cocinarse agrega la cebolla, deja
                    que se dore un poco más y antes de sacar de la sartén añade 2
                    cdas. de Mermelada de Mango con chile Habanero McCormick®. Sirve
                    los tacos de pollo sobre tortillas de harina y acompaña con gajos
                    de limón.
                    <h3> Papas al horno con arrachera</h3>
                    <br />
                    <br />
                    Con el Sazonador fajita de res McCormick® puedes hacer unas papas
                    al horno con arrachera. Marina fajitas de arrachera con 1 cda.
                    sopera del sazonador, asa la carne en parrilla o sartén. Cocina
                    las papas al horno, envueltas en papel aluminio por una hora a
                    180°C, cuando las papas estén cocidas, córtalas en forma de cruz y
                    rellénalas con una mezcla de crema, queso rallado para gratinar y
                    una cda. del sazonador. Sirve la papa con tiras de arrachera
                    encima.
                    <h2> Tips para mejorar tu próxima parrillada</h2>
                    <br />
                    <br />
                    Para que tus parrilladas exploten de sabor y tengan una textura
                    crujiente te dejamos unos tips con Grill Mates de McCormick®:
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg24,
                    texto: (
                        <div>
                            Con el Sazonador Grill Mates Spicy Montreal Steak® puedes
                            marinar bisteces o milanesas de res, con el jugo de un limón y
                            una cda. sopera de aceite, espolvoreando dos cdas. por cada
                            medio kilo de carne o al gusto.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg25,
                    texto: (
                        <div>
                            Con Grill Mates Hamburger®, puedes agregar dos cdas. del
                            sazonador a 500 gramos de carne molida de res, cerdo, pollo o
                            pavo. Mezcla muy bien, forma las hamburguesas y cocínalas con
                            una cdta. de aceite. Puedes prepararlas en sartén o en un
                            asador casero. Acompaña con vegetales.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg26,
                    texto: (
                        <div>
                            Grill Mates Montreal Steak® es ideal para los fanáticos de la
                            parrilla. Marina 8 camarones grandes, abiertos tipo mariposa
                            con el jugo de un limón, una cdta. de aceite de oliva y una
                            cdta. del sazonador. Asa los camarones en una parrilla o
                            sartén y acompáñalos con Mayonesa McCormick® con jugo de
                            limón.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "curcuma-especia-sabor-unico",
            titulo: "Cúrcuma: La especia que da un sabor único",
            imagen: imgbnr7,
            descripcion: (
                <div>
                    Preparar algo en la cocina es un momento único para muchos, aunque
                    hay veces en que no sabemos qué ponerle a nuestros platillos para
                    darles ese toque especial. Por eso, hoy te contaremos de una
                    especia que hará de tus comidas toda una experiencia, sin
                    complicarte tanto. Nos referimos a la cúrcuma, que es un tallo
                    seco de una planta tropical de la familia del jengibre. <br />
                    <br />
                    <b>Cúrcuma, ¿y eso con qué se come?</b> <br />
                    <br />
                    Sus propiedades fueron utilizadas por primera vez 0en la India en
                    tiempos prehistóricos, en aquella época se usaba como colorante
                    para lana, de ahí su nombre, ya que la palabra cúrcuma, viene del
                    sánscrito y significa amarillo. La cúrcuma tiene un aroma seco y
                    un sabor ligeramente amargo y picante. En la India, es muy
                    utilizada en el pescado, arroz, carne y es la base de muchos
                    platillos.
                    <br />
                    <br />
                    <b>Sus beneficios</b>
                    <br />
                    <br />
                    A los componentes de la cúrcuma se le atribuyen muchísimos
                    beneficios para el cuerpo. Te ayuda a digerir bien los alimentos,
                    alivia la sensación de empacho, actúa como un excelente
                    antiinflamatorio estomacal e intestinal, reduce el dolor en
                    articulaciones y tiene antioxidantes, por lo que se dice que posee
                    un efecto rejuvenecedor en la piel.
                    <br />
                    <br />
                    <b>Leche dorada</b>
                    <br />
                    <br />
                    La leche dorada o golden milk es una bebida muy saludable, hecha a
                    base de leche y cúrcuma, entre otros ingredientes. En Occidente,
                    se ha vuelto tendencia tomarla por su delicioso sabor y por el
                    bienestar que aporta, ya que las propiedades de esta bebida ayudan
                    a fortalecer el sistema inmunológico, mejorar el estado de ánimo,
                    prevenir la caída del cabello, ayudar a la circulación de la
                    sangre, entre otros.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg21,
                    texto: (
                        <div>
                            Para prepararla sólo necesitas calentar leche en un cazo con
                            una rama de Canela entera McCormick®, dos Clavos enteros
                            McCormick®, dos granos de Pimienta entera McCormick®, una
                            cdta. de Jengibre molido McCormick® y un poco de cardamomo.
                            Baja el fuego antes de que llegue a ebullición y añade una
                            cdta. de Cúrcuma McCormick®, remueve bien y déjala unos
                            minutos a fuego lento. Para endulzar, añade un poco de Miel
                            Carlota®.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg22,
                    texto: (
                        <div>
                            Con Cúrcuma McCormick®, puedes preparar muchos platillos y
                            potenciar su sabor, como un delicioso arroz blanco; antes de
                            agregarle agua o caldo, lo aromatizas y das un color amarillo
                            con ½ cdta.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg23,
                    texto: (
                        <div>
                            También puedes cocinar un consomé amarillo con pollo y
                            verduras, sólo la añades al momento de la cocción y obtendrás
                            un consomé muy sustancioso y sabroso.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: (
                <div>
                    Si quieres salirte de lo cotidiano y darle ese giro especial a tus
                    platillos, la Cúrcuma McCormick® es lo que necesitas. Cambia la
                    experiencia de tus comidas del día a día y maximiza su sabor,
                    diviértete explorando tu creatividad al cocinar y conoce todo lo
                    que pueden aportar las especias a tu vida.
                </div>
            ),
        },
        {
            nombre: "platillos-navidenos-faciles-cena-familia",
            titulo: "Platillos navideños fáciles para una cena en familia",
            imagen: imgbnr6,
            descripcion: (
                <div>
                    <b>Navidad McCormick®</b>
                    <br />
                    <br />
                    Navidad, esa época del año que tanto esperamos y anhelamos por dos
                    razones principales que nunca van a cambiar: el amor y el sabor.
                    <br />
                    <br />
                    El amor porque son días para compartir, para dar, para abrazarnos
                    más fuerte que nunca y tan cálido como siempre. Y la segunda e
                    igual de importante, el sabor, porque alguna vez, un genio dijo:
                    “a barriga llena, corazón contento”, y definitivamente sabía lo
                    que decía. Y lo sabía porque no hay nada comparado al sabor de la
                    comida... hecha con amor.
                    <br />
                    <br />
                    Quizá seas de los que planean la cena con tiempo, tal vez seas de
                    los que solo llevan las bebidas a casa de tus padres o tus
                    suegros, puede que recién te hayas casado y sea tu primera Navidad
                    así, al final, sea como sea que decidas pasar esta fecha y con
                    quien decidas hacerlo, haz que valga la pena; haz que esta Navidad
                    sea para recordar.
                    <br />
                    <br />Y qué mejor que hacerla memorable con recetas clásicas pero
                    con un toque especial, con los mejores sabores que tiene
                    McCormick®.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg18,
                    texto: (
                        <div>
                            Por ejemplo, puedes utilizar nuestra Mayonesa de la Línea
                            Balance McCormick® para preparar la clásica pasta de coditos o
                            nuestra Mayonesa McCormick® con jugo de limón para una
                            ensalada de camote.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg19,
                    texto: (
                        <div>
                            Si decides preparar el famoso pavo, cerdo o lomo, vas a
                            necesitar marinarlo y puedes hacerlo usando nuestra Pimienta
                            con Limón, Clavo Molido, Canela Molida, Mostaza y agregar
                            alguna de nuestras Mermeladas con Chile McCormick®, tú decides
                            el sabor según tu antojo.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg20,
                    texto: (
                        <div>
                            Y bueno, no pueden faltar los postres, puedes preparar un
                            panqué navideño con fruta e incluir en la receta alguna de
                            nuestras Mermeladas Dulces McCormick® y para complementar, un
                            ponche con Miel Carlota® o un té calientito de Canela a la
                            Manzana McCormick®.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: (
                <div>
                    Y como sabemos que vas a querer las recetas completas, puedes
                    visitar nuestras redes sociales para encontrarlas, descubrir más
                    tips y recomendaciones para todo momento.
                    <br />
                    <br />
                    Así que, ya sabes, para estas fechas decembrinas y fiestas
                    navideñas, celebremos con entusiasmo el sabor de la vida con
                    McCormick®, y no olvides siempre consentir a los tuyos.
                    <br />
                    <br />
                    ¡FELIZ NAVIDAD Y FELICES FIESTAS TE DESEA McCORMICK®!
                    <br />
                    <br />
                    FB: McCormick México IG: @McCormick_mx YT: McCormick México
                </div>
            ),
        },
        {
            nombre: "las-mejores-opciones-para-festejar-el-día-del-niño",
            titulo: "Las mejores opciones para festejar el día del niño en casa",
            imagen: imgbnr1,
            descripcion: (
                <div>
                    <b>
                        Celebra con tus pequeños y saca tu niño interior con el postre
                        más delicioso y versátil.
                    </b>
                    <br />
                    El día del niño ya está a la vuelta de la esquina, por eso es
                    importante tener algo preparado para sorprender a tu pequeño y que
                    juntos se diviertan como nunca. Este día tan especial siempre está
                    lleno de risas, alegría, diversión y postres. Por eso te daremos
                    algunas opciones deliciosas para preparar en la cocina y
                    divertirte en familia.
                    <br />
                    <b>¡Lo único que necesitas es gelatina!</b> Divertida por su
                    consistencia, frescura, colores vivos y es ideal para prepararla
                    con tus niños.{" "}
                    <b>
                        McCormick cuenta con una amplia variedad de sabores, los
                        clásicos como fresa, piña y limón y sus variedades de malteada:
                        vainilla, chocolate y fresa.
                    </b>
                    <br />
                    Pero si lo que buscas es sorprender, tienes que probar{" "}
                    <b>las gelatinas con brillitos</b> en sus deliciosos sabores como
                    mora azul, piña, cereza, limón o si te gustan los sabores
                    arriesgados y picositos las gelatinas con chilito de sandía y
                    mango son la opción ideal.
                    <br />
                    Ya que tienes el postre perfecto, te dejamos algunas ideas para
                    que se diviertan desde casa en familia.
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen:
                        "https://herdezstorage.blob.core.windows.net/publicresources/mccormick/recetas/Imagen Receta/62_Mosaico_Gelatina.jpg",
                    texto: (
                        <div>
                            Arma divertidas figuras, puedes preparar gelatinas de
                            diferentes sabores y colocarlas en moldes de cubitos de hielo,
                            así cuando esté sólida tendrás bloques de colores brillantes
                            para que tú y tu pequeño dejen volar su imaginación. Recuerda
                            que tu gelatina deberá llevar menos cantidad de agua para que
                            sea más consistente.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen:
                        "https://herdezstorage.blob.core.windows.net/publicresources/mccormick/recetas/Imagen Receta/67_Gelatina_Arcoiris.png",
                    texto: (
                        <div>
                            Si tienes moldes de paleta en casa puedes preparar mitad de
                            gelatina sabor malteada de vainilla McCormick y posteriormente
                            dos de tus sabores preferidos de gelatina de agua McCormick
                            con alguna fruta y deja que los niños te ayuden a prepararlas.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen:
                        "https://herdezstorage.blob.core.windows.net/publicresources/mccormick/recetas/Imagen Receta/63_Gelatinas_Pi_a_Topping_Mermelada.jpg",
                    texto: (
                        <div>
                            Puedes hacer gelatinas en vasitos con los colores de su
                            superhéroe o personaje favorito, pídeles a tus niños que lo
                            dibujen en un cartón y pégalos en el vaso para que tenga su
                            diseño.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: (
                <div>
                    <b>
                        Tip: Para que la gelatina no se te pegue en los moldes, puedes
                        sumergir unos segundos en agua caliente, utilizar un spray
                        antiadherente neutro o unas gotitas de aceite vegetal.
                    </b>
                    <br />
                    <b>Consejos para festejar en casa con tus niños</b>
                    <br />
                    Baila con tus hijos, hagan manualidades, produzcan su propia obra
                    de teatro, preparen el cine en casa, bajen las luces y coman
                    palomitas, si tienes el espacio podrías crear un fuerte con
                    sábanas y almohadas. <b>Conoce sus intereses</b>, si a tu hijo le
                    gusta jugar videojuegos, jueguen juntos, si lo suyo es hacer
                    videos en TikTok, háganlos.
                    <br />
                    Feliz día del niño.
                </div>
            ),
        },
        {
            nombre: "mayonesa-limon-acompanante-ideal-botanas",
            titulo: "Mayonesa con limón: el acompañante ideal para botanas",
            imagen: imgbnr2,
            descripcion: (
                <div>
                    <b>Porque comer delicioso es lo mejor.</b>
                    <br />
                    Ahora que nos toca estar en casa, podemos darnos tiempo para
                    preparar deliciosas botanas y disfrutar en familia, por eso hoy te
                    compartimos algunos tips sencillos y prácticos para que dejes
                    satisfecho tu antojo con Mayonesa McCormick® con jugo de limón.
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg1,
                    texto: (
                        <div>
                            Mezcla Mayonesa McCormick® con jugo de limón con salsa picante
                            y chile piquín, para botanas de tu preferencia, unta tostadas
                            con esta mayonesa, va perfecto para una tarde de Netflix.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg2,
                    texto: (
                        <div>
                            Muele Mayonesa McCormick® con jugo de limón con un poco de
                            chile serrano, más 1/2 tz. de hojas de cilantro, te quedará un
                            sabroso dip para salir de lo convencional y darle un nuevo
                            sabor a tus papas fritas.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg3,
                    texto: (
                        <div>
                            Prepara un relleno para sándwiches con pollo desmenuzado, sólo
                            necesitas Mayonesa McCormick® con jugo de limón, Paprika
                            McCormick®, Pimienta negra molida McCormick® y un poco de
                            cebollín picado. Rellena tus sándwiches o bollitos de pan y
                            acompaña con la ensalada de tu preferencia, un plato rápido y
                            delicioso para que sigan divirtiéndose.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
                {
                    imagen: recetasimg4,
                    texto: (
                        <div>
                            Dip de tocino para botanas, sólo necesitas ½ tz. de Mayonesa
                            McCormick® con jugo de limó, 6 rebanadas de tocino doradito y
                            escurrido, 2 cdas. de chile chipotle adobado de lata, una
                            pizca de sal, Paprika McCormick® y Pimienta negra molida
                            McCormick®. Muele todo en el vaso de la licuadora muy bien y
                            acompaña con bastones de zanahoria, apio o galletas saladas.
                            Si se te antojó, no esperes más y ve a la cocina.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: (
                <div>
                    Con estos pequeños tips, ya estás listo para una noche de pelis,
                    una tarde de juegos o una exquisita cena, que consienta a tu
                    antojo.
                </div>
            ),
        },
        {
            nombre: "aderezo-mayonesa-ingrediente-postres",
            titulo: "Aderezo de mayonesa: el ingrediente perfecto para postres",
            imagen: imgbnr3,
            descripcion: (
                <div>
                    Lo más divertido en la cocina es experimentar con ingredientes
                    nuevos y descubrir pequeños trucos que facilitan la vida. Por eso
                    te vamos a contar un secreto para que todos tus postres queden
                    deliciosos y que seguro nunca lo habías pensado: Aderezo de
                    Mayonesa McCormick®.
                    <br />
                    Sí, lo leíste bien, al estar hecha con yema de huevo y aceite
                    vegetal, es el sustituto perfecto de estos ingredientes, te sacará
                    de cualquier apuro y mejor aún, su composición hará que tus
                    postres sean más ricos ya que realza los sabores dulces, hace más
                    suave la masa y es versátil al adaptarse a una gran variedad de
                    ingredientes, harinas, coberturas y sabores. Además, es muy
                    práctica y sencilla de usar, inténtalo y verás la diferencia.
                    <br />
                    <b>Sencillos tips para mejorar tus postres.</b>
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg6,
                    texto: (
                        <div>
                            En lugar de huevo y aceite añade ½ tz. de Aderezo de Mayonesa
                            McCormick® a la preparación de un pastel de chocolate o en la
                            masa para un panqué de naranja, quedarán más húmedos y
                            esponjosos
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg7,
                    texto: (
                        <div>
                            Para preparar un cremoso betún o cubierta de los cupcakes, en
                            un recipiente hondo agrega 1 tz. de Aderezo de Mayonesa
                            McCormick® y ½ tz. de azúcar glass cernida. Puedes añadir 2
                            gotas de colorante vegetal o 2 cdas. soperas de cocoa cernida
                            y 1/2 cdta. de esencia de vainilla.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
                {
                    imagen: recetasimg8,
                    texto: (
                        <div>
                            Se puede agregar a la masa para galletas y pastas secas, ya
                            que no altera su consistencia crujiente y porosa. Si la receta
                            de la masa lleva huevo, por cada pieza sustituye 1 cda. de
                            Aderezo de Mayonesa McCormick®
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "como-usar-mostaza-comida",
            titulo: "¿Cómo usar mostaza en la comida?",
            imagen: imgbnr4,
            descripcion: (
                <div>
                    La <b>Mostaza McCormick®</b> es el complemento ideal para darle un
                    toque delicioso a las comidas cuando sientes que algo les hace
                    falta. Tiene un sabor que acentúa y realza todos tus platillos y
                    lo mejor de todo es que su aporte calórico no es significativo en
                    comparación con otros aderezos. Es un must para transformar tus
                    preparaciones, con sólo 1 cdta. en carnes asadas, ensaladas y
                    pescados puedes crear platillos con un sabor único.
                    <br />
                    Te compartimos algunas opciones para ponerle mostaza a platillos
                    saludables y seguirte cuidando:
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg9,
                    texto: (
                        <div>
                            Mezcla 1 cda. de Mostaza McCormick® con 1 cdta. de aceite
                            vegetal y el jugo de 1 limón con sal y pimienta, te quedará un
                            aderezo ligero y saludable, perfecto para una ensalada o
                            pasta.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg10,
                    texto: (
                        <div>
                            Agrégasela a chuletas de cerdo, bisteces de res o pechuga de
                            pollo. En un tazón pon 2 cdtas. de Mostaza McCormick®, ½ cdta.
                            de aceite de oliva, el jugo de 1 limón, 1 cdta. de perejil
                            picado finamente, sal y pimienta al gusto. Con esta
                            preparación marina la proteína que hayas elegido, puedes
                            cocinarlo a la parrilla o en sartén. La carne quedará jugosa,
                            con un sabor diferente y delicioso. Acompáñala con ensalada
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    imagen: recetasimg12,
                    texto: (
                        <div>
                            Úsala en vegetales verdes como espárragos, ejotes y espinacas.
                            Por cada 100 g combina con 2 cdtas. de Mostaza McCormick®, 1
                            cdta. de salsa tipo inglesa, 1 cdta. de margarina o
                            mantequilla baja en grasa. Saltea los vegetales en una sartén,
                            agrega la preparación de mostaza, sal y pimienta al gusto,
                            espolvoréalos con ½ cdta. de ajonjolí tostado o almendras
                            tostadas.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
                {
                    imagen: recetasimg13,
                    texto: (
                        <div>
                            Añádela a chiles poblanos o pimientos mexicanos. Necesitas 1
                            tz. de rajas de poblano limpias o de pimientos morrones de
                            colores, puedes agregar 100 g de pechuga de pollo desmenuzada,
                            ½ cdta. de aceite de oliva, 2 cdas. de cebolla rebanada, 2
                            cdtas. de Mostaza McCormick®, 1 cda. de crema baja en grasa,
                            sal y pimienta al gusto. Saltea las rajas con cebolla y pollo
                            en una sartén con aceite, agrega crema, Mostaza McCormick®,
                            sal y pimienta. Acompáñalo con 2 tortillas de maíz o nopal y
                            obtendrás un guisado saludable y variado.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: <div></div>,
        },
        {
            nombre: "cocina-molecular-4-tecnicas-casa",
            titulo: "Cocina molecular: 4 Técnicas que puedes hacer en casa",
            imagen: imgbnr5,
            descripcion: (
                <div>
                    <b>
                        Descubre con McCormick® todas las posibilidades que te ofrece la
                        cocina molecular.
                    </b>
                    <br />
                    El futuro es ahora y podemos experimentarlo en la cocina.
                    Pareciera que el término cocina molecular es un tanto complejo y
                    quizás te haga pensar en laboratorios y científicos, pero la
                    verdad es que podemos hacerlo nosotros mismos desde casa.
                    Básicamente, este tipo de cocina nace cuando combinas la ciencia
                    con el arte culinario; ésta busca conocer los mecanismos químicos
                    al momento de cocinar para llegar a la deconstrucción, que se
                    refiere a separar los elementos de un platillo en sus diferentes
                    texturas.
                    <br />
                    <b>Deconstruyendo la cocina molecular</b>
                    <br />
                    Existen diferentes métodos que se utilizan en la cocina molecular,
                    en este caso abordaremos sólo algunos:
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: recetasimg14,
                    texto: (
                        <div>
                            Cocinar al vacío, se trata de cocinar en bolsas selladas al
                            vacío a bajas temperaturas durante largos periodos de tiempo.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/mosaico-de-gelatina",
                },
                {
                    imagen: recetasimg15,
                    texto: (
                        <div>
                            Por ejemplo, unas esferas de miel con tomillo, espumas de
                            mostaza con miel, la Mayonesa McCormick® puede funcionar como
                            un emulsionante para dar una textura cremosa, con nitrógeno
                            líquido y Mermeladas McCormick® se pueden preparar diversos
                            helados con texturas crujientes.
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-arcoiris",
                },
                {
                    parrafo: true,
                    texto: (
                        <div>
                            Por otro lado, la hipercongelación con nitrógeno se utiliza en
                            helados, purés, mousses, gelatinas y creaciones crujientes por
                            fuera y suavecitas por dentro, son toda una experiencia. La
                            esferificación se usa para transformar los líquidos en
                            increíbles esferas, el papel comestible para decorar un
                            platillo, al igual que espumas preparadas con lecitina de
                            soya. Varios elementos de la cocina molecular se utilizan en
                            las cocinas gourmet actuales y son un espectáculo tanto para
                            la vista como para el paladar.
                            <br />
                            La cocina molecular se trata de experimentar con texturas y
                            sabores que la cocina tradicional ha incorporado para mejorar
                            sus platillos sin perder características y sabor. En ella se
                            utilizan especias para mejorar los sabores y procesos para
                            mejorar las texturas.
                            <br />
                            En fin, el cielo es el límite y lo mejor es que te daremos un
                            par de recetas para que experimentes en casa algunos platillos
                            de cocina molecular, te sientas como todo un experto y
                            sorprendas a tu familia.
                            <br />
                            <b> Las mejores recetas moleculares en tu casa:</b>
                        </div>
                    ),
                },
                {
                    imagen: recetasimg16,
                    texto: (
                        <div>
                            Mousse de jamón
                            <ul>
                                <li>
                                    {" "}
                                    Pica finamente 100 g de jamón de pierna y 50 g de nuez,
                                    dóralos en una sartén con una cdta. de aceite vegetal,
                                    añade ¼ de cdta. de Sal con ajo McCormick®, ¼ de cdta. de
                                    Pimienta negra molida McCormick®, ¼ de cdta. de Paprika
                                    McCormick®, retira del fuego y deja refrescar unos
                                    minutos.
                                </li>
                                <li>
                                    Muele en el vaso de la licuadora la preparación de jamón
                                    con 1/2 tz. de crema para batir, 3 cdas. de Mayonesa
                                    McCormick® de la variedad de tu preferencia (con jugo de
                                    limón o de la línea Balance McCormick®) y 1 cdta. de
                                    grenetina en polvo hidratada en 2 cdas. de agua. Muele muy
                                    bien hasta obtener una salsa espesa. Vierte en un molde
                                    previamente engrasado con aceite vegetal y refrigera 2
                                    horas. Obtendrás un mousse de textura cremosa, puedes
                                    decorar desde un simple sándwich o hot dog, hasta una
                                    pechuga de pollo o un corte de carne a la parrilla. Rinde
                                    4 porciones aprox.
                                </li>
                            </ul>
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
                {
                    imagen: recetasimg17,
                    texto: (
                        <div>
                            Aire de leche con especias
                            <ul>
                                <li>
                                    {" "}
                                    Necesitarás 200 ml de crema para batir, 30 ml de leche de
                                    coco, 2 g de lecitina de soya* en polvo, 1 rama de Canela
                                    entera McCormick, 1 pizca de Pimienta Negra molida
                                    McCormick®, 1 cda. sopera de Miel Carlota® y Canela molida
                                    McCormick® al gusto para decorar. Mezcla la crema con la
                                    leche de coco, canela en rama, pimienta negra molida y
                                    miel, cocina a fuego suave a una temperatura de 60 °C,
                                    deja enfriar, retira la rama de canela, añade la lecitina
                                    de soya y bate a velocidad alta con una batidora de
                                    inmersión, en licuadora o con un batidor manual de globo,
                                    déjalo reposar y calienta a 50°C, luego vuelve a batir
                                    hasta obtener el aire. Puedes decorar una Gelatina con
                                    brillitos de McCormick® con este aire, rinde 4 porciones
                                    aprox.
                                </li>
                            </ul>
                        </div>
                    ),
                    url: "https://mccormick.mx/receta/gelatina-de-pi%C3%B1a-con-topping-de-mermelada-mccormick",
                },
            ],
            foter: (
                <div>
                    La lecitina de soya, alginato de sodio, agar-agar, goma arábiga,
                    ácido ascórbico y materias primas para preparar este tipo de
                    cocina molecular se consiguen en droguerías, proveedores
                    especializados por internet y tiendas de productos y materias
                    primas para la industria restaurantera.
                    <br />
                    Puedes utilizar cualquier producto de la línea <b>McCormick®</b>,
                    mayonesas, tés, especias, sazonadores, mostaza o gelatinas con
                    brillitos para incursionar en la cocina molecular. Solo debes
                    poner a trabajar tu creatividad e imaginación para tener tu propio
                    laboratorio de sabores en casa.
                </div>
            ),
        },
        {
            nombre: "sentirte-mejor-mayonesas-mccormick.-balance",
            titulo:
                "Comienza a sentirte mejor este 2022 con las Mayonesas McCormick Balance",
            imagen: recetasimg61,
            descripcion: (
                <div>
                    Para muchos de nosotros, enero es una nueva oportunidad para
                    comenzar a cuidarnos y hacer conciencia de nuestra salud, y qué
                    mejor forma de hacerlo que comenzando por nuestra alimentación,
                    porque como ya sabes, se dice que somos lo que comemos. Aunque se
                    tenga la creencia de que cuidarse significa renunciar al sabor,
                    hoy con las <b>Mayonesas McCormick Balance</b>, queremos
                    demostrarte que eso no es verdad, pues puedes cuidarte y entrar en
                    balance con todo el sabor. <br />
                    <br />
                    <h2>Comienza a vivir una vida más saludable</h2>
                    <br />
                    Comer bien y de manera balanceada, es fundamental para mantenerse
                    sano y alejar todos los padecimientos crónicos que se pueden
                    generar a causa del sobrepeso o la obesidad, pero eso no significa
                    renunciar a los postres o panes, sino saber comer porciones
                    adecuadas y sustituir ingredientes, como las azúcares refinadas,
                    grasas o aceites para cocinar de manera inteligente.
                    <br />
                    <br />
                    De esta manera, le damos a nuestro organismo la oportunidad de
                    acostumbrarse a otros sabores e ingredientes más saludables. Estos
                    aceites se han convertido en una excelente opción para las{" "}
                    <a href="https://www.mccormick.mx/productos/mayonesas">
                        mayonesas
                    </a>
                    , porque son saludables y pueden ir con cualquier comida:
                    pescados, mariscos, ensaladas, sándwiches y para gratinar
                    platillos. Por eso McCormick y su línea de{" "}
                    <b>Mayonesas Balance</b>, te invitan a combinarlas con lo que
                    quieras.
                    <br />
                    <br />
                    <h2>Comer saludable no significa estar a dieta</h2>
                    <br />
                    Si estás por empezar un plan alimenticio, debes saber que en
                    muchos de ellos se recomienda reducir el consumo de grasas para
                    bajar de peso, sin embargo, está demostrado que los planes con
                    baja ingesta de carbohidratos son mejores, pues las pérdidas de
                    grasa pueden ser de casi el doble. Además, de esta manera, no solo
                    cuidas tu peso, sino que también comienzas a{" "}
                    <b>mejorar tus hábitos</b>, lo que se traducen en mejoras para tu
                    organismo, pues cuando aprendes a comer bien, tienes menor riesgo
                    de un rebote.
                    <br />
                    <br />
                    Por ello es necesario cambiar nuestros hábitos alimenticios, y
                    pensar en consumir alimentos que representen una baja ingesta de
                    azúcares o sacarosa, así como también pensar en consumir{" "}
                    <b>grasas saludables</b>, grandes cantidades de frutas y
                    vegetales, y limitar el consumo de carnes rojas, sustituyéndolas
                    por carnes magras como el pescado y el pollo.
                    <br />
                    <br />
                    Otra gran opción para cuidar nuestra alimentación es a través de
                    las <b>dietas cetogénicas</b> o las <b>keto</b>, que consisten en
                    cambiar los hábitos sin sacrificar el sabor y sin sentir hambre.
                    Este tipo de dietas traen <b>beneficios metabólicos</b>,
                    relacionados con la insulina y hasta dermatológicos, ya que evitan
                    el acné <br />
                    <br />Y aunque estas dietas no limitan las grasas, lo más
                    recomendable es que en tu día a día incluyas aceites vegetales,
                    como los que tienen las <b>Mayonesas McCormick Balance</b>, que te
                    ayudarán como antioxidantes naturales, mejorar la circulación y la
                    piel. Como ves, no es nada difícil mantener un estilo de vida
                    saludable, sin perder el sabor, solo es cuestión de poner manos a
                    la obra. <br />
                    <br />
                    <h2> Las mejores combinaciones saludables con mayonesa</h2>
                    <br />A continuación, te damos unos tips y recetas fáciles
                    balanceadas para que cocines en 30 minutos y, que te lleves a la
                    oficina, trabajo o el lunch de los estudiantes, con{" "}
                    <b>mayonesas</b>, <b>especias</b>, y mucho sabor McCormick®.
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr23,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Pechugas de Pollo con Aderezo de Mayonesa McCormick® Balance
                                Aceite de Aguacate
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>4 pechugas medianas sin hueso ni piel</p>
                            <p>½ cdita. de Sal de Mar con Hierbas Finas McCormick®</p>
                            <p>¼ cdita. de Pimienta Negra Molida McCormick®</p>
                            <p>
                                ½ cdita. de Ajo en{" "}
                                <a href="https://www.mccormick.mx/producto/ajo-en-polvo">
                                    Polvo McCormick®
                                </a>
                            </p>
                            <p>1 cda. de aceite de oliva</p>
                            <p>
                                180 g de espinacas baby de bolsa o 3 tazas de espinacas
                                limpias, cortadas en tiras delgadas
                            </p>
                            <p>
                                1 pimiento rojo mediano, sin venas ni semillas, cortado en
                                tiras delgadas
                            </p>
                            <p>
                                1 tz. de{" "}
                                <a href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-aguacate">
                                    Aderezo de Mayonesa Balance Aceite de Aguacate McCormick®
                                </a>
                            </p>
                            <p>100 g de queso de cabra</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Sazona las pechugas por ambos lados con Sal de Mar con
                                    Hierbas Finas McCormick®, Pimienta Negra McCormick® y Ajo
                                    en Polvo McCormick®.
                                </li>
                                <li>
                                    Calienta el aceite de oliva en una sartén a fuego medio,
                                    dora las pechugas por ambos lados.
                                </li>
                                <li>
                                    Retiralas del fuego y en la misma sartén agrega los
                                    pimientos, revuelve para que se doren un poco, agrega
                                    espinacas, deja que se reduzcan, añade el{" "}
                                    <b>
                                        Aderezo de Mayonesa Balance Aceite de Aguacate
                                        McCormick®
                                    </b>{" "}
                                    y el queso de cabra. Mezcla todo para que se integre,
                                    regresa las pechugas, tapa la sartén y cocina por 15
                                    minutos a fuego bajo.
                                </li>
                                <li>Sirve caliente.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes usar alguna crema baja en grasa en lugar de queso
                                    de cabra y prepararte un termo o vaso con té frío de la
                                    línea McCormick®, del sabor de tu preferencia. Solo añade
                                    ½ cucharadita de Jengibre en Polvo McCormick® o Canela
                                    Molida McCormick® y tus bebidas tendrán un sabor fresco
                                    que a la vez te ayudará a prevenir enfermedades
                                    respiratorias.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr24,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Frittata con Aderezo de Mayonesa McCormick® Balance con
                                Aceite de Soya{" "}
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>6 huevos</p>
                            <p>Sal al gusto</p>
                            <p>¼ cdita. de Pimienta Negra Molida McCormick®</p>
                            <p>8 espárragos tiernos cortados por la mitad</p>
                            <p>1 pimiento rojo mediano cortado en cubos pequeños</p>
                            <p>1 cdita. de aceite de oliva</p>
                            <p>150 g de queso de cabra natural</p>
                            <p>
                                1 tz. de{" "}
                                <a href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-soya">
                                    Aderezo de Mayonesa McCormick® Balance con Aceite de Soya
                                </a>
                            </p>
                            <p>
                                100 g de tocino ligeramente dorado y escurrido de grasa en
                                papel absorbente
                            </p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Calienta una sartén con aceite de oliva, dora ligeramente
                                    los espárragos con el pimiento y retira del fuego.
                                </li>
                                <li>
                                    Mezcla en un tazón los huevos con sal y pimienta, y bate
                                    hasta que estén espumosos.
                                </li>
                                <li>
                                    Mezcla el queso de cabra con el{" "}
                                    <b>
                                        Aderezo de Mayonesa McCormick® Balance con Aceite de
                                        Soya.
                                    </b>
                                </li>
                                <li>
                                    Mezcla los huevos batidos con las verduras y el queso.
                                    Vierte en una sartén con antiadherente, coloca encima las
                                    rebanadas de tocino, tapa y cocina a fuego muy bajo, hasta
                                    que se cocine el huevo.
                                </li>
                                <li>
                                    Si tienes horno, vierte la mezcla en un refractario o
                                    molde para hornear con las rebanadas de tocino encima,
                                    hornea a 180°C por 15 minutos o hasta que esté dorado el
                                    tocino y la mezcla de huevo quede firme.
                                </li>
                                <li>
                                    Sirve caliente y decora con jitomates cherry o los
                                    vegetales que tengas en casa.
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes usar cualquier verdura para tu frittata, o tortilla
                                    de huevo italiana, calabacita, cebollas, pimientos, ejotes
                                    tiernos y puedes mezclar ½ taza de yogurt griego bajo en
                                    grasa y sin azúcar con ¼ de taza de{" "}
                                    <b>
                                        Aderezo de Mayonesa McCormick® Balance con Aceite de
                                        Soya.
                                    </b>{" "}
                                    Esta mezcla dejará cremosa tu tortilla de huevo y además
                                    será rico en proteína y sabor.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr25,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Ensalada Keto de Camarones con Aderezo de Mayonesa
                                McCormick® Balance Aceite de Ajonjolí
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>200 g de camarones medianos pelados y limpios sin venas</p>
                            <p>½ cdita. de Pimienta Negra Molida McCormick®</p>
                            <p>
                                1 tz. de{" "}
                                <a href="https://www.mccormick.mx/producto/mayonesa-balance-con-aceite-de-ajonjol%C3%AD">
                                    Aderezo de Mayonesa McCormick® Balance con Aceite de
                                    Ajonjolí
                                </a>
                            </p>
                            <p>1/2 tz. de jitomates cherry cortados por la mitad</p>
                            <p>80 g de aceitunas verdes sin hueso </p>
                            <p>2 cdas. de cilantro picado finamente</p>
                            <p>1 aguacate mediano maduro, pero firme cortado en cubos</p>
                            <p>
                                2 tz. de kale o mezcla de lechugas desinfectadas y bien
                                escurridas
                            </p>
                            <p>1 limón amarillo cortado en 4 partes</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Espolvorea con pimienta los camarones, agrega 2 cucharadas
                                    de{" "}
                                    <b>
                                        Aderezo de Mayonesa McCormick® Balance con Aceite de
                                        Ajonjolí.
                                    </b>
                                </li>
                                <li>
                                    Calienta una sartén o parrilla casera y asa los camarones
                                    por ambos lados hasta que estén dorados. Retiralos del
                                    fuego y déjalos enfriar..
                                </li>
                                <li>
                                    En un tazón tritura con un tenedor 2 cucharadas de cubos
                                    de aguacate con el resto del{" "}
                                    <b>
                                        Aderezo de Mayonesa McCormick® Balance con Aceite de
                                        Ajonjolí
                                    </b>
                                    , mezcla e incorpora las hojas de kale o lechugas,
                                    jitomate, aceitunas, cilantro y cubos de aguacate.
                                </li>
                                <li>Sirve en 4 platos y coloca encima los camarones.</li>
                                <li>Acompaña con gajos de limón.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    La proteína de esta ensalada puedes sustituirla por cubos
                                    de salmón, tiras de pechuga de pollo o cubos de atún
                                    fresco. Marina las proteínas con el{" "}
                                    <b>
                                        Aderezo de Mayonesa McCormick® Balance con Aceite de
                                        Ajonjolí
                                    </b>
                                    , les aportará humedad y las dejará más jugosos a la hora
                                    de asarlos o cocinarlos.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
        },
        {
            nombre: "soprende-postres-saludables-mermelada",
            titulo:
                "Sorprende a tu pequeño con estos postres saludables con mermelada",
            imagen: recetasimg58,
            descripcion: (
                <div>
                    <br />
                    <h2>Breve historia de la mermelada </h2>
                    <br />
                    Según recientes estudios históricos, los inventores de la
                    mermelada fueron los antiguos Egipcios. Los confiteros de Ramsés
                    II el Grande, elaboraban hace tres mil trescientos años confituras
                    de fruta, hierbas y especias que terminaron siendo muy populares
                    en el Egipto faraónico. La <b>conserva de frutas</b> era ya muy
                    estimada desde la antigüedad.
                    <br />
                    <br />
                    Hace miles de años era necesario sacar partido a la fruta,
                    elemento perecedero, lo que se conseguía utilizando la <b>miel</b>
                    : el alimento embadurnado con esta sustancia no se corrompía, sino
                    que de hecho se conservaba.
                    <br />
                    <br />
                    Cuando empezó a utilizarse el azúcar, ya en la Edad Media,
                    confitar era tarea que se llevaba a cabo cubriendo con un baño de
                    azúcar las frutas que se querían conservar y cuyo resultado era el
                    “confite”.
                    <br />
                    <br />
                    La palabra <b>mermelada</b> proviene del término portugués
                    “marmelada”, cuyo significado es confitura de membrillo. Tanto en
                    gallego como en portugués, membrillo se dice “marmelo”, que
                    proviene a su vez de la voz en latín “melimelum” (una variedad de
                    manzana). Del mismo modo, “melimelum” proviene del griego
                    “melimelon” que significa manzana.
                    <br />
                    <br />
                    <h2>Mermeladas amigables con tu salud</h2>
                    <br />
                    Existen en el mercado <b>mermeladas sin azúcar</b> (sacarosa) o
                    endulzadas con Stevia. Esta hierba es originaria de Paraguay y su
                    uso se ha popularizado por el dulzor de sus hojas, que son entre
                    15 y 30 veces más dulces que el azúcar. Su ingesta no aporta
                    calorías, pero su sabor es intensamente dulce. En este punto es
                    también interesante destacar la utilidad de su uso en dietas
                    infantiles, ya que la obesidad infantil está en gran parte
                    relacionada con el consumo excesivo de azúcares refinados. <br />
                    <br />
                    Esta es una muy buena noticia para los niños en este mes de abril;
                    ya que pueden consumir mermeladas riquísimas para aprovechar las
                    propiedades de las frutas, cargadas de vitaminas, minerales y
                    antioxidantes, sin los peligros asociados a la sacarosa. Sin
                    embargo, las mermeladas con azúcar consumidas en el desayuno con
                    pan tostado son una buena fuente de energía durante el día y
                    podemos quemar los azúcares con ejercicio, solamente debemos tener
                    prudencia de no comer demasiado y a todas horas.
                    <br />
                    <br />
                    <h2>
                        Beneficios de la mermelada y sus diferentes tipos y sabores
                    </h2>
                    <br />
                    Las mermeladas son muy útiles también en la preparación de{" "}
                    <b>postres</b>: panes, pasteles, tartas, tostadas, etc. Los niños
                    y los adultos podrán festejar con opciones dulces deliciosas y
                    saludables hechas con frutas de temporada y conservadas como{" "}
                    <a href="https://www.mccormick.mx/productos/mermeladas">
                        mermeladas
                    </a>{" "}
                    como: <b>fresas</b>, cítricos, <b>frutos rojos</b>, azules o hasta
                    amarillos; todos altamente coloridos y con grandes cantidades de
                    antioxidantes que serán una delicia para grandes y chicos.
                    <br />
                    <br />
                    Algunas{" "}
                    <a href="https://www.mccormick.mx/productos/especias-y-condimentos">
                        especias
                    </a>{" "}
                    también se utilizan en mermeladas, como: el cardamomo, jengibre,
                    nuez moscada, canela, vainilla, pimienta, entre otras. Aportan un
                    extra de sabor genial, además aportar propiedades aromáticas,
                    antisépticas, antioxidantes, antiinflamatorias, aperitivas,
                    antiparasitarias y antidepresivas. Recordemos que las plantas son
                    fábricas químicas extraordinarias, que utilizan la fotosíntesis y
                    las enzimas para fabricar infinidad de sustancias químicas muy
                    útiles para la salud.
                    <br />
                    <br />
                    A continuación te damos algunas recetas y tips para degustar un
                    delicioso postre con mermelada, ideales para que cocines con los
                    niños y compartan días muy amenos en familia.
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr26,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                {" "}
                                Mini Pastelitos de Mantequilla y Mermelada de Piña
                                McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 6 a 8</b>
                            </p>
                            <p>
                                <b>Material:</b> 1 cortador redondo de 6 cm de diámetro
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>2 tazas de harina de trigo</p>
                            <p>2 cucharaditas de polvo de hornear</p>
                            <p>1 pizca de sal </p>
                            <p>
                                170 gr de mantequilla a temperatura ambiente o margarina
                            </p>
                            <p>1 cucharada de aceite vegetal</p>
                            <p>1 taza de azúcar </p>
                            <p>4 huevos</p>
                            <p>1 cucharadita de esencia de vainilla</p>
                            <p>1 cucharadita de ralladura de naranja o limón </p>
                            <p>¾ de taza de leche con el jugo de ½ limón</p>
                            <p>
                                1 taza de{" "}
                                <a href="https://www.mccormick.mx/producto/mermelada-de-pi%C3%B1a">
                                    Mermelada de Piña McCormick®
                                </a>{" "}
                                ó <b>Conserva Balance de Piña McCormick®</b>
                            </p>
                            <p>1 taza de azúcar glass</p>
                            <p>1 cucharada de agua</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Con la ayuda de una batidora eléctrica, bate en un tazón
                                    la mantequilla, aceite y azúcar.
                                </li>
                                <li>
                                    Agrega los huevos uno a uno, la esencia de vainilla y
                                    ralladura de naranja o limón.
                                </li>
                                <li>
                                    Tamiza o pasa por un colador la harina, el polvo de
                                    hornear y la sal.
                                </li>
                                <li>
                                    Agrega poco a poco las mezclas anteriores mientras
                                    continúas batiendo. Alterna con la leche. Una vez
                                    integrado todo, deberás tener una masa espesa pero de
                                    textura poco ligera.
                                </li>
                                <li>
                                    Engrasa y espolvorea con harina un molde cuadrado de unos
                                    30 X 20 cm aproximadamente. Vierte la mezcla y distribuye
                                    parejo.
                                </li>
                                <li>
                                    Precalienta el horno a 180°C, hornea unos 35 minutos.
                                    Recuerda, no abrir el horno hasta pasado este tiempo.
                                    Inserta un palillo y si sale limpio es que tu panqué ya
                                    está cocido. Retira del horno, déjalo enfriar unas 2
                                    horas.
                                </li>
                                <li>
                                    Corta los discos del mismo tamaño y en cada panquecito,
                                    coloca una cucharada generosa de{" "}
                                    <b>Mermelada de Piña McCormick®</b>, coloca otro
                                    panquecito encima para formar un pastel.
                                </li>
                                <li>
                                    Mezcla el azúcar glass con la cucharada de agua para
                                    obtener una textura ligera. Baña con un poco de este
                                    glaseado en cada pastelito. Decora al gusto.
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes rellenar con cualquier otro sabor de{" "}
                                    <b>Mermelada o conserva Balance McCormick®</b> y el
                                    glaseado lo puedes pintar con una gotita de color azul
                                    vegetal o rosa. También te invitamos a probar decorando
                                    con trocitos de fruta como duraznos, fresas o chispas de
                                    colores. Los niños pueden ayudarte a preparar estos
                                    pastelitos y se divertirán. Si no tienes batidora
                                    eléctrica puedes batir en un tazón con una pala de madera.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr28,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Monstruos Traviesos de Mousse de Mermelada de Mango
                                McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Material:</b> 4 vasos de plástico de unos 120 ml
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>
                                150 gr de galletas de chocolate de tu preferencia quebradas
                                en trozos regulares
                            </p>
                            <p>Jarabe de chocolate al gusto</p>
                            <p>500 gr de crema entera para batir sin azúcar</p>
                            <p>
                                1 taza de{" "}
                                <a href="https://www.mccormick.mx/producto/mermelada-de-mango">
                                    Mermelada de Mango McCormick®
                                </a>{" "}
                                o <b>Conserva McCormick® de Mango</b>
                            </p>
                            <p>4 malvaviscos blancos medianos partidos por la mitad </p>
                            <p>
                                4 blueberries partidos por la mitad o lunetas de chocolate{" "}
                            </p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Bate la crema con una batidora eléctrica hasta que doble
                                    su volumen. Cuando veas que se hacen picos, ya estará
                                    lista.
                                </li>
                                <li>
                                    En un tazón mezcla la <b>Mermelada de Mango McCormick®</b>{" "}
                                    con una cuarta parte de la crema, mueve de forma
                                    envolvente. Agrega el resto de la crema hasta que se
                                    integren muy bien.
                                </li>
                                <li>
                                    Coloca en el fondo de cada vaso una porción del mousse,
                                    encima coloca las galletas, baña con un poco del jarabe de
                                    chocolate y termina con otra capa de mousse de mango.
                                    Repite la operación hasta terminar los 4 vasos.
                                </li>
                                <li>
                                    Para los ojos, unta con un poco de mermelada cada mitad de
                                    malvavisco y pega el ojito con blueberries o lunetas.
                                </li>
                                <li>
                                    Coloca las 2 mitades de malvaviscos en cada vaso para que
                                    se vean los ojos.
                                </li>
                                <li>Refrigera por 2 horas y disfruta.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes hacer este postre con cualquier sabor de{" "}
                                    <b>Mermelada McCormick®</b>, también puedes usar galletas
                                    de vainilla.{" "}
                                </li>
                                <li>
                                    Para que la crema de batir quede firme, recuerda
                                    mantenerla en refrigeración.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr27,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Bisquets Caseros con Mermelada McCormick® con Frutos Rojos
                            </h3>
                            <p>
                                <b>Porciones: 8</b>
                            </p>
                            <p>
                                <b>Material:</b> 1 cortador redondo de 5 o 6 cm de diámetro
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>
                                450 gr de harina + 100 gr para manejar la masa y enharinar
                                el rodillo de panadería
                            </p>
                            <p>1 cucharadita de bicarbonato de sodio</p>
                            <p>1/2 de cucharadita de polvo de hornear</p>
                            <p>¼ de cucharadita de sal</p>
                            <p>3 cucharadas de azúcar </p>
                            <p>90 gr de mantequilla fría cortada en cubos</p>
                            <p>70 gr de arándanos deshidratados</p>
                            <p>
                                200 ml de leche evaporada mezclada con una cucharada de jugo
                                de limón
                            </p>
                            <p>
                                1 huevo batido (reserva la mitad para barnizar al final)
                            </p>
                            <p>200 gr de queso crema </p>
                            <p>
                                1 taza de{" "}
                                <b>
                                    Mermelada McCormick® de Frutos Rojos o Conserva Balance de
                                    Frutos Rojos McCormick®
                                </b>
                            </p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    En un tazón, combina la harina con el bicarbonato, el
                                    polvo de hornear y la sal.
                                </li>
                                <li>
                                    Agrega la mantequilla en cubos y amasa un poco para formar
                                    una masa arenosa pero que conserve grumos de mantequilla.
                                </li>
                                <li>
                                    Incorpora la mitad del huevo batido, los arándanos y la
                                    leche poco a poco. Amasa un poco más para que se integren.
                                    Tu masa deberá estar suave y manejable, si la sientes
                                    pegajosa, unta tus manos con más harina.
                                </li>
                                <li>
                                    Mete al congelador la masa unos 15 minutos, saca la masa y
                                    estírala con un rodillo de panadería en una superficie
                                    plana y enharinada. Dobla la masa por la mitad y vuelve a
                                    congelar otros 15 minutos.
                                </li>
                                <li>Extiende nuevamente la masa de un grosor de 2 cm. </li>
                                <li>
                                    Con la ayuda del cortador, corta discos de masa y
                                    colócalos en una charola forrada con papel para hornear.
                                </li>
                                <li>
                                    Barniza con el resto del huevo batido la parte de encima
                                    de cada bisquet y déjalos reposar 1 hora para que doblen
                                    su volumen y la masa descanse.
                                </li>
                                <li>Precalienta el horno a 180°C</li>
                                <li>
                                    Hornéalos por 15 a 18 minutos o hasta que estén dorados.
                                </li>
                                <li>Retíralos del horno y déjalos enfriar.</li>
                                <li>
                                    Córtalos por la mitad y rellénalos de queso crema y encima
                                    agrega una porción generosa de{" "}
                                    <b>Mermelada McCormick® de Frutos Rojos.</b>
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Cuando prepares una masa para pan, es mejor que te falte
                                    líquido y le agregues de a cucharadas agua o leche, de lo
                                    contrario se perderán las cantidades que ya habías medido.
                                </li>
                                <li>
                                    Los niños pueden ayudarte a amasar ya que ayuda mucho a la
                                    coordinación motriz de sus manos. Puedes rellenar estos
                                    bísquets con fresas o rebanadas de plátano y la mermelada
                                    McCormick® del sabor que le guste más a los niños y
                                    llevarlos de lunch o como merienda de la tarde.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
        },
        {
            nombre: "aderezos-mayonesa-pescados-mariscos",
            titulo: "Aderezos con mayonesa para acompañar pescados y mariscos",
            imagen: recetasimg59,
            descripcion: (
                <div>
                    Con el calor que se vive en gran parte del país y sobre todo en
                    época de <b>Cuaresma</b>, ¿a quién no se le antoja un ceviche de
                    pescado, un aguachile verde o el clásico cóctel de camarón para
                    refrescarse en la comida? Obviamente con el toque de sabor que el{" "}
                    <b>Aderezo de Mayonesa McCormick®</b> les pone a nuestros
                    platillos favoritos.
                    <br />
                    <br />
                    <h2>¿Cuál es el mejor aderezo para pescados y mariscos? </h2>
                    <br />Y es que si nos ponemos a pensar, toda la{" "}
                    <b>comida del mar</b> es riquísima por sí sola, pero al
                    acompañarla con algún dip, aderezo, salsa o ensalada, hacemos que
                    su sabor resalte todavía más. Piénsalo un momento, un taquito de
                    pescado de entrada ya se nos hace agua la boca, pero si le agregas
                    una cucharadita de{" "}
                    <a href="https://www.mccormick.mx/producto/aderezo-de-mayonesa">
                        Aderezo de Mayonesa McCormick®
                    </a>
                    , ¿a poco no sabe mejor?
                    <br />
                    <br />
                    Así es nuestro aderezo, le pone lo sabroso a la comida que más nos
                    gusta de esta temporada. Su textura y cremosidad realzan el sabor
                    de los alimentos para que los disfrutemos al máximo.
                    <br />
                    <br />
                    <h2>
                        Consejos para disfrutar de los mejores pescados y mariscos
                    </h2>
                    <br />
                    En la <b>Cuaresma</b> nos enfrentamos a la creencia de que
                    consumir buenos <b>pescados y mariscos</b> es caro, pero hay gran
                    variedad de opciones accesibles. Acércate con tu proveedor de
                    productos marinos y pregunta por: carpa, lisa o mújol, mojarra
                    (tilapia chica y grande), corvina, jurel, almejas, gurrubata o
                    bagre y maximiza sus sabores con nuestro aderezo de mayonesa
                    McCormick® al cocinarlos.
                    <br />
                    <br />
                    Hagamos de esta temporada un momento delicioso y sano porque
                    recuerda que muchos pescados y mariscos son grandes fuentes de
                    proteínas, vitaminas, minerales y ácidos grasos buenos como el
                    Omega 3.
                    <br />
                    <br />A continuación, 2 recetas sencillas y muy ricas utilizando{" "}
                    <b>Aderezo de Mayonesa McCormick®</b> para que disfrutes la
                    Cuaresma como más nos gusta: comiendo las delicias que el mar
                    tiene para nosotros.
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr29,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Sopes de Camarón Enchilado al Estilo Nayarit con Aderezo de
                                Mayonesa McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>250 g de masa de maíz</p>
                            <p>2 cucharadas de salsa de chile chipotle adobado</p>
                            <p>1 cucharada de Aderezo de Mayonesa McCormick® </p>
                            <p>½ taza de frijoles refritos bayos</p>
                            <p>2 cucharadas de aceite vegetal</p>
                            <p>250 g de camarón mediano pelado y desvenado</p>
                            <p>Para el aderezo:</p>
                            <p>1 limón (el jugo)</p>
                            <p>¼ de taza de Aderezo de Mayonesa McCormick®</p>
                            <p>
                                2 cucharadas de salsa de chile de árbol de botana de tu
                                preferencia
                            </p>
                            <p>
                                ¼ de taza de lechuga romanita cortada en tiras delgadas,
                                desinfectadas y bien escurridas
                            </p>
                            <p>2 cucharadas de queso tipo panela rallado fino</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Mezcla la masa de maíz con el chile chipotle y la
                                    cucharada de Aderezo de Mayonesa McCormick®, amasa muy
                                    bien para que se integre todo.
                                </li>
                                <li>
                                    Forma los sopes haciendo una tortilla de unos 8 cm
                                    aproximadamente y unos 2 cm de grosor. Cocínalos en un
                                    sartén o comal, retíralas del fuego y pellizca los bordes
                                    para formar el sope. Repite hasta terminar la masa.
                                </li>
                                <li>
                                    Calienta aceite en el mismo sartén y dora los sopes por
                                    ambos lados.
                                </li>
                                <li>
                                    En un tazón, combina jugo de limón, ¼ de taza de Aderezo
                                    de Mayonesa McCormick® y salsa de chile de árbol. Mezcla y
                                    añade los camarones.
                                </li>
                                <li>
                                    Calienta un sartén con una cucharada de aceite, cocina los
                                    camarones hasta que estén dorados.
                                </li>
                                <li>
                                    Unta cada sope con una cucharadita de frijol refrito,
                                    encima coloca los camarones, la lechuga y espolvorea con
                                    el queso.
                                </li>
                                <li>
                                    Puedes añadir un hilo de Aderezo de Mayonesa McCormick® al
                                    final para dar ese toque cremoso y de sabor. Repite hasta
                                    terminar con todos los sopes.
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Cuando peles camarones, frota tus manos con jugo de limón
                                    para que no te quede la típica sensación pegajosa.
                                </li>
                                <li>
                                    Si no tienes masa de maíz, sustituye por harina de maíz.
                                    Solo tienes que hidratarla con poca agua fría según las
                                    instrucciones del producto.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr30,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Tacos de Pescado y Aderezo Verde de Mayonesa McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>
                                2 filetes de pescado cortados en mitades o tiras a lo largo
                            </p>
                            <p>
                                Sal y{" "}
                                <a href="https://www.mccormick.mx/producto/pimienta-negra-molida">
                                    Pimienta Negra Molida McCormick®
                                </a>{" "}
                                al gusto
                            </p>
                            <p>1 clara de huevo batida</p>
                            <p>½ taza de harina de maíz</p>
                            <p>¼ de taza de aceite vegeta</p>
                            <p>4 tortillas de harina muy delgadas</p>
                            <p>2 limones cortados por la mitad</p>
                            <p>2 cucharadas de granos de elote amarillo</p>
                            <p>4 rebanadas de aguacate</p>

                            <p>Para el aderezo:</p>
                            <p>½ taza de Aderezo de Mayonesa McCormick® </p>
                            <p>
                                ½ taza de pepino sin semillas cortado en cubitos (reserva la
                                mitad para guarnición)
                            </p>
                            <p>2 cucharadas de hojas de cilantro desinfectadas</p>
                            <p>1 chile serrano sin semillas cortado en trozos</p>
                            <p>2 tomates verdes cortados en trozos</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>Espolvorea sal y pimienta al pescado.</li>
                                <li>
                                    Pasa cada tira de pescado por la clara de huevo,
                                    empanízalos ligeramente en la harina de maíz y presiona
                                    bien para que se adhiera.
                                </li>
                                <li>
                                    Calienta el aceite y fríelos hasta que estén dorados por
                                    ambos lados. Escúrrelos muy bien en papel absorbente.
                                </li>
                                <li>Para el aderezo:</li>
                                <li>
                                    Coloca en el vaso de la licuadora el Aderezo de Mayonesa
                                    McCormick®, la mitad del pepino, cilantro, chile y tomate
                                    verde, muele muy bien hasta obtener una salsa. Si queda
                                    muy espesa puedes añadir una cucharada de agua purificada
                                    fría y así deberá quedar bien molido.
                                </li>
                                <li>
                                    Calienta tus tortillas de harina, coloca una tira de
                                    pescado en cada una, baña con el aderezo verde de Mayonesa
                                    McCormick®, agrega a cada taco cubitos de pepino, granos
                                    de elote y una rebanada de aguacate. Acompaña con limón.
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes sustituir el pescado por camarones medianos pelados
                                    y sin venas.
                                </li>
                                <li>
                                    Compra siempre el pescado de temporada, estará más fresco
                                    y a mejor precio, si no consumes pescados puedes hacer
                                    estos tacos veganos con tiras de calabacita o berenjena
                                    asadas.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
        },
        {
            nombre: "regalos-te-maestro",
            titulo: "Regalos con té para sacar un 10 con tu maestro",
            imagen: recetasimg60,
            descripcion: (
                <div>
                    En este mes de mayo festejamos a las madres y a nuestros mentores
                    en la escuela. Y qué mejor que sorprenderlos, además de con
                    deliciosas comidas y fiestas, con <b>té</b>. <br />
                    <br />
                    <h2>¿Cuál es el mejor sabor de té para regalar?</h2>
                    <br />
                    El té puede ser una alternativa para hidratarse, pero con muchas
                    más opciones y variedad de sabores y olores. El{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-verde">
                        té verde
                    </a>{" "}
                    es uno de los favoritos por su excelente sabor y es un{" "}
                    <b>antioxidante</b> que ayuda a retardar los signos de
                    envejecimiento, es un <b>excelente digestivo</b> y ayuda a bajar
                    de peso. El{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-negro">
                        té negro
                    </a>{" "}
                    y el{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-rojo">
                        té rojo
                    </a>
                    , que son los más conocidos, tienen un sabor muy intenso, y además
                    cuentan con propiedades para <b>quemar grasa</b> y{" "}
                    <b>reducir el colesterol</b>. Todos los tipos de té tienen muchos
                    beneficios para la salud y son una aventura para el paladar.
                    <br />
                    <br />
                    Las <b>infusiones</b>, que también llamamos tés, tienen
                    propiedades medicinales, como la{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-de-manzanilla">
                        manzanilla
                    </a>{" "}
                    que es muy buena para tratar problemas del{" "}
                    <b>sistema digestivo y respiratorio</b> , para limpiar heridas y
                    para los ojos, entre otros beneficios. Por ejemplo, el{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-de-lim%C3%B3n">
                        té de limón
                    </a>{" "}
                    es excelente para tratar enfermedades respiratorias y estimula el{" "}
                    <b>sistema inmunológico</b>; el de{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-de-canela-a-la-manzana">
                        canela
                    </a>{" "}
                    es buenísimo para el sistema digestivo y permite{" "}
                    <b>controlar la glucosa</b> en la sangre; el té de menta se
                    utiliza para eliminar el mal aliento y los gases; y el famoso{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-de-tila">
                        té de tila
                    </a>{" "}
                    ayuda a dormir por sus propiedades relajantes.
                    <br />
                    <br />
                    <h2>Combina los tés con otras especias</h2>
                    <br />
                    Estas fechas son muy oportunas para recomendar o regalar tés a las
                    personas más queridas, para que se sientan halagadas porque nos
                    preocupamos por ellas y así desearles plena salud y bienestar.
                    Además, la mezcla con otras hojas, nos ayudan a preparar{" "}
                    <b>infusiones frías o calientes</b>, elaboradas con combinaciones
                    de tés que nos hacen degustar de diversas bebidas de sabores, como
                    por ejemplo el{" "}
                    <a href="https://www.mccormick.mx/producto/t%C3%A9-de-jengibre---lim%C3%B3n">
                        té de jengibre
                    </a>{" "}
                    con cúrcuma. Una mezcla prodigiosa como lo es el{" "}
                    <a href="https://www.mccormick.mx/producto/chai">té chai:</a>a con
                    té negro, canela, cardamomo, jengibre, clavo, pimienta negra y
                    nuez moscada; esta incorporación de especias, le confiere
                    propiedades para adelgazar, antinflamatorias y analgésicas, pero
                    sobre todo <b>mejora la digestión</b>, ayuda a eliminar
                    flatulencias, diarreas y dolores de estómago.
                    <br />
                    <br />
                    <h2>Dile lo que sientes con un té</h2>
                    <br />
                    Nuestras madres son los seres más festejados y queridos en el
                    mundo, porque nos dan la vida, nos hacen sentir amados y mimados,
                    así que hagamos lo que ellas hacen, preocupémonos por la salud y
                    el bienestar, disfrutando el consumo de la enorme variedad de
                    sabores y olores de{" "}
                    <a href="https://www.mccormick.mx/productos/tes-sobres">
                        tés
                    </a>{" "}
                    disponibles. Y lo mejor de todo, es que el mundo del té permite
                    una gran versatilidad en las preparaciones. No sólo se trata de
                    una taza con agua caliente y hierbas, se puede experimentar con
                    bebidas mucho más elaboradas y mezclas de distintos tés, hasta
                    llevarlo a la preparación de platillos en la cocina, mixología y
                    repostería. Realmente la imaginación es el límite.
                    <br />
                    <br />
                    <h2>Mejora tu salud un té al día</h2>
                    <br />
                    Es muy importante fomentar la reducción del consumo de bebidas con
                    altos contenidos de azúcar y gas, a las que somos muy afectos los
                    mexicanos, así que, se pueden tomar decisiones saludables sin
                    perder el sabor en estas temporadas de calor: refresquémonos mejor
                    con un delicioso <b>té helado</b>. Fomentar el consumo diario de
                    esta maravillosa bebida, muy común en los países orientales y que
                    poco a poco ha ido ganando terreno en occidente, tiene muchos
                    beneficios, pues además de hidratar, aporta a nuestros cuerpos
                    antioxidantes y minerales.
                    <br />
                    <br />
                    Te dejamos algunas recetas para que puedas disfrutar de esta
                    deliciosa y versátil bebida.
                    <br />
                    <br />
                </div>
            ),
            recetas: [
                {
                    imagen: imgbnr31,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Limonada de Fresa con Té de Limón McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>3 tazas de agua hirviendo</p>
                            <p>
                                4 sobres de <b>Té de Limón McCormick®</b>
                            </p>
                            <p>
                                1 cucharada de <b>Aderezo de Mayonesa McCormick®</b>
                            </p>
                            <p>
                                2 cucharadas de{" "}
                                <a href="https://www.mielcarlota.com.mx/">
                                    Miel de abeja Carlota®
                                </a>
                            </p>
                            <p>2 limones (el jugo sin semilla)</p>
                            <p>2 fresas frescas o congeladas desinfectadas</p>
                            <p>1 ½ taza de cubos de hielo</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    En una olla pequeña coloca agua, los sobres de Té
                                    McCormick®, la Miel Carlota® y jugo de limón. Cocina hasta
                                    que suelte el hervor unos 2 minutos, desecha las bolsitas
                                    de té,y deja enfriar.
                                </li>
                                <li>
                                    Muele la infusión de té con las fresas, cuela la
                                    preparación, sirve en cuatro vasos con hielos.
                                </li>
                                <li>
                                    Decora con hojas de menta fresca o albahaca y algunas
                                    fresas cortadas en cuadritos.
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes comprar un vaso vintage de cristal con tapa o
                                    térmico y regalárselo a mamá el 10 de mayo, con varios
                                    sobrecitos de Té McCormick de diferentes sabores.{" "}
                                </li>
                                <li>
                                    Puedes infusionar el té y mezclar con la miel y alguna
                                    fruta como zarzamora, frambuesa, duraznos o piña. Cuela
                                    esta preparación y cuando se te antoje, solamente debes
                                    añadir agua mineral para tener una soda tipo italiana
                                    saludable y fresca para el verano.
                                </li>
                                <li>
                                    También puedes añadir muchas especias a la hora que
                                    agregas el té en agua caliente para que se infusionen muy
                                    bien los sabores. Prueba con: canela, cardamomo, jengibre
                                    en polvo, cúrcuma o un toque de chile piquín o pimienta de
                                    cayena.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr32,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Tazón de Desayuno con Té Matcha McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>4 cucharaditas rasas de Té Matcha McCormick®</p>
                            <p>1 taza de cubos de hielo</p>
                            <p>1 plátano tipo Tabasco grande, congelado</p>
                            <p>2 tazas de yogurt griego sin azúcar natural</p>
                            <p>¼ de taza de leche de coco sin azúcar</p>
                            <p>¼ de taza de moras azules</p>
                            <p>¼ de taza de frambuesas o fresas</p>
                            <p>
                                ½ taza de granola con nueces o almendras de tu preferencia
                            </p>
                            <p>1 cucharadita de Canela Molida McCormick®</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    Coloca en la licuadora el hielo, plátano, yogurt, leche de
                                    coco y <b>Té Matcha McCormick®</b>. Muele muy bien, deberá
                                    quedar una textura espesa pero manejable.
                                </li>
                                <li>Distribuye la preparación en 4 tazones.</li>
                                <li>
                                    Decora encima con la fruta y espolvorea con Canela Molida
                                    McCormick®.{" "}
                                </li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes añadir cualquier otra fruta de temporada: duraznos,
                                    chabacanos, ciruelas rojas o amarillas, hojitas de menta o
                                    cubitos de pepino.
                                </li>
                                <li>
                                    Si te gusta el chocolate, añade 2 cucharadas de cocoa a la
                                    hora de moler el té matcha y ½ aguacate, obtendrás así
                                    mucho sabor y cremosidad en tus licuados.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    imagen: imgbnr33,
                    texto: (
                        <div className="contain-recetas">
                            <h3 style={{ marginBottom: "40px" }}>
                                Sangría de Té de Jamaica y Jengibre McCormick®
                            </h3>
                            <p>
                                <b>Porciones: 4</b>
                            </p>
                            <p>
                                <b>Ingredientes:</b>
                            </p>
                            <p>4 tazas de agua </p>
                            <p>
                                4 sobres de{" "}
                                <a href="https://www.mccormick.mx/producto/t%C3%A9-de-jamaica">
                                    Té de Jamaica McCormick®
                                </a>
                            </p>
                            <p>1 cucharadita de Canela Molida McCormick®</p>
                            <p>1 cucharadita de Jengibre en Polvo McCormick®</p>
                            <p>2 cucharadas de Miel de abeja Carlota®</p>
                            <p>1 limón (el jugo sin semillas)</p>
                            <p>1 cucharadita de semillas de chía</p>
                            <p>1 taza de cubitos de melón amarillo o verde</p>
                            <p>2 tazas de cubos de hielo</p>
                            <p>
                                <b>Procedimiento:</b>
                            </p>
                            <ul>
                                <li>
                                    En una olla coloca el agua, el{" "}
                                    <b>Té de Jamaica McCormick®</b>, canela y jengibre en
                                    polvo McCormick®, calienta hasta que hierva durante 5
                                    minutos.
                                </li>
                                <li>
                                    Retira del fuego, deja enfriar muy bien, desecha las
                                    bolsitas de té y mezcla con la Miel de abeja Carlota®,
                                    semillas de chía, cubitos de melón y hielo.
                                </li>
                                <li>Distribuye en 4 vasos y sirve bien frío.</li>
                            </ul>
                            <p>
                                <b>Tip:</b>
                            </p>
                            <ul>
                                <li>
                                    Puedes regalar una cajita con alguna bebida preparada de{" "}
                                    <b>Té McCormick®</b>, y colocar fruta, algún sándwich o
                                    ensalada, con varios sobres de <b>Té McCormick®</b> de
                                    sabores. Harás la diferencia y regalaras algo saludable y
                                    original.{" "}
                                </li>
                                <li>
                                    Si quieres preparar una tisana de frutas, solamente añade
                                    algunos pedacitos de fruta deshidratada o fresca como
                                    zarzamora, frambuesa, cubitos de manzana o durazno.
                                </li>
                            </ul>
                        </div>
                    ),
                },
            ],
        },
    ],
};

export default Dummy;
