const OBTENER_RECETAS_POR_CATEGORIA = "OBTENER_RECETAS_POR_CATEGORIA";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_RECETAS_POR_CATEGORIA:
            return { ...state, categoriaRecetas: action.categoriaRecetas }

        default:
            return state;
    }

}

export default reducer;