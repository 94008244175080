const OBTENER_PRODUCTOS_RELACIONADOS_CON_PRODUCTOS = "OBTENER_PRODUCTOS_RELACIONADOS_CON_PRODUCTOS";



let initialState = {recetas:[]};
const reducer = (state = initialState, action) => {

    switch (action.type) {

        case OBTENER_PRODUCTOS_RELACIONADOS_CON_PRODUCTOS:
            return { ...state, productos: action.productos }

        default:
            return state;
    }

}

export default reducer;