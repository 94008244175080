import React, { PureComponent } from 'react'
import axios from 'axios'
import Swiper from 'react-id-swiper'
import Fade from 'react-reveal/Fade'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import { connect } from 'react-redux'
class TePuedeInteresarMobile extends PureComponent {
    constructor() {
        super()
        this.state = {
            categoriasRelacionadas: []
        }
    }
    componentDidMount() {
        this.LoadRecetas()
    }

    LoadRecetas = async () => {

        const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/tags?fields=*.*,recetas.recetas_id.*&filter[nombre]=Te puede interesar`);
        const  dataPublic = this.props.data?this.props.data.filter((e)=>{return(e.estatus ==="published")}):null
        const recPublic = categoriasRelacionadas.data.data[0].recetas.filter((e)=>{return(e.recetas_id.estatus ==="published")})
        this.setState({
            categoriasRelacionadas: this.props.data?this.props.data.length > 0 ? dataPublic : recPublic.map((e) => { return (e.recetas_id) }): recPublic.map((e) => { return (e.recetas_id) })
        })
    }
    render() {
        const paramsRecetasplatillo = {
            slidesPerView: 1,
            spaceBetween: "-4%",
            loop: false,
        }
        return (
            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", marginTop: "30px" }}>
                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                        <div style={{ width: "100%", textAlign: "center", marginTop: "35px", marginBottom: "15px" }} >
                            <label style={{ fontSize: "28px", marginTop: "15px" }} className="CocogooseProRegular">TE PUEDE INTERESAR</label><br />
                        </div>

                        {(() => {
                            if (this.state.categoriasRelacionadas.length > 0) {
                                const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                                    return (
                                        <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.url}`) }} className="contain-swip" >
                                            <img style={{ width: "93%", height: "auto", marginLeft: "10px" }} src={process.env.REACT_APP_CMS_ASSETS + element.imagen} />
                                            <div style={{ padding: "20px", fontFamily: "Avenir-Medium", paddingBottom: "0px" }}>
                                                <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                                                <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
                                                <br /> <br />
                                                {/*<div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                                        <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                                                    </div>*/}
                                            </div>
                                        </div>
                                    )
                                })
                                return (
                                    <div style={{ width: "inherit" }} className="swiper-recetas-p">
                                        <Swiper {...paramsRecetasplatillo}>
                                            {recetas}
                                        </Swiper>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
            </>
        )
    }
}


export default (TePuedeInteresarMobile)