import { type as GetRecipesFavorites } from '../actions/GetRecipesFavorites';
import React from 'react'
import axios from 'axios'
import recetas from '../data/recetas'



const defaultState = [
   
];

function reducer(state = defaultState, { type }) {
    switch (type) {
        case GetRecipesFavorites: {
          
          
            axios.get(process.env.REACT_APP_API_URL + '<URL api>')
            .then(res => {
               return res.data
            })
            .catch(error => {
            });
      
            return recetas.filter((element,index)=>{
                return element.id <= 3
            });
        }

        default:
              return recetas.filter((element,index)=>{
                return element.id <=2
            });;
    }
}

export default reducer;
